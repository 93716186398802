<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="'Confirm updating'" :header-content="'Are you sure you want to continue?'" />
    <div class="modal-footer delete-item">
      <button class="button nice" @click="onConfirm">
        OK
      </button>
      <button class="button clear" @click="onClose">
        Cancel
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    onClose: Function,
    onConfirm: Function,
  },
};
</script>
