var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row app-wrapper"},[_c('sidebar',{class:_vm.sidebarClassName,attrs:{"route":_vm.currentRoute}}),_vm._v(" "),_c('section',{staticClass:"app-content columns",class:_vm.contentClassName},[_c('router-view'),_vm._v(" "),_c('command-bar')],1),_vm._v(" "),_c('TheSIPEndpoint'),_vm._v(" "),_c('MissedCallMenu'),_vm._v(" "),_c('TheRelaySIPEndpoint'),_vm._v(" "),_c('div',{staticClass:"flex row hidden-iframe"},[_c('div',{staticClass:"columns medium-12"},[_c('pulse-loader',{attrs:{"loading":true,"color":_vm.color}}),_vm._v(" "),_c('iframe',{key:_vm.iframeKey,staticStyle:{"height":"calc(100vh - 66px)","border":"none"},attrs:{"src":_vm.generateDigitalSign,"allow":"clipboard-read; clipboard-write","sandbox":"allow-scripts allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation","width":"100%","height":"80%"}})],1)]),_vm._v(" "),(
      _vm.all_limits.account_user &&
        _vm.all_limits.account_user.popup &&
        _vm.user_role.role === 'administrator'
    )?_c('div',[_c('woot-modal',{staticClass:"account-selector--modal",attrs:{"show":_vm.showLimitModel,"on-close":_vm.closeDeletePopup}},[_c('div',{staticClass:"column content-box"},[_c('woot-modal-header',{attrs:{"header-title":'Credit issue',"header-content":'Please pay for services or remove extra agents and inboxes'}}),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"medium-8 columns medium-offset-1"},[_c('label',{class:{ error: _vm.$v.selectedAgents.$error }},[_c('multiselect',{attrs:{"options":this.agent_list,"track-by":"id","label":"name","multiple":true,"close-on-select":false,"open-direction":"bottom","clear-on-select":false,"hide-selected":true,"selected-label":"","min":_vm.agentSubtract,"select-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_SELECT'),"deselect-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_REMOVE'),"placeholder":'Pick agents for remove'},on:{"select":_vm.$v.selectedAgents.$touch},model:{value:(_vm.selectedAgents),callback:function ($$v) {_vm.selectedAgents=$$v},expression:"selectedAgents"}}),_vm._v(" "),(_vm.$v.selectedAgents.$error)?_c('span',{staticClass:"message"},[_vm._v("\n                "+_vm._s(_vm.$t('FORMS.MULTISELECT.AGENT_REMOVE_VALIDATION_ERROR', {
                    min_agent: _vm.agentSubtract,
                  }))+"\n              ")]):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"medium-8 columns medium-offset-1"},[_c('label',{class:{ error: _vm.$v.selectedInboxes.$error }},[_c('multiselect',{attrs:{"options":this.inbox_list,"track-by":"id","label":"name","multiple":true,"close-on-select":false,"clear-on-select":false,"hide-selected":true,"selected-label":"","open-direction":"bottom","min":_vm.inboxSubtract,"select-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_SELECT'),"deselect-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_REMOVE'),"placeholder":'Pick inboxes for remove'},on:{"select":_vm.$v.selectedInboxes.$touch},model:{value:(_vm.selectedInboxes),callback:function ($$v) {_vm.selectedInboxes=$$v},expression:"selectedInboxes"}}),_vm._v(" "),(_vm.$v.selectedInboxes.$error)?_c('span',{staticClass:"message"},[_vm._v("\n                "+_vm._s(_vm.$t('FORMS.MULTISELECT.INBOX_REMOVE_VALIDATION_ERROR', {
                    min_inbox: _vm.inboxSubtract,
                  }))+"\n              ")]):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"medium-4 medium-offset-1 columns"},[_c('p',[_vm._v("\n              Remove minimum agents:\n              "+_vm._s(_vm.agentSubtract > 0 ? _vm.agentSubtract : 0)+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              Remove minimum inboxes:\n              "+_vm._s(_vm.inboxSubtract > 0 ? _vm.inboxSubtract : 0)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"medium-4 columns text-right"},[_c('woot-button',{attrs:{"is-disabled":false},on:{"click":_vm.confirmDeletion}},[_vm._v("\n              "+_vm._s('Remove')+"\n            ")])],1)])],1)])],1):(
      _vm.all_limits.account_user &&
        _vm.all_limits.account_user.popup &&
        _vm.user_role.role !== 'administrator'
    )?_c('div',[_c('woot-modal',{staticClass:"account-selector--modal-agent",attrs:{"show":_vm.showLimitModel,"on-close":_vm.closeDeletePopup}},[_c('div',{staticClass:"column content-box"},[_c('woot-modal-header',{attrs:{"header-title":'Credit issue',"header-content":'Contact with admin to pay for extra services.'}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }