<template>
  <woot-modal :show.sync="show" :on-close="onCancel">

    <div class="column content-box">
      <woot-modal-header
          :header-title="
          `${$t('CONTACT_PAYMENT.TITLE')} - ${contact.name || contact.email}`
        "
          :header-content="$t('CONTACT_PAYMENT.DESC')"
      />
      <form class="create-transaction--form" @submit.prevent="onSubmit">
        <div class="row">
          <div class="medium-12 columns">
<!--            <label :class="{ error: $v.reason.$error }">-->
            {{ $t('CONTACT_PAYMENT.FORM.REASON.LABEL') }}
              <textarea
                  v-model="reason"
                  :label="$t('CONTACT_PAYMENT.FORM.REASON.LABEL')"
                  :placeholder="$t('CONTACT_PAYMENT.FORM.REASON.PLACEHOLDER')"
              />
<!--            </label>-->
          </div>
        </div>
        <div class="medium-12 columns">
<!--          <label :class="{ error: $v.amount.$error }">-->
          {{ $t('CONTACT_PAYMENT.FORM.AMOUNT.LABEL') }}
            <input
                v-model="amount"
                class="medium-12 columns"
                type="number" step="0.01"
                :label="$t('CONTACT_PAYMENT.FORM.AMOUNT.LABEL')"
                :placeholder="$t('CONTACT_PAYMENT.FORM.AMOUNT.PLACEHOLDER')"
            />
<!--          </label>-->
        </div>
<!--        <div class="row">-->
<!--          <input-->
<!--              v-model="invoice"-->
<!--              class="medium-12 columns"-->
<!--              type="number" step="0.01"-->
<!--              :label="$t('CONTACT_PAYMENT.FORM.INVOICE.LABEL')"-->
<!--              :placeholder="$t('CONTACT_PAYMENT.FORM.INVOICE.PLACEHOLDER')"-->
<!--          />-->
<!--        </div>-->
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
                :loading="uiFlags.isUpdating"
                :button-text="$t('CONTACT_PAYMENT.FORM.SUBMIT')"
            />
            <button class="button clear" @click.prevent="onCancel">
              {{ $t('CONTACT_PAYMENT.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>

</template>

<script>
import {mapGetters} from "vuex";
import alertMixin from "../../../../../shared/mixins/alertMixin";
import { DuplicateContactException} from "../../../../../shared/helpers/CustomErrors";
import {required} from "vuelidate/lib/validators";

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },

    conversationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      amount: '',
      invoice: '',
      reason: '',
    }
  },
  validations: {
    amount: {
      required,
    },
    reason: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },
  watch: {
    transaction() {
      this.setContactObject();
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },

    getTransactionObject() {
      return {
        contact_id: this.contact.id,
        conversation_id: this.conversationId,
        reason: this.reason,
        amount: this.amount,
        invoice: this.invoice
      };
    },

    async onSubmit() {
      try {
        await this.$store.dispatch('createTransaction', this.getTransactionObject());
        this.showAlert(this.$t('CONTACT_PAYMENT.SUCCESS_MESSAGE'));
        this.$emit('cancel');
      } catch (error) {
        console.log(error)
          this.showAlert(this.$t('CONTACT_PAYMENT.ERROR_MESSAGE'));
      }
    },
  }
}
</script>

<style scoped>
.create-transaction--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

.columns {
  padding: 0 var(--space-smaller);
}
}

</style>
