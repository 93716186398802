const numberTypes =  {
    LOCAL: {label: "Local", code: "local"},
    TOLL_FREE: {label: "Toll Free", code: "toll-free"}
}

export default numberTypes;

export const numberTypesArray = [
    numberTypes.LOCAL,
    numberTypes.TOLL_FREE
];
