/* global axios */
import ApiClient from './ApiClient';

class Inboxes extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  getAssignableAgents(inboxId) {
    return axios.get(`${this.url}/${inboxId}/assignable_agents`);
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  deleteInboxAvatar(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/avatar`);
  }

    get_channel_limit() {
        return axios.get(`${this.url}/channel_limits.json`);
    }

    stripeConnect(){
        return axios.get(`${this.url}/stripe`);
    }

    get_user_credentials() {
        return axios.get(`${this.url}/user_credentials`);
    }

    TeamChannel({inboxId}) {
        return axios.post(`${this.url}/${inboxId}/create_team_inbox`);
    }

    create_FB_review_conversation(inboxId, data) {
        return axios.post(`${this.url}/${inboxId}/create_fb_review_conversation`, {
            inboxId,
            data
        });
    }

    createGoogleReview(params) {
        return axios.post(`${this.url}/create_google_review_channel`, params);
    }

    inboxSignature(inboxId ,params) {
        return axios.post(`${this.url}/${inboxId}/inbox_signature`, params, {
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
    }

    updateSignature(inboxId ,params) {
        return axios.put(`${this.url}/${inboxId}/toggle_signature`, params);
    }

    youtubeChannel(params) {
        return axios.post(`${this.url}/create_youtube_inbox`, params,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    }

    fetchGoogleReviewAccount() {
        return axios.get(`${this.url}/fetch_greview_account`);
    }

    fetchGoogleYoutubeAccount() {
        return axios.get(`${this.url}/fetch_gyoutube_account`);
    }
}

export default new Inboxes();
