export const isPhoneE164 = value => !!value.match(/^\+[1-9]\d{1,14}$/);
export const isPhoneE164OrEmpty = value => isPhoneE164(value) || value === '';
export const shouldBeUrl = (value = '') =>
  value ? value.startsWith('http') : true;
export function validatePhoneForE164(phoneNumber) {
    if(phoneNumber.length === 2 && phoneNumber.substring(0, 1) === '+' && !isNaN(phoneNumber%10)){
        return true;
    }
    else if(phoneNumber.length === 1 && phoneNumber.substring(0, 1) === '+' ) {
        return true;
    }
    else {
        return !!phoneNumber.match((/^\+[1-9]\d{1,14}$/)) || phoneNumber === '';
    }
}
