import SettingsContent from '../Wrapper.vue';
import { frontendURL } from '../../../../helper/URLHelper';
import Index from '../ai_agents/Index.vue';
import Settings from './Settings';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/ai_agents'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'ai_agent_list';
        return {
          headerTitle: 'AGENT_MGMT.AI_AGENT.VIEW_TRACKS.HEADER',
          icon: 'ion-person-stalker',
          newButtonRoutes: ['ai_agent_list'],
          showBackButton,
          showNewButton: false,
        };
      },
      children: [
        {
          path: '',
          name: 'ai_agents_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'ai_agent_list',
          component: Index,
          roles: ['administrator'],
        },
        {
          path: ':agentId',
          name: 'settings_agent_show',
          component: Settings,
          roles: ['administrator','agent'],
        },
      ],
    },
  ],
};
