<template>
  <woot-modal
    :show.sync="show"
    :on-close="onClose"
    modal-type="right-aligned"
    :close-on-backdrop-click="false"
  >
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.AI_AGENT.EDIT_HEADING')"
        :header-content="$t('AGENT_MGMT.AI_AGENT.EDIT_SUB_HEADING')"
      />
      <edit-agent-form
        :in-progress="uiFlags.isUpdating"
        :ai-agent="aiAgent"
        @success="onSuccess"
        @cancel="onClose"
      />
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import EditAgentForm from './EditAgentForm.vue';

export default {
  components: { EditAgentForm },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    aiAgent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      inboxesList: 'inboxes/getInboxes',
    }),
  },
  async mounted() {
    await this.$store.dispatch('inboxes/get');
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAgent() {
      const selectedInboxes = this.inboxes?.map(x => x.id);
      try {
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType,
          limits: this.agentConversationLimits,
          inboxes: selectedInboxes,
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        if (error.response.status === 422) {
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE'));
        } else {
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
        }
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
  },
};
</script>
<style scoped>
.modal-container--full-width {
  width: 100rem !important;
}

.multiselect__content-wrapper {
  max-height: 300px !important;
}

.modal-container {
  min-width: 100rem !important;
}

.modal-mask.right-aligned .modal-container {
  width: 100rem !important;
}

.modal-mask.right-aligned {
  justify-content: flex-end;

  .modal-container {
    border-radius: 0;
    width: 90rem !important;
  }
}

.page-top-bar {
  padding: 1rem 3.2rem 0px 2rem !important;
}
</style>
