<template>
    <woot-modal :show.sync="showAgentModel" :on-close="onClose" class="account-selector--modal">
        <woot-modal-header
                :header-title="'Select Agents'"
        />
        <div class="columns medium-10 model_content">
            <multiselect
                    v-model="selectedAgents"
                    :options="agentList"
                    track-by="id"
                    label="name"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :hide-selected="true"
                    selected-label
                    :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                    :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                    :placeholder="$t('FOLDER_MGMT.AGENTS.PLACEHOLDER')"
            >
            </multiselect>
            <div class="modal-footer columns medium-10 float-right">
                <button
                        class="button primary"
                        @click="shareItems"
                >
                    {{ $t('FOLDER_MGMT.AGENTS.SHARE') }}
                </button>
                <woot-button class="button clear" @click.prevent="onClose">
                    {{ $t('FOLDER_MGMT.AGENTS.CANCEL') }}
                </woot-button>
            </div>
        </div>
    </woot-modal>

</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ShareFiles.vue",
    computed: {
        ...mapGetters({
            agentList: 'agents/getAgents',
        }),
    },
    data() {
        return {
            selectedAgents: [],
            showAgentModel: true,
        };
    },
    props: {
        onClose: {
            type: Function,
            required: true,
        },
        vaultType: {
            type: String,
            required: true,
        },
        fileType: {
            type: String,
            required: false,
        },
        vaultId: {
            type: Number,
            required: true,
        },
        vaultName: {
            type: String,
            required: false,
        },
        fileUrl: {
            type: String,
            required: false,
        }
    },
    mounted() {
        this.$store.dispatch('agents/get')
    },
    methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        async shareItems() {
            try {
                this.onClose();
                await this.$store.dispatch('share_agents', {
                    'vault_id': this.vaultId,
                    'vault_type': this.vaultType,
                    'selected_agents': this.selectedAgents,
                    'file_type': this.fileType
                });
                this.$store.dispatch('addLog', {
                    logType: this.vaultType,
                    logAction: 'share',
                    name: this.vaultName,
                    fileUrl: this.fileUrl,
                });
                this.selectedAgents = [];
                this.showAlert(this.$t('FOLDER_MGMT.AGENTS.API.SUCCESS_MESSAGE'));
            } catch (error) {
                this.showAlert(this.$t('FOLDER_MGMT.AGENTS.API.ERROR_MESSAGE'));
            }
        }
    }
}
</script>

<style scoped>
.account-selector--modal {
    .modal-container {
      height: 35rem !important;
      width: 50rem !important;
    }
}
.model_content {
  margin-left: 6%;
  margin-top: 3%;
 }
</style>
