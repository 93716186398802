import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  getAgentLimits(inboxType) {
    if (inboxType !== undefined) {
      return axios.get(`${this.url}/agent_limit.json?type=${inboxType}`);
    }
  }

  google_auth_save(data) {
    return axios.post(`${this.url}/google_auth`, { data: data });
  }

  update_google_auth(data) {
    return axios.post(`/api/v1/agents/update_google_auth`, { data: data });
  }

  sign_out_google() {
    return axios.get(`${this.url}/sign_out_google_auth`);
  }

  save_event_calendar(data) {
    return axios.post(`${this.url}/create_event_calendar`, { data: data });
  }

  update_event_calendar(data) {
    return axios.post(`${this.url}/calender_details`, { data: data });
  }

  save_secondary_calendar(data) {
    return axios.post(`${this.url}/secondary_calendar`, { data: data });
  }

  get_calendar() {
    return axios.get(`${this.url}/calendars`);
  }

  get_account_calendar(data) {
    return axios.post(`${this.url}/account_calendar`, { data: data });
  }

  delete_calendar(id) {
    return axios.post(`${this.url}/delete_calendar`, { id: id });
  }

  confirmation_email(id) {
    return axios.post(`${this.url}/resend_confirmation`, { id: id });
  }

  users_token(token) {
    return axios.post(`${this.url}/users_jwt`, { data: token });
  }

  generate_link(data) {
    return axios.post(`${this.url}/event_link`, { data: data });
  }

  generate_ai_agent(data) {
    return axios.post(`${this.url}/create_ai_agent`, { data });
  }

  edit_ai_agent(data) {
    return axios.post(`${this.url}/update_ai_agent`, { data });
  }

  get_ai_agent() {
    return axios.get(`${this.url}/ai_agent_list`);
  }

  delete_ai_agent(id) {
    return axios.post(`${this.url}/purge_ai_agent`, { id: id });
  }

  update_web_content(data){
    return axios.put(`${this.url}/update_web_content`, { data });
  }

  additional_settings(data){
    return axios.put(`${this.url}/additional_settings`, { data });
  }
}

export default new Agents();
