<template>
  <woot-modal
    :show="show"
    :on-close="handleClose"
    :class="['tags-selector--modal', { 'expanded': isMultiselectOpen }]"
    :closeOnBackdropClick="false"
  >
    <div class="column">
      <woot-modal-header
        header-title="Export Contatcs"
        header-content="Select option to export contacts"
      />
      <form class="row" @submit.prevent="handleSubmit">
        <div class="medium-12 columns">
          <div>
            <input
              type="radio"
              id="export-all"
              value="all"
              v-model="exportOption"
            />
            <label for="export-all">Export All Contacts</label>

            <input
              type="radio"
              id="export-labeled"
              value="labeled"
              v-model="exportOption"
            />
            <label for="export-labeled">Export Labeled Contacts</label>
          </div>
        </div>

        <div v-if="exportOption == 'labeled'" class="medium-12 columns">
          <multiselect
            v-model="selectedTags"
            :options="accountLabels"
            :close-on-select="false"
            :clear-on-select="false"
            @open="multiselectopened"
            @close="multiselectclosed"
            :preserve-search="true"
            placeholder="Select tags to filter"
            label="title"
            track-by="title"
          >
          </multiselect>
        </div>

        <div class="modal-footer medium-12 columns">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="isSubmitDisabled"
              :button-text="$t('CREATE_ACCOUNT.FORM.SUBMIT')"
              :loading="loading"
              button-class="large expanded"
            />
          </div>
        </div>
      </form>

    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from "vue-multiselect";
import alertMixin from 'shared/mixins/alertMixin';
export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Multiselect
  },
  data() {
    return {
      loading: false,
      selectedTags: [],
      isMultiselectOpen: false,
      exportOption:'all',
    };
  },
  computed: {
    ...mapGetters({
      accountLabels: 'labels/getLabels',
    }),
    isSubmitDisabled() {
      if(this.exportOption == 'labeled' && this.selectedTags.length === 0)
      {
        return true;
      }
      else{
        return false
      }
    }
  },
  methods: {
    handleClose() {
      this.resetForm();
      this.$emit('close-export-modal');
    },
    async handleSubmit() {
      try {
        this.loading = true;
        const exportParams = {
          tag: this.selectedTags,
          export_option: this.exportOption,
        };
        let response = await this.$store.dispatch('contacts/export_account_contacts', exportParams);

        if (response.data.contacts.length > 0) {
          const meaningfulContacts = response.data.contacts.filter(contact => {
            return Object.values(contact).some(value => value && value.trim() !== '');
          });

          if (meaningfulContacts.length > 0) {
            let successMessage = `Contacts exported`;
            const csvContent = this.generateCSV(meaningfulContacts);
            this.downloadCSV(csvContent, 'contacts.csv');
            this.loading = false;
            this.showAlert(successMessage);
            this.handleClose();
          } else {
            this.showAlert('No meaningful contacts to export');
            this.loading = false;
            this.handleClose();
          }
        }
         else {
          this.loading = false;
          this.showAlert('No contacts to export');
          this.handleClose();
        }
      } catch (error) {
        this.loading = false;
        this.showAlert('No contacts to export');
        this.handleClose()
      }
    },
    generateCSV(contacts) {
      const headers = ['name', 'email', 'phone_number', 'Company', 'description', 'Social Profiles', 'note'];
      const rows = contacts.map(contact => [
        contact.name,
        contact.email,
        contact.phone_number,
        contact.company,
        contact.description,
        contact.social_profiles,
        contact.note
      ]);
      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
      ].join('\n');
      return csvContent;
    },
    downloadCSV(content, filename) {
      const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    resetForm() {
      this.selectedTags = [];
      this.exportOption = 'all'
    },
    multiselectopened() {
      this.isMultiselectOpen = true;
    },
    multiselectclosed() {
      this.isMultiselectOpen = false;
    },
  },
};
</script>
<style lang="scss">
.alert-wrap {
  margin: var(--space-zero) var(--space-large);
  margin-top: var(--space-medium);

  .callout {
    display: flex;
    justify-content: center;
  }
}
.tags-modal{
  min-height: 500px;
}

.tags-selector--modal {
  .tags-modal {
    min-height: 300px;
    transition: min-height 0.3s ease;

    .modal-footer {
      transition: margin-top 0.3s ease;
    }
  }

  &.expanded {
    .tags-modal {
      min-height: 600px;
    }

    .modal-footer {
      margin-top: 300px;
    }
  }
}

.icon-wrap {
  font-size: var(--font-size-big);
  margin-left: var(--space-smaller);
  margin-right: var(--space-slab);
}
</style>
