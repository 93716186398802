<template>
    <div v-if="availableCameras.length > 0 || availableMicrophones.length > 0">
        <label v-if="availableCameras.length > 0">
            Camera
            <select name="camera"
                    id="camera-select"
                    @change="onChangeCamera"
                    v-model="selectedCamera"
            >
                <option v-for="availableCamera in availableCameras"
                        :key="availableCamera.value"
                        :value="availableCamera.value"
                >
                    {{availableCamera.label}}
                </option>
            </select>
        </label>

        <label v-if="availableMicrophones.length > 0">
            Microphone
            <select name="microphone"
                    id="microphone-select"
                    @change="onChangeMicrophone"
                    v-model="selectedMicrophone"
            >
                <option v-for="availableMicrophone in availableMicrophones"
                        :key="availableMicrophone.value"
                        :value="availableMicrophone.value"
                >
                    {{ availableMicrophone.label }}
                </option>
            </select>
        </label>
    </div>
</template>

<script>
export default {
    name: "CameraAndMicrophoneSettings",
    data: function () {
        return {
            selectedCamera: "",
            selectedMicrophone: "",
            availableCameras: [],
            availableMicrophones: []
        }
    },
    async mounted() {
        navigator.mediaDevices.addEventListener('devicechange', this.onMounted);

        await this.onMounted();
    },
    methods: {
        onChangeCamera(e) {
            if (window.localStorage) {
                window.localStorage.setItem("selectedCamera", e.target.value);
            }

            this.$store.commit("sipEndpoint/SET_REFRESH_DEVICES_JSSIP", true);
            this.$store.commit("sipEndpoint/SET_REFRESH_DEVICES_RELAY", true);
        },

        onChangeMicrophone(e) {
            if (window.localStorage) {
                window.localStorage.setItem("selectedMicrophone", e.target.value);
            }

            this.$store.commit("sipEndpoint/SET_REFRESH_DEVICES_JSSIP", true);
            this.$store.commit("sipEndpoint/SET_REFRESH_DEVICES_RELAY", true);
        },

        async onMounted() {
            let mediaDevicesInfo = null;

            try {
                mediaDevicesInfo = await navigator.mediaDevices.enumerateDevices();
            } catch (e) {
                console.log(e);
            }

            const availableCameras = [];
            const availableMicrophones = [];

            for (const mediaInfo of mediaDevicesInfo) {
                if (mediaInfo.kind === "videoinput") {
                    availableCameras.push({value: mediaInfo.deviceId, label: mediaInfo.label});
                }

                if (mediaInfo.kind === "audioinput") {
                    availableMicrophones.push({value: mediaInfo.deviceId, label: mediaInfo.label});
                }
            }

            this.availableCameras = availableCameras;
            this.availableMicrophones = availableMicrophones;

            if (window.localStorage) {
                const selectedCamera = window.localStorage.getItem("selectedCamera");
                const selectedMicrophone = window.localStorage.getItem("selectedMicrophone");

                if (selectedCamera) {
                    const isAvailableCamerasIncludeSelected = availableCameras.find((item) => {return item.value === selectedCamera});

                    this.selectedCamera = isAvailableCamerasIncludeSelected ? selectedCamera : (availableCameras.length > 0 ? availableCameras[0].value : "");

                } else {
                    this.selectedCamera = availableCameras.length > 0 ? availableCameras[0].value : "";
                }

                if (selectedMicrophone) {
                    const isAvailableMicrophoneIncludeSelected = availableMicrophones.find((item) => {return item.value === selectedMicrophone});

                    this.selectedMicrophone = isAvailableMicrophoneIncludeSelected ? selectedMicrophone : (availableMicrophones.length > 0 ? availableMicrophones[0].value : "");
                } else {
                    this.selectedMicrophone = availableMicrophones.length > 0 ? availableMicrophones[0].value : "";
                }
            } else {
                this.selectedCamera = availableCameras.length > 0 ? availableCameras[0].value : "";
                this.selectedMicrophone = availableMicrophones.length > 0 ? availableMicrophones[0].value : "";
            }
        }
    }
}
</script>

<style scoped>
#camera-select {
    cursor: pointer;
}

#microphone-select {
    cursor: pointer;
}
</style>
