<template>
  <div class="column content-box" style="background-color: rgb(251 252 253);">
    <div class="main-wrapper" v-if="schedule_screen === false">
      <table class="main-table" cellpadding="0" cellspacing="0">
        <tbody>
        <tr class="main-tr">
          <td class="main-td">


            <div class="u-row-container img-container">
              <div class="u-row">
                <div class="div-border">

                  <div class="u-col u-col-100 div-col">
                    <div class="div-width">

                      <div class="div-table">


                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="img-outer-td" align="left">

                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                <tr>
                                  <td class="img-inner-td" align="center">

                                    <img class="img" align="center" border="0" src="~dashboard/assets/images/conversate_header.png" alt="Image" title="Image" width="400" />

                                  </td>
                                </tr>
                              </table>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div class="u-row-container u-container">
              <div class="u-row">
                <div class="div-border">

                  <div class="u-col u-col-100 div-col">
                    <div class="div-width">

                      <div class="div-table">

                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="meet-td" align="left">

                              <h1 class="meeting-h">
                                Congratulations, Meeting scheduled.
                              </h1>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div class="u-row-container u-container">
              <div class="u-row">
                <div class="div-border">

                  <div class="u-col u-col-100 div-col">
                    <div class="div-width">

                      <div class="div-table">

                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="meeting-time-td" align="left">

                              <h3 class="meeting-time-h">
                                {{get_calender_details ? get_calender_details.duration : ''}}minutes meeting
                              </h3>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div class="u-row-container u-container">
              <div class="u-row">
                <div class="div-border">

                  <div class="u-col u-col-100 div-col">
                    <div class="div-width">

                      <div class="div-table">

                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="border-outside-td" align="left">

                              <table class="border-table" height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tbody>
                                <tr class="main-tr">
                                  <td class="border">
                                    <span>&#160;</span>
                                  </td>
                                </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div class="u-row-container u-container">
              <div class="u-row">
                <div class="div-border">

                  <div class="u-col u-col-50 cal-div">
                    <div class="div-width">

                      <div class="div-table">

                        <table class="table-font bottom_0" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="cal-td" align="left">

                              <div class="justify_content">
                                <div class="cal-container">
                                  <h5 class="cal-box-month">{{ event_month }}</h5>
                                  <h1 class="cal-box-date">{{event_date}}</h1>
                                </div>
                              </div>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="cal-time-td" align="left">

                              <h1 class="cal-time-h">
                                {{event_time.time}}<span style="font-size: 12px;">{{event_time.zone}}</span>
                              </h1>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div>

                  <div class="u-col u-col-50 main-second-sec">
                    <div class="div-width">

                      <div class="div-table">

                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="name-td" align="left">

                              <h2 class="name-h">
                                {{ this.guest_name}}
                              </h2>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="e-address-td" align="left">

                              <h4 class="e-address-h">
                                {{ this.guest_email}}
                              </h4>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="email-td" align="left">
                              <h4 class="email-h">
                                {{ this.guest_phone}}
                              </h4>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="u-row-container u-container">
              <div class="u-row">
                <div class="div-border">
                  <div class="u-col u-col-100 div-col">
                    <div class="div-width">
                      <div class="div-table">
                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="last-p-td" align="left">
                              <div class="last-p-div">
                                <p class="last-p">{{ get_calender_details ? get_calender_details.description : ''}}</p>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="u-row-container u-container">
              <div class="u-row">
                <div class="div-border">
                  <div class="u-col u-col-100 div-col">
                    <div class="div-width">
                      <div class="div-table">
                        <table class="table-font" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                          <tr>
                            <td class="last-p-td" align="left">
                              <div class="last-p-div">
                                <p class="last-p">{{get_calender_details ? get_calender_details.summary : ''}}</p>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row container_row" v-if="schedule_screen">
      <div class="medium-4" style="border-right: 1px solid whitesmoke;">
        <h1 class="display page-title margin_top">
<!--          <i class="icon ion-arrow-graph-up-right header&#45;&#45;icon"></i><span>Welcome {{get_calender_details.contact.name}}</span>-->
        </h1>
        <div class="row margin_top">
          <div class="medium-10 medium-offset-1">
            <label :class="{ error: $v.guest_name.$error }">
              <input
                  v-model.trim="guest_name"
                  type="text"
                  :placeholder="'Enter your Name'"
                  @input="$v.guest_name.$touch"
              />
            </label>
          </div>
        </div>
        <div class="row">
          <div class="medium-10 medium-offset-1">
            <label :class="{ error: $v.guest_email.$error }">
              <input
                  v-model.trim="guest_email"
                  type="text"
                  :placeholder="'Enter your Email'"
                  @input="$v.guest_email.$touch"
              />
            </label>
          </div>
        </div>
        <div class="row">
          <div class="medium-10 medium-offset-1">
            <label :class="{ error: $v.guest_phone.$error }">
              <input
                  v-model.trim="guest_phone"
                  type="number"
                  :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.HELP_2') "
                  @input="$v.guest_phone.$touch"
              />
<!--              <span class="message">-->
<!--                {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.HELP_3') }}-->
<!--              </span>-->
<!--              <span v-if="$v.guest_phone.$error" class="message">-->
<!--            {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR_2') }}-->
<!--          </span>-->
            </label>
          </div>
        </div>

        <div class="row margin_top">
          <div class="medium-11 medium-offset-1"><h6>{{ get_calender_details ? get_calender_details.description : ''}} </h6></div>
        </div>
        <div class="row margin_top">
          <h6 class="medium-offset-1"><i class="icon  ion-ios-clock"></i><span class="margin_left">{{get_calender_details ? get_calender_details.duration : ''}} </span><span>min</span></h6>
        </div>
        <div class="row margin_top margin_below">
          <div class="medium-11 medium-offset-1"><span class="span_font">{{get_calender_details ? get_calender_details.summary : ''}} </span>
          </div>
        </div>
      </div>
      <div class="medium-5">
        <h1 class="display page-title medium-offset-1 margin_top">
          <span>Select Date & Time</span>
        </h1>
        <form class="edit-event--form" @submit.prevent="editEvent">
          <div class="row margin_top">
            <div class="medium-11 medium-offset-1">
              <date-picker
                  is-expanded
                  color="blue"
                  title-position="left"
                  v-model='selectedDate'
                  :min-date='new Date()'
                  :disabled-dates='{ weekdays: offDays }'
                  @dayclick='availableTime'
                  :masks="{ input: ['DD/MM/YY']}"
                  class="themeStyles"
              />
            </div>
          </div>
          <div class="row">
            <div class="medium-11 medium-offset-1 margin_to_5">
             <h6> <i v-if="this.time_period !== ''" class="ion-android-calendar"></i> <span>{{this.time_period !== "" ? this.time_period : '' }}</span> </h6>
            </div>
          </div>

          <div class="modal-footer margin_top margin_bottom">
            <div v-if="this.selectedDate !== ''" class="medium-11 medium-offset-1">
              <woot-submit-button
                  :button-text="$t('EDIT_EVENT.FORM.SUBMIT')"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="medium-3 text-center duration_height">
        <div class="span_time">{{ dateHeader }}</div>
        <div class="follow" v-for="(duration, index) in durations"
             v-model="start_time" :key="index">
          <div class="btn_icon"  v-on:click="setCurrentDuration(index)" v-bind:class="`start_time-${index}`">{{ duration }}</div>
          <span class="btn_icon last" v-show="false" v-bind:class="`hide1-${index}`">{{ duration }}</span>
          <span class="btn_icon first" v-show="false" v-bind:class="`hide2-${index}`"   v-on:click="openMenu(index, duration)">Confirm</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from 'vuex';
import {frontendURL} from "../../helper/URLHelper";
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import alertMixin from "../../../shared/mixins/alertMixin";
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import differenceInMinutes from "date-fns/differenceInMinutes";
import {email,required} from "vuelidate/lib/validators";
import {isPhoneE164OrEmpty} from 'shared/helpers/Validators';
import {isEmpty} from "jssip/lib/Utils";

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly';
const URL = 'https://accounts.google.com/o/oauth2/token';

export default {
  mixins: [alertMixin],
  components: {
    datetime: Datetime,
    Calendar,
    DatePicker
  },

  data(){
    return{
      datetime12: '',
      event: '',
      start_date: '',
      end_date: '',
      summary: '',
      description: '',
      guest_name: '',
      guest_email: '',
      guest_phone: '',
      days: [ "-",  "9:00am - 5:00pm", "9:00am - 5:00pm",
        "9:00am - 5:00pm", "9:00am - 5:00pm", "9:00am - 5:00pm", "-"],
      selectedDate: '',
      days_off: [],
      schedule: [],
      week_days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      durations: '',
      start_time: '',
      time_period: '',
      schedule_screen: true,
      authorized: false,
    };
  },
  validations: {
    guest_name: {
      required
    },
    guest_email: {
      required,
      email,
    },
    guest_phone: {
      required
    }
  },

  computed: {
    ...mapGetters({
      get_calender_details: 'get_calender_details'
    }),
    offDays: function () {
      this.schedule = []
      var schedule = this.schedule;
      let hours = ''
      let minute = ''
      let zone = ''
      if (!this.get_calender_details.schedule) {
        let year = moment(Date.now()).format('YYYY');
        let month = moment(Date.now()).format('MM');
        let day = moment(Date.now()).format('D');
        if(moment(this.selectedDate).format('D') === day) {
          hours = moment(Date.now()).format('hh')
          zone = moment(Date.now()).format('A')
        }else {
          hours = '09'
          minute = '00'
          zone = 'AM'
        }
        let auto_start_date = new Date(year, month, day, hours, '00');
        let auto_end_date = new Date(year, month, day, '05', '00');
        let f_start_date = moment(moment(auto_start_date + zone).format('hh:mm A'), 'hh:mm A');
        let f_end_date = moment(moment(auto_end_date + "PM").format('hh:mm A'), 'hh:mm A');
        while ((f_end_date.diff(f_start_date, 'minutes')) >= this.get_calender_details.duration) {
          schedule.push(f_start_date.format('hh:mm A'));
          f_start_date = moment(moment(f_start_date).add(this.get_calender_details.duration, 'minutes').format('hh:mm A'), 'hh:mm A');
        }
        this.schedule = schedule
        this.days_off = [1, 7];
        return this.days_off
      } else {
        var i = 1;
        for (let key in this.get_calender_details.schedule) {
          let value = this.get_calender_details.schedule[key];
          var date_time = JSON.parse((value.replace(/^"(.*)"$/, "$1")).replaceAll("=>", ":"))
          var vm = this;
          schedule[i] = [];
          if (!isEmpty(date_time)) {
            date_time.forEach(function (obj) {
            var start_date = '';
            var end_date = '';
            var start_A = '';
            var end_A = '';
            if (obj?.start_time) {
              if (obj.start_time.hasOwnProperty('hh')) {
                start_date = obj.start_time.hh + ":" + obj.start_time.mm
                start_A = obj.start_time.A
              } else {
                start_A = obj.start_time.slice(obj.start_time.length - 2);
                start_date = (obj.start_time.replace("AM", "").replace("PM", "")).trim();
              }
              if (obj.start_time.hasOwnProperty('hh')) {
                end_date = obj.end_time.hh + ":" + obj.end_time.mm
                end_A =  obj.end_time.A
              } else {
                end_A = obj.end_time.slice(obj.end_time.length - 2);
                end_date = (obj.end_time.replace("AM", "").replace("PM", "")).trim();
              }

              start_date = start_date.split(':');
              end_date = end_date.split(':');
              start_date = new Date('', '', '', start_date[0], start_date[1]);
              end_date = new Date('', '', '', end_date[0], end_date[1]);
              let final_end_date = moment(moment(end_date + end_A).format('hh:mm A'), 'hh:mm A');
              let new_start_date = moment(moment(start_date + start_A).format('hh:mm A'), 'hh:mm A');
              while ((final_end_date.diff(new_start_date, 'minutes')) >= vm.get_calender_details.duration) {
                schedule[i].push(new_start_date.format('hh:mm A'));
                new_start_date = moment(moment(new_start_date).add(vm.get_calender_details.duration, 'minutes').format('hh:mm A'), 'hh:mm A');
              }
            }else {
              schedule[i].push('');
            }
          });
          }
          i++
        }
        for (var value in schedule) {
          if (schedule[value][0] === "") {
            vm.days_off.push(parseInt(value));
          }
        }
        vm.replaceKeys();
      }
      return vm.days_off;
    },
    dateHeader() {
      if(this.selectedDate !== ""){
        return moment(this.selectedDate).format('dddd, MMMM D')
      }else {
        let date = new Date();
        return moment(date).format('dddd, MMMM D')
      }
    },
    event_month() {
      return moment(this.selectedDate).format('MMM')
    },
    event_date() {
      return moment(this.selectedDate).format('D')
    },
    event_time() {
      let time = moment(this.start_date).format('h:mm'),
       zone = moment(this.start_date).format('A');
      return {
        time,
        zone
      };
    }
  },

  props: {
  },

  async created() {
    await this.getCalendarEvent();
    this.api = gapi;
    this.handleClientLoad();
  },

 methods: {
  async getCalendarEvent() {
    try {
      await this.$store.dispatch('eventDetails', this.$route.params.id)
    }catch (e) {
      console.log("error in" , e)
    }
  },
  handleClientLoad() {
    this.api.load('client:auth2', this.initClient);
  },
  initClient() {
    var details = this.get_calender_details;
    this.summary = details.summary
    this.description = details.description
    let vm = this;
    vm.api.client.init({
      apiKey: vm.get_calender_details.google_api_key,
      clientId: vm.get_calender_details.client_id,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    }).then(function () {
      const auth2 = vm.api.auth2.getAuthInstance()
      if (auth2.isSignedIn.get()) {
        vm.authorized = true;
        vm.api.auth2.getAuthInstance().isSignedIn.listen(vm.authorized)
      } else {
        var tokens =  vm.parseUser(details.user);
        var credentials = {
          client_id: vm.get_calender_details.client_id,
          client_secret: vm.get_calender_details.client_secret,
          refresh_token: tokens.refresh_token,
          grant_type: "refresh_token",
          redirect_uri: vm.get_calender_details.frontend_url
        };
        fetch(URL, {
          'method': 'POST',
          'body': JSON.stringify(credentials),
          headers: {'Content-Type': 'application/json'}
        }).then(resp =>  resp.json()).then(token => {
          token.refresh_token = tokens.refresh_token
          token.user_id = details.user.id
          vm.updateGoogleAuth(token)
          vm.authorized = true;
          vm.api.client.setToken({ access_token: token.access_token});
          // Listen for sign-in state changes.
          console.log(vm.api.auth2.getAuthInstance().isSignedIn.get())
        });
      }

    });
  },
  parseUser(user) {
    var data = JSON.parse(user.google_tokens);
    return data;
  },
  editEvent() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return alertMixin.methods.showAlert('Please add basic information.');
    }
    if(!this.start_date) {
      return alertMixin.methods.showAlert('Please confirm the schedule time.');
    }

    const event = {
      'summary': this.summary,
      'location': '',
      'description': this.description,
      'start': {
        'dateTime': this.start_date,
        'timeZone': 'UTC'
      },
      'end': {
        'dateTime': moment(this.start_date).add(this.get_calender_details.duration, 'minutes'),
        'timeZone': 'UTC'
      },
      'recurrence': [
        'RRULE:FREQ=DAILY;COUNT=1'
      ],
      'attendees': [
        {'email': this.guest_email ? this.guest_email : this.get_calender_details.contact.email}
      ],
      'reminders': {
        'useDefault': false,
        'overrides': [
          {'method': 'email', 'minutes': 24 * 60},
          {'method': 'popup', 'minutes': 30}
        ]
      },
      'guestsCanModify': true
    };
    try {
      var request =  this.api.client.calendar.events.insert({
        'calendarId': this.get_calender_details.calendar.calendar_id,
        'resource': event
      });
      var vm = this;
      request.execute(function(event) {
        if(event.code === 401) {
          alertMixin.methods.showAlert('Session expired, please contact with agent to reconnect google account');
        }else if(event.htmlLink !== undefined) {
          alertMixin.methods.showAlert('Event added successfully');
          vm.updateEventDetails({...event, calendar_etag: vm.$route.params.id })
          vm.schedule_screen = false;
        }else {
          alertMixin.methods.showAlert('There was an error in event request, please refresh page.');
        }
      });
    }catch (error) {
      alertMixin.methods.showAlert('There was an error in event request, please refresh page.');
    }
  },
  async updateGoogleAuth(data){
    try {
      await this.$store.dispatch("agents/updateGoogleAuth", data)
    }catch (error) {
      console.log(error)
    }
  },
  async updateEventDetails(data){
    try {
      await this.$store.dispatch("agents/updateEvent", data)
    }catch (error) {
      console.log(error)
    }
  },
  replaceKeys() {
    const replaceKeys = (arr, obj) => {
      const keys = Object.keys(obj);
      const res = {};
      for(let a in arr){
        res[arr[a]] = obj[keys[a]];
        obj[arr[a]] = obj[keys[a]];
        delete obj[keys[a]];
      }
    };
  return  replaceKeys(this.week_days, this.schedule);
  },
  async availableTime() {
    await this.offDays
    if (!this.get_calender_details.schedule) {
      this.durations = this.schedule;
    } else {
      let day = moment(this.selectedDate).format('ddd');
      this.durations = this.schedule[day];
    }

    return this.durations;
  },
  setCurrentDuration(id) {
     if(!(document.querySelector('.start_time-'+id).style.display == 'none')){
       document.querySelectorAll('div[class*="start_time-"]').forEach(function(obj){ obj.style.display = 'inline-block';});
       document.querySelector('.start_time-'+id).style.display = 'none';
       document.querySelectorAll('span[class*="hide1-"]').forEach(function(obj){ obj.style.display = 'none';});
       document.querySelectorAll('span[class*="hide2-"]').forEach(function(obj){ obj.style.display = 'none';});
       document.querySelector('.hide1-'+id).style.display = 'inline-block';
       document.querySelector('.hide2-'+id).style.display = 'inline-block';
     }
   },
  openMenu(index, value) {
     let zone = value.includes("AM") ? 'AM' : 'PM';
     let duration_split = value.replace("AM", "").replace("PM", "").trim();
     duration_split = duration_split.split(':');
     let year = moment(this.selectedDate).format('YYYY');
     let month = moment(this.selectedDate).format('MM');
     let day = moment(this.selectedDate).format('D');
     let duration_start_date = new Date(year, Number(month) - 1, day, duration_split[0], duration_split[1]);
     let duration_end_date = new Date('', '', '', duration_split[0], duration_split[1]);
     duration_end_date = moment(duration_end_date + zone).add(this.get_calender_details.duration, 'minutes').format('hh:mm A');
     this.time_period = value + ' - ' + duration_end_date + ', ' + moment(this.selectedDate).format('dddd, MMMM D, YYYY');
     this.start_date = moment(moment(duration_start_date + zone).format('dddd, MMMM D YYYY, h:mm:ss a')).format();
   },
 },
}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.label-color--container {
  display: flex;
  align-items: center;
}

.label-color--display {
  border-radius: $space-smaller;
  height: $space-normal;
  margin-right: $space-smaller;
  width: $space-normal;
}

.settings {
  overflow: auto;
}

.display {
  display: flex;
}

.header_style {
  margin-bottom: 10px;
  margin-left: 5%;
}

.margin_top {
  margin-top: 5%;
}

.margin_bottom {
  margin-bottom: 5%;
}

.margin_left {
  margin-left: 5px;
}
td {
  text-align: center !important;
}

.margin_to_5 {
  margin-top: 5rem;
}

.follow {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.btn_icon:hover {
  border: 2px solid #006bff;
}
.btn_icon, .label {
  background-color: white; /* #1f7a7a */
  border: 1px solid rgb(0, 162, 255);
  line-height: 2.5rem;
  padding: 3px 0;
  color: rgb(0, 107, 255);
}
.label {
  border-radius: 0.4rem;
  position: absolute;
  font-size: 16px;
  font-weight: 750;
  letter-spacing: 0.0816em;
  padding: 5px 10px;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  pointer-events: none;
  text-transform: uppercase;
  transition: opacity 0.3s 0.50s ease-out;
}
.btn_icon {
  display: inline-block;
  width: 21rem;
  border-radius: 5px;
  padding: 7px;
}
.btn_icon.first {
  width: 6em;
  cursor: pointer;
  color: #ffffff;
  background-color: #006bff;
}

.btn_icon.first:hover {
  background-color: rgba(0, 162, 255, 0.8);
  border: none !important;
}

.btn_icon.last {
  width: 6em;
  background-color: #666666;
  border-color: transparent;
  color: white;
}
.container_row {
  max-width: 75%;
  margin-top: 5%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 1px 8px 0 whitesmoke;
  border: 1px solid whitesmoke;
}
.span_font {
  font-size: 14px;
}
.span_time {
  font-size: 13px;
  padding-top: 6rem;
  margin-bottom: 1rem;
  text-align: left;
  margin-left: 5rem;
}
.themeStyles {
  border: none;
  color: #006bff;
}
.margin_negative {
  margin-left: -19px;
}
.duration_height{
  height: 50rem;
  overflow: auto;
}

.justify_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 2rem;
}

table,
td {
  color: #000000;
}

@media only screen and (min-width: 520px) {
  .u-row {
    width: 500px !important;
  }
  .u-row .u-col {
    vertical-align: top;
  }
  .u-row .u-col-50 {
    width: 250px !important;
  }
  .u-row .u-col-100 {
    width: 500px !important;
  }
}

@media (max-width: 520px) {
  .u-row-container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .u-row .u-col {
    min-width: 230px !important;
    max-width: 100% !important;
    /* display: block !important; */
  }
  .u-row {
    width: calc(100% - 40px) !important;
  }
  .u-col {
    width: 100% !important;
  }
  .u-col>div {
    margin: 0 auto;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  color: #000000;
  background-color: #f5f5f5;
}

table,
tr,
td {
  vertical-align: top;
  border-collapse: collapse;
  border: none !important;
}

p {
  margin: 0;
}

.ie-container table,
.mso-container table {
  table-layout: fixed;
}

* {
  line-height: inherit;
}

a[x-apple-data-detectors='true'] {
  color: inherit !important;
  text-decoration: none !important;
}
.main-wrapper{
  width: 60rem;
  margin: auto;
}
.main-table{
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
  vertical-align: top;
  min-width: 320px;
  Margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.main-tr{
  vertical-align: top;
}
.main-td{
  word-break: break-word;
  border-collapse: collapse !important;
  vertical-align: top;
  padding: 0;
}
div{
  padding: 0;
}
.img-container{
  background-color: #000a7d;
}
.u-row{
  Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;
}
.div-border{
  border-collapse: collapse;display: table;width: 100%;background-color: transparent;
}
.div-col{
  max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;
}
.div-width{
  width: 100% !important;
}
.div-table{
  padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;
}
.table-font{
  font-family:arial,helvetica,sans-serif;
}
.img-outer-td{
  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;
  padding-bottom: 0 !important;
}
.img-inner-td{
  padding: 0;
}
.img{
  outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 400px;
}
.u-container{
  padding: 0px; background-color: transparent
}
.meet-td{
  overflow-wrap:break-word;word-break:break-word;padding:30px 10px 0 10px;font-family:arial,helvetica,sans-serif;
}
.meeting-h{
  margin: 0; line-height: 140%; text-align: center; word-wrap: break-word; font-weight: bold; font-family: arial,helvetica,sans-serif; font-size: 25px;
}
.meeting-time-h{
  margin: 0; line-height: 140%; text-align: center; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 18px; color: #29abe2;
}
.meeting-time-td{
  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;
}
.border-outside-td{
  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;
}
.border-table{
  border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #BBBBBB;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%; width: 85%;
}
.border{
  word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;     border-bottom: 1px solid #dfe0ea !important;
}
.cal-div{
  max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;
}
.cal-td{
  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;text-align: -webkit-center;
}
.cal-container{
  border: 1px solid #838388; width: 40%;
  border-top: none;
}
.cal-box-month{
  margin: 0;background-color: #000a7d;padding: 5px 0 5px 0;color: #29abe2;
}
.cal-box-date{
  margin: 0;
}
.cal-time-td{
  overflow-wrap:break-word;word-break:break-word;padding:0 0 10px 0;font-family:arial,helvetica,sans-serif;
}
.cal-time-h{
  margin-right: 4rem; line-height: 140%; text-align: right; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 22px;
}
.last-p{
  font-size: 14px; line-height: 140%; text-align: center;
}
.last-p-div{
  line-height: 140%; text-align: left; word-wrap: break-word;
}
.last-p-td{
  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;
}
.email-h{
  margin: 0; line-height: 140%; text-align: left; word-wrap: break-word; font-weight: bold; font-family: arial,helvetica,sans-serif; font-size: 15px;
}
.email-td{
  overflow-wrap:break-word;word-break:break-word;padding:5px 10px 10px 10px;font-family:arial,helvetica,sans-serif;
}
.e-address-h{
  margin: 0; line-height: 140%; text-align: left; word-wrap: break-word; font-weight: bold; font-family: arial,helvetica,sans-serif; font-size: 15px;
}
.e-address-td{
  overflow-wrap:break-word;word-break:break-word;padding:5px 10px 0 10px;font-family:arial,helvetica,sans-serif;
}
.name-h{
  margin: 0px; line-height: 140%; text-align: left; word-wrap: break-word; font-weight: bold; font-family: arial,helvetica,sans-serif; font-size: 22px;
}
.name-td{
  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;
}
.main-second-sec{
  max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;
}
.bottom_0 {
  margin-bottom: 0;
}
.margin_below {
  margin-bottom: 4rem;
}

</style>
