<template>
  <div class="row align-middle align-justify">
    <div class="row align-middle specific-period">
      <div>
        <strong>Specific period:</strong>
      </div>
      <div>
        <date-range-picker v-model="value.dateRange" opens="right" />
      </div>
      <div>
        <button class="button" @click="clearDateRange">Clear</button>
      </div>
    </div>
    <div class="row align-middle">
      <div class="row align-middle per-page">
        <div>
          <strong>Per page:</strong>
        </div>
        <div>
          <select v-model="value.per_page">
            <option
              v-for="(option, index) in per_page_options"
              :key="index"
              v-bind:value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <paginate
          v-model="value.page"
          :page-count="total_pages"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination-container'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Paginate from 'vuejs-paginate';

export default {
  components: { Paginate, DateRangePicker },
  props: ['value', 'total_pages'],
  data() {
    return {
      per_page_options: [10, 20, 50, 100],
    };
  },
  methods: {
    clearDateRange: function () {
      this.$emit('input', {
        ...this.value,
        dateRange: {
          startDate: null,
          endDate: null,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.vue-daterange-picker {
  display: block !important;
  margin-right: var(--space-medium);
  .reportrange-text {
    border: 1px solid #e0e6ed;
    border-radius: var(--border-radius-normal);
    height: 3.9rem;
    line-height: calc(3.9rem - 2px);
    min-width: 100px;
    padding: 0 15px;
    font-size: var(--font-size-small);
  }
}
.pagination-container {
  display: flex;
  background: white;
  align-items: center;
  border: 1px solid #e0e6ed;
  margin: 0;
  padding: 0;
  border-radius: var(--border-radius-normal);
  li {
    display: block;
    padding: 0;
    margin: 0;
    &:not(:last-child) {
      border-right: 1px solid #e0e6ed;
    }
    a {
      display: block;
      padding: 0 15px;
      color: rgb(10, 9, 9);
      height: calc(3.9rem - 2px);
      line-height: 3.9rem;
      cursor: pointer;
      color: #3c4858;
      font-weight: 600;
    }
    &.active {
      background: #e0e6ed;
      a {
        cursor: default;
      }
    }
    &.disabled {
      a {
        cursor: default;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.transactions-history-container {
  position: relative;
  .loader {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f9fafc;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: 'Loading...';
      color: #3c4858;
      font-size: var(--font-size-large);
    }

    &.active {
      pointer-events: all;
      opacity: 0.7;
    }
  }
}
.specific-period {
  strong {
    color: #3c4858;
    font-weight: var(--font-weight-black);
    font-size: var(--font-size-default);
    margin-right: var(--space-small);
  }
}
.per-page {
  margin-right: var(--space-medium);
  select {
    margin: 0;
  }
  strong {
    color: #3c4858;
    font-weight: var(--font-weight-black);
    font-size: var(--font-size-default);
    margin-right: var(--space-small);
  }
}
</style>
