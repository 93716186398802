function decodeBase64(encodedStr) {
  return atob(encodedStr);
}

export const createMessengerScript = pageId => {
  const decodedFbAppId = decodeBase64(window.chatwootConfig.fbAppId);
  return `<script>
  window.fbAsyncInit = function() {
    FB.init({
      appId: "${decodedFbAppId}",
      xfbml: true,
      version: "v4.0"
    });
  };
  (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

</script>
<div class="fb-messengermessageus"
  messenger_app_id="${decodedFbAppId}"
  page_id="${pageId}"
  color="blue"
  size="standard" >
</div>
`;
};
