import { WOOT_PREFIX } from './constants';
import CryptoJS from 'crypto-js';

export const isEmptyObject = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const arrayToHashById = array =>
  array.reduce((map, obj) => {
    const newMap = map;
    newMap[obj.id] = obj;
    return newMap;
  }, {});

export const IFrameHelper = {
  isIFrame: () => window.self !== window.top,
  sendMessage: msg => {
    window.parent.postMessage(
      `chatwoot-widget:${JSON.stringify({ ...msg })}`,
      '*'
    );
  },
  isAValidEvent: e => {
    const isDataAString = typeof e.data === 'string';
    const isAValidWootEvent =
      isDataAString && e.data.indexOf(WOOT_PREFIX) === 0;
    return isAValidWootEvent;
  },
  getMessage: e => JSON.parse(e.data.replace(WOOT_PREFIX, '')),
};
export const RNHelper = {
  isRNWebView: () => window.ReactNativeWebView,
  sendMessage: msg => {
    window.ReactNativeWebView.postMessage(
      `chatwoot-widget:${JSON.stringify({ ...msg })}`
    );
  },
};


export const decrypt = (encryptedData) => {
  const key = '123456789abcdefgh0123456789abcde';
  const [base64Iv, base64Encrypted] = encryptedData.split('--');
  const iv = CryptoJS.enc.Base64.parse(base64Iv);
  const encrypted = CryptoJS.enc.Base64.parse(base64Encrypted);
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encrypted },
    CryptoJS.enc.Utf8.parse(key),
    { iv: iv }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};
