import Vue from 'vue';
import * as types from '../mutation-types';
import UserNotificationSettings from '../../api/userNotificationSettings';

const state = {
  showNotification: false,
  newUnreadCount: 0,
  unreadFlag: false,
};

export const getters = {
  getShowNotification($state) {
    return $state.showNotification;
  },
  getNewUnreadCount($state) {
    return $state.newUnreadCount;
  },
  getUnreadFlag($state) {
    return $state.unreadFlag;
  },
};

export const actions = {};

export const mutations = {
  toogleShowNotification($state, value) {
    $state.showNotification = value;
  },
  toogleUnreadFlag($state, value) {
    $state.unreadFlag = value;
  },
  incrementNewUnreadCount($state, value) {
    $state.newUnreadCount = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
