var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-header row"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 columns"},[_c('h1',{staticClass:"page-title"},[_c('woot-sidemenu-icon'),_vm._v(" "),(_vm.showBackButton)?_c('back-button',{attrs:{"back-url":_vm.backUrl}}):_vm._e(),_vm._v(" "),_c('i',{class:_vm.iconClass}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.headerTitle))])],1)]),_vm._v(" "),_c('div',{staticClass:"col-md-8 columns"},[(
            _vm.isAdmin &&
              _vm.currentUser.accounts[0].stripe &&
              !_vm.currentUser.accounts[0].stripe_account &&
              _vm.headerTitle === 'Account settings'
          )?_c('button',{staticClass:"button icon success nice",on:{"click":function($event){$event.preventDefault();return _vm.connectStripe()}}},[_c('i',{staticClass:"ion-android-add-circle icon-margin"}),_vm._v("\n          "+_vm._s(_vm.$t('PROFILE_SETTINGS.STRIPE_BTN_TEXT'))+"\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.isAdmin &&
              _vm.currentUser.accounts[0].stripe_account &&
              _vm.headerTitle === 'Account settings'
          )?_c('button',{staticClass:"button icon success nice"},[_vm._v("\n          "+_vm._s(_vm.$t('PROFILE_SETTINGS.STRIPE_CONNECTED_BTN_TEXT'))+"\n        ")]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"medium-3 columns"},[(_vm.showNewButton && _vm.isAdmin)?_c('router-link',{staticClass:"button icon success nice button--fixed-right-top",attrs:{"to":_vm.buttonRoute}},[_c('i',{staticClass:"icon ion-android-add-circle icon-margin"}),_vm._v("\n      "+_vm._s(_vm.buttonText)+"\n    ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }