<template>
  <div class="wizard-body small-9 columns"
       style="height: auto"
       v-if="!isPortRequestSubmitted"
  >

    <page-header
      :header-title="$t('INBOX_MGMT.ADD.SIGNALWIRE.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.SIGNALWIRE.DESC')"
    />

    <form class="row signalwire-inbox-form"
          @submit.prevent
    >

      <div class="medium-12 columns">
        <label :class="{ error: $v.channelName.$error }">
          * {{ $t('INBOX_MGMT.ADD.SIGNALWIRE.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            placeholder="Channel Name"
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.SIGNALWIRE.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-12 columns two-columns-wrapper">
        <div class="two-column-item">
          <label>
            <span>* Communication Type</span>
            <div class="select">
              <v-select :options="communicationTypeOptions"
                        v-model="communicationType"
                        placeholder="Choose Communication Type"
              >
              </v-select>
            </div>
          </label>
        </div>

        <div class="two-column-item"
             v-if="(!isPortRequest && isCommunicationTypePresent)"
        >
          <label>
            <span>* Number Type</span>
            <div class="select">
              <v-select :options="numberTypeOptions"
                        v-model="numberType"
                        placeholder="Choose Number Type"
              >
              </v-select>
            </div>
          </label>
        </div>

        <div class="two-column-item"
             v-if="isPortRequest"
        >
          <label :class="{ error: $v.phoneNumber.$error }">
            * Number To Port
            <input
                v-model.trim="phoneNumber"
                type="text"
                placeholder="Phone Number"
                @blur="$v.phoneNumber.$touch"
            />
            <span v-if="$v.phoneNumber.$error" class="message">
              This field is required
            </span>
          </label>
          <span class="field-description">
               Phone number to port including country code.
          </span>
        </div>
      </div>

      <div v-if="((numberType === numberTypeOptions[0]) && !isPortRequest && isCommunicationTypePresent)"
           class="medium-12 columns"
      >
        <div class="two-columns-wrapper">
          <div class="two-column-item">
            <label>
              <span>Area Code</span>
              <input type="text"
                     v-model.trim="areaCode"
                     placeholder="Area Code"
              >
            </label>
          </div>

          <div class="two-column-item">
            <label>
              <span>Region</span>
              <div class="select">
                <v-select :options="regionTypeOptions"
                          v-model="inRegion"
                          placeholder="Choose Region"
                >
                </v-select>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="medium-12 columns"
           v-if="(numberType && !isPortRequest && isCommunicationTypePresent)"
      >
        <label>
          <span>* Phone Number</span>
          <div class="select">
            <v-select :options="phoneNumberOptions"
                      v-model="phoneNumber"
                      placeholder="Choose Phone Number"
                      @search="debounceFetchPhoneNumbers"
                      :loading="isLoading"
            >

            </v-select>
          </div>
        </label>
      </div>



      <div class="medium-12 columns"
           v-if="(isCommunicationTypePresent && !isPortRequest)"
      >
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.SIGNALWIRE.SUBMIT_BUTTON')"
          :disabled="!(isPhoneNumberPresent && isCommunicationTypePresent)"
          @click="createChannel"
        />
      </div>

      <div class="medium-12 columns service-to-port-wrapper"
           v-if="isPortRequest"
      >
        <label>
          <span>* Services To Port</span>
          <div class="select">
            <v-select :options="servicesToPortOptions"
                      v-model="servicesToPort"
                      :class="$v.servicesToPort.$error ? 'select-error' : ''"
                      placeholder="Choose Service"
            >
            </v-select>
          </div>
        </label>
      </div>

      <label class="menu-label"
             v-if="isPortRequest"
      >
        Current Service Provider Details
      </label>

      <div class="medium-12 columns two-columns-wrapper"
           v-if="isPortRequest"
      >
        <div class="two-column-item">

          <label :class="{ error: $v.providerName.$error }">
            * Current Provider Name
            <input
                v-model.trim="providerName"
                type="text"
                placeholder="Provider Name"
                @blur="$v.providerName.$touch"
            />
            <span v-if="$v.providerName.$error" class="message">
              This field is required
            </span>
          </label>
          <span class="field-description">
               Name of the number's current provider.
          </span>
        </div>

        <div class="two-column-item">
          <label>
            Current Provider Account Number
            <input
                v-model.trim="providerAccountNumber"
                type="text"
                placeholder="Account Number"
            />
          </label>

          <span class="field-description">
               May be optional. Some carriers require the account number when accepting port requests, so include it if possible.
          </span>
        </div>

      </div>

      <div class="medium-12 columns two-columns-wrapper"
           v-if="isPortRequest"
      >
        <div class="two-column-item">
          <label>
            <span>* Account Type</span>
            <div class="select">
              <v-select :options="accountTypesOptions"
                        :class="$v.accountType.$error ? 'select-error' : ''"
                        v-model="accountType"
                        placeholder="Choose Account Type"
              >

              </v-select>
            </div>
          </label>

          <span class="field-description">
            What type of account were the numbers associated with?
          </span>
        </div>

        <div class="two-column-item">
          <label :class="{ error: $v.authorizedName.$error }">
            * Authorized Name Of The Account
            <input
                v-model.trim="authorizedName"
                type="text"
                placeholder="Must be the legal first and last name"
                @blur="$v.authorizedName.$touch"
            />
            <span v-if="$v.authorizedName.$error" class="message">
              This field is required
            </span>
          </label>

          <span class="field-description">
            Name used to sign the letter of authorization. Must be the legal first and last name (not a business or corporation name).
          </span>
        </div>
      </div>


      <div class="medium-12 columns two-columns-wrapper"
           v-if="isPortRequest"
      >
        <div class="two-column-item">
          <label :class="{ error: $v.billingPhoneNumber.$error }">
            * Billing Phone Number
            <input
                v-model.trim="billingPhoneNumber"
                type="text"
                placeholder="Billing Phone Number"
                @blur="$v.billingPhoneNumber.$touch"
            />
            <span v-if="$v.billingPhoneNumber.$error" class="message">
              This field is required
            </span>
          </label>

          <span class="field-description">
            Primary contact telephone number for your account on record with the current service provider.
          </span>
        </div>

        <div class="two-column-item">
          <label>
            Company Name
            <input
                v-model.trim="companyName"
                type="text"
                placeholder="Company Name"
            />
          </label>

          <span class="field-description">
            Optional. If this number was associated with a business, what was that businesses name.
          </span>
        </div>
      </div>

      <label class="menu-label"
             v-if="isPortRequest"
      >
        Phone Service Address
      </label>

      <div style="margin-bottom: 20px">
         <span class="field-description"
               v-if="isPortRequest"
         >
        This is not necessarily the same as your billing address. We require the location where the phone calls take place on file with the current operating network. If you have doubts about the location the operating network has on file, please request a customer service record (CSR)
      </span>
      </div>




      <div class="medium-12 columns two-columns-wrapper"
           v-if="isPortRequest"
      >
        <div class="two-column-item">
          <label :class="{ error: $v.address.$error }">
            * Address
            <textarea
                v-model.trim="address"
                type="text"
                placeholder="Address"
                @blur="$v.address.$touch"
                class="form-text-area"
            />
            <span v-if="$v.address.$error" class="message">
              This field is required
            </span>
          </label>
        </div>

        <div class="two-column-item">
          <label>
            Address 2
            <textarea
                v-model.trim="addressExtra"
                type="text"
                placeholder="Extra address"
                class="form-text-area"
            />
          </label>
        </div>
      </div>

      <div class="medium-12 columns two-columns-wrapper"
           v-if="isPortRequest"
      >
        <div class="two-column-item">
          <label :class="{ error: $v.city.$error }">
            * City
            <input
                v-model.trim="city"
                type="text"
                placeholder="City"
                @blur="$v.city.$touch"
            />
            <span v-if="$v.city.$error" class="message">
              This field is required
            </span>
          </label>
        </div>

        <div class="two-column-item">
          <label :class="{ error: $v.state.$error }">
            * State / Province
            <input
                v-model.trim="state"
                type="text"
                placeholder="State / Province"
                @blur="$v.state.$touch"
            />
            <span v-if="$v.state.$error" class="message">
              This field is required
            </span>
          </label>
        </div>
      </div>

      <div class="medium-12 columns two-columns-wrapper"
           v-if="isPortRequest"
      >
        <div class="two-column-item">
          <label :class="{ error: $v.postalCode.$error }">
            * ZIP / Postal Code
            <input
                v-model.trim="postalCode"
                type="text"
                placeholder="ZIP / Postal Code"
                @blur="$v.postalCode.$touch"
            />
            <span v-if="$v.postalCode.$error" class="message">
              This field is required
            </span>
          </label>
        </div>

        <div class="two-column-item">
          <label>
            <span>* Country</span>
            <div class="select">
              <v-select :options="countryOptions"
                        v-model="country"
                        :class="$v.country.$error ? 'select-error' : ''"
                        placeholder="Choose Country"
              >

              </v-select>
            </div>
          </label>
        </div>
      </div>

      <label class="menu-label"
             v-if="isPortRequest"
      >
        Additional Information
      </label>

      <div class="medium-12 columns two-columns-wrapper"
           v-if="isPortRequest"
      >
        <div class="two-column-item">
          <label>
            Last Four Characters Of SSN
            <input
                v-model.trim="ssnLastFourCharacters"
                type="text"
                placeholder="Last four characters of SSN"
            />
          </label>

          <span class="field-description">
            Applies only when porting wireless telephone numbers from specific carriers (e.g. Verizon Wireless)
          </span>
        </div>

        <div class="two-column-item">
          <label>
            PIN
            <input
                v-model.trim="pin"
                type="text"
                placeholder="PIN"
            />
          </label>

          <span class="field-description">
            If you have a PIN, please provide it. This is often REQUIRED information for Verizon Wireless, MagicJack, Integra, and some other carriers.
          </span>
        </div>
      </div>

      <div class="medium-12 columns"
           v-if="isPortRequest"
           style="margin: 0 10px"
      >

      </div>

      <div v-if="isPortRequest">
        <woot-submit-button
            button-text="Submit Request"
            :loading="false"
            @click="postBusinessInformation"
        />
      </div>

    </form>
  </div>

  <div class="wizard-body small-9 columns submitted-message"
       style="height: auto"
       v-else-if="isPortRequestSubmitted"
  >
    <div class="submit-message">
      Request Submitted
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../../index';
import PageHeader from '../../../SettingsSubPageHeader';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import IvrForm from "./IvrForm/IvrForm";
// import Label from "../../../../../../components/widgets/Label";
// import Label from "../../../../../../components/widgets/LabelSelector";
import {regionTypesArray} from "./constants/RegionTypes";
import communicationTypes from "./constants/CommunicationTypes";
import {communicationTypesArray} from "./constants/CommunicationTypes";
import {numberTypesArray} from "./constants/NumberTypes";
import {requestStatusTypes} from "./constants/RequestStatusType";
import {accountTypesArray} from "./constants/AccountTypes";
import {countryTypesArray} from "./constants/CountryTypes";
import {servicesToPortTypesArray} from "./constants/ServicesToPortTypes";


import debounce from "lodash/debounce"

const shouldStartWithPlusSign = (value = '') => value.startsWith('+');

export default {
  components: {
    // Label,
    PageHeader,
    vSelect,
    IvrForm
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      phoneNumber: null,
      numberType: null,
      isoCountry: "US",
      areaCode: null,
      inRegion: null,
      communicationType: null,
      numberTypeOptions: numberTypesArray,
      regionTypeOptions: regionTypesArray,
      communicationTypeOptions: communicationTypesArray,

      //  business information
      providerName: null,
      providerAccountNumber: null,
      accountType: null,
      accountTypesOptions: accountTypesArray,
      authorizedName: null,
      billingPhoneNumber: null,
      companyName: null,
      address: null,
      addressExtra: null,
      city: null,
      state: null,
      postalCode: null,
      ssnLastFourCharacters: null,
      pin: null,
      country: null,
      countryOptions: countryTypesArray,
      servicesToPort: null,
      servicesToPortOptions: servicesToPortTypesArray,
      //  business information

      isPortRequestSubmitted: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      phoneNumberOptions: "signalwireInboxCreation/getAvailablePhoneNumbers",
    }),

    isLoading: function () {
      return this.$store.getters["signalwireInboxCreation/getRequestStatus"] === requestStatusTypes.LOADING;
    },

    isPortRequest: function () {
      return ((this.communicationType === communicationTypes.SMS_ONLY_BUSINESS_PORTED) || (this.communicationType === communicationTypes.VOICE_AND_SMS_BUSINESS_PORTED))
    },

    isCommunicationTypePresent: function () {
      return this.communicationType !== null;
    },

    isPhoneNumberPresent: function () {
      return this.phoneNumber !== null;
    },

    isVoiceNewNumber: function () {
      return this.communicationType === communicationTypes.VOICE_AND_SMS_NEW;
    },
  },
  validations: {
    channelName: { required },
    phoneNumber: { required },
    providerName: { required },
    accountType: { required },
    authorizedName: {required},
    billingPhoneNumber: { required },
    address: { required },
    city: { required },
    state: { required },
    postalCode: { required },
    country: { required },
    servicesToPort: { required }
  },

  created: function () {
    this.debounceFetchPhoneNumbers = debounce(this.fetchPhoneNumbers, 500);
    this.debounceFetchPhoneNumbers_2 = debounce(function () {
      this["signalwireInboxCreation/getAvailablePhoneNumbersAsync"]({
        numberType: this.numberType.code,
        isoCountry: this.isoCountry,
        areaCode: this.areaCode ? this.areaCode : null,
        contains: "",
        inRegion: this.inRegion ? this.inRegion.code : null
      });
    }, 500);
  },

  watch: {
    numberType: function () {
      this["signalwireInboxCreation/getAvailablePhoneNumbersAsync"]({
        numberType: this.numberType.code,
        isoCountry: this.isoCountry,
        areaCode: this.areaCode ? this.areaCode : null,
        contains: "",
        inRegion: this.inRegion ? this.inRegion.code : null
      });

      this.phoneNumber = null;
    },
    inRegion: function () {
      this["signalwireInboxCreation/getAvailablePhoneNumbersAsync"]({
        numberType: this.numberType.code,
        isoCountry: this.isoCountry,
        areaCode: this.areaCode ? this.areaCode : null,
        contains: "",
        inRegion: this.inRegion ? this.inRegion.code : null
      });

      this.phoneNumber = null;
    },
    areaCode: function () {
      this.debounceFetchPhoneNumbers_2();

      this.phoneNumber = null;
    },

    communicationType: function () {
      this.phoneNumber = null;
    }
  },

  methods: {
    ...mapActions([
        "signalwireInboxCreation/getAvailablePhoneNumbersAsync",
        "signalwireInboxCreation/postBusinessInformationAsync"
    ]),
    async createChannel() {
      if (this.isPortRequest) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
      } else {
        this.$v.channelName.$touch();

        if (this.$v.channelName.$invalid) {
          return;
        }
      }

      try {
        const signalwireChannel = await this.$store.dispatch(
          'inboxes/createSignalwireChannel',
          {
            signalwire_link: {
              name: this.channelName,
              communication_type: this.communicationType.code,
              phone_number: this.phoneNumber
            },
          }
        );

        console.log(signalwireChannel);

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: signalwireChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.SIGNALWIRE.API.ERROR_MESSAGE'));
      }
    },

    async fetchPhoneNumbers(search, loading) {
      loading(true);

      await this["signalwireInboxCreation/getAvailablePhoneNumbersAsync"]({
        numberType: this.numberType.code,
        isoCountry: this.isoCountry,
        areaCode: this.areaCode ? this.areaCode : null,
        contains: search,
        inRegion: this.inRegion ? this.inRegion.code : null
      });

      loading(false);
    },

    async postBusinessInformation() {
      this.$v.$touch();
      if (this.$v.$invalid) {

        console.log(this.$v);
        return;
      }

      await this["signalwireInboxCreation/postBusinessInformationAsync"]({
        accountType: this.accountType,
        address: this.address,
        addressExtra: this.addressExtra,
        authorizedName: this.authorizedName,
        billingPhoneNumber: this.billingPhoneNumber,
        city: this.city,
        companyName: this.companyName,
        country: this.country.code,
        pin: this.pin,
        postalCode: this.postalCode,
        providerAccountNumber: this.providerAccountNumber,
        providerName: this.providerName,
        ssnLastFourCharacters: this.ssnLastFourCharacters,
        state: this.state,
        servicesToPort: this.servicesToPort,
      });

      this.isPortRequestSubmitted = true;
    },
  },
};
</script>

<style>
.select {
  margin-bottom: 1.6rem;
}

.vs__search{
  height: 29px;
}

.two-columns-wrapper {
  display: flex;

  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  justify-items: center;

  margin-bottom: 30px;
}

.two-column-item {
  width: 48%;
}

.two-column-item input[type="text"] {
  margin-bottom: 5px;
}


.two-column-item textarea {
  margin-bottom: 5px;
}

@media only screen and (max-width: 750px) {
  .two-columns-wrapper {
    flex-direction: column;
  }

  .two-column-item {
    width: 100%;

    margin-bottom: 10px;
  }

  .row {
    flex-direction: column;
  }
}

.menu-label {
  font-size: 24px;

  margin-bottom: 10px;
}

.form-text-area {
  min-height: 70px;
}

.field-description {
  font-size: 12px;
}

.vs__dropdown-toggle {
  border: 1px solid #e0e6ed;
}

.vs__dropdown-menu {
  border: 1px solid #e0e6ed;
}


.signalwire-inbox-form .error .message {
  display: inline;
}

.signalwire-inbox-form .error input[type="text"] {
  margin-bottom: 5px;
}

.signalwire-inbox-form .error textarea {
  margin-bottom: 5px;
}

.two-column-item .select {
  margin-bottom: 5px;
}

.service-to-port-wrapper .select {
  margin-bottom: 5px;
}

.submitted-message {
  font-size: 64px;
}

.select-error .vs__dropdown-toggle {
  border: solid 1px red;
}

.submit-message {
  display: flex;

  align-content: center;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.ivr-form {
  margin-bottom: 30px;
}

.ivr-form textarea {
  resize: vertical;

  min-height: 60px;
}

.ivr-form__menu-label {
  font-size: 24px;
}

.ivr-form__buttons-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
</style>
