import * as types from "../mutation-types";
export const state = {
    currentAction: null,
    isRegistered: false,
    isSession: false,
    isCallMenuOpen: false,
    isRelayRegistered: false,
    refreshDevicesJSSIP: false,
    refreshDevicesRelay: false,
};

export const getters = {
    getCurrentAction($state) {
        return $state.currentAction;
    },
    getIsRegistered($state) {
        return $state.isRegistered;
    },
    getIsRelayRegistered($state) {
        return $state.isRelayRegistered;
    },
    getIsSession($state) {
        return $state.isSession;
    },
    getCallMenuOpen($state) {
        return $state.isCallMenuOpen;
    },
    getRefreshDevicesJSSIP($state) {
        return $state.refreshDevicesJSSIP;
    },
    getRefreshDevicesRelay($state) {
        return $state.refreshDevicesRelay;
    }
};
export const mutations = {
    [types.default.SET_CURRENT_ACTION]($state, payload) {
        $state.currentAction = payload;
    },
    [types.default.SET_IS_REGISTERED]($state, payload) {
        $state.isRegistered = payload;
    },
    [types.default.SET_IS_RELAY_REGISTERED]($state, payload) {
        $state.isRelayRegistered = payload;
    },
    [types.default.SET_IS_SESSION]($state, payload) {
        $state.isSession = payload;
    },
    [types.default.SET_IS_CALL_MENU_OPEN]($state, payload) {
        $state.isCallMenuOpen = payload;
    },
    [types.default.SET_REFRESH_DEVICES_JSSIP]($state, payload) {
        $state.refreshDevicesJSSIP = payload;
    },
    [types.default.SET_REFRESH_DEVICES_RELAY]($state, payload) {
        $state.refreshDevicesRelay = payload;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
