<template>
    <woot-modal :show.sync="showFolderModal" :on-close="() => $emit('close-folder-model')">
        <woot-modal-header
                :header-title="$t('FOLDER_MGMT.ADD.TITLE')"
        />
        <form class="row" @submit.prevent="addFolder">
            <woot-input
                    v-model.trim="name"
                    :class="{ error: $v.name.$error }"
                    class="medium-12 columns"
                    :label="$t('FOLDER_MGMT.FORM.NAME.LABEL')"
                    :placeholder="$t('FOLDER_MGMT.FORM.NAME.PLACEHOLDER')"
                    @input="$v.name.$touch"
            />
            <woot-input
                    v-model.trim="notes"
                    class="medium-12 columns"
                    :label="$t('FOLDER_MGMT.FORM.NOTES.LABEL')"
                    :placeholder="$t('FOLDER_MGMT.FORM.NOTES.PLACEHOLDER')"
            />
            <label class="w-full">
                <span style="margin-right: 1rem;"> Access: </span>
                <input
                        id="private"
                        v-model="access"
                        type="radio"
                        name="selectedType"
                        value="private"
                        checked
                        class="label-input"
                />
                <label class="label-tag" for="private">{{
                    $t('FOLDER_MGMT.FORM.ACCESS.LABEL_PRIVATE')
                    }}</label>
                <span></span><span></span>
                <input
                        id="public"
                        v-model="access"
                        type="radio"
                        name="selectedType"
                        value="public"
                        class="label-input"
                />
                <label class="label-tag" for="public">{{
                    $t('FOLDER_MGMT.FORM.ACCESS.LABEL_PUBLIC')
                    }}</label>
            </label>
            <div class="medium-12 columns footer-margin-top">
                <woot-button>
                    {{ $t('FOLDER_MGMT.FORM.CREATE') }}
                </woot-button>
                <woot-button class="button clear" @click.prevent="onClose">
                    {{ $t('FOLDER_MGMT.FORM.CANCEL') }}
                </woot-button>
            </div>
        </form>
    </woot-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';

export default {
    mixins: [alertMixin],
    props: {
        showFolderModal: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            access: 'private',
            notes: '',
            name: '',
            showOnSidebar: true,
            folder_type: ''
        };
    },
    validations: {
        name: {
            required,
        },
    },
    methods: {
        onClose() {
            this.$emit('close-folder-model');
        },
        addFolder() {
            this.$store
                .dispatch('add_folder', {
                    name: this.name,
                    notes: this.notes,
                    access_type: this.access,
                    folder_type: 'folder'
                })
                .then(() => {
                    this.showAlert(this.$t('FOLDER_MGMT.ADD.API.SUCCESS_MESSAGE'));
                    this.$store.dispatch('addLog', {
                        logType: 'folder',
                        logAction: 'add',
                        name: this.name,
                    });
                    this.onClose();
                })
                .catch(() => {
                    this.showAlert(this.$t('FOLDER_MGMT.ADD.API.ERROR_MESSAGE'));
                });
        },
    },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.label-footer-button {
  display: inline;
}
.label-input {
  margin-right: 0px !important;
}
.label-tag {
  margin-left: 0px !important;
}
.footer-margin-top {
  margin-top: 2rem !important;
}
</style>
