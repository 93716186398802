/* global axios */
import ApiClient from '../ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  get({ inboxId, status, assigneeType, page, labels, range, teamId, chatDuration }) {
    return axios.get(this.url, {
      params: {
        inbox_id: inboxId,
        team_id: teamId,
        status,
        assignee_type: assigneeType,
        page,
        labels,
        range,
        chat_duration: chatDuration
      },
    });
  }

  search({ q }) {
    return axios.get(`${this.url}/search`, {
      params: {
        q,
        page: 1,
      },
    });
  }

  conversationIdBySipUsername({sip_username}){
    return axios.get(`${this.url}/get_conversation_id_by_sip_username`, {
      params: {
        sip_username: sip_username,
      },
    });
  }

  toggleStatus({ conversationId, status, snoozedUntil = null }) {
    return axios.post(`${this.url}/${conversationId}/toggle_status`, {
      status,
      snoozed_until: snoozedUntil,
    });
  }

  assignAgent({ conversationId, agentId }) {
    return axios.post(
      `${this.url}/${conversationId}/assignments?assignee_id=${agentId}`,
      {}
    );
  }

  assignTeam({ conversationId, teamId }) {
    const params = { team_id: teamId };
    return axios.post(`${this.url}/${conversationId}/assignments`, params);
  }

  markMessageRead({ id }) {
    return axios.post(`${this.url}/${id}/update_last_seen`);
  }

  markNotificationRead({ id }) {
    return axios.post(`${this.url}/${id}/notification_last_seen`);
  }

  toggleTyping({ conversationId, status }) {
    return axios.post(`${this.url}/${conversationId}/toggle_typing_status`, {
      typing_status: status,
    });
  }

  toggleCamera({ conversationId, status }) {
    return axios.post(`${this.url}/${conversationId}/toggle_camera_status`, {
      camera_status: status,
    });
  }

  mute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/mute`);
  }

  unmute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/unmute`);
  }

  meta({ inboxId, status, assigneeType, labels, teamId }) {
    return axios.get(`${this.url}/meta`, {
      params: {
        inbox_id: inboxId,
        status,
        assignee_type: assigneeType,
        labels,
        team_id: teamId,
      },
    });
  }

  sendEmailTranscript({ conversationId, email }) {
    return axios.post(`${this.url}/${conversationId}/transcript`, { email });
  }

  saveTransaction(data) {
    return axios.post(`${this.url}/save_transaction`, {
        data: data,
    });
  }

  update_comment_message(data) {
    return axios.post(`${this.url}/update_comment`, {
        data: data,
    });
  }
}

export default new ConversationApi();
