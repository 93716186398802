<template xmlns="http://www.w3.org/1999/html">
  <div class="contact--profile">
    <div class="contact--info main-contact-left-padding">
    <div class="btn-flex">
        <thumbnail
            :src="contact.thumbnail"
            size="64px"
            :badge="channelType"
            :username="contact.name"
            :status="contact.availability_status"
        />
    </div>

      <div class="contact--details">
        <h3 class="sub-block-title contact--name">
          {{ contact.name }}
        </h3>
        <p v-if="additionalAttributes.description" class="contact--bio"></p>
        <social-icons :social-profiles="socialProfiles" />

        <div v-if="( (contact) && (contact.phone_number !== null) && (contact.sip_username === null) && (inboxPhoneOptions && inboxPhoneOptions.length > 0))"
             class="jssip-call-wrapper"
        >

            <div v-if="inboxPhoneOptions && inboxPhoneOptions.length > 0">
                <v-select :options="inboxPhoneOptions"
                          v-model="selectedInbox"
                          placeholder="Choose Inbox"
                >
                </v-select>
            </div>

          <div class="sip-endpoint-widget-wrapper"
               v-if="inboxPhoneOptions.length > 0"
          >

            <div :class="`sip-endpoint-widget__button sip-endpoint-widget__call-button ${(!isRegistered || isSession || !selectedInbox) ? 'sip-endpoint-widget__call-button--disable' : ''}`"
                 @click="setActionPhoneNumber"
            >
              <img src="~dashboard/assets/images/phone.svg" alt="Call" width="32px" height="32px">
            </div>


            <div v-if="selectedInbox"
                 class="sip-endpoint-widget__phone-number-label"
            >
              {{`From: ${formatPhoneNumber(selectedInbox.code.phone_number)}`}}
            </div>

          </div>
        </div>

        <div v-if="isShowWebWidgetButtons">
          <div class="sip-endpoint-widget-wrapper sip-endpoint-widget-wrapper--web-widget">
            <!-- {{contact.availability_status}} -->
            <div :class="`sip-endpoint-widget__button sip-endpoint-widget__call-button ${(!isRegistered || isSession || contact.availability_status !== 'online') ? 'sip-endpoint-widget__call-button--disable' : ''}`"
                 @click="setActionSipEndpoint"
            >
              <img src="~dashboard/assets/images/phone.svg" alt="Call" width="32px" height="32px">
            </div>
              <!-- {{contact.availability_status}} -->
              <div :class="`sip-endpoint-widget__button sip-endpoint-widget__call-button ${(!isRegistered || isSession || contact.availability_status !== 'online') ? 'sip-endpoint-widget__call-button--disable' : ''}`"
                   @click="setActionSipEndpointShareScreen"
              >
                  <img src="~dashboard/assets/images/monitor-share.svg" alt="Call" width="32px" height="32px">
              </div>
          </div>
        </div>

        <div class="contact--metadata">
          <contact-info-row
            :href="contact.email ? `mailto:${contact.email}` : ''"
            :value="contact.email"
            icon="ion-email"
            emoji="✉️"
            :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
            show-copy
          />

          <contact-info-row
            :href="contact.phone_number ? `tel:${contact.phone_number}` : ''"
            :value="contact.phone_number"
            icon="ion-ios-telephone"
            emoji="📞"
            :title="$t('CONTACT_PANEL.PHONE_NUMBER')"
          />

          <contact-info-row
            :href="contact.note ? contact.note : ''"
            :value="contact.note"
            icon="ion-code"
            emoji="📕"
          />
          <contact-info-row
            v-if="additionalAttributes.location"
            :value="additionalAttributes.location"
            icon="ion-map"
            emoji="🌍"
            :title="$t('CONTACT_PANEL.LOCATION')"
          />
          <contact-info-row
            :value="additionalAttributes.company_name"
            icon="ion-briefcase"
            emoji="🏢"
            :title="$t('CONTACT_PANEL.COMPANY')"
          />
        </div>

<!--      <button id="show-modal" class="button clear primary btn-event event" @click="toggleShowEventModal">Create Event</button>-->
          <transition name="menu-slide" class="bottom-nav">
            <div
                v-if="isEventOpened"
                v-on-clickaway="closeEventMenu"
                class="dropdown-pane dropdown-pane--open"
            >
              <woot-dropdown-menu>
                <woot-dropdown-item
                    v-for="eList in eventList"
                    :key="eList.slug"
                    class="status-items"
                >
                  <woot-button
                      variant="clear"
                      size="small"
                      color-scheme="secondary"
                      class-names="status-change--dropdown-button"
                      @click="create_event(eList)">
                    {{ eList.name }}
                  </woot-button>
                </woot-dropdown-item>
              </woot-dropdown-menu>
            </div>
          </transition>

          <woot-button
              v-if="conversationId"
              variant="clear"
              class-names="button clear primary btn-event schedule"
              @click="openEventMenu"
          >
            Send Schedule Link
          </woot-button>

<!--        <a-->
<!--            :href="getCalendsoLink"-->
<!--            target="_blank"-->
<!--            class="button clear primary btn-event schedule"-->
<!--        >-->
<!--          Add Event-->
<!--        </a><br>-->
<!--        <router-link-->
<!--          class="button clear primary btn-event schedule"-->
<!--          :to="MeetingsHistoryPath">-->
<!--        {{ $t('CONTACT_PANEL.SCHEDULE_HISTORY') }}-->
<!--      </router-link>-->
      <div class="contact-actions">
          <button v-if="contact.stripe_payment && conversationId"
                  class="button new-message small button-height expanded primary"
                  @click="togglePaymentModal">
              {{ $t('CONTACT_PAYMENT.LABEL') }}
          </button>
          <router-link
                  v-if="contact.stripe_payment && conversationId"
                  class="button smooth small button-height expanded primary"
                  :to="StripeHistoryPath">
              {{ $t('PAYMENT_HISTORY.LABEL') }}
          </router-link>
          <br />
      </div>
      <div class="contact-actions">
        <woot-button
          class="new-message button-height"
          size="small expanded"
          @click="toggleConversationModal"
        >
          {{ $t('CONTACT_PANEL.NEW_MESSAGE') }}
        </woot-button>
        <woot-button
          class="button-height"
          variant="smooth"
          size="small expanded"
          @click="toggleEditModal"
        >
          {{ $t('EDIT_CONTACT.BUTTON_LABEL') }}
        </woot-button>
      </div>
      </div>

      <div class="contact-actions">
        <woot-button
          v-if="showNewMessage"
          v-tooltip="$t('CONTACT_PANEL.NEW_MESSAGE')"
          title="$t('CONTACT_PANEL.NEW_MESSAGE')"
          class="new-message button-width button-height"
          icon="ion-chatboxes"
          size="small expanded"
          @click="toggleConversationModal"
        />
        <woot-button
          v-tooltip="$t('EDIT_CONTACT.BUTTON_LABEL')"
          title="$t('EDIT_CONTACT.BUTTON_LABEL')"
          class="edit-contact button-width button-height"
          icon="ion-edit"
          variant="smooth"
          size="small expanded"
          @click="toggleEditModal"
        />
        <woot-button
          v-if="isAdmin"
          v-tooltip="$t('CONTACT_PANEL.MERGE_CONTACT')"
          title="$t('CONTACT_PANEL.MERGE_CONTACT')"
          class="merge-contact button-width button-height"
          icon="ion-merge"
          variant="smooth"
          size="small expanded"
          color-scheme="secondary"
          :disabled="uiFlags.isMerging"
          @click="openMergeModal"
        />
        <woot-button
          v-if="isAdmin"
          v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
          title="$t('DELETE_CONTACT.BUTTON_LABEL')"
          class="delete-contact button-width button-height"
          icon="ion-trash-a"
          variant="smooth"
          size="small expanded"
          color-scheme="alert"
          :disabled="uiFlags.isDeleting"
          @click="toggleDeleteModal"
        />
      </div>
      <edit-contact
        v-if="showEditModal"
        :show="showEditModal"
        :contact="contact"
        @cancel="toggleEditModal"
      />
      <new-conversation
        v-if="contact.id"
        :show="showConversationModal"
        :contact="contact"
        @cancel="toggleConversationModal"
      />
      <contact-merge-modal
        v-if="showMergeModal"
        :primary-contact="contact"
        :show="showMergeModal"
        @close="toggleMergeModal"
      />
    </div>
    <woot-confirm-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="confirmDeleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="contact.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
    <payment-request
      v-if="showPaymentModal"
      :show="showPaymentModal"
      :contact="contact"
      :conversationId="conversationId"
      @cancel="togglePaymentModal"
    />
    <div class="row">
      <div class="medium-10">
        <create-event
          v-if="showEventModal"
          :show="showEventModal"
          :contact="contact"
          :conversationId="conversationId"
          @cancel="toggleShowEventModal"
        />
      </div>
    </div>
    </div>

</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import ContactInfoRow from './ContactInfoRow';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import SocialIcons from './SocialIcons';
import EditContact from './EditContact';
import PaymentRequest from "./PaymentRequest";
import History from "./History";
import { frontendURL } from "../../../../helper/URLHelper";
import vSelect from 'vue-select';

// import Label from "../../../../components/widgets/Label";
import { actionTypes } from "../../../../components/TheSIPEndpoint/constants/actionTypes";

import {phoneNumberHelper} from "../../../../../shared/helpers/PhoneNumberHelper";

import NewConversation from './NewConversation';
import ContactMergeModal from 'dashboard/modules/contact/ContactMergeModal';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { mapGetters } from 'vuex';
import Label from "../../../../components/ui/Label";
import CreateEvent from "./CreateEvent";
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';

const JUSTSCHED_URL= `${window.chatwootConfig.calendsoLink}api/teams/event-type-info`
export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
    CreateEvent,
    Label,
    PaymentRequest,
    ContactInfoRow,
    EditContact,
    Thumbnail,
    SocialIcons,
    NewConversation,
    ContactMergeModal,
    History,
    vSelect
  },
  mixins: [alertMixin, adminMixin, clickaway],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelType: {
      type: String,
      default: '',
    },
    conversationId: {
      type: [Number, String],
      required: true,
    },
    showNewMessage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
      showPaymentModal: false,
      showHistoryComponent: false,
      showEventModal: false,
      type: '',
      phoneNumbers: [{ phone: "" }],
      selectedInbox: null,
      inboxPhoneOptions: null,
      inboxWebWidgetOptions: null,
      jwt: '',
      isEventOpened: false,
      eventList: [],
      profile: [],
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      isRegistered: 'sipEndpoint/getIsRegistered',
      isSession: 'sipEndpoint/getIsSession',
      uiFlags: 'contacts/getUIFlags',
    }),
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
    StripeHistoryPath() {
      return frontendURL(`accounts/${this.accountId}/conversations/${this.conversationId}/history/${this.contact.id}`);
    },
    MeetingsHistoryPath() {
      return frontendURL(`accounts/${this.accountId}/contacts/${this.contact.id}/history`);
    },
    isShowWebWidgetButtons() {
      return (this.contact) && (this.contact.sip_username !== null) && (this.inboxWebWidgetOptions) && (this.inboxWebWidgetOptions.find((inbox) => {
        try{
          for(let i=0; i<this.contact.contact_inboxes.length; i++){
            if (inbox.code && this.contact.contact_inboxes && this.contact.contact_inboxes[i]) {
              if(inbox.code.inbox_id === this.contact.contact_inboxes[i].inbox.id){
                return true;
              }
            }
          }
          return false;
        }
        catch(e){
          //nothing
        }
      }))
    },
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('DELETE_CONTACT.CONFIRM.YES')} ${this.contact.name}`;
    },
    deleteRejectText() {
      return `${this.$t('DELETE_CONTACT.CONFIRM.NO')} ${this.contact.name}`;
    },
    confirmDeleteMessage() {
      return `${this.$t('DELETE_CONTACT.CONFIRM.MESSAGE')} ${
          this.contact.name
      } ?`;
    },
    confirmPlaceHolderText() {
      return `${this.$t('DELETE_CONTACT.CONFIRM.PLACE_HOLDER', {
        contactName: this.contact.name,
      })}`;
    },
    getCalendsoLink() {
      return `${window.chatwootConfig.calendsoLink}auth/login?token=${this.jwt}`;
    }
  },

  created() {
    this.currentUser;
    this.inboxPhoneOptions = this.currentUser.sip_endpoints?.filter((item) => {return item.phone_number !== null && item.account_id == this.accountId}).map((item) => {
      return {
        label: `${item.inbox_name}`,
        code: item
      }
    });

    this.inboxWebWidgetOptions = this.currentUser.sip_endpoints?.filter((item) => {return item.phone_number === null}).map((item) => {
      return {
        label: `${item.inbox_name}`,
        code: item
      }
    });
    this.eventData();
  },


  methods: {
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    setActionPhoneNumber() {
      if (this.isRegistered && !this.isSession && this.selectedInbox) {
        this.$store.commit("sipEndpoint/SET_CURRENT_ACTION", {
          contactPhoneNumber: this.contact.phone_number,
          inbox: this.selectedInbox.code,
          type: actionTypes.PHONE_NUMBER_CALL
        });
      }
    },
    setActionSipEndpoint() {
      if (this.isRegistered && !this.isSession && this.contact.availability_status === "online") {
        const webWidgetInbox = this.contact.contact_inboxes[0].inbox;

        this.$store.commit("sipEndpoint/SET_CURRENT_ACTION", {
          contactId: this.contact.id,
          contactSipUsername: this.contact.sip_username,
          agentSipUsername: this.inboxWebWidgetOptions.find((inbox) => {return inbox.code.inbox_id === webWidgetInbox.id}).code.username,
          inboxId: webWidgetInbox.id,
          conversationId: this.conversationId,
          type: actionTypes.SIP_ENDPOINT_CALL
        });
      }
    },
    setActionSipEndpointShareScreen() {
      if (this.isRegistered && !this.isSession && this.contact.availability_status === "online") {
        const webWidgetInbox = this.contact.contact_inboxes[0].inbox;

        this.$store.commit("sipEndpoint/SET_CURRENT_ACTION", {
          contactId: this.contact.id,
          contactSipUsername: this.contact.sip_username,
          agentSipUsername: this.inboxWebWidgetOptions.find((inbox) => {return inbox.code.inbox_id === webWidgetInbox.id}).code.username,
          inboxId: webWidgetInbox.id,
          conversationId: this.conversationId,
          type: actionTypes.SIP_ENDPOINT_CALL_SCREEN_SHARE
        });
      }
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    toggleShowEventModal() {
      this.showEventModal = !this.showEventModal;
    },
    togglePaymentModal() {
      this.showPaymentModal = !this.showPaymentModal;
    },
    showHistory() {
      this.showHistoryComponent = !this.showHistoryComponent;
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumberHelper.format(phoneNumber);
    },
    eventData() {
      let vm = this;
      const encodedString = Buffer.from(this.currentUser.email).toString('base64')
      try {
        fetch(JUSTSCHED_URL + `?email=${encodedString}`, {
          method: 'GET',
          headers: { 'Content-Type"': 'application/json' },
        })
          .then(resp => resp.json())
          .then(event => {
            vm.eventList = event;
            vm.isEventOpened = false;
          });
      } catch (error) {
        throw new Error(error);
      }
    },
    openEventMenu() {
      this.isEventOpened = true;
    },
    closeEventMenu() {
      this.isEventOpened = false;
    },
    create_event(event) {
      let data = {
        conversation_id: this.conversationId,
        url: event.url,
      };
      try {
        this.$store.dispatch('agents/generateLink', data);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
@import '~vue2-datepicker/scss/index.scss';

$namespace: 'xmx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />
$default-color: #555;
$primary-color: #1284e7;

.contact--profile {
  position: relative;
  align-items: flex-start;
  padding: var(--space-normal);
  margin-bottom: var(--space-normal);

  .user-thumbnail-box {
    margin-right: var(--space-normal);
  }
}

.contact--details {
  margin-top: var(--space-small);
  width: 100%;
}

.contact--info {
  text-align: left;
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
}

.contact--metadata {
  margin-bottom: var(--space-small);
}

.contact-actions {
  margin-top: var(--space-small);
}

.margin-bottom {
  margin-bottom: 3%;
}

.right-text {
  text-align: right;
}
.contact-actions {
  display: flex;
  align-items: center;
  width: 100%;

  .new-message,
  .edit-contact,
  .merge-contact,
  .delete-contact {
    margin-right: var(--space-small);
  }
}
.merege-summary--card {
  padding: var(--space-normal);
}

.button--contact-menu {
  position: absolute;
  right: var(--space-normal);
  top: 0;
}

.contact-actions {
  display: flex;
  align-items: center;
  width: 100%;
}

.btn-flex {
  display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;

  margin-top: 3%;

    width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1em;
}

.authentification {
  text-align: center;
}

hr {
  border: 1px solid black;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  overflow-x: auto;
}

.string {
  color: green;
}

.number {
  color: purple;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color
  : black;
}

.modal-mask {
  z-index: 1234 !important;
}
.modal-container {
  width: 80rem !important;
}

.mx-datepicker svg {
  vertical-align: 0.85em;
}

.btn-event {
  font-size: 12px;
  margin-left: 3%;
}

.padding_0 {
  padding-top: 0px;
}

.model_width {
  width: 80rem !important;
}
//.modal-container
body{
  background-color: red !important;
}

.margin_5 {
  margin-top: 5px;
}

.margin_10 {
  margin-top: 10px;
}

.center {
  text-align: center;
}

.border_bottom {
  border-bottom: 1px solid #e6ebea;
  margin-top: 10px;
}
.container_margin {
  margin-top: 2.5rem;
}
.btn_toggle {
  border-radius: 4px;
  border: 1px solid rgb(0, 162, 255);
  padding: 8px;
  color: rgb(0, 107, 255);
  display: inline-block;
  cursor: pointer;
}
.btn_toggle:hover {
  border: 2px solid #006bff;
}
.padding_1 {
  padding: 1rem;
}
.dynamic_div {
  margin-bottom: 7rem;
  border-left: 1px solid #cad0d4;
}
.manual_row {
  border-left: 1px solid #cad0d4;
  margin-bottom: 6rem;
}
.margin_left_2 {
  margin-left: 2rem;
}
.margin_right_3 {
  text-align: right;
  margin-right: 3rem;
}
.weekly_text {
  margin-left: 1rem;
  font-weight: 600;
}

.sip-endpoint-widget-wrapper {
  display: flex;

  justify-content: flex-start;
  align-content: space-between;
  align-items: center;

  margin: 15px 0 0 0;
}

.sip-endpoint-widget-wrapper--web-widget {
    margin-bottom: 15px;
}

.sip-endpoint-widget__button {
  display: flex;

  justify-content: center;
  align-items: center;
  align-content: center;

  margin-right: 20px;

  width: 40px;

  padding: 10px;

  border-radius: 50%;

  cursor: pointer;
}

.sip-endpoint-widget__button:active {
  transform: scale(0.8);
}

.sip-endpoint-widget__call-button {
  background-color: #44ce4b;
}

.sip-endpoint-widget__call-button--disable {
  background-color: gray;
}

.sip-endpoint-widget__phone-number-label {
  color: #3c4858;

}
.model-80 {
  width: 80rem;
}
.event {
  width: 100%;
  padding-bottom: 0;
  border-bottom-width: 0;
}
.schedule {
  padding-top: 0;
  border-top-width: 0;
  margin-top: -1rem;
}

.jssip-call-wrapper {
    padding: 15px;

    border: 1px solid #f0f4f5;

    margin-top: 10px;
    margin-bottom: 10px;

    border-radius: 5px;
}

.buttons-wrapper {
    width: 180px;

}

.full-width-button {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-micro) var(--space-smaller);
}

.status-view {
  display: flex;
  align-items: baseline;

  & &--title {
    color: var(--b-600);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    margin-left: var(--space-small);

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
.button-height {
  height: 3rem !important;
}
.button-width {
  width: 3rem !important;
}
.main-contact-padding {
  padding: 1rem !important;
}
.main-contact-left-padding {
  padding-left: 1rem !important;
}
</style>
