export const sipEndpointAPI = {
    async getSipInboxMembers(accountId, inboxId) {
        const response = await axios.get(`/api/v1/accounts/${accountId}/inboxes/${inboxId}/sip_inbox_members`);

        return response;
    },

    async getSipEndpoints(accountId) {
        const response = await axios.get(`/api/v1/accounts/${accountId}/current_sip_endpoints`);

        return response;
    },

    async getToken(resource) {
        const response = await axios.post("/api/v1/accounts/jwt_token", {resource: resource}, {
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });

        return response;
    },

    async refreshToken(refresh_token) {
        const response = await axios.post("/api/v1/accounts/refresh_jwt_token", {refresh_token: refresh_token}, {
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });

        return response;
    }
}
