<template>
    <div class="vault-container">
        <div class="flex row padding_zero_space">
            <div class="columns medium-1 back-button ion-ios-arrow-left">
                <span @click="onClose()">Back</span>
            </div>
            <div class="columns medium-6 medium-offset-4 flex-dir-row display-flex justify-content-flex-end">
                <div style="margin-top: 7px; font-size: 13px;">
                    <span> Note: If documents section not loading, please click on refresh button</span>
                </div>
                <div class="ion-android-refresh refresh-button">
                    <span  @click="refreshIframe">Refresh</span>
                </div>
            </div>
        </div>
        <div class="flex row padding_zero_space">
            <div class="columns medium-12">
                <pulse-loader :loading="true" :color="color" v-if="showLoader"></pulse-loader>
                <iframe :key="iframeKey" :src="signDocsLink" allow="clipboard-read; clipboard-write"
                        sandbox="allow-scripts allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation"
                        style="height: calc(100vh - 66px);border: none;"
                        width="100%" height="80%" v-on:load="stopLoader"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
    name: "SignatureListView",
    components: {
        PulseLoader
    },
    ...mapGetters({
        currentUser: 'getCurrentUser',
    }),
    props: {
        onClose: {
            type: Function,
            required: true,
        },
        user: {
            type: Object,
            required: true
        }
    },
    created() {
        this.generateDigitalSign();
    },
    computed: {
    },
    data () {
        return {
            selectedTabIndex: 0,
            showLoader: true,
            color: 'black',
            signDocsLink: '',
            iframeKey: 0, // Initialize the key with a value
      }
    },
    methods: {
        onTabChange(selectedTabIndex) {
            this.selectedTabIndex = selectedTabIndex;
        },
        stopLoader() {
            this.showLoader = false;
        },
        generateDigitalSign() {
            const user = this.user;
            const encodedEmail = encodeURIComponent(user.email);
            this.signDocsLink = `${window.chatwootConfig.signDocsUrl}/#/?parent=conversate&name=${user.name}&reference_id=${user.account_id}&email=${encodedEmail}&digest=${encodedEmail}`;
        },
        refreshIframe() {
            this.showLoader = true; // Show the loader while the iframe is reloading
            this.iframeKey++; // Increment the key to trigger iframe reload
        },
    }
}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.settings {
    background: $color-white;

    .settings--content {
        div:last-child {
            border-bottom: 0;
        }
    }

    .tabs {
        padding: 0;
        margin-bottom: -1px;
    }
}

.medium-5 {
  flex: 0 0 41.6666666667%;
  max-width: 34.500% !important;
}
</style>
