import ApiClient from '../ApiClient';

class SignalwireChannel extends ApiClient {
  constructor() {
    super('channels/signalwire_links', { accountScoped: true });
  }

  getAvailableNumbers(payload) {
      return axios.post(`${this.url}/available_numbers`,{
          signalwire_link: {
              number_type: payload.numberType,
              iso_country: payload.isoCountry,
              area_code: payload.areaCode,
              contains: payload.contains,
              in_region: payload.inRegion
          }
      },
          {
              headers: {
                  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
              }
          });
  }

  postBusinessInformation(payload) {
      const accountId = window.location.pathname.split('/')[3];

      return axios.post(`/api/v1/accounts/${accountId}/business_informations`, {
          business_information: {
              account_type: payload.accountType,
              address: payload.address,
              address_extra: payload.addressExtra,
              authorized_name: payload.authorizedName,
              billing_phone_number: payload.billingPhoneNumber,
              city: payload.city,
              company_name: payload.companyName,
              country: payload.country,
              pin: payload.pin,
              postal_code: payload.postalCode,
              provider_account_number: payload.providerAccountNumber,
              provider_name: payload.providerName,
              ssn_last_four_characters: payload.ssnLastFourCharacters,
              state: payload.state,
              services_to_port: payload.servicesToPort,
          }
      }, {
          headers: {
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
      });
  }

  UpdateIVRSettings(payload) {
      const accountId = window.location.pathname.split('/')[3];

      return axios.put(`/api/v1/accounts/${accountId}/channels/signalwire_links/${payload.channel_id}`, {
          signalwire_link: {
              ivr_tree: payload.ivr_tree
          }
      }, {
          headers: {
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
      });
  }

  UpdateAIIVRSettings(payload) {
    const accountId = window.location.pathname.split('/')[3];

    return axios.put(`/api/v1/accounts/${accountId}/channels/signalwire_links/${payload.channel_id}`, {
        signalwire_link: {
            ai_ivr_tree: payload.ai_ivr_tree
        }
    }, {
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    });
  }

  UpdateIVRAgentSettings(payload) {
    const accountId = window.location.pathname.split('/')[3];

    return axios.put(`/api/v1/accounts/${accountId}/channels/signalwire_links/${payload.channel_id}`, {
        signalwire_link: {
            ivr_agent_tree: payload.ivr_agent_tree
        }
    }, {
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    });
  }


  createSmsBotSettings(payload) {
      return axios.post("/api/v1/sms_bots", payload, {
          headers: {
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
      });
  }

    updateSmsBotSettings(payload, id) {
        return axios.patch(`/api/v1/sms_bots/${id}`, payload, {
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
    }

    getSmsBot(id) {
        return axios.get(`/api/v1/sms_bots/${id}`);
    }

    create(data) {
        const accountId = window.location.pathname.split('/')[3];

        return axios.post(`/api/v1/accounts/${accountId}/channels/signalwire_links`, data, {
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
    }
}

export default new SignalwireChannel();
