<template>
  <div class="conv-header">
    <div class="user">
      <Thumbnail
        :src="currentContact ? currentContact.thumbnail : currentChat.chat_type === 'group' ? '' :  chatMetadata.assignee.thumbnail"
        size="40px"
        :badge="inboxBadge"
        :username="currentContact ? currentContact.name : currentChat.chat_type === 'group' ? '' : chatMetadata.assignee.name"
      />
      <div class="user--profile__meta">
        <h3 class="user--name text-truncate" v-if='chatMetadata.channel === "Channel::TeamChat" && currentChat.chat_type === "private" '>
          {{  currentChat.meta.assignee.available_name  }}
        </h3>
        <h3 class="user--name text-truncate" v-else-if='(chatMetadata.channel === "Channel::TeamChat" && currentChat.chat_type === "group") '>
          {{ inboxName(chat.inbox_id) }}
        </h3>
        <h3 class="user--name text-truncate" v-else >
          {{ currentContact ? currentContact.name : chatMetadata.assignee.name }}
        </h3>
        <div class="conversation--header--actions">
          <inbox-name :inbox="inbox" class="margin-right-small" />
          <span
            v-if="isSnoozed"
            class="snoozed--display-text margin-right-small"
          >
            {{ snoozedDisplayText }}
          </span>
          <woot-button
            v-if="chatMetadata.channel !== 'Channel::TeamChat'"
            class="user--profile__button margin-right-small"
            size="small"
            variant="link"
            @click="$emit('contact-panel-toggle')"
          >
            {{ contactPanelToggleText }}
          </woot-button>
        </div>
      </div>
    </div>
    <div
      class="header-actions-wrap"
      :class="{ 'has-open-sidebar': isContactPanelOpen }"
    >
      <div class="multiselect-box multiselect-wrap--small" v-if="chat.chat_type !== 'private'">
        <i class="icon ion-headphone" />
        <multiselect
          v-model="currentChat.meta.assignee"
          :loading="uiFlags.isFetching"
          :allow-empty="true"
          deselect-label=""
          :options="agentList"
          :placeholder="$t('CONVERSATION.ASSIGNMENT.SELECT_AGENT')"
          select-label=""
          label="name"
          selected-label
          track-by="id"
          @select="assignAgent"
          @remove="removeAgent"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <availability-status-badge
                :status="props.option.availability_status"
              />
              <span class="option__title">{{ props.option.name }}</span>
            </div>
          </template>
          <span slot="noResult">{{ $t('AGENT_MGMT.SEARCH.NO_RESULTS') }}</span>
        </multiselect>
      </div>
      <more-actions :conversation-id="currentChat.id"  v-if="chat.meta.channel !== 'Channel::TeamChat'"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MoreActions from './MoreActions';
import Thumbnail from '../Thumbnail';
import agentMixin from '../../../mixins/agentMixin.js';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import inboxMixin from 'shared/mixins/inboxMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { hasPressedAltAndOKey } from 'shared/helpers/KeyboardHelpers';
import wootConstants from '../../../constants';
import differenceInHours from 'date-fns/differenceInHours';
import InboxName from '../InboxName';
import AvailabilityStatusBadge from '../conversation/AvailabilityStatusBadge';


export default {
  components: {
    InboxName,
    MoreActions,
    Thumbnail,
    AvailabilityStatusBadge
  },
  mixins: [alertMixin, inboxMixin, agentMixin, eventListenerMixins],
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getAgents: 'inboxAssignableAgents/getAssignableAgents',
      uiFlags: 'inboxAssignableAgents/getUIFlags',
      currentChat: 'getSelectedChat',
    }),

    chatMetadata() {
      return this.chat.meta;
    },

    currentContact() {
      if(this.chatMetadata.sender) {
        return this.$store.getters['contacts/getContact'](
          this.chat.meta.sender.id
        );
      }else {
      }
    },
    agentList() {
      const { inbox_id: inboxId } = this.chat;
      const agents = this.getAgents(inboxId) || [];
      return [
        {
          confirmed: true,
          name: 'None',
          id: 0,
          role: 'agent',
          account_id: 0,
          email: 'None',
        },
        ...agents,
      ];
    },
    isSnoozed() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.SNOOZED;
    },
    snoozedDisplayText() {
      const {snoozed_until: snoozedUntil} = this.currentChat;
      if (snoozedUntil) {
        // When the snooze is applied, it schedules the unsnooze event to next day/week 9AM.
        // By that logic if the time difference is less than or equal to 24 + 9 hours we can consider it tomorrow.
        const MAX_TIME_DIFFERENCE = 33;
        const isSnoozedUntilTomorrow =
            differenceInHours(new Date(snoozedUntil), new Date()) <=
            MAX_TIME_DIFFERENCE;
        return this.$t(
            isSnoozedUntilTomorrow
                ? 'CONVERSATION.HEADER.SNOOZED_UNTIL_TOMORROW'
                : 'CONVERSATION.HEADER.SNOOZED_UNTIL_NEXT_WEEK'
        );
      }
      return this.$t('CONVERSATION.HEADER.SNOOZED_UNTIL_NEXT_REPLY');
    },

    contactPanelToggleText() {
      return `${
        this.isContactPanelOpen
          ? this.$t('CONVERSATION.HEADER.CLOSE')
          : this.$t('CONVERSATION.HEADER.OPEN')
      } ${this.$t('CONVERSATION.HEADER.DETAILS')}`;
    },
    inbox() {
      const { inbox_id: inboxId } = this.chat;
      return this.$store.getters['inboxes/getInbox'](inboxId);
    },
  },

  methods: {
    assignAgent(agent) {
      if(this.chat.meta.channel !== 'Channel::TeamChat'){
        this.$store
          .dispatch('assignAgent', {
            conversationId: this.currentChat.id,
            agentId: agent.id,
          })
          .then(() => {
            this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
          });
      }
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndOKey(e)) {
        this.$emit('contact-panel-toggle');
      }
    },
    removeAgent() {
      if(this.chat.meta.channel !== 'Channel::TeamChat'){
      this.$store
        .dispatch('assignAgent', {
          conversationId: this.currentChat.id,
          agentId: 0,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
        });
    }
  },

      onTogglePanel() {
        this.$emit('contact-panel-toggle');
      },

    inboxName(inboxId) {
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox.name || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conv-header {
  flex: 0 0 var(--space-jumbo);
}

.option__desc {
  display: flex;
  align-items: center;
}

.option__desc {
  &::v-deep .status-badge {
    margin-right: var(--space-small);
    min-width: 0;
    flex-shrink: 0;
  }
}

.user--name {
  display: inline-block;
  font-size: var(--font-size-medium);
  line-height: 1.3;
  margin: 0;
  text-transform: capitalize;
  width: 100%;
}

.conversation--header--actions {
  align-items: center;
  display: flex;
  font-size: var(--font-size-mini);

  .user--profile__button {
    padding: 0;
  }

  .snoozed--display-text {
    font-weight: var(--font-weight-medium);
    color: var(--y-900);
  }
}
</style>
