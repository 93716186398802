export default {
  GRAVATAR_URL: 'https://www.gravatar.com/avatar/',
  ASSIGNEE_TYPE: {
    ME: 'me',
    UNASSIGNED: 'unassigned',
    ALL: 'all',
    PRIVATE: 'private',
    GROUP: 'group',
  },
  STATUS_TYPE: {
    OPEN: 'open',
    RESOLVED: 'resolved',
    BOT: 'bot',
    PENDING: 'pending',
    SNOOZED: 'snoozed',
    ALL: 'all',
  },
  CHAT_DURATION: {
    TODAY: 24,
    ONE_WEEK: 7,
    TWO_WEEKS: 14,
    THREE_WEEKS: 21,
    ONE_MONTH: 31,
    TWO_MONTHS: 60,
    THREE_MONTHS: 30,
    ALL: 'all_time',
  }
};
export const DEFAULT_REDIRECT_URL = '/app/';
