<template>
<div>
  <div class="profile--settings--row row">
    <div class="columns small-12 forward-settings__main-wrapper">

      <div class="forward-settings_header">
        Forward To PSTN
      </div>

      <div>
        <input
            id="forward-settings__is-enabled-checkbox"
            v-model="isEnabled"
            class="forward-settings__checkbox"
            type="checkbox"
            @input="handleIsEnabled"
        />

        <label for="forward-settings__is-enabled-checkbox">
          Enable Forwarding
        </label>
      </div>

        <div class="forward-settings_phone-input-wrapper">
          <VuePhoneNumberInput v-model="enteredPhoneNumber"
                               default-country-code="US"
                               :no-country-selector="true"
                               @update="onNumberUpdate"
                               :disabled="!isEnabled"
          />
        </div>

        <div class="forward-settings__buttons-wrapper">

          <woot-submit-button
              button-text="Submit"
              :loading="updateInProgress"
              :disabled="!( (isEnabled  && isEnteredNumberCorrect) || (!isEnabled))"
              @click="updateForwardSettings"
          />

        </div>

    </div>

  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import vSelect from 'vue-select';
import VuePhoneNumberInput from "vue-phone-number-input";
import {globalAIP} from "../../../../api/globalAIP";
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  name: "ForwardSettings",
  components: {
    vSelect,
    VuePhoneNumberInput
  },
  props: {
    inbox: {
      type: Object
    }
  },
  data() {
    return {
      enteredPhoneNumber: "",
      isEnteredNumberCorrect: false,
      formattedPhoneNumber: "",

      inboxMembers: null,
      selectedAgents: null,

      isEnabled: false,
      updateInProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      userId: 'getCurrentUserID'
    }),
  },

  async mounted() {

    this.$nextTick(async function() {
      const response = await globalAIP.getForwardingSettings(this.accountId, this.inbox.id, this.userId);

      this.isEnabled = response.data.forward_enabled;
      this.enteredPhoneNumber = response.data.forward_to;
    });

  },

  methods: {
    onNumberUpdate(data) {
      this.isEnteredNumberCorrect = data.isValid;
      this.formattedPhoneNumber = data.e164;
    },
    handleIsEnabled(e) {
      this.isEnabled =  e.target.checked;
      if(!this.isEnabled){
        this.enteredPhoneNumber = "";
      }
    },
    buildPayload() {
      let payload = {};

      if (this.isEnteredNumberCorrect) {
        payload.forward_to = this.formattedPhoneNumber;
      } else {
        payload.forward_to = "";
      }

      payload.forward_enabled = this.isEnabled;

      console.log(payload);

      return payload;
    },
    async updateForwardSettings() {
      this.updateInProgress = true;

      const response = await globalAIP.updateForwardingSettings(this.accountId, this.inbox.id, this.userId, this.buildPayload());

      this.updateInProgress = false;
      this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      this.$store.commit('dashboard/SET_IS_FORWARDING_MODAL_OPEN', false);
    }
  }
}
</script>

<style scoped>
.forward-settings_header {
  font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Tahoma, Arial, sans-serif;

  font-size: 20px;

  display: flex;

  align-items: center;
  align-content: center;

  justify-content: center;

  margin-bottom: 10px;
}

.forward-settings__main-wrapper {
  padding: 1.6rem;
}

.forward-settings__checkbox {
  cursor: pointer;
}

.forward-settings__buttons-wrapper {
  margin-top: 10px;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}
</style>
