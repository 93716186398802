<template>
  <div class="column content-box">
    <div class="row display header_style">
      <div class="medium-12">
        <h1 class="display page-title">
          <i class="icon ion-arrow-graph-up-right header--icon"></i><span>{{ $t('MEETING_PAGE.HEADER') }}</span>
        </h1>
      </div>
    </div>

    <div class="row" style="display: flex; background-color: #f9fafc; border-top: 1px solid #e0e6ed;">
      <section class="contacts-table-wrap">
        <empty-state
            v-if="showSearchEmptyState"
            :title="$t('CONTACTS_PAGE.LIST.404')"
        />
      </section>
      <div class="small-11 columns table-margin-left-3">
        <table class="woot-table">
<!--           Header-->
          <thead>
          <th
              v-for="thHeader in $t('MEETING_PAGE.LIST.TABLE_HEADER')"
              :key="thHeader"
          >
            {{ thHeader}}
          </th>
          </thead>
          <tbody>
          <tr v-for="history in getScheduleHistory.schedules">
            <td>
              <span class="">{{ history.id }}</span>
            </td>
            <td>
              <span class="">{{ history.organizer }}</span>
            </td>
            <td>
              <span class="">{{ history.attendees }}</span>
            </td>
            <td class="summary_box">
              <span >{{ history.description }}</span>
            </td>
            <td class="summary_box">
              <span >{{ history.summary }}</span>
            </td>
            <td class="time_box">
              <span class="">{{ IntoTime(history.start_date) }} - {{ IntoTime(history.end_date) }} <br> {{ IntoDate(history.end_date) }} </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import EmptyState from "../../../../components/widgets/EmptyState";
import moment from "moment";

export default {
  name: "ScheduleHistory",
  components: {
    EmptyState
  },
  props: {
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getScheduleHistory: 'contacts/getScheduleHistory'
    }),
  },
  data() {
    return {
      meetingList: []
    }
  },
  mounted() {
    this.getScheduleList();
  },
  methods: {
    async getScheduleList() {
      try {
        let contactId = this.$route.params.contact_id
        await this.$store.dispatch('contacts/fetchMeetingList', contactId)
        this.meetingList = this.getScheduleHistory;
      }catch (e) {
        console.log("error in" , e)
      }
    },
    IntoTime(timed) {
      return moment(timed).format('h:mm a');
    },
    IntoDate(timed) {
      return moment(timed).format('YYYY-MM-DD');
    },
  }
}
</script>

<style scoped>
.label-color--container {
  display: flex;
  align-items: center;
}

.settings {
  overflow: auto;
}

.display {
  display: flex;
}

.header_style {
  margin-bottom: 10px;
  margin-left: 5%;
}

.table-margin-left-3 {
  margin-left: 3rem;
  margin-top: 1rem;
}
.content-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.summary_box {
  width: 22rem;
}
.time_box {
  width: 15rem;
}
</style>
