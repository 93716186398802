<template>
    <div class="vault-container">
        <div class="row flex padding_zero_space">
            <span class="columns medium-1 back-button ion-ios-arrow-left" @click="onClose()">Back</span>
            <span title="upload files" class="columns medium-1 cursor-pointer ml_3"  @click="showFileModel"> <i class="ion-android-add-circle"></i> Add File </span>
            <div class="columns medium-3 medium-offset-2 search-wrap">
              <i class="ion-ios-search-strong search-icon" />
              <input
                      type="text"
                      :placeholder="$t('FOLDER_MGMT.FILE_SEARCH.PLACEHOLDER')"
                      class="contact-search"
                      :value="searchQuery"
                      @keyup.enter="onSearchSubmit"
                      @input="onInputSearch"
              />
              <woot-button
                      class="search_btn"
                      :is-loading="false"
                      @click="onSearchSubmit"
              >
                {{ $t('FOLDER_MGMT.FILE_SEARCH.TITLE') }}
              </woot-button>
            </div>
        </div>
        <div class="row">
            <div class="medium-2 columns list-table">
                <ul class="table_component_ul">
                    <li v-for="(folder, index) in folders" :class="folder.id === folderId ? 'active' : 'not-active'"
                        @click.prevent="swapFolder(folder)" class="cursor-pointer">
                        {{folder.name}}
                    </li>
                </ul>
            </div>
            <div class="medium-8 columns medium-offset-1" v-if="folderAttachments.length">
                <div class="main-body">
                    <div class="body-component" v-for="(file, index) in folderAttachments" :key="file.id">
                        <ul>
                            <li @click="preview(filePath(file))">
                                <img  :src=filePath(file)  v-if="file.content_type === 'image/png' || file.content_type === 'image/jpg'">
                                <img  src="/google-docs.png" class="docs" v-else />
                            </li>
                            <li class="list-name">
                                <p>
                                    {{ file.name }}
                                </p>
                            </li>
                            <li class="list-size">
                                <p>
                                    {{ formatSize(file.size) }}
                                </p>
                            </li>
                            <li class="list-icons">
                                <i title="preview" @click="preview(filePath(file))" class="preview cursor-pointer icon ion-eye"></i>
                                <i title="download" @click="download(filePath(file),  file.name)" class="download cursor-pointer icon ion-android-download"></i>
                                <i title="share" v-if="!folderShared" @click="showAgentsPopup(file, 'file', 'linkedFile')" class="ion-android-share-alt download cursor-pointer"></i>
                                <i title="delete" v-if="!folderShared" @click="openFileDeletePopup(file)" class="remove icon cursor-pointer ion-android-delete"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="medium-4 columns medium-offset-4 no-transactions-found m_t" v-else>
                Empty folder
            </div>
        </div>
        <upload-file
                :on-close="closeFileModel"
                :folder-id="folderId"
                v-if="showModelFile"
        />
        <woot-delete-modal
                :show.sync="showFileDeletePopup"
                :on-close="closeFileDeletePopup"
                :on-confirm="deleteFile"
                :title="$t('FOLDER_MGMT.FILE_DELETE.CONFIRM.TITLE')"
                :message="deleteMessage"
                :confirm-text="deleteConfirmText"
                :reject-text="deleteRejectText"
        />
        <share-files  v-if="showAgentModel"
                      :vault-type="vaultType"
                      :on-close="closeAgentsPopup"
                      :vault-id="vaultId"
                      :file-type="fileType"
                      :vault-name="vaultName"
                      :file-url="fileUrl"
        />
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import UploadFile from "./UploadFile"
import Image from "../../../../components/widgets/conversation/bubble/Image";
import shareFiles from './ShareFiles'
import {isEmpty} from "jssip/lib/Utils";

export default {
    components: {Image, UploadFile, shareFiles},
    data() {
        return {
            showModelFile: false,
            showFileDeletePopup: false,
            currentFileId: '',
            searchQuery: '',
            AllRecords: [],
            showAgentModel: false,
            vaultType: '',
            vaultId: '',
            fileType: '',
            vaultName: '',
            fileName: '',
            fileUrl: '',
        }
    },
    computed: {
        ...mapGetters({
            folderAttachments: 'getFoldersAttachments'
        }),
        deleteConfirmText() {
            return `${this.$t('FOLDER_MGMT.DELETE.CONFIRM.YES')}`;
        },
        deleteRejectText() {
            return `${this.$t('FOLDER_MGMT.DELETE.CONFIRM.NO')}`;
        },
        deleteMessage() {
            return `${this.$t('FOLDER_MGMT.DELETE.CONFIRM.MESSAGE')}`;
        },
    },
    props: {
        onClose: {
            type: Function,
            required: true,
        },
        folderId: {
            type: String,
            required: false,
        },
        folders: {
            type: Array,
            required: false,
        },
        folderShared: {
            type: Boolean,
            default: false,
        }
    },
    async mounted() {
        await this.$store.dispatch('get_attachments', this.folderId)
    },
    methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        showFileModel() {
            this.showModelFile = true;
        },
        closeFileModel() {
            this.showModelFile = false;
        },
        openFileDeletePopup(file) {
            this.showFileDeletePopup = true;
            this.showModelFile = false;
            this.currentFileId = file.id;
            this.fileName = file.name;
            this.fileUrl = `${window.chatwootConfig.fileCloudUrl}/${file.short_path}`;
        },
        closeFileDeletePopup() {
            this.showFileDeletePopup = false;
            this.fileUrl = '';
        },
        async deleteFile() {
            try {
                await this.$store.dispatch('delete_folder_files', {"id": this.currentFileId});
                this.showAlert(this.$t('FOLDER_MGMT.FILE_DELETE.API.SUCCESS_MESSAGE'));
                this.currentFileId = '';
                this.closeFileDeletePopup();
                this.$store.dispatch('addLog', {
                    logType: 'file',
                    logAction: 'delete',
                    name: this.fileName,
                    fileUrl: this.fileUrl,
                });
                this.fileUrl = ''
                this.fileName = '';
            } catch (error) {
                this.showAlert(this.$t('FOLDER_MGMT.FILE_DELETE.API.ERROR_MESSAGE'));
            }
        },
        formatSize(size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size?.toString() + ' B'
        },
        filePath(file) {
            return `${window.chatwootConfig.fileCloudUrl}/${file.short_path}`;
        },
        async swapFolder(folder) {
            this.folderId = folder.id;
            this.folderShared = folder.is_shared;
            await this.$store.dispatch('get_attachments', folder.id)
        },
        download(url, name) {
            let vm = this;
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', name);
                document.body.appendChild(fileLink);
                vm.fileUrl = fileLink;
                fileLink.click();
            });
            this.$store.dispatch('addLog', {
                logType: 'file',
                logAction: 'download',
                name: name,
                fileUrl: this.fileUrl,
            });
        },
        preview(url) {
            window.open(url)
        },
        async onSearchSubmit() {
            if (this.searchQuery) {
                this.AllRecords = this.folderAttachments;
              const resp = await  this.$store.dispatch('search_records', {
                    search: this.searchQuery,
                });
                this.$store.dispatch('set_files_record', resp);
            }
        },
        onInputSearch(event) {
            this.searchQuery = event.target.value;
            if(isEmpty(this.searchQuery) && this.AllRecords.length) {
                this.$store.dispatch('set_files_record', this.AllRecords);
            }
        },
        showAgentsPopup(vault, type, fileType) {
            this.vaultId = vault.id;
            this.vaultType = type;
            this.fileType = fileType;
            this.vaultName = vault.name;
            this.showAgentModel = true;
            this.fileUrl = `${window.chatwootConfig.fileCloudUrl}/${vault.short_path}`;
        },
        closeAgentsPopup() {
            this.vaultId = '';
            this.vaultType = '';
            this.fileType = '';
            this.vaultName = '';
            this.showAgentModel = false;
            this.fileUrl = ''
        },
    }
}
</script>
<style>
.main-body {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}
.body-component {
    word-break: break-all;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    max-width: 172px;
}
.body-component ul {
    list-style: none;
    margin-bottom: 20px;
}
.body-component ul li img {
    width: 150px;
    height: 80px
}
.body-component ul li .docs {
    width: 141px;
}
.list-name {
    word-break: normal;
    flex-wrap: wrap;
}
.list-name p {
    word-break: break-all;
    padding: 10px 0 0 0;
}
.list-size p {
    font-size: 1.2rem;
    margin-bottom: 3px;
}
.list-icons .preview {
    width: 20px;
    font-size: 20px;
    padding: 0 10px;
}
.list-icons .download {
    font-size: 20px;
    padding: 0 10px;
}
.list-icons .remove {
    font-size: 20px;
    padding: 0 10px;
}
.list-table {
    border-top: 1px solid #e0e6ed;
}
.header_position {
 display: flex;
 margin-bottom: 2rem;
 padding-left: 1rem;
}
.ml_3 {
    font-size: 17px;
    margin-right: 2%;
    display: flex;
    align-items: center;
}
.table_component_ul {
    list-style: none;
    background-color: white;
    margin-top: 3%;
    cursor: pointer;
}
.table_component_ul li {
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 3%;
    border-radius: 0.4rem;
}
.active {
    color: #1f93ff;
    background: #f4f6fb;
}
.not-active {
    color: #6e6f73;
}
.search-wrap {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 0.8rem;
}
.search-icon {
    position: absolute;
    top: 1px;
    left: 1rem;
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: 1.8rem;
    color: #5A5B5F;
}
.contact-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-left: 3.2rem;
    padding-right: 6rem;
    border-color: #C8D6E6;
}

.search_btn {
    margin-left: 0.8rem;
    height: 3rem;
    right: 0.4rem;
    position: absolute;
    padding: 0 0.8rem;
    transition: transform 100ms linear;
    transform: translateX(-1px);
}

.search_btn.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
}
.bottom-margin {
    margin-bottom: 0px;
}
.padding_zero_space {
    padding: 0.5% 0;
}
.m_t {
 margin-right: 3%;
}

</style>
