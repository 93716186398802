<template>
  <div class="column app-content-background">
    <div class="header page-title">
      <div class="row">
        <h1 class="page-title_credits">
          <woot-sidemenu-icon />
          <i class="icon ion-cash" style="margin-right: 1.6rem"></i>
          {{ $t('CREDIT_HISTORY.HEADER') }}
        </h1>
      </div>
    </div>
    <div class="content-box">
      <div class="row current-balance">
        <div>Current balance:</div>
        <div class="balance-value">
          <span>{{ balance }}</span> credits
        </div>
      </div>
      <TransactionsHistory />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TransactionsHistory from './../components/TransactionsHistory.vue';

export default {
  components: {
    TransactionsHistory,
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),
    balance() {
      let account = this.getAccount(this.accountId);

      return account ? account.credit_balance : '0.0';
    },
  },
};
</script>

<style scoped lang="scss">
.app-content-background {
  background-color: #f9fafc;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.page-title {
  margin: 0;
  border-bottom: 1px solid #e0e6ed;
  background-color: #ffffff;
}
.page-title_credits {
  display: flex;
  margin: 0;
  padding: 1rem;
  font-size: 2.4rem;
}
.content-box {
  padding: 1.6rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.current-balance {
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  margin: var(--space-large) 0;
}
.balance-value {
  font-size: 1rem;
  span {
    font-size: 2.8rem;
    padding: 0 0 0 8px;
    font-weight: var(--font-weight-bold);
  }
}
</style>
