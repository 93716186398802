<template>
  <div class="column app-content-background">
    <div class="header page-title">
      <div class="row text-right">
        <div class="medium-3">
          <h1 class="page-title_calendar">
              <woot-sidemenu-icon />
            <i class="icon ion-android-calendar" style="margin-right: 1.6rem"></i>
            {{ $t('CREATE_CALENDER.SCHEDULE') }}
          </h1>
        </div>
<!--        <div class="medium-3 medium-offset-6">-->
<!--          <multiselect-->
<!--              v-model="selectAgent"-->
<!--              :options="agentList"-->
<!--              track-by="id"-->
<!--              label="name"-->
<!--              :multiple="false"-->
<!--              :close-on-select="false"-->
<!--              :clear-on-select="false"-->
<!--              :hide-selected="true"-->
<!--              placeholder="Agent Bookings"-->
<!--              selected-label-->
<!--              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"-->
<!--              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"-->
<!--              :class="'padding_1'"-->
<!--              @select="searchBookings"-->
<!--          /></div>-->
      </div>
    </div>

    <!-- List Calendar Response -->
    <div class="row row_custom">
      <div class="medium-12 small-12 columns margin_top_1">
        <pulse-loader :loading="true" :color="color" v-if="showLoader"></pulse-loader>
        <iframe :src="iframeLink" allow="clipboard-read; clipboard-write"
                sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox   allow-presentation allow-same-origin allow-scripts  allow-top-navigation allow-top-navigation-by-user-activation"
                style="height: calc(100vh - 66px);border: none;"
                width="100%" height="100%" v-on:load="stopLoader"></iframe>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import alertMixin from "../../../../../shared/mixins/alertMixin";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  components: {
    PulseLoader
  },
  computed: {
    ...mapGetters({
      getCalendars: 'agents/getCalendars',
      currentUser: 'getCurrentUser',
      agentList: 'agents/getAgents',
    }),
    getCalendsoLink() {
      this.iframeLink = `${window.chatwootConfig.calendsoLink}auth/login?token=${this.jwt}`;
      return this.iframeLink;
    }
  },

  data() {
    return {
      showCalenderModal: false,
      showLoader: true,
      calender_name: '',
      color: 'black',
      items: undefined,
      api: undefined,
      authorized: 'false',
      showDeletePopup: false,
      selectedAgents: [],
      calendar_id: '',
      jwt: '',
      selectAgent: [],
      iframeLink: ''
    }
  },
  created() {
    this.currentUser;
    this.jsonToken();
    this.$store.dispatch('agents/get')
  },
  methods: {
    async userCalendarsInfo() {
      try {
        await this.$store.dispatch('agents/getUserCalendars')
      } catch (error) {
        console.log(error)
      }
    },
    jsonToken() {
      try {
        this.$store.dispatch('agents/jwtToken', '').then(res => {
          this.jwt = res?.token
          this.iframeLink = `${window.chatwootConfig.calendsoLink}auth/login?token=${res?.token}`;
        })
      } catch (error) {
        throw new Error(error);
      }
    },
    stopLoader() {
      this.showLoader = false;
    },
    searchBookings(agent) {
      try {
        let vm = this;
        this.$store.dispatch('agents/jwtToken', agent).then(res => {
          vm.jwt = res?.token
          vm.iframeLink = `${window.chatwootConfig.calendsoLink}auth/login?token=${res?.token}&callbackUrl=%2Fbookings%2Fupcoming`
        })
      } catch (error) {
        throw new Error(error);
      }
    }
  }
}
</script>

<style scoped>
.short-code {
  width: 17rem;
}
  .page-title {
    margin: 0;
    border-bottom: 1px solid #e0e6ed;
    background-color: #ffffff;
  }
  .page-title_calendar {
    display: flex;

    margin: 0;
    padding: 1rem;
    font-size: 2.4rem;
  }
  .calendar_button {
    padding: 1rem;
  }
  table thead th {
    font-weight: 500;
    padding-bottom: 1rem;
    padding-top: 14px;
    padding-left: 4rem;
  }
  table tbody tr {
    padding: 1%;
  }
  table tbody td {
    padding-left: 4rem;
  }
  .margin_top_1 {
    margin-top: 1rem;
  }
  .padding_1 {
    padding-top: 4px;
    margin-bottom: 6px;
  }
  .app-content-background{
    background-color: #f9fafc;
    overflow: auto;
  }
</style>
