<template>
    <div>
      <modal :on-close="closeAiLanguagesMenu" :show="isAiLanguagesMenuOpen">
        <div class="ai-language-wrapper">
          <div class="ai-language-menu">
            <div class="ai-language_header">
              Add Voice
            </div>
            <div>
                <div class="form-block">
                    <label>
                        Language
                        <select name="Language"
                            @change="onChangeLanguage"
                            v-model="enteredLanguage.name"
                        >
                            <option v-for="supportedLanguage in supportedLanguages"
                                :key="supportedLanguage"
                                :value="supportedLanguage"
                            >
                                {{supportedLanguage}}
                            </option>
                        </select>
                    </label>
                    <label>
                        Gender
                        <select name="gender"
                                @change="onChangeGender"
                                v-model="enteredLanguage.gender"
                        >
                            <option v-for="supportedGender in supportedGenders"
                                    :key="supportedGender"
                                    :value="supportedGender"
                            >
                                {{supportedGender}}
                            </option>
                        </select>
                    </label>
                    <label>
                        Voice
                        <select name="voice"
                                @change="onChangeVoice"
                                v-model="enteredLanguage.voice"
                        >
                            <option v-for="supportedVoice in supportedVoices"
                                    :key="supportedVoice"
                                    :value="supportedVoice"
                            >
                                {{supportedVoice}}
                            </option>
                        </select>
                        <!-- <span class="field-description">
                          Find audio samples in the <a href="https://cloud.google.com/text-to-speech/docs/voices">Google Cloud Voices docs.</a>
                        </span> -->
                    </label>
                    <div class="ai-language__button-wrapper">
                      <woot-submit-button
                          button-text="Submit"
                          @click="enterLanguage"
                      />
                    </div>
            </div>
          </div>
        </div>
        </div>
      </modal>
      <modal :on-close="closeAiFunctionsMenu" :show="isAiFunctionsMenuOpen">
        <div class="ai-language-wrapper">
          <div class="ai-language-menu">
            <div class="ai-language_header">
              Add Function
            </div>
            <div>
              <label>
                Name
                <textarea
                    class="low-input"
                    v-model.trim="functionName"
                    type="text"
                />
              </label>
              <label>
                Purpose
                <textarea
                    class="low-input"
                    v-model.trim="functionPurpose"
                    type="text"
                />
              </label>
              <label>Arguments</label>
              <table class="bordered-table">
                <thead>
                    <tr>
                      <th>
                          Name
                      </th>
                      <th>
                          Description
                      </th>
                      <th>
                          Actions
                      </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                      v-for="argument in functionArguments"
                    >
                      <td>
                        <textarea
                          class="low-input"
                          v-model.trim="argument.name"
                          type="text"
                        />
                      </td>
                      <td>
                        <textarea
                          class="low-input"
                          v-model.trim="argument.description"
                          type="text"
                        />
                      </td>
                      <td> 
                        <div
                          v-if="functionArguments.length !== 1"
                          @click="() => {deleteRowArgument(argument)}"
                        >
                          <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
              <div class="ai-functions__button-wrapper">
                      <woot-submit-button
                          button-text="Add Argument"
                          @click="AddArgument"
                      />
              </div>
              <label>
                Web Hook URL
                <textarea
                    class="low-input"
                    v-model.trim="webhookUrl"
                    type="text"
                />
              </label>
              <label>
                Web Hook Auth User (Optional)
                <textarea
                    class="low-input"
                    v-model.trim="webhookAuthUser"
                    type="text"
                />
              </label>
              <label>
                Web Hook Auth Password (Optional)
                <textarea
                    class="low-input"
                    v-model.trim="webhookAuthPassword"
                    type="text"
                />
              </label>
            </div>
            <div>
                <div class="form-block">
                    <div class="ai-language__button-wrapper">
                      <woot-submit-button
                          button-text="Submit"
                          @click="enterFunction"
                      />
                    </div>
            </div>
          </div>
        </div>
        </div>
      </modal>
    <div v-if="agents.length !== 0" class="medium-12 columns ivr-form">
      <div style="margin-bottom: 15px">
        <label class="ivr-form__menu-label">
          AI Interactive Voice Response
        </label>
  
        <span class="field-description">
          Most business phone systems feature an AI Interactive Voice Response menu that helps direct callers to the right place. You can collect voicemails and automatically transfer callers to the proper department.
        </span>
      </div>

      <div class="row">
        <div>
          <label>
            AI Agent
            <select
              v-model="aiAgent"
              @change="onChangeAiAgent"
              class="status--filter"
            >
              <option value=null disabled selected>
                Select an existing AI Agent
              </option>
              <option
                v-for="item in aiAgentList"
                :key="item.id"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </label>
        </div>
      </div>
      <!-- v-if="aiAgent" -->
      <div v-if="aiAgent">
        <div class="ivr-item-wrapper">
          <label>
            AI Voices
          </label>
          <div class="form-block">
            <div class="languages-table">
              <table class="bordered-table">
                <colgroup>
                    <col style="width: 30%" />
                    <col style="width: 30%" />
                    <col style="width: 30%" />
                    <col style="width: 10%" />
                </colgroup>

                <thead>
                    <tr>
                      <th>
                          Name
                      </th>
                      <th>
                          Gender
                      </th>
                      <th>
                          Voice
                      </th>
                      <th>
                        Actions
                      </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                      v-for="language in selectedLanguages"
                      :key="language.code"
                    >
                      <td>{{ language.name }}</td>
                      <td>{{ language.gender }}</td>
                      <td>{{ language.voice }}</td>
                      <td> 
                        <div
                          v-if="selectedLanguages.length !== 1"
                          @click="() => {deleteRow(language)}"
                        >
                          <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
          </div>
        </div>
        </div>
        <div class="ivr-form__buttons-wrapper">
        <woot-submit-button
            button-text="Add Voice"
            @click="addLanguage"
        />
        </div>
        <div class="ivr-item-wrapper">
          <label>
            Functions
          </label>
          <div class="form-block">
            <div class="languages-table">
              <table class="bordered-table">
                <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 75%" />
                    <col style="width: 10%" />
                </colgroup>

                <thead>
                    <tr>
                      <th>
                          Name
                      </th>
                      <th>
                          Purpose
                      </th>
                      <th>
                          Actions
                      </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                      v-for="aiFunction in aiFunctions"
                      :key="aiFunction.name"
                    >
                      <td>{{ aiFunction.name }}</td>
                      <td>
                        {{ aiFunction.purpose }}
                      </td>
                      <td> 
                        <div
                          @click="() => {deleteRowFunction(aiFunction)}"
                        >
                          <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
          </div>
        </div>
        <div>
        </div>
        </div>
        <div class="ivr-form__buttons-wrapper">
          <woot-submit-button
              button-text="Add Function"
              @click="addFunction"
          />
        </div>
        <div>
          <input
              id="sms-bot-settings__is-ai-ivr-enabled-checkbox"
              v-model="isEnabled"
              class="sms-bot-settings__checkbox"
              type="checkbox"
              @input="handleIsEnabled"
          />
    
          <label for="sms-bot-settings__is-enabled-checkbox">
            Enable AI IVR
          </label>
        </div>
      <div>
        <div v-if="isEnabled">
          <label>
            Departments
          </label>
        </div>

        <div v-if="isEnabled" class="form-block"
           v-for="(item, index) in menuItems"
          >
          <div class="delete-icon"
              v-if="menuItems.length !== 1"
              @click="() => {deleteItem(index)}"
            >
            <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
          </div>


          <div class="ivr-item-wrapper">
            <label :class="{ error: menuItems[index].error_keyword }">
              Department name

              <textarea
                  class="low-input"
                  v-model.trim="menuItems[index].department_name"
                  @input="onDepartmentNameInput($event, index)"
                  type="text"
                  placeholder="Enter department name, e.g. 'Support'"
              />
            </label>


            <label :class="{ error: menuItems[index].error_response }">
              Agents
              <Multiselect
                v-model="item.agents"
                :options="agents"
                :multiple="true"
                track-by="id"
                label="name"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                placeholder="Any Available Agent (By Default)"
                selected-label
              >
              </Multiselect>
            </label>
            <div v-if="inbox.working_hours_enabled">
              <input
                  v-model="menuItems[index].availability"
                  class="sms-bot-settings__checkbox"
                  type="checkbox"
              />
        
              <label>
                Available after business hours
              </label>
            </div>
          </div>
        </div>
        <div v-if="isEnabled" class="ivr-form__buttons-wrapper">
          <woot-submit-button
              button-text="Add Department"
              @click="addItem"
          />
        </div>
        <div>
          <label>
            No Available Agents Message
            <textarea
                class="prompt-textarea"
                v-model.trim="unavailableText"
                type="text"
                placeholder="To leave a message for the agent, please leave a detailed message with your full name, phone number, email address and your message you would like forwarded. "
          />
          </label>
        </div>
        <div>
          <input
              id="missed-call-menu__is_recording_enabled-checkbox"
              v-model="call_recording_enabled"
              class="missed-call-menu__checkbox"
              type="checkbox"
              @input="handleIsIVRRecordingEnabled($event)"
          />
          <label for="missed-call-menu__is_recording_enabled-checkbox">
            Record customer calls with IVR Agent
          </label>
          </div>
      
          <input
              id="missed-call-menu__is-enabled-checkbox"
              v-model="ivr_extensions_enabled"
              class="missed-call-menu__checkbox"
              type="checkbox"
              @input="handleIsIVRExtensionsEnabled($event)"
          />
          <label for="missed-call-menu__is-enabled-checkbox">
            Enable IVR Extensions
          </label>
          <div v-if="ivr_extensions_enabled">
            <div class="ivr-item-wrapper">
              <div class="ivr-extensions_body-modal" v-for="agent in agents">
                  <label>
                      {{agent.name}}
                  </label>
                  <input class="ivr-extensions_input-number" type="number" @keydown="onPress" v-model="ivr_extensions.find(obj => {return obj.agent === agent.id}).extension_code" v-on:change="onInutIvrNumbers($event, agent.id)" min="100" max="999"/>
              </div>
            </div>
          </div> 
      </div>
      <div class="ivr-form__buttons-wrapper">
        <woot-submit-button
            v-if="agentId == null"
            button-text="Create"
            :loading="updateInProgress"
            @click="updateIVRSettings"
        />
        <woot-submit-button
            v-if="agentId != null"
            button-text="Update"
            :loading="updateInProgress"
            @click="updateIVRSettings"
        />
        <woot-submit-button
            v-if="agentId != null"
            id="ivr-form__buttons-wrapper--destroy"
            button-text="Destroy"
            :loading="updateInProgress"
            @click="destroyIVRAgent"
        />
      </div>
    </div>
  </div>
  </div>
  </template>
  
  <script>
  import vSelect from 'vue-select';
  import Multiselect from "vue-multiselect";
  import 'vue-select/dist/vue-select.css';
  import signalwireChannelAPI from "../../../../../../../api/channel/signalwireChannel";
  import {globalAIP} from "../../../../../../../api/globalAIP";
  import {sipEndpointAPI} from "../../../../../../../api/SipEndpointAPI";
  import {mapGetters} from "vuex";
  import Modal from '../../../../../../../components/Modal';
  import alertMixin from 'shared/mixins/alertMixin';
  // import {ivrAgentAPI} from "../../../../../../../api/ivrAgentApi";

  const JUSTSCHED_URL= `${window.chatwootConfig.calendsoLink}api/teams/event-type-info`
  
  export default {
    mixins: [alertMixin],
    name: "IvrForm",
    components: {
      vSelect,
      Multiselect,
      Modal
    },
    props: {
      agents: {
        type: Array,
        default: [],
        required: false
      },
      inbox: {
        type: Object
      }
    },
    data() {
      return {
        sortedAgents: null,
        aiAgent: null,
        agentId: null,
        sipAgents: null,
        enteredLanguage: {name: "", gender: "", voice: ""},
        pickedLanguage: "",
        pickedGender: "",
        prompt: "",
        aiFunctions: [],
        isAiFunctionsMenuOpen: false,
        functionArguments: [{name: "", description: ""}],
        functionName: "",
        functionPurpose: "",
        working_hours: null,
        webhookUrl: "",
        webhookAuthUser: "",
        webhookAuthPassword: "",
        selectedLanguages:[{name: "English (United States)", gender: "male", voice: "Liam (American)"}],
        supportedLanguages: [],
        supportedGenders: ["male", "female"],
        supportedVoices:[],
        isAiLanguagesMenuOpen: false,
        call_recording_enabled: false,
        ivr_extensions_enabled: false,
        ivr_extensions: [],
        updateInProgress: false,
        isEnabled: false,
        linkColor: "#4e80cc",
        unavailableText: "",
        menuItems: [
          {
            department_name: "",
            agents: [],
            availability: false
          }
        ],
        faqItems: [
          {
            question: "",
            answer: "",
          }
        ],
        attributesItems: [""],
      }
    },
      async mounted() {
        this.$store.dispatch('agents/getAIAgents');
        try{
          const response =  await sipEndpointAPI.getSipInboxMembers(this.accountId, this.inbox.id);
          this.sipAgents = response.data.sip_agents;
          this.sortedAgents = this.agents.map(agent => { return {id: agent.id, name: agent.name, sip_username: response.data.sip_agents.filter(endpoint => { if(agent.name == endpoint.name){return endpoint}})[0].sip_username}});
        }
        catch(error) {
          console.log(error);
        }
        try{
          const response = await axios.get(
            `https://agentapi.conversate.us/v1/ivr/get_languages`
          );
          this.supportedLanguages = response.data.languages;
        }
        catch(error) {
          console.log(error);
        }
        
        this.isEnabled = this.inbox.is_new_ivr_enabled;
          if (this.inbox.ivr_agent_tree) {
              this.aiAgent = this.inbox.ivr_agent_tree.ai_agent ? this.inbox.ivr_agent_tree.ai_agent : null;
              this.agentId = this.inbox.ivr_agent_tree.agent_id != null ? this.inbox.ivr_agent_tree.agent_id : null;
              this.ivr_extensions_enabled = this.inbox.ivr_agent_tree.ivr_extensions_enabled ? this.inbox.ivr_agent_tree.ivr_extensions_enabled : false;
              this.call_recording_enabled = this.inbox.ivr_agent_tree.call_recording_enabled ? this.inbox.ivr_agent_tree.call_recording_enabled : false;
              this.aiFunctions = this.inbox.ivr_agent_tree.functions ? this.inbox.ivr_agent_tree.functions : [];
              this.unavailableText = this.inbox.ivr_agent_tree.no_available_agents_message ? this.inbox.ivr_agent_tree.no_available_agents_message : "";
              this.selectedLanguages = this.inbox.ivr_agent_tree.languages ? this.inbox.ivr_agent_tree.languages : [{name: "English (United States)", gender: "male", voice: "Liam (American)"}];
              if(this.inbox.ivr_agent_tree.ivr_extensions){
                this.ivr_extensions = this.inbox.ivr_agent_tree.ivr_extensions;
                for(const agent of this.agents){
                  if(!this.ivr_extensions.find(obj => {
                    return obj.agent === agent.id;
                  })){
                    this.ivr_extensions.push({agent: agent.id, extension_code: null});
                  }
  
                }
              }
              else{
                for(const agent of this.agents){
                  this.ivr_extensions.push({agent: agent.id, extension_code: null})
                }
              }
              this.menuItems = this.inbox.ivr_agent_tree.ivr_tree ? this.inbox.ivr_agent_tree.ivr_tree.map(menuItem => {return {department_name: menuItem.department_name, agents: this.agents.filter(agent => menuItem.agents.includes(agent.id)), availability: menuItem.availability}}) :  [{department_name: "", agents: [], availability: false}];
          }
      },
      watch: {
        agents(){
          this.sortedAgents = this.agents.map(agent => { return {id: agent.id, name: agent.name, sip_username: this.sipAgents.filter(endpoint => { if(agent.name == endpoint.name){return endpoint}})[0].sip_username}});
          if(this.inbox.ivr_agent_tree.ivr_extensions){
            this.ivr_extensions = this.inbox.ivr_agent_tree.ivr_extensions;
            for(const agent of this.agents){
              if(!this.ivr_extensions.find(obj => {
                return obj.agent === agent.id;
              })){
                this.ivr_extensions.push({agent: agent.id, extension_code: null});
              }
  
            }
          }
          else{
            for(const agent of this.agents){
              this.ivr_extensions.push({agent: agent.id, extension_code: null})
            }
          }
          this.menuItems = this.inbox.ivr_agent_tree.ivr_tree ? this.inbox.ivr_agent_tree.ivr_tree.map(menuItem => {return {department_name: menuItem.name, agents: this.agents.filter(agent => menuItem.agents.includes(agent.id)), availability: menuItem.availability}}) :  [{department_name: "", agents: [], availability: false}];
        }
      },
      computed: {
        ...mapGetters({
          aiAgentList: 'agents/getAiAgents',
          accountId: 'getCurrentAccountId',
          currentUser: 'getCurrentUser'
        }),
  
      ivrAgentTree: function () {
        let ivr_agent_tree = {};
        ivr_agent_tree.languages = this.selectedLanguages;
        ivr_agent_tree.functions = this.aiFunctions;
        ivr_agent_tree.agent_id = this.agentId;
        ivr_agent_tree.ivr_enabled = this.isEnabled;
        ivr_agent_tree.call_recording_enabled = this.call_recording_enabled;
        ivr_agent_tree.ivr_extensions_enabled = this.ivr_extensions_enabled;
        if (this.ivr_extensions_enabled){
          ivr_agent_tree.ivr_extensions = this.ivr_extensions.length === 0 ? [] : this.ivr_extensions.filter(obj => obj.extension_code !== null);
        }
        if (this.isEnabled){
          ivr_agent_tree.ivr_tree = this.menuItems.map(item => { return {department_name: item.department_name, agents: item.agents.length > 0 ? item.agents.map(obj => obj.id) : this.agents.map(obj => obj.id), availability: item.availability}});
        }
        if(this.aiAgent){
          ivr_agent_tree.ai_agent = this.aiAgent;
          ivr_agent_tree.test_agent = false;
          ivr_agent_tree.agent_name = this.aiAgent.name;
          ivr_agent_tree.company_name = this.aiAgent.company_name;
          ivr_agent_tree.personality = this.aiAgent.personality;
          ivr_agent_tree.prompt = this.aiAgent.prompt;
          ivr_agent_tree.assigned_phone_number = this.inbox.phone_number;
          ivr_agent_tree.template_details = this.aiAgent.template_detail;
          ivr_agent_tree.training = this.aiAgent.training;
          ivr_agent_tree.website_content = this.aiAgent.web_content.content ? this.aiAgent.web_content.content : " ";
          ivr_agent_tree.greeting_message = this.aiAgent.greeting;
          ivr_agent_tree.assistant_id = this.aiAgent.assistant_id;
          ivr_agent_tree.scheduling_url = this.aiAgent.schedule_link;
          let collectionAttributes = [];
          if (this.aiAgent.collect_name) {
            collectionAttributes.push('name');
          }
          if (this.aiAgent.collect_email) {
            collectionAttributes.push('email');
          }
          if (this.aiAgent.collect_phone) {
            collectionAttributes.push('phone');
          }
          ivr_agent_tree.data_collection = collectionAttributes;
          ivr_agent_tree.custom_attributes = this.aiAgent.custom_attributes;
          ivr_agent_tree.advanced = { prompt_confidence: this.aiAgent.prompt_confidence, top_p: this.aiAgent.prompt_top, temperature: this.aiAgent.prompt_temperature };
          ivr_agent_tree.notifications = {
            email: {
              email_addresses: this.aiAgent.notification_attributes.email.email,
              enabled: this.aiAgent.notification_attributes.email.enabled
            },
            sms:{
              phone_numbers: this.aiAgent.notification_attributes.sms.phone_number,
              enabled: this.aiAgent.notification_attributes.sms.enabled
            }
          };
          ivr_agent_tree.no_available_agents_message = this.unavailableText;
        }
       
        return ivr_agent_tree;
      }
    },
    methods: {
      onChangeAiAgent(e){
      },
      async createIVRAgent(){
        let sortedIvrExtensions = this.ivr_extensions_enabled ? this.ivr_extensions.map(extension => {return {extension_code: extension.extension_code, sip_uri: this.sortedAgents.filter(agent => {if(extension.agent == agent.id){return agent}})[0].sip_username + "@aeonian-conversate.sip.signalwire.com"}}) : [];
        let config = {
          headers: {
            projectId: "d5ed93de-1616-4886-9f61-3eb0323c3150",
            apiToken: "PT4833ab54206c5fc51d2eda618e21908225247b79d86987d1",
            spaceUrl: "aeonian.signalwire.com"
          }
        }
        let sortedIvrTree = this.isEnabled ? this.ivrAgentTree.ivr_tree.map(department => {return {department_name: department.department_name, availability: department.availability, assigned_agents_sip_uri: this.sortedAgents.filter(agent => { return department.agents.some(department_agent => department_agent == agent.id)}).map(agent => {return agent.sip_username + "@aeonian-conversate.sip.signalwire.com"})}}) : [];
        try {
          const response = await axios.post(
            `https://agentapi.conversate.us/v1/ivr/create_agent`, 
            {
              "account_id": this.accountId.toString(),
              "inbox_id": this.inbox.id,
              "notifications": this.ivrAgentTree.notifications,
              "test_agent": this.ivrAgentTree.test_agent,
              "agent_name": this.ivrAgentTree.agent_name,
              "company_name": this.ivrAgentTree.company_name,
              "personality": this.ivrAgentTree.personality,
              "languages": this.ivrAgentTree.languages,
              "inboxes": this.ivrAgentTree.inboxes,
              "prompt": this.ivrAgentTree.prompt,
              "assigned_phone_number": this.ivrAgentTree.assigned_phone_number,
              "template_details": this.ivrAgentTree.template_details,
              "training": this.ivrAgentTree.training,
              "website_content": this.ivrAgentTree.website_content,
              "call_recording_enabled": this.call_recording_enabled,
              "document_reference_agent_id": this.ivrAgentTree.assistant_id,
              "greeting_message": this.ivrAgentTree.greeting_message,
              "scheduling_url": this.ivrAgentTree.scheduling_url,
              "data_collection": this.ivrAgentTree.data_collection,
              "custom_attributes": this.ivrAgentTree.custom_attributes,
              "advanced": this.ivrAgentTree.advanced,
              "functions": this.ivrAgentTree.functions,
              "working_hours": {working_hours: this.inbox.working_hours, timezone: this.inbox.timezone},
              "no_available_agents_message": this.ivrAgentTree.no_available_agents_message,
              "ivr_extensions": sortedIvrExtensions,
              "ivr_tree": sortedIvrTree,
              "ivr_enabled": this.ivrAgentTree.ivr_enabled,
              "ivr_extensions_enabled": this.ivrAgentTree.ivr_extensions_enabled
            },
            config
          );
          console.log("Create response: " + JSON.stringify(response.data.message));
          this.agentId = response.data.agentId;
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
          try {
            this.updateInProgress = true;
    
            const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;
    
            const response = await signalwireChannelAPI.UpdateIVRAgentSettings({
                channel_id: channel_id,
                ivr_agent_tree: this.ivrAgentTree
            });
    
            await this.$store.dispatch("inboxes/get");
    
            this.showAlert("Update Successful");
          }
          catch (e) {
            this.showAlert("Error");
          }
          this.updateInProgress = false;
        } catch (error) {
            console.log(error);
            this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
        }
      },
      async updateAgent(){
          let sortedIvrExtensions = this.ivr_extensions_enabled ? this.ivr_extensions.map(extension => {return {extension_code: extension.extension_code, sip_uri: this.sortedAgents.filter(agent => {if(extension.agent == agent.id){return agent}})[0].sip_username + "@aeonian-conversate.sip.signalwire.com"}}) : [];
          let sortedIvrTree = this.isEnabled ? this.ivrAgentTree.ivr_tree.map(department => {return {department_name: department.department_name, availability: department.availability, assigned_agents_sip_uri: this.sortedAgents.filter(agent => { return department.agents.some(department_agent => department_agent == agent.id)}).map(agent => {return agent.sip_username + "@aeonian-conversate.sip.signalwire.com"})}}) : [];
          let config = {
            headers: {
              projectId: "d5ed93de-1616-4886-9f61-3eb0323c3150",
              apiToken: "PT4833ab54206c5fc51d2eda618e21908225247b79d86987d1",
              spaceUrl: "aeonian.signalwire.com"
            }
          }
          try {
            const response = await axios.post(
              `https://agentapi.conversate.us/v1/ivr/update_agent/${this.agentId}`,
              {
              "account_id": this.accountId.toString(),
              "inbox_id": this.inbox.id,
              "notifications": this.ivrAgentTree.notifications,
              "test_agent": this.ivrAgentTree.test_agent,
              "agent_name": this.ivrAgentTree.agent_name,
              "company_name": this.ivrAgentTree.company_name,
              "personality": this.ivrAgentTree.personality,
              "languages": this.ivrAgentTree.languages,
              "inboxes": this.ivrAgentTree.inboxes,
              "prompt": this.ivrAgentTree.prompt,
              "assigned_phone_number": this.ivrAgentTree.assigned_phone_number,
              "template_details": this.ivrAgentTree.template_details,
              "training": this.ivrAgentTree.training,
              "website_content": this.ivrAgentTree.website_content,
              "call_recording_enabled": this.call_recording_enabled,
              "document_reference_agent_id": this.ivrAgentTree.assistant_id,
              "greeting_message": this.ivrAgentTree.greeting_message,
              "scheduling_url": this.ivrAgentTree.scheduling_url,
              "data_collection": this.ivrAgentTree.data_collection,
              "custom_attributes": this.ivrAgentTree.custom_attributes,
              "advanced": this.ivrAgentTree.advanced,
              "functions": this.ivrAgentTree.functions,
              "working_hours": {working_hours: this.inbox.working_hours, timezone: this.inbox.timezone},
              "no_available_agents_message": this.ivrAgentTree.no_available_agents_message,
              "ivr_extensions": sortedIvrExtensions,
              "ivr_tree": sortedIvrTree,
              "ivr_enabled": this.ivrAgentTree.ivr_enabled,
              "ivr_extensions_enabled": this.ivrAgentTree.ivr_extensions_enabled
            },
            config
            );
            console.log("Update response: " + JSON.stringify(response.data));
            this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
            try {
              this.updateInProgress = true;
      
              const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;
      
              const response = await signalwireChannelAPI.UpdateIVRAgentSettings({
                  channel_id: channel_id,
                  ivr_agent_tree: this.ivrAgentTree
              });
      
              await this.$store.dispatch("inboxes/get");
      
              this.showAlert("Update Successful");
            } catch (e) {
              this.showAlert("Error");
            }
            this.updateInProgress = false;
          } catch (error) {
              console.log(error);
              this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
          }
      },
      openPromptConfig(){
        this.promptExpanded = !this.promptExpanded;
      },
      openPostPromptConfig(){
        this.postPromptExpanded = !this.postPromptExpanded;
      },
      onDepartmentNameInput(e, index){
        this.menuItems[index].department_name = this.menuItems[index].department_name.replace(/[^\w\s]/gi, '')
      },
      deleteRow(row){
        this.selectedLanguages = this.selectedLanguages.filter((language) => language !== row);
      },
      deleteRowFunction(row){
        this.aiFunctions = this.aiFunctions.filter((aiFunction) => aiFunction !== row);
      },
      deleteRowArgument(row){
        this.functionArguments = this.functionArguments.filter((argument) => argument !== row);
      },
      closeAiLanguagesMenu(){
        this.isAiLanguagesMenuOpen = false;
      },
      closeAiFunctionsMenu(){
        this.isAiFunctionsMenuOpen = false;
      },
      onChangeLanguage(e){
        // this.pickedLanguage = this.enteredLanguage.language.languageCode;
        // this.supportedGenders = this.supportedLanguages.filter((language) => language.languageCodes[0] === this.pickedLanguage).map((language) => language.ssmlGender).filter((value, index, array) => array.indexOf(value) === index);
      },
      async onChangeGender(e){
        const response = await axios.get(
          `https://agentapi.conversate.us/v1/ivr/get_voices/${e.target.value}`
        );
        this.supportedVoices = response.data.voices.map((voice) => voice.name);
        // this.supportedVoices = this.supportedLanguages.filter((language) => language.languageCodes[0] === this.pickedLanguage).filter((language) => language.ssmlGender === e.target.value).map((language) => language.name);
      },
      onChangeVoice(e){
      },
      enterLanguage(){
        this.selectedLanguages.push({name: this.enteredLanguage.name, gender: this.enteredLanguage.gender, voice: this.enteredLanguage.voice});
        this.enteredLanguage = {name: "", gender: "", voice: ""};
        this.supportedVoices = [];
        this.closeAiLanguagesMenu();
      },
      enterFunction(){
        this.aiFunctions.push({name: this.functionName, purpose: this.functionPurpose, arguments: this.functionArguments, webhook_url: this.webhookUrl, webhook_username: this.webhookAuthUser, webhook_password: this.webhookAuthPassword});
        this.functionName = "";
        this.functionPurpose = "";
        this.functionArguments = [{name: "", description: ""}];
        this.webhookUrl = "";
        this.webhookAuthPassword = "";
        this.webhookAuthUser = "";
        this.closeAiFunctionsMenu();
      },
      deleteItem(index) {
        this.menuItems.splice(index, 1);
      },
      deleteFaqItem(index) {
        this.faqItems.splice(index, 1);
      },
      deleteAttributesItem(index) {
        this.attributesItems.splice(index, 1);
      },
      addItem(){
        this.menuItems.push({department_name: "", agents: [], availability: false});
      },
      addFaqItem(){
        this.faqItems.push({question: "", answer: ""});
      },
      addAttributesItem(){
        this.attributesItems.push("");
      },
      addLanguage(){
        this.isAiLanguagesMenuOpen = true;
      },
      AddArgument(){
        this.functionArguments.push({name: "", description: ""});
      },
      addFunction(){
        this.isAiFunctionsMenuOpen = true;
      },
      addNotificationDomain(){
        this.notificationDomain.push("");
      },
      removeNotificationDomain(index){
        this.notificationDomain.splice(index, 1);
      },
      addNotificationPhone(){
        this.notificationPhone.push("");
      },
      removeNotificationPhone(index){
        this.notificationPhone.splice(index, 1);
      },
      onPress(e){
        if(e.key == "-"){
          e.preventDefault();
        }
        if(e.key == "+"){
          e.preventDefault();
        }
        if(e.key == "e"){
          e.preventDefault();
        }
        return true;
      },
      onInutIvrNumbers(e, agent){
                if(e.target.value.toString().length == 3 && !this.ivr_extensions.find(obj => {
                  return obj.extension_code === e.target.value && obj.agent !== agent;
                })){
                  this.ivr_extensions.find(obj => {
                    return obj.agent === agent
                  }).extension_code = e.target.value;
                }
                else{
                  this.ivr_extensions.find(obj => {
                    return obj.agent === agent
                  }).extension_code = null;
                }
      },
        async handleIsEnabled(e) {
          this.isEnabled = e.target.checked;
          if(e.target.checked == true){
            await globalAIP.updateNewIVREnabled(this.accountId, this.inbox.id, this.isEnabled);
          }
          await this.$store.dispatch("inboxes/get");
        },
        handleIsIVRRecordingEnabled(e) {
          this.call_recording_enabled = e.target.checked;
        },
        handleIsIVRExtensionsEnabled(e) {
          this.ivr_extensions_enabled = e.target.checked;
        },
        handleIsNameAskingEnabled(e) {
          this.isNameAskingEnabled = e.target.checked;
        },
        handleIsEmailAskingEnabled(e) {
          this.isEmailAskingEnabled = e.target.checked;
        },
        handleIsMessageAskingEnabled(e) {
          this.isMessageAskingEnabled = e.target.checked;
        },
        handleIsSendingEnabled(e) {
          this.isSendingInfoEnabled = e.target.checked;
        },
        handleIsSchedulingEnabled(e) {
          this.isSchedulingEnabled = e.target.checked;
        },
        onHover(){
            this.linkColor = "#872cd1"
        },
        onLeave(){
          this.linkColor = "#4e80cc"
        },
        setIsMissedCallMenu(){
          this.$store.commit('missedCall/setMissedCallMenuOpen', true);
        },
      async updateIVRSettings() {
        if(this.ivrAgentTree.agent_id == null){
          this.createIVRAgent();
        }
        else{
          this.updateAgent();

        }
      },
      async destroyIVRAgent() {
        try {
          const response = await axios.delete(
            `https://agentapi.conversate.us/v1/ivr/delete_agent/${this.agentId}`
          );
          console.log("Destroy response: " + JSON.stringify(response.data));
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        } catch (error) {
            console.log(error);
            this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
        }
        try {
          this.updateInProgress = true;
  
          const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;
  
          const response = await signalwireChannelAPI.UpdateIVRAgentSettings({
              channel_id: channel_id,
              ivr_agent_tree: {}
          });
  
          await this.$store.dispatch("inboxes/get");
          this.agentId = null;
          this.showAlert("Update Successful");
        }
        catch (e) {
          this.showAlert("Error");
        }
        this.updateInProgress = false;
      }
    }
  }
  </script>
  
  <style scoped>
  .ivr-extensions_body-modal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ivr-extensions_input-number{
    width: 200px;
  }
  .ivr-form {
    margin-bottom: 30px;
  }
  
  .ivr-form textarea {
    resize: vertical;
  
    min-height: 45px;
  }
  
  .ivr-form__menu-label {
    font-size: 24px;
  }
  
  .ivr-form__buttons-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  #ivr-form__buttons-wrapper--destroy{
    background-color: red;
  }
  
  .field-description {
    font-size: 12px;
  }
  
  .select {
    margin-bottom: 1.6rem;
  }
  
  .ivr-item-wrapper {
    border: 1px solid #e0e6ed;
  
    padding: 20px;
  
    border-radius: 5px;
  
    margin-bottom: 15px;
  }
  
  .ivr-form__symbol-chooser-wrapper {
      width: 100%;
  
      display: flex;
  
      justify-content: center;
      align-items: center;
      align-content: center;
  
      margin-top: 20px;
  }
  
  .ivr-form__symbol-chooser-wrapper--first {
      margin-top: 0;
  }
  
  .ivr-form__symbol-item {
      display: flex;
  
      justify-content: center;
      align-content: center;
      align-items: center;
  
      width: 60px;
      height: 40px;
  
      font-size: 20px;
      font-weight: bold;
      border-radius: 5px;
  
      color: white;
  
      cursor: pointer;
  
      background-color: #40A2F7;
  
      margin-right: 10px;
  }
  
  .ivr-form__symbol-item--active {
      background-color: #60CE4D;
  }
  
  .textarea-custom-attributes{
    width:90%;
  }
  .delete-icon-custom-attributes{
  padding: 0;
  position: absolute;
  top:10px;
  right:0px;
  border:none;
  background: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
  }
  .delete-icon{
  padding: 0;
  position: absolute;
  top:20px;
  right:-30px;
  border:none;
  background: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.form-block {
  margin-top: 10px;

  position: relative;
}
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .ivr-form__global-chooser-wrapper {
      margin-bottom: 20px;
  
      padding: 25px 20px 25px 20px;
  
      border-radius: 5px;
  
      /*border: 1px solid rgba(224,230,237,0.4);*/
  
      background-color: #F9FAFC;
  
  }

  .ai-language_header {
  font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Tahoma, Arial, sans-serif;

  font-size: 20px;

  display: flex;

  align-items: center;
  align-content: center;

  justify-content: center;

  margin-bottom: 10px;
  margin-top: 10px;
}

.ai-language-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ai-language-menu {
  width: 80%;
}
.ai-language__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px;
}

.ai-functions__button-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px;
}
.range-box{
  margin: 15px;
}

.range-form{
  display: flex;
  padding-right: 10px;
  grid-gap: 10px;
}
.input-range-form{
  width: 100%;
  height: 1.5rem;
  padding: 0;
}
.prompt-textarea{
  height: 150px;
}
.range-label{
  width: 50%
}

.low-input{
  height: 30px;
}

.advanced-config{
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer
}

  </style>
  