import ApiClient from './ApiClient';

class LabelsAPI extends ApiClient {
  constructor() {
    super('labels', { accountScoped: true });
  }
  exportLabels(param) {
    return axios.get(`${this.url}/export_label?name=${param}`);
  }
}

export default new LabelsAPI();
