<template>
  <input type="checkbox"
         class="checkbox"
         :checked="checked"
         :style="{width: width, height: height}"
         @click.stop="onClick"
  >
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        checked: {
            type: Boolean
        },

        onClick: Function,

        width: {
            type: String,
            default: "none"
        },

        height: {
            type: String,
            default: "none"
        }


    }
}
</script>

<style scoped>
.checkbox {
    cursor: pointer;
    margin: 0;
}
</style>
