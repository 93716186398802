<template>
  <div>
    <div class="notelist-wrap">
      <div class="row note-header">
        <div class="columns col-md-12 ">
          <h3 class="block-title">
            {{ $t('NOTES.HEADER.TITLE') }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="columns col-md-12 padding-card">
          <add-note @add="onAddNote" />
          <div class="scrollable-container">
            <contact-note
              v-for="note in notes"
              :id="note.id"
              :key="note.id"
              :note="note.content"
              :user-name="note.user.name"
              :time-stamp="note.created_at"
              :thumbnail="note.user.thumbnail"
              @edit="onEditNote"
              @open-delete-modal="onOpenDeleteModal"
            />
          </div>
        </div>
      </div>
    </div>

    <woot-delete-modal
      :show.sync="showFileDeletePopup"
      :on-close="closeFileDeletePopup"
      :on-confirm="onDeleteNote"
      :title="`Are you sure you want to delete note?`"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import ContactNote from './ContactNote';
import AddNote from './AddNote';

export default {
  components: {
    ContactNote,
    AddNote,
  },

  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModelFile: false,
      showFileDeletePopup: false,
      showAgentModel: false,
      noteId: null,
      noteText: '',
    };
  },
  computed: {
    deleteConfirmText() {
      return `Yes`;
    },
    deleteRejectText() {
      return `Cancel`;
    },
    deleteMessage() {
      return this.noteText;
    },
  },

  methods: {
    onOpenDeleteModal(id, note) {
      this.showFileDeletePopup = true;
      this.noteId = id;
      this.noteText = note;
    },
    closeFileDeletePopup() {
      this.showFileDeletePopup = false;
    },
    onDeleteNote() {
      this.$emit('delete', this.noteId);
      this.showFileDeletePopup = false;
    },

    onAddNote(value) {
      this.$emit('add', value);
    },

    onEditNote(value) {
      this.$emit('edit', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrap {
  margin-top: var(--space-one);
}

.scrollable-container {
  height: 60vh;
  overflow-y: auto;
}

.note-header {
  border: 1px solid var(--color-border);
  padding: 1rem;
  background: white;
}

.padding-card {
  padding: 2rem;
}
</style>
