<template>
    <div class="wizard-body columns content-box small-9">
        <div v-if="!hasLoginStarted" class="login-init full-height">
            <a href="#" @click="startLogin()">
                <img
                        src="~dashboard/assets/images/channels/facebook_login.png"
                        alt="Facebook-logo"
                />
            </a>
            <p>
                {{
                useInstallationName(
                    $t('INBOX_MGMT.ADD.FB.LEAD_HELP'),
                    globalConfig.installationName
                )
                }}
            </p>
          <p>
            {{$t('INBOX_MGMT.ADD.FB.LEAD_NOTE')}}
          </p>
        </div>
        <div v-else>
            <loading-state v-if="showLoader" :message="emptyStateMessage" />
            <form v-if="!showLoader" class="row" @submit.prevent="createChannel()">
                <div class="medium-12 columns">
                    <page-header
                            :header-title="$t('INBOX_MGMT.ADD.DETAILS.TITLE')"
                            :header-content="
              useInstallationName(
                $t('INBOX_MGMT.ADD.DETAILS.DESC'),
                globalConfig.installationName
              )
            "
                    />
                </div>
                <div class="medium-7 columns">
                    <div class="medium-12 columns">
                        <div class="input-wrap" :class="{ error: $v.selectedPage.$error }">
                            {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PAGE') }}
                            <multiselect
                                    v-model.trim="selectedPage"
                                    :close-on-select="true"
                                    :allow-empty="true"
                                    :options="getSelectablePages"
                                    track-by="id"
                                    label="name"
                                    :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                    :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                    :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_A_VALUE')"
                                    selected-label
                                    @select="setPageName"
                            />
                            <span v-if="$v.selectedPage.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER') }}
              </span>
                        </div>
                    </div>
                    <div class="medium-12 columns">
                        <label :class="{ error: $v.pageName.$error }">
                            {{ $t('INBOX_MGMT.ADD.FB.INBOX_NAME') }}
                            <input
                                    v-model.trim="pageName"
                                    type="text"
                                    :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_NAME')"
                                    @input="$v.pageName.$touch"
                            />
                            <span v-if="$v.pageName.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.ADD_NAME') }}
              </span>
                        </label>
                    </div>

                  <div class="columns col-md-12">
                    <label>
                      {{ $t('INBOX_MGMT.ADD.FB.CHAT_SERVICE') }}
                    </label>
                    <div class="widget--feature-flag">
                      <span class="lead_chat_services">
                      <input
                        v-model="smsService"
                        type="checkbox"
                        :class="{ error: serviceRequired}"
                        value="sms_service"
                        @input="handleChatTypes"
                      />
                      <label for="sms_service" :class="{ 'error-text': serviceRequired }">
                        {{ $t('INBOX_MGMT.ADD.FB.SMS_SERVICE') }}
                      </label>
                        </span>
                      <div v-if="smsService">
                       <div class="row">
                        <div class="columns col-md-12">
                          <label :class="{ error: smsInboxRequired}" >
                          <div>
                            <div class="input-group">
                              <select v-model="selectedSmsInbox" @change="handleSmsInboxChange" class="form-control custom-select">
                                <option value="" disabled>{{ $t('INBOX_MGMT.ADD.FB.OPTION_LABEL') }}</option>
                                <option v-for="option in availibleSmsInboxes" :key="option.id" :value="option">{{ option.name }}</option>
                              </select>
                            </div>
                          </div>
                          </label>
                        </div>
                       </div>
                      </div>
                      </div>
                    <div class="widget--feature-flag">
                      <span  >
                      <input
                        v-model="emailService"
                        type="checkbox"
                        value="email_service"
                        onchange="handleChatTypes"
                      />
                      <label for="email_service" :class="{ 'error-text': serviceRequired }">
                        {{ $t('INBOX_MGMT.ADD.FB.EMAIL_SERVICE') }}
                      </label>
                        </span>

<!--                      <div v-if="emailService" class="row mb-6">-->
<!--                        <div class="columns col-md-12">-->
<!--                          <label>Availible Email Inboxes</label>-->
<!--                          <div>-->
<!--                            <div class="input-group">-->
<!--                              <select :options="inboxesList" v-model="selectedEmailInboxes" @change="handleSmsInboxChange" class="form-control custom-select">-->
<!--                              </select>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <label >
                    {{ $t('INBOX_MGMT.ADD.FB.AUTOMATED_MESSAGE_LABEL') }}
                    <textarea
                      :placeholder= "$t('INBOX_MGMT.ADD.FB.AUTOMATED_MESSAGE_PLACEHOLDER')"
                      v-model="automatedMessage" type="text" />
                  </label>
                    <div class="medium-12 columns text-right">
                        <input type="submit" value="Create Inbox" class="button" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
/* eslint-env browser */
/* global FB */
import { required } from 'vuelidate/lib/validators';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import { mapGetters } from 'vuex';
import ChannelApi from '../../../../../api/channels';
import PageHeader from '../../SettingsSubPageHeader';
import router from '../../../../index';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import accountMixin from '../../../../../mixins/account';
import Label from '../../../../../components/ui/Label.vue';

const FB_APP_ID =`${window.chatwootConfig.fbAppId}`;
export default {
    components: {
      Label,
        LoadingState,
        PageHeader,
    },
    mixins: [globalConfigMixin, accountMixin],
    data() {
        return {
            isCreating: false,
            selectedSmsInbox:{},
          autoMessageRequired:true,
          automatedMessage:'',
            availibleSmsInboxes:[],
            selectedEmailInboxes:{},
            availibleEmailInboxes:[],
            omniauth_token: '',
            smsInboxRequired:true,
            serviceRequired:false,
            user_access_token: '',
            smsService: true,
            emailService: false,
            channel: 'lead',
            selectedPage: { name: null, id: null },
            pageName: '',
            pageList: [],
            emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
            hasLoginStarted: false,
        };
    },

    validations: {
        pageName: {
            required,
        },

        selectedPage: {
            isEmpty() {
                return this.selectedPage !== null && !!this.selectedPage.name;
            },
        },
    },

    computed: {
        showLoader() {
            return !this.user_access_token || this.isCreating;
        },
        getSelectablePages() {
            return this.pageList.filter(item => !item.exists);
        },
        ...mapGetters({
            currentUser: 'getCurrentUser',
            globalConfig: 'globalConfig/get',
            inboxesList: 'inboxes/getInboxes',
        }),
    },

    created() {
        this.initFB();
        this.loadFBsdk();
        this.fetchSmsInboxes();
    },

    mounted() {
        this.initFB();
    },
  watch: {
   smsService(newValue) {
      if (newValue === true || this.emailService === true) {
        this.serviceRequired = false;
      }
    },
    emailService(newValue) {
      if (newValue === true || this.smsService === true) {
        this.serviceRequired = false;
      }
    },
  },
    methods: {
      fetchSmsInboxes(){
        this.availibleSmsInboxes = this.inboxesList.filter(item => item.channel_type === 'Channel::SignalwireLink').map(({ name, id }) => ({ name, id }));
        return this.availibleSmsInboxes;
      },
      decodeBase64() {
        return atob(FB_APP_ID);
      },
      handleSmsInboxChange(){
        if (!this.selectedSmsInbox || Object.keys(this.selectedSmsInbox).length === 0) {
          this.smsInboxRequired = true;
        } else {
          this.smsInboxRequired = false;
        }
        },
      handleChatTypes(type) {
        if (type === 'sms_service') {
          this.smsService = !this.smsService;
        } else if (type === 'email_service') {
          this.emailService = !this.emailService;
        }
      },
        startLogin() {
            this.hasLoginStarted = true;
            this.tryFBlogin();
        },

        setPageName({ name }) {
            this.$v.selectedPage.$touch();
            this.pageName = name;
        },

        initChannelAuth(channel) {
            if (channel === 'facebook') {
                this.loadFBsdk();
            }
        },

        initFB() {
            if (window.fbSDKLoaded === undefined) {
              const fbId = this.decodeBase64()
                window.fbAsyncInit = () => {
                    FB.init({
                        appId: fbId,
                        xfbml: true,
                        version: 'v7.0',
                        status: true,
                    });
                    window.fbSDKLoaded = true;
                    FB.AppEvents.logPageView();
                };
            }
        },

        loadFBsdk() {
            ((d, s, id) => {
                let js;
                // eslint-disable-next-line
                const fjs = (js = d.getElementsByTagName(s)[0]);
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        },

        tryFBlogin() {
            FB.login(
                response => {
                    if (response.status === 'connected') {
                        this.fetchPages(response.authResponse.accessToken);
                    } else if (response.status === 'not_authorized') {
                        // The person is logged into Facebook, but not your app.
                        this.emptyStateMessage = this.$t(
                            'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
                        );
                    } else {
                        // The person is not logged into Facebook, so we're not sure if
                        // they are logged into this app or not.
                        this.emptyStateMessage = this.$t(
                            'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
                        );
                    }
                },
                {
                    scope:
                        'instagram_basic,pages_manage_metadata,pages_read_engagement,pages_show_list,leads_retrieval,pages_read_user_content,business_management',
                }
            );
        },

        async fetchPages(_token) {
            try {
                const response = await ChannelApi.fetchFacebookPages(
                    _token,
                    this.accountId,
                    'lead'
                );
              console.log(response, 'response:: L +++++++++++++++++++')
                const {
                    data: { data },
                } = response;
                this.pageList = data.page_details;
                this.user_access_token = data.user_access_token;
            } catch (error) {
                // Ignore error
            }
        },

        channelParams() {

        if(this.smsService==true)
        {
          return {
            user_access_token: this.user_access_token,
            page_access_token: this.selectedPage.access_token,
            page_id: this.selectedPage.id,
            inbox_name: this.pageName || this.selectedPage.name,
            sms_service: this.smsService,
            email_service: this.emailService,
            signalwire_link_inbox: this.selectedSmsInbox.id,
            automated_reply: this.automatedMessage
          };
        }

          return {
            user_access_token: this.user_access_token,
            page_access_token: this.selectedPage.access_token,
            page_id: this.selectedPage.id,
            inbox_name: this.pageName || this.selectedPage.name,
            sms_service: this.smsService,
            email_service: this.emailService,
            automated_reply: this.automatedMessage
          };

        },

        createChannel() {
            this.$v.$touch();

            if(!this.smsService && !this.emailService)
            {
              this.serviceRequired=true;
              return;
            }

          if (this.smsService == true) {
            if (!this.selectedSmsInbox || Object.keys(this.selectedSmsInbox).length === 0) {
              this.smsInboxRequired = true;
              return;
            } else {
              this.smsInboxRequired = false;
            }
          }
          else{
            this.selectedSmsInbox=null;
          }


          if (!this.$v.$error) {
                this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
                this.isCreating = true;
              this.$store
                    .dispatch('inboxes/createLeadChannel', this.channelParams())
                    .then(data => {
                        router.replace({
                            name: 'settings_inboxes_add_agents',
                            params: { page: 'new', inbox_id: data.id },
                        });
                    })
                    .catch(() => {
                        this.isCreating = false;
                    });
            }
        },
    },
};
</script>

<style scoped lang="scss">

.lead_chat_services {
  display: flex;
}

.lead_chat_services input {
  margin-top: 4px;
}

.lead_chat_services label {
  word-wrap: break-word;
  flex-grow: 1;
}

.error-text {
  color: red;
}
</style>
