<template>
  <div
    class="small-3 columns channel"
    :class="{ inactive: !channel.channel_active }"
    @click="onItemClick"
  >
    <img
      v-if="channel.key === 'facebook'"
      src="~dashboard/assets/images/channels/messenger.png"
    />
    <img
      v-if="channel.key === 'twitter'"
      src="~dashboard/assets/images/channels/twitter.png"
    />
    <img
      v-if="channel.key === 'telegram'"
      src="~dashboard/assets/images/channels/telegram.png"
    />
    <img
      v-if="channel.key === 'api' && !channel.thumbnail"
      src="~dashboard/assets/images/channels/api.png"
    />
    <img
      v-if="channel.key === 'api' && channel.thumbnail"
      :src="channel.thumbnail"
    />
    <img
      v-if="channel.key === 'email'"
      src="~dashboard/assets/images/channels/email.png"
    />
    <img
      v-if="channel.key === 'line'"
      src="~dashboard/assets/images/channels/line.png"
    />
    <img
      v-if="channel.key === 'website'"
      src="~dashboard/assets/images/channels/website.png"
    />
    <!-- <img
      v-if="channel.key === 'twilio'"
      src="~dashboard/assets/images/channels/twilio.png"
    /> -->
    <img
        v-if="channel.key === 'review'"
        src="~dashboard/assets/images/channels/review.png"
        style="height: 69%; width: 80%;"
    />
    <img
      v-if="channel.key === 'signalwire'"
      src="~dashboard/assets/images/channels/mobile-sms-icon.png"
    />
    <img
      v-if="channel.key === 'team_chat'"
      src="~dashboard/assets/images/channels/team.png"
      style="height: 69%; width: 80%;"
       />
<!--    @mouseover="mouseOver"-->
<!--    @mouseleave="active = false"-->
    <img
        v-if="channel.key === 'sms'"
        src="~dashboard/assets/images/channels/sms.png"
    />
    <img
        v-if="channel.key === 'whatsapp'"
        src="~dashboard/assets/images/channels/whatsapp.png"
    />
    <img
        v-if="channel.key === 'youtube'"
        src="~dashboard/assets/images/channels/youtube.png"
    />
    <img
            v-if="channel.key === 'lead'"
            src="~dashboard/assets/images/channels/facebook.png"
    />
    <!--    <img-->
<!--      v-if="channel.key === 'instagram'"-->
<!--      src="~dashboard/assets/images/channels/instagram-logo-1.jpg"-->
<!--    />-->
<!--    <div style="text-align: center; font-size: 15px" v-show="active">Coming Soon!</div>-->
    <h3 v-if="channel.key == 'lead'" class="channel__title">
      {{$t('INBOX_MGMT.ADD.FB.META')}} {{ channel.name }}
    </h3>
    <h3 v-if="channel.key !== 'lead'" class="channel__title">
      {{ channel.name }}
    </h3>
  </div>
</template>
<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isActive() {
      const { key } = this.channel;
      if (Object.keys(this.enabledFeatures).length === 0) {
        return false;
      }
      if (key === 'facebook') {
        return this.enabledFeatures.channel_facebook;
      }
      if (key === 'twitter') {
        return this.enabledFeatures.channel_twitter;
      }
      if (key === 'email') {
        return this.enabledFeatures.channel_email;
      }

      return [
        'website',
        'twilio',
        'api',

      ].includes(key);
      // return ['website', 'twilio', 'api', 'signalwire', 'team_chat'].includes(key);
      return ['website', 'api', 'signalwire', 'team_chat', 'review', 'whatsapp', 'sms', 'telegram', 'line', 'youtube', 'lead'].includes(key);
    },
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    onItemClick() {
      if (this.channel.channel_active) {
        this.$emit('channel-item-click', this.channel.key);
      }
    },
    mouseOver: function(){
      this.active = !this.active;
    }
  },
};
</script>
