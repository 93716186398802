<template>
  <div
    class="main-class"
  >
    <settings-sub-page-header
      :header-title="$t('INBOX_MGMT.ADD.GOOGLE.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.GOOGLE.DESCRIPTION')"
    />
    <form
      class="microsoft--sign-in-form medium-8 columns"
      @submit.prevent="requestAuthorization"
    >
      <woot-input
        v-model.trim="email"
        type="text"
        :placeholder="$t('INBOX_MGMT.ADD.GOOGLE.EMAIL_PLACEHOLDER')"
        @blur="$v.email.$touch"
      />
      <woot-submit-button
        icon="brand-twitter"
        button-text="Sign in with Google"
        type="submit"
        :loading="isRequestingAuthorization"
      />
    </form>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import googleClient from 'dashboard/api/channel/googleClient';
import SettingsSubPageHeader from '../../../SettingsSubPageHeader.vue';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: { SettingsSubPageHeader },
  mixins: [alertMixin],
  data() {
    return { email: '', isRequestingAuthorization: false };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    async requestAuthorization() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.isRequestingAuthorization = true;
        const response = await googleClient.generateAuthorization({
          email: this.email
        });
        const {
          data: { url },
        } = response;

        window.location.href = url;
      } catch (error) {
        console.log("error", error);
        this.showAlert(this.$t('INBOX_MGMT.ADD.GOOGLE.ERROR_MESSAGE'));
      } finally {
        this.isRequestingAuthorization = false;
      }
    },
  },
};
</script>

<style scoped>
.microsoft--sign-in-form {
  @apply mt-6;
}

.main-class {
  //background-color: #1a202c;
  width: 75%;
  max-width: 75%;
  background: white;
  height: 100%;
  padding: 6px;
}


</style>
