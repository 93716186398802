<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.AUTH.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.AUTH.DESC')"
    />
    <div class="row channels">
      <channel-item
        v-for="channel in channel_data"
        :key="channel"
        :channel="channel"
        :enabled-features="enabledFeatures"
        @channel-item-click="initChannelAuth"
      />
    </div>
  </div>
</template>

<script>
import ChannelItem from 'dashboard/components/widgets/ChannelItem';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';
import { mapGetters } from 'vuex';

export default {
  components: {
    ChannelItem,
    PageHeader,
  },
  data() {
    return {
      channelList: this.channelList,
      inboxType: '',
      enabledFeatures: {},
    };
  },
  computed: {
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
      enabledFeatures: {};
    },

    // channelList() {
    //   const { apiChannelName, apiChannelThumbnail } = this.globalConfig;
    //   return [
    //     { key: 'website', name: 'Website' },
    //     { key: 'facebook', name: 'Messenger' },
    //     { key: 'twitter', name: 'Twitter' },
    //     { key: 'whatsapp', name: 'WhatsApp' },
    //     { key: 'sms', name: 'SMS via Twilio' },
    //     { key: 'email', name: 'Email' },
    //     {
    //       key: 'api',
    //       name: apiChannelName || 'API',
    //       thumbnail: apiChannelThumbnail,
    //     },
    //     { key: 'telegram', name: 'Telegram' },
    //     { key: 'line', name: 'Line' },
    //   ];
    // },
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      channel_data: 'inboxes/getChannelInfo'
    }),
  },
  mounted() {
    this.initializeEnabledFeatures();
    this.channelInfo()
  },
  methods: {
    async initializeEnabledFeatures() {
      await this.$store.dispatch('accounts/get', this.accountId);
      this.enabledFeatures = this.account.features;
    },
    async channelInfo() {
      await this.$store.dispatch('inboxes/getChannelLimits');
    },
    initChannelAuth(channel) {
      let data = this.channel_data.filter(c => c.key.toLowerCase().indexOf(channel) > -1);
      this.checkChannelType(data[0].key)
      var newArray = this.inboxesList.filter(item =>  (this.inboxType === "Channel::FacebookReview" ? item.channel_type === this.inboxType || item.channel_type === 'Channel::GoogleReview' : item.channel_type === this.inboxType))
      if (newArray.length >= data[0].channel_limit || data[0].current_channel_count >= data[0].total_channel_limit) {
        this.showAlert(this.$t('INBOX_MGMT.DETAILS.ERROR'));
      }else {
        const params = {
          page: 'new',
          sub_page: channel,
        };
        router.push({ name: 'settings_inboxes_page_channel', params });
      }
    },
    checkChannelType(selectedInbox) {
      switch (selectedInbox) {
        case "email":
          this.inboxType = 'Channel::Email'
          break;
        case "website":
          this.inboxType = 'Channel::WebWidget'
          break;
        case "facebook":
          this.inboxType = 'Channel::FacebookPage'
          break;
        case "twitter":
          this.inboxType = 'Channel::TwitterProfile';
          break;
        // case "twilio":
        //   this.inboxType = 'Channel::TwilioSms';
        //   break;
        case "api":
          this.inboxType = 'Channel::Api';
          break;
        case "signalwire":
          this.inboxType = 'Channel::SignalwireLink';
          break;
        case "team_chat":
          this.inboxType = 'Channel::TeamChat';
          break;
        case "review":
          this.inboxType = 'Channel::FacebookReview';
          break;
        case "google_review":
          this.inboxType = 'Channel::GoogleReview';
          break;
        case "whatsapp":
          this.inboxType = 'Channel::Whatsapp';
          break;
        case "youtube":
          this.inboxType = 'Channel::Youtube';
          break;
        case "lead":
          this.inboxType = 'Channel::Lead';
          break;
      }
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>
<style scoped>
.height-auto {
  height: auto;
}
</style>
