import CalendarsView from "./components/CalendarsView";
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
    {
        path: frontendURL('accounts/:accountId/calendars'),
        name: 'calendars_dashboard',
        roles: ['administrator', 'agent'],
        component: CalendarsView,
    },
];
