<template>
  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <contact-info-panal
            :show-close-button="false"
            :show-avatar="false"
            :contact="contact"
          />
        </div>
      </div>
    </div>

<!--      <div class="w-[75%] h-full">-->
<!--        <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">-->
<!--          <woot-tabs-item-->
<!--            v-for="tab in tabs"-->
<!--            :key="tab.key"-->
<!--            :name="tab.name"-->
<!--            :show-badge="false"-->
<!--          />-->
<!--        </woot-tabs>-->
<!--      </div>-->

    <div class="center col-md-6 bg-slate-25 dark:bg-slate-800">
      <contact-notes
        v-if="selectedTabIndex === 0"
        :contact-id="Number(contactId)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactPanel from './ContactPanel';
import ContactNotes from 'dashboard/modules/notes/NotesOnContactPage';
import ContactInfoPanal from '../../../routes/dashboard/contacts/components/ContactInfoPanal.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

export default {
  components: {
    ContactInfoPanal,
    ContactPanel,
    ContactNotes,
    Thumbnail,
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('NOTES.HEADER.TITLE'),
        },
      ];
    },
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
  },
  mounted() {
    this.fetchContactDetails();
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
    fetchContactDetails() {
      const { contactId: id } = this;
      this.$store.dispatch('contacts/show', { id });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.wrap {
  @include three-column-grid(27.2rem);
  min-height: 0;

  background: var(--color-background-light);
  border-top: 1px solid var(--color-border);
}
.left {
  overflow: auto;
}
.center {
  border-right: 1px solid var(--color-border);
  border-left: 1px solid var(--color-border);
  height: 100vh;
  background: var(--b-50);
}

.right {
  padding: var(--space-normal);
}
.icon-padding {
 padding: 1rem;
}

.contact-name-padding {
  margin-left: 1rem;
}

</style>
