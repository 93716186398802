<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-container">
      <div class="modal-body">
        <div class="column">
          <div class="search-bar">
            <input
              type="text"
              v-model="searchQuery"
              @input="filterItems"
              placeholder="Search or jump to"
              class="search-bar"
            />
          </div>
          <div>
            <ul class="item-list">
              <li
                v-for="(item, index) in filteredItems"
                :key="index"
                :class="[
                  { selected: selectedIndex === index },
                  { hovered: hoverIndex === index }
                ]"
                @mouseover="hoverIndex = index"
                @mouseleave="hoverIndex = -1"
                @click="selectItem(index)"
              >
                <fluent-icon icon="wand" size="14" class="ai-typing--icon ai-assist-button-child"></fluent-icon> <!-- Replace 'icon' with the actual class or URL if needed -->
                {{ item.label }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <span>↵ to select</span>
            <span>⬆⬇ to navigate</span>
            <span>esc to close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: '',
      items: [
        {
          label: "Rephrase",
          key: 'rephrase',
          icon: 'ai-typing--icon',
        },
        {
          label: "Fix Spelling Grammer",
          key: 'fix_spelling_grammar',
          icon: 'ai-typing--icon',
        },
        {
          label: "Expand",
          key: 'expand',
          icon: 'ai-typing--icon',
        },
        {
          label: "Shorten",
          key: 'shorten',
          icon: 'ai-typing--icon',
        },
        {
          label: "Change Tone to friendly",
          key: 'make_friendly',
          icon: 'ai-typing--icon',
        },
        {
          label: "Use Formal Tone",
          key: 'make_formal',
          icon: 'ai-typing--icon',
        },
        {
          label: "Simplify",
          key: 'simplify',
          icon: 'ai-typing--icon',
        },
      ],
      filteredItems: [],
      selectedIndex: '',
      hoverIndex: -1,
      selectedItems: [],
    };
  },
  mounted() {
    this.filteredItems = this.items;
    document.addEventListener('keydown', this.handleKeydown);
    document.addEventListener('mousedown', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    filterItems() {
      const query = this.searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) =>
        item.label.toLowerCase().includes(query)
      );
    },
    handleKeydown(event) {
      if (this.show) {
        switch (event.key) {
          case 'ArrowDown':
            this.selectedIndex =
              (this.selectedIndex + 1) % this.filteredItems.length;
            break;
          case 'ArrowUp':
            this.selectedIndex =
              (this.selectedIndex - 1 + this.filteredItems.length) %
              this.filteredItems.length;
            break;
          case 'Escape':
            this.handleClose();
            break;
          case 'Enter':
            this.selectItem(this.selectedIndex);
            break;
          default:
            break;
        }
      }
    },
    handleOutsideClick(event) {
      const modalContainer = this.$el.querySelector('.modal-container');
      if (this.show && !modalContainer.contains(event.target)) {
        this.handleClose();
      }
    },
    selectItem(index) {
      const selectedItem = this.filteredItems[index];
      if (!this.selectedItems.includes(selectedItem)) {
        this.selectedItems.push(selectedItem);
        this.$emit('item-selected', selectedItem);
      }
      this.handleClose();
    },
    handleClose() {
      this.resetForm();
      this.$emit('close-modal');
    },
    resetForm() {
      this.selectedItems=[];
      this.searchQuery = '';
      this.filteredItems = this.items;
      this.selectedIndex = '';
      this.hoverIndex = -1;
    },
  },
};
</script>



<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  border-radius: 8px;
  width: 400px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-bar {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 6px;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item-list li {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.item-list li i {
  margin-right: 8px;
  color: #007bff;
}

.item-list li.selected {
  background-color: #e0e0e0;
}

.item-list li.hovered {
  border-left: 3px solid #007bff;
  background-color: #f0f0f0;
}

.modal-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
</style>


