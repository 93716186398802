<template>
  <woot-modal
    :show="show"
    :on-close="handleClose"
    class="payment-selector--modal"
  >
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CREDIT_BALANCE.HEADER_TEXT')"
        :header-content="$t('CREDIT_BALANCE.SUB_HEADER_TEXT')"
      />
      <form class="row" @submit.prevent="handleSubmit">
        <div class="medium-12 columns">
          <label :class="{ error: $v.creditAmount.$error }">
            {{ $t('CREDIT_BALANCE.FORM.AMOUNT.LABEL') }}
            <input
              v-model.trim="creditAmount"
              type="number"
              :placeholder="$t('CREDIT_BALANCE.FORM.AMOUNT.PLACEHOLDER')"
              @input="$v.creditAmount.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.name.$error }">
            {{ $t('CREDIT_BALANCE.FORM.NAME.LABEL') }}

            <input
              v-model.trim="name"
              type="text"
              :placeholder="$t('CREDIT_BALANCE.FORM.NAME.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.email.$error }">
            {{ $t('CREDIT_BALANCE.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="email"
              type="text"
              :placeholder="$t('CREDIT_BALANCE.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.email.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <stripe-element-card
            ref="elementRef"
            :pk="decodeBase64()"
            @token="tokenCreated"
            class="medium-12 columns"
          />
        </div>

        <div class="medium-12 columns">
          <input
            id="hmacMandatory"
            v-model="autoCharge"
            type="checkbox"
            @change="handleAutoCharge"
          />
          <label for="hmacMandatory">
            {{ $t('CREDIT_BALANCE.FORM.AUTO_CHARGE.LABEL') }}
          </label>
        </div>
        <div class="medium-12 columns" v-if="showAutoCharge">
          <label :class="{ error: minimumBalanceRequired }">
            {{ $t('CREDIT_BALANCE.FORM.MINIMUM_AMOUNT.LABEL') }}
            <input
              v-model.trim="minimumBalance"
              type="number"
              :placeholder="$t('CREDIT_BALANCE.FORM.MINIMUM_AMOUNT.PLACEHOLDER')"
            />
          </label>
        </div>
        <div class="medium-12 columns" v-if="showAutoCharge">
          <label :class="{ error: autoChargeAmountRequired }">
            {{ $t('CREDIT_BALANCE.FORM.AUTO_CHARGE_AMOUNT.LABEL') }}
            <input
              v-model.trim="autoChargeAmount"
              type="number"
              :placeholder="$t('CREDIT_BALANCE.FORM.AUTO_CHARGE_AMOUNT.PLACEHOLDER')"
            />
          </label>
        </div>
        <div class="modal-footer medium-12 columns">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="$v.creditAmount.$invalid || $v.name.$invalid || $v.email.$invalid "
              :button-text="$t('CREATE_ACCOUNT.FORM.SUBMIT')"
              :loading="loading"
              button-class="large expanded"
            />
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
const PUBLISH_KEY = `${window.chatwootConfig.stripePublishKey}`;
export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StripeElementCard,
  },
  data() {
    return {
      creditAmount: '',
      currentAccount:'',
      minimumBalanceRequired: false,
      autoChargeAmountRequired: false,
      minimumBalance: '',
      loading: false,
      autoChargeAmount: '',
      autoCharge: false,
      showAutoCharge: false,
      repeatAutoBalance: '',
      name: '',
      email: '',
      cardToken: '',
      token: null,
      cvc: ''
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.fetchAccountDetails();
      }
    }
  },
  validations: {
    creditAmount: {
      required,
      minValue: minValue(25),
    },
    name: {
      required,
    },
    email: {
      required,
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
  },
  methods: {
    decodeBase64() {
      return atob(PUBLISH_KEY);
    },
    fetchAccountDetails(){
      this.currentAccount = this.getAccount(this.accountId);
      this.autoCharge=this.currentAccount.auto_charge
      if (this.autoCharge==true)
      {
        this.showAutoCharge=true
        this.minimumBalance = this.currentAccount.minimum_amount/100;
        this.minimumBalance=this.minimumBalance.toString()
        this.autoChargeAmount = this.currentAccount.charge_amount;
      }
    },
    handleAutoCharge() {
      this.showAutoCharge = !this.showAutoCharge;
      if(!this.showAutoCharge)
      {
        this.minimumBalance='';
        this.autoChargeAmount='';
      }
    },
    tokenCreated(token) {
      this.cardToken = token;
    },
    onChange(value) {
      this.expiryMonth = value;
    },
    handleClose() {
      this.resetForm();
      this.$emit('close-credit-balance-modal');
    },
    async handleSubmit() {
      this.$refs.elementRef.submit();
      this.loading = true;
      await new Promise(resolve => setTimeout(resolve, 2000));

      if (this.autoCharge && (!this.autoChargeAmount || this.autoChargeAmount.trim() === '') && (!this.minimumBalance || this.minimumBalance.trim() === '')) {
        this.autoChargeAmountRequired = true;
        this.minimumBalanceRequired = true;
        this.loading=false
        return;
      } else if (this.autoCharge && (!this.autoChargeAmount || this.autoChargeAmount.trim() === '') && this.minimumBalance) {
        this.autoChargeAmountRequired = true;
        this.minimumBalanceRequired = false;
        this.loading=false
        return;
      } else if (this.autoCharge && this.autoChargeAmount && (!this.minimumBalance || this.minimumBalance.trim() === '')) {
        this.autoChargeAmountRequired = false;
        this.minimumBalanceRequired = true;
        this.loading=false
        return;
      } else {
        this.autoChargeAmountRequired = false;
        this.minimumBalanceRequired = false;
      }

      if (typeof this.cardToken === 'undefined' || Object.keys(this.cardToken).length === 0 || this.cardToken== '') {
        this.loading = false;
        return;
      }


      const data = {
        creditAmount: this.creditAmount,
        isAutoCharge: this.autoCharge,
        autoChargeAmount: this.autoChargeAmount,
        cardToken: this.cardToken,
        name: this.name,
        email: this.email,
        minimumBalance: this.minimumBalance,
        id: this.accountId
      };

      try {
        let response = await this.$store.dispatch('accounts/paymentIntent', data);
          this.loading = false;
          this.showAlert("Payment Succeed");
          window.location.reload();
          this.handleClose();
      } catch (error) {
        this.showAlert(error);
        this.loading = false;
        this.handleClose();
      }
    },
    resetForm() {
      this.creditAmount = '';
      this.minimumBalanceRequired = false;
      this.autoChargeAmountRequired = false;
      this.minimumBalance = '';
      this.loading = false;
      this.autoChargeAmount = '';
      this.autoCharge = false;
      this.showAutoCharge = false;
      this.repeatAutoBalance = '';
      this.name = '';
      this.email = '';
      this.cardToken = '';
      this.token = null;
      this.cvc = '';
      if (this.$refs.elementRef) {
        this.$refs.elementRef.clear();
      }
    }
  },
};
</script>
<style lang="scss">
.alert-wrap {
  margin: var(--space-zero) var(--space-large);
  margin-top: var(--space-medium);

  .callout {
    display: flex;
    justify-content: center;
  }
}

.icon-wrap {
  font-size: var(--font-size-big);
  margin-left: var(--space-smaller);
  margin-right: var(--space-slab);
}
</style>
