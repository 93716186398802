<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="ion-android-add-circle"
      @click="openAddPopup()"
    >
      {{ $t('AGENT_MGMT.AI_AGENT.HEADER_BTN_TXT') }}
    </woot-button>

    <div></div>

    <!-- List Agents -->
    <div class="row">
      <div class="small-8 columns with-right-space ">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('AGENT_MGMT.AI_AGENT.LOADING')"
        />
        <div v-else class="overflow-horizontal">
          <p v-if="!aiAgentList.length">
            {{ $t('AGENT_MGMT.AI_AGENT.LIST.404') }}
          </p>
          <table v-else class="woot-table">
            <thead>
              <th
                v-for="thHeader in $t(
                  'AGENT_MGMT.AI_AGENT.VIEW_TRACKS.LIST.TABLE_HEADER'
                )"
                :key="thHeader"
              >
                {{ thHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(agent, index) in aiAgentList" :key="agent.id">
                <!-- Gravtar Image -->
                <td>
                  <thumbnail
                    :src="agent.thumbnail"
                    class="columns"
                    :username="agent.name"
                    size="40px"
                  />
                </td>
                <!-- Agent Name + Email -->
                <td>
                  <span class="agent-name">{{ agent.name }}</span>
                </td>
                <td>
                  <span v-if="agent.active_agent == true" class="agent-name"> {{ $t('AGENT_MGMT.AI_AGENT.LIST.ENABLED') }} </span>
                  <span v-if="agent.active_agent == false" class="agent-name"> {{ $t('AGENT_MGMT.AI_AGENT.LIST.DISABLED') }} </span>
                </td>
                <td>
                  <span class="agent-name">{{
                    showInboxes(agent.bot_inboxes)
                  }}</span>
                </td>
                <td>
                  <div class="button-wrapper">
                    <woot-button
                      variant="link"
                      color-scheme="secondary"
                      icon="ion-edit"
                      class-names="grey-btn"
                      @click="openEditPopup(agent)"
                    >
                      {{ $t('AGENT_MGMT.EDIT.BUTTON_TEXT') }}
                    </woot-button>
                    <woot-button
                      variant="link"
                      color-scheme="secondary"
                      icon="ion-close-circled"
                      class-names="grey-btn"
                      :is-loading="loading[agent.id]"
                      @click="openDeletePopup(agent, index)"
                    >
                      {{ $t('AGENT_MGMT.DELETE.BUTTON_TEXT') }}
                    </woot-button>
                    <router-link
                      :to="addAccountScoping(`settings/ai_agents/${agent.id}`)"
                    >
                      <woot-button
                        icon="ion-gear-b"
                        variant="link"
                        color-scheme="secondary"
                        class-names="grey-btn"
                      >
                        {{ $t('INBOX_MGMT.SETTINGS') }}
                      </woot-button>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="small-4 columns">
        <span
          v-html="
            useInstallationName(
              $t('AGENT_MGMT.AI_AGENT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>

    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-ai-agent :on-close="hideAddPopup" />
    </woot-modal>

    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-ai-agent :on-close="hideEditPopup" :ai-agent="currentAgent" />
    </woot-modal>

    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import AddAiAgent from './AddAiAgent.vue';
import EditAiAgent from './EditAiAgent.vue';
import accountMixin from '../../../../mixins/account';
import WootButton from '../../../../components/ui/WootButton';

const DELETE_AI_ASSISTANT_URL = `${window.chatwootConfig.deleteAssistantUrl}`;

export default {
  components: {
    WootButton,
    Thumbnail,
    AddAiAgent,
    EditAiAgent,
  },
  mixins: [globalConfigMixin, accountMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      showHistories: false,
      selectedAgent: {},
    };
  },
  computed: {
    ...mapGetters({
      aiAgentList: 'agents/getAiAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.currentAgent.name
      } ?`;
    },
  },
  mounted() {
    this.$store.dispatch('agents/getAIAgents').then(() => {
      console.log();
    });
  },
  methods: {
    showEditAction(agent) {
      return true;
    },
    showDeleteAction(agent) {
      return true;
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    // Edit Function
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Function
    openDeletePopup(agent) {
      this.showDeletePopup = true;
      this.currentAgent = agent;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAgent.id] = true;
      this.closeDeletePopup();
      this.deleteAgent(this.currentAgent.assistant_id);
    },
    async deleteAgent(id) {
      const currentAgentId = this.currentAgent.id;
      try {
        await axios.delete(`${DELETE_AI_ASSISTANT_URL}/${id}`);
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'));
      } finally {
        await this.$store.dispatch('agents/deleteAIAgent', currentAgentId);
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    showInboxes(botInbox) {
      if (botInbox) {
        const inboxNames = botInbox.map(entry => entry.inbox_name);
        return inboxNames.join(', ');
      }

      return '';
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.overflow-horizontal {
  overflow-x: auto;
}
</style>
