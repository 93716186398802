<template>
  <div class="wizard-body small-9 columns">
    <div class="row">
      <div class="small-6 columns">
        <page-header
            :header-title="$t('INBOX_MGMT.ADD.YOUTUBE.TITLE')"
            :header-content="$t('INBOX_MGMT.ADD.YOUTUBE.DESC')"
        />
        <div class="medium-12 columns">
          <label :class="{ error: $v.inbox_name.$error }">
            {{ $t('INBOX_MGMT.ADD.YOUTUBE.CHANNEL_NAME.LABEL') }}
            <input
                v-model.trim="inbox_name"
                type="text"
                :placeholder="
            $t('INBOX_MGMT.ADD.YOUTUBE.CHANNEL_NAME.PLACEHOLDER')
          "
                @blur="$v.inbox_name.$touch"
            />
            <span v-if="$v.inbox_name.$error" class="message">{{
                $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.ERROR')
              }}</span>
          </label>
        </div>

        <label class="medium-12 columns">
          <div class='g-sign-in-button' @click="handleAuthClick">
            <div class=content-wrapper>
              <div class='logo-wrapper'>
                <img src='https://developers.google.com/identity/images/g-logo.png'>
              </div>
              <span class='text-container'>
                <span>Sign in with Google</span>
              </span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import router from "../../../../index";
import {required} from "vuelidate/lib/validators";

const SCOPES = 'https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.force-ssl';
const URL = 'https://accounts.google.com/o/oauth2/token';
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest']
export default {
  name: "Youtube",
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      uiFlags: 'inboxes/getUIFlags',
    }),
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
  },
  data() {
    return {
      api: undefined,
      authorized: false,
      data_obj: [],
      inbox_name: '',
      auth_token: '',
      gYoutubeObj: []
    };
  },
  validations: {
    inbox_name: { required },
  },
  created() {
    this.currentUser;
    this.api = gapi;
    this.handleClientLoad();
  },
  methods: {
    handleClientLoad() {
      this.api.load('client:auth2', this.initClient);
    },
    handleAuthClick(event) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let vm = this;
      let googleAuth = this.api.auth2.getAuthInstance();
      Promise.resolve(googleAuth.grantOfflineAccess())
          .then(data => {
            var credentials = {
              client_id: this.currentUser.client_id,
              code: data.code,
              grant_type: "authorization_code",
              client_secret: this.currentUser.client_secret,
              redirect_uri: this.currentUser.frontend_url
            };
            fetch(URL, {
              'method': 'POST',
              'body': JSON.stringify(credentials),
              headers: {'Content-Type': 'application/json'}
            }).then(resp => resp.json()).then(async token => {
              vm.auth_token = token
              await vm.saveGoogleAuth(token)
              vm.authorized = true;
              await vm.getUserVideos(token.access_token);

               setTimeout(async function(){
                 await vm.createChannel();
              }, 5000);
            })
          })
    },
    async getUserVideos(token) {
      let vm = this;
      // fetch users youtube channels list
      return await fetch(`https://www.googleapis.com/youtube/v3/channels?access_token=${token}&key=${vm.currentUser.google_api_key}&mine=True`, {
        'method': 'GET',
        headers: {'Content-Type': 'application/json'}
      }).then(resp => resp.json()).then((res) => {
        // handle response
        res.items.forEach(async (channel) => {
          // fetch channel playlist
          await fetch(`https://www.googleapis.com/youtube/v3/channels?access_token=${token}&id=${channel.id}&key=${vm.currentUser.google_api_key}&part=contentDetails`, {
            'method': 'GET',
            headers: {'Content-Type': 'application/json'}
          }).then(resp => resp.json()).then(PList => {
            PList.items.forEach(async (playlist) => {
              // fetch videos of channel playlist
              await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?access_token=${token}&playlistId=${playlist?.contentDetails?.relatedPlaylists?.uploads}&key=${vm.currentUser.google_api_key}&part=snippet&maxResults=50`, {
                'method': 'GET',
                headers: {'Content-Type': 'application/json'}
              }).then(resp => resp.json()).then(videos => {
                videos.items.forEach(async video => {
                  // fetch videos comment
                  await fetch(`https://www.googleapis.com/youtube/v3/commentThreads?access_token=${token}&part=snippet,replies&videoId=${video?.snippet?.resourceId?.videoId}&key=${vm.currentUser.google_api_key}&maxResults=50`, {
                    'method': 'GET',
                    headers: {'Content-Type': 'application/json'}
                  }).then(resp => resp.json()).then(comments => {
                    vm.data_obj?.push({'id': video.id, 'snippet': video.snippet, 'comments': comments.items})
                  })
                })
              })
            })
          })
        })
      }).catch((err) => { // handle error
        console.log(err);
      })
    },
    initClient() {
      let vm = this;
      vm.api.client.init({
        apiKey: vm.currentUser.google_api_key,
        clientId: vm.currentUser.client_id,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(async function () {
        const auth2 = vm.api.auth2.getAuthInstance()
        if (auth2.isSignedIn.get()) {
          vm.authorized = true;
        } else {
          var tokens = await vm.parseUser(vm.currentUser);
          if(tokens.error) {
            vm.authorized = '';
          }else {
            if(vm.currentUser.google_auth_tokens) {
              var credentials = {
                client_id: vm.currentUser.client_id,
                client_secret: vm.currentUser.client_secret,
                refresh_token: tokens.refresh_token,
                grant_type: "refresh_token",
                redirect_uri: vm.currentUser.frontend_url
              };
              fetch(URL, {
                'method': 'POST',
                'body': JSON.stringify(credentials),
                headers: {'Content-Type': 'application/json'}
              }).then(resp => resp.json()).then(token => {
                token.refresh_token = tokens.refresh_token
                token.user_id = vm.currentUser.id
                // vm.updateGoogleAuth(token)
                vm.api.client.setToken({access_token: token.access_token});
                // Listen for sign-in state changes.
                console.log(vm.api.auth2.getAuthInstance().isSignedIn.get())
              });
            }
          }
        }
      });
    },
    async createChannel() {
      this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
      this.isCreating = true;
      await this.$store.dispatch('inboxes/createYoutubeChannel', {
        data: this.data_obj,
        inbox_name: this.inbox_name,
        auth_token: this.auth_token
      }).then(inbox => {
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {page: 'new', inbox_id: inbox.id},
        });
      }).catch((err) => {
        console.log(err.response);
        this.isCreating = false;
      });
    },
    async saveGoogleAuth(data){
      try {
        await this.$store.dispatch('agents/googleAuth', data)
      }catch (error) {
        console.log(error)
      }
    },
    async updateGoogleAuth(data){
      try {
        await this.$store.dispatch("agents/updateGoogleAuth", data)
      }catch (error) {
        console.log(error)
      }
    },
    getYoutubeAccounts() {
      let vm = this;
      this.$store.dispatch('inboxes/getYoutubeChannel').then(res => {
        vm.gYoutubeObj = res?.gchannel;
      })
    },
    parseUser(user) {
        const auth_data = JSON.parse(user.google_auth_tokens);
        return auth_data;
    },

  }
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
}

.g-sign-in-button {
  margin: 10px;
  display: inline-block;
  width: 240px;
  height: 50px;
  background-color: #4285f4;
  color: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.g-sign-in-button:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-sign-in-button:active {
  background-color: #3367D6;
  transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.g-sign-in-button img {
  width: 18px;
  height: 18px;
}

.g-sign-in-button .logo-wrapper {
  padding: 15px;
  background: #fff;
  width: 48px;
  height: 100%;
  border-radius: 1px;
  display: inline-block;
}

.g-sign-in-button .text-container {
  font-family: Roboto,arial,sans-serif;
  font-weight: 500;
  letter-spacing: .21px;
  font-size: 16px;
  line-height: 48px;
  vertical-align: top;
  border: none;
  display: inline-block;
  text-align: center;
  width: 180px;
}
.g-btn {
  padding-top: 30%;
  padding-left: 3%;
}
</style>
