<template>
    <div>
      <modal :on-close="closeAiLanguagesMenu" :show="isAiLanguagesMenuOpen">
        <div class="ai-language-wrapper">
          <div class="ai-language-menu">
            <div class="ai-language_header">
              Add Language
            </div>
            <div>
                <div class="form-block">
                    <label>
                        Language
                        <select name="Language"
                            @change="onChangeLanguage"
                            v-model="enteredLanguage.language"
                        >
                            <option v-for="item in formattedLanguages"
                                :key="item.languageCode"
                                :value="item"
                            >
                                {{item.language}}
                            </option>
                        </select>
                    </label>
                    <label>
                        Gender
                        <select name="gender"
                                @change="onChangeGender"
                                v-model="enteredLanguage.gender"
                        >
                            <option v-for="supportedGender in supportedGenders"
                                    :key="supportedGender"
                                    :value="supportedGender"
                            >
                                {{supportedGender}}
                            </option>
                        </select>
                    </label>
                    <label>
                        Voice
                        <select name="voice"
                                @change="onChangeVoice"
                                v-model="enteredLanguage.voice"
                        >
                            <option v-for="supportedVoice in supportedVoices"
                                    :key="supportedVoice"
                                    :value="supportedVoice"
                            >
                                {{supportedVoice}}
                            </option>
                        </select>
                        <span class="field-description">
                          Find audio samples in the <a href="https://cloud.google.com/text-to-speech/docs/voices">Google Cloud Voices docs.</a>
                        </span>
                    </label>
                    <div class="ai-language__button-wrapper">
                      <woot-submit-button
                          button-text="Submit"
                          @click="enterLanguage"
                      />
                    </div>
            </div>
          </div>
        </div>
        </div>
      </modal>
      <modal :on-close="closeAiFunctionsMenu" :show="isAiFunctionsMenuOpen">
        <div class="ai-language-wrapper">
          <div class="ai-language-menu">
            <div class="ai-language_header">
              Add Function
            </div>
            <div>
              <label>
                Name
                <textarea
                    class="low-input"
                    v-model.trim="functionName"
                    type="text"
                />
              </label>
              <label>
                Purpose
                <textarea
                    class="low-input"
                    v-model.trim="functionPurpose"
                    type="text"
                />
              </label>
              <label>Arguments</label>
              <table class="bordered-table">
                <thead>
                    <tr>
                      <th>
                          Name
                      </th>
                      <th>
                          Description
                      </th>
                      <th>
                          Actions
                      </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                      v-for="argument in functionArguments"
                    >
                      <td>
                        <textarea
                          class="low-input"
                          v-model.trim="argument.name"
                          type="text"
                        />
                      </td>
                      <td>
                        <textarea
                          class="low-input"
                          v-model.trim="argument.description"
                          type="text"
                        />
                      </td>
                      <td> 
                        <div
                          v-if="functionArguments.length !== 1"
                          @click="() => {deleteRowArgument(argument)}"
                        >
                          <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
              <div class="ai-functions__button-wrapper">
                      <woot-submit-button
                          button-text="Add Argument"
                          @click="AddArgument"
                      />
              </div>
              <label>
                Web Hook URL
                <textarea
                    class="low-input"
                    v-model.trim="webhookUrl"
                    type="text"
                />
              </label>
              <label>
                Web Hook Auth User (Optional)
                <textarea
                    class="low-input"
                    v-model.trim="webhookAuthUser"
                    type="text"
                />
              </label>
              <label>
                Web Hook Auth Password (Optional)
                <textarea
                    class="low-input"
                    v-model.trim="webhookAuthPassword"
                    type="text"
                />
              </label>
            </div>
            <div>
                <div class="form-block">
                    <div class="ai-language__button-wrapper">
                      <woot-submit-button
                          button-text="Submit"
                          @click="enterFunction"
                      />
                    </div>
            </div>
          </div>
        </div>
        </div>
      </modal>
    <div v-if="agents.length !== 0" class="medium-12 columns ivr-form">
      <div style="margin-bottom: 15px">
        <label class="ivr-form__menu-label">
          AI Interactive Voice Response
        </label>
  
        <span class="field-description">
          Most business phone systems feature an AI Interactive Voice Response menu that helps direct callers to the right place. You can collect voicemails and automatically transfer callers to the proper department.
        </span>
      </div>
      <div>
        <input
            id="sms-bot-settings__is-ai-agent-enabled-checkbox"
            v-model="isAiAgentEnabled"
            class="sms-bot-settings__checkbox"
            type="checkbox"
            @input="handleIsAiAgentEnabled"
        />
  
        <label for="sms-bot-settings__is-enabled-checkbox">
          Enable AI Agent
        </label>
      </div>
      <div v-if="isAiAgentEnabled || isEnabled">
        <div class="ivr-item-wrapper">
          <label>
            AI Languages
          </label>
          <div class="form-block">
            <div class="languages-table">
              <table class="bordered-table">
                <colgroup>
                    <col style="width: 25%" />
                    <col style="width: 20%" />
                    <col style="width: 20%" />
                    <col style="width: 25%" />
                    <col style="width: 10%" />
                </colgroup>

                <thead>
                    <tr>
                      <th>
                          Name
                      </th>
                      <th>
                          Code
                      </th>
                      <th>
                          Gender
                      </th>
                      <th>
                          Voice
                      </th>
                      <th>
                        Actions
                      </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                      v-for="language in selectedLanguages"
                      :key="language.code"
                    >
                      <td>{{ language.name }}</td>
                      <td>
                        {{ language.code }}
                      </td>
                      <td>{{ language.gender }}</td>
                      <td>{{ language.voice }}</td>
                      <td> 
                        <div
                          v-if="selectedLanguages.length !== 1"
                          @click="() => {deleteRow(language)}"
                        >
                          <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
          </div>
        </div>
        </div>
        <div class="ivr-form__buttons-wrapper">
        <woot-submit-button
            button-text="Add Language"
            @click="addLanguage"
        />
        </div>
      <div>
        <label>
          AI Agent Setup
          <textarea
              class="low-input"
              v-model.trim="agentName"
              type="text"
              placeholder="Enter AI Agent Name"
          />
          <select name="personality"
                  v-model="agentPersonality"
          >
              <option :value="null" disabled>Select AI Agent Personality</option>
              <option :value="'Informal'">Informal</option>
              <option :value="'Professional'">Professional</option>
              <option :value="'Witty'">Witty</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          Prompt
          <textarea
              class="prompt-textarea"
              id="prompt-textarea"
              v-model.trim="intro"
              type="text"
              placeholder="Use natural language to provide initial instructions and settings to configure the agent. It may help to think of it as instructing a human intern on their first day. For more complex tasks, use markdown style headers to define steps."
        />
        </label>
      </div>
      <label class="advanced-config" id="prompt-config" @click="openPromptConfig">
        <img src="https://icongr.am/entypo/triangle-down.svg?size=32&color=000000"/>
            Advanced Config
      </label>
      <div class="range-box" v-if="promptExpanded">
        <div class="range-form"> 
          <input class="input-range-form" type="range" id="prompt_confidence" v-model="prompt_confidence" min="0" max="1" step="0.01"/>
          <label class="range-label">
              Prompt Confidence: {{ prompt_confidence }}
          </label>
        </div>
        <span class="field-description">
            Threshold to fire a speech-detect event at the end of the utterance. Float value between 0.0 and 1.0. Decreasing this value will reduce the pause after the user speaks, but may introduce false positives.
        </span>
        <div class="range-form">
          <input class="input-range-form" type="range" id="prompt_top_p" v-model="prompt_top_p" min="0" max="1" step="0.01"/>
          <label class="range-label">
              Prompt Top P: {{ prompt_top_p }}
          </label>
        </div>
        <span class="field-description">
            Randomness setting. Alternative to temperature. Float value between 0.0 and 1.0. Closer to 0 will make the output less random.
        </span>
        <div class="range-form">
          <input class="input-range-form" type="range" id="prompt_temperature" v-model="prompt_temperature" min="0" max="2" step="0.01"/>
          <label class="range-label">
              Temperature: {{ prompt_temperature }}
          </label>
        </div>
        <span class="field-description">
            Randomness setting. Float value between 0.0 and 2.0. Closer to 0 will make the output less random.        
        </span>
      </div>
      <div>
        <label>
          Post Prompt Text
          <textarea
              class="prompt-textarea"
              id="post-prompt-textarea"
              v-model.trim="postPrompt"
              type="text"
              placeholder="Summarize the conversation"
        />
        </label>
        <!-- <span class="field-description">
            Use natural language to provide final instructions and settings to tell the agent what to do after the call ends. For more complex tasks, use markdown-styled steps. You can also provide a response framework with placeholders and tell the agent to replace the placeholders with caller information. 
          </span> -->
        <label>
          Post Prompt URL
          <textarea
              class="low-input"
              id="post-prompt-url-textarea"
              v-model.trim="postPromptUrl"
              type="text"
        />
        </label>
        <label class="advanced-config" id="post-prompt-config" @click="openPostPromptConfig">
        <img src="https://icongr.am/entypo/triangle-down.svg?size=32&color=000000"/>
            Advanced Config
      </label>
      <div class="range-box" v-if="postPromptExpanded">
        <div class="range-form">
          <input class="input-range-form" type="range" id="post_prompt_top_p" v-model="post_prompt_top_p" min="0" max="1" step="0.01"/>
          <label class="range-label">
              Prompt Top P: {{ post_prompt_top_p }}
          </label>
        </div>
        <span class="field-description">
            Randomness setting. Alternative to temperature. Float value between 0.0 and 1.0. Closer to 0 will make the output less random.
        </span>
        <div class="range-form">
          <input class="input-range-form" type="range" id="post_prompt_temperature" v-model="post_prompt_temperature" min="0" max="2" step="0.01"/>
          <label class="range-label">
              Temperature: {{ post_prompt_temperature }}
          </label>
        </div>
        <span class="field-description">
            Randomness setting. Float value between 0.0 and 2.0. Closer to 0 will make the output less random.        
        </span>
      </div>
      </div>
      <div>
        <label>
            Data Collection 
      </label>
      </div>
      <div class="ivr-item-wrapper">
        <div>
          <input
            id="sms-bot-settings__is-name-asking-enabled-checkbox"
            v-model="isNameAskingEnabled"
            class="sms-bot-settings__checkbox"
            type="checkbox"
            @input="handleIsNameAskingEnabled"
          />
          <label for="sms-bot-settings__is-name-asking-enabled-checkbox">
            Collect Name
          </label>
        </div>
        <div>
          <input
            id="sms-bot-settings__is-email-asking-enabled-checkbox"
            v-model="isEmailAskingEnabled"
            class="sms-bot-settings__checkbox"
            type="checkbox"
            @input="handleIsEmailAskingEnabled"
          />
          <label for="sms-bot-settings__is-email-asking-enabled-checkbox">
            Collect Email
          </label>
        </div>
        <div>
          <input
            id="sms-bot-settings__is-message-asking-checkbox"
            v-model="isMessageAskingEnabled"
            class="sms-bot-settings__checkbox"
            type="checkbox"
            @input="handleIsMessageAskingEnabled"
          />
          <label for="sms-bot-settings__is-message-asking-checkbox">
            Collect Message
          </label>
        </div>
        <div>
          <label>
            Custom Attributes
          </label>
          <div class="form-block"
           v-for="(item, index) in attributesItems"
          >
            <div class="delete-icon-custom-attributes"
                v-if="attributesItems.length > 0"
                @click="() => {deleteAttributesItem(index)}"
              >
                <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
            </div>
            <textarea
                  class="textarea-custom-attributes"
                  v-model.trim="attributesItems[index]"
                  type="text"
                  placeholder="Enter name of custom attribute, e.g. 'Fax' or 'Address'"
              />
          </div>
          <div class="ivr-form__buttons-wrapper">
            <woot-submit-button
                button-text="Add Custom Attribute"
                @click="addAttributesItem"
            />
          </div>
        </div>
      </div>
      <div>
        <label>
          Messages & Notifications
        </label>
      </div>
      <div class="ivr-item-wrapper">
        <div>
          <label>
            <input
              id="public-1"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="none"
              class="label-input"
            />
            <label class="label-tag" for="public-1">{{
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.NONE')
            }}</label>
          </label>
          <label>
            <input
              id="public-2"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="email"
              class="label-input"
            />
            <label class="label-tag" for="public-2">{{
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.EMAIL')
            }}</label>
          </label>
          <label>
            <input
              id="public-3"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="sms"
              class="label-input"
            />
            <label class="label-tag" for="public-3">{{
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.SMS')
            }}</label>
          </label>
          <label>
            <input
              id="public-4"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="emailSms"
              class="label-input"
            />
            <label class="label-tag" for="public-4">{{
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.EMAIL_SMS')
            }}</label>
          </label>
        </div>
        <div v-if="notificationType !== 'none'">
          <label>
            Additional Emails & Phones
          </label>
          <div class="form-block"
           v-for="(item, index) in notificationDomain"
          >
            <div class="delete-icon-custom-attributes"
                v-if="notificationDomain.length > 1"
                @click="() => {removeNotificationDomain(index)}"
              >
                <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
            </div>
            <textarea
                  class="textarea-custom-attributes"
                  v-model.trim="notificationDomain[index]"
                  type="text"
                  placeholder="example@example.com"
              />
          </div>
          <div class="ivr-form__buttons-wrapper">
            <woot-submit-button
                button-text="Add Email"
                @click="addNotificationDomain"
            />
          </div>
          <div class="form-block"
           v-for="(item, index) in notificationPhone"
          >
            <div class="delete-icon-custom-attributes"
                v-if="notificationPhone.length > 1"
                @click="() => {removeNotificationPhone(index)}"
              >
                <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
            </div>
            <textarea
                  class="textarea-custom-attributes"
                  v-model.trim="notificationPhone[index]"
                  type="text"
                  placeholder="+1234567890"
              />
          </div>
          <div class="ivr-form__buttons-wrapper">
            <woot-submit-button
                button-text="Add Phone"
                @click="addNotificationPhone"
            />
          </div>
        </div>
      </div>
      </div>
      <div v-if="isAiAgentEnabled && currentUser.role === 'administrator'">
        <label>
          Scheduling
        </label>
        <div class="ivr-item-wrapper">
          <div>
            <input
              id="sms-bot-settings__is-scheduling-enabled-checkbox"
              v-model="isSchedulingEnabled"
              class="sms-bot-settings__checkbox"
              type="checkbox"
              @input="handleIsSchedulingEnabled"
            />
            <label for="sms-bot-settings__is-scheduling-enabled-checkbox">
              Ability to schedule an appointment
            </label>
          </div>
          <div v-if="isSchedulingEnabled">
              <select name="scheduling"
                      v-model="enteredSchedulingLink"
              >
                  <option :value="null" disabled>Select Schedule Link</option>
                  <option v-for="schedulingLink in schedulingLinks"
                          :key="schedulingLink.slug"
                          :value="schedulingLink.name"
                  >
                      {{schedulingLink.name}}
                  </option>
              </select>
          </div>
        </div>
        <div>
          <label>
            Custom Knowledge
          </label>
        </div>

        <div class="form-block"
           v-for="(item, index) in faqItems"
          >
          <div class="delete-icon"
              v-if="faqItems.length !== 1"
              @click="() => {deleteFaqItem(index)}"
            >
            <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
          </div>
          <div class="ivr-item-wrapper">
              <label :class="{ error: faqItems[index].error_keyword }">
                Name
                <textarea
                    class="low-input"
                    v-model.trim="faqItems[index].question"
                    type="text"
                    placeholder="Enter the name of the custom knowledge'"
                />
              </label>
              <label :class="{ error: faqItems[index].error_keyword }">
                Content
                <textarea
                    style="min-height: 85px"
                    v-model.trim="faqItems[index].answer"
                    type="text"
                    placeholder="Enter the content of the custom knowledge"
                />
              </label>
          </div>
        </div>
        <div class="ivr-form__buttons-wrapper">
        <woot-submit-button
            button-text="Add Content"
            @click="addFaqItem"
        />
        </div>
      </div>
      <div class="ivr-item-wrapper">
          <label>
            Functions
          </label>
          <div class="form-block">
            <div class="languages-table">
              <table class="bordered-table">
                <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 75%" />
                    <col style="width: 10%" />
                </colgroup>

                <thead>
                    <tr>
                      <th>
                          Name
                      </th>
                      <th>
                          Purpose
                      </th>
                      <th>
                          Actions
                      </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                      v-for="aiFunction in aiFunctions"
                      :key="aiFunction.name"
                    >
                      <td>{{ aiFunction.name }}</td>
                      <td>
                        {{ aiFunction.purpose }}
                      </td>
                      <td> 
                        <div
                          @click="() => {deleteRowFunction(aiFunction)}"
                        >
                          <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
          </div>
        </div>
        </div>
        <div class="ivr-form__buttons-wrapper">
        <woot-submit-button
            button-text="Add Function"
            @click="addFunction"
        />
        </div>
      <div>
        <input
            id="sms-bot-settings__is-ai-ivr-enabled-checkbox"
            v-model="isEnabled"
            class="sms-bot-settings__checkbox"
            type="checkbox"
            @input="handleIsEnabled"
        />
  
        <label for="sms-bot-settings__is-enabled-checkbox">
          Enable AI IVR
        </label>
      </div>
      <div v-if="isEnabled">
        <div>
          <label>
            Departments
          </label>
        </div>

        <div class="form-block"
           v-for="(item, index) in menuItems"
          >
          <div class="delete-icon"
              v-if="menuItems.length !== 1"
              @click="() => {deleteItem(index)}"
            >
            <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
          </div>


          <div class="ivr-item-wrapper">
            <label :class="{ error: menuItems[index].error_keyword }">
              Department name

              <textarea
                  class="low-input"
                  v-model.trim="menuItems[index].name"
                  @input="onDepartmentNameInput($event, index)"
                  type="text"
                  placeholder="Enter department name, e.g. 'Support'"
              />
            </label>


            <label :class="{ error: menuItems[index].error_response }">
              Agents
              <Multiselect
                v-model="item.agents"
                :options="agents"
                :multiple="true"
                track-by="id"
                label="name"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                placeholder="Any Available Agent (By Default)"
                selected-label
              >
              </Multiselect>
            </label>
            <div v-if="inbox.working_hours_enabled">
              <input
                  v-model="menuItems[index].availability"
                  class="sms-bot-settings__checkbox"
                  type="checkbox"
              />
        
              <label>
                Available after business hours
              </label>
            </div>
            
          </div>
        </div>
      <div class="ivr-form__buttons-wrapper">
        <woot-submit-button
            button-text="Add Department"
            @click="addItem"
        />
      </div>
      <div>
        <label>
          No Available Agents Message
          <textarea
              class="prompt-textarea"
              v-model.trim="unavailableText"
              type="text"
              placeholder="To leave a message for the agent, please leave a detailed message with your full name, phone number, email address and your message you would like forwarded. "
        />
        </label>
      </div>
          <input
              id="missed-call-menu__is-enabled-checkbox"
              v-model="ivr_extensions_enabled"
              class="missed-call-menu__checkbox"
              type="checkbox"
              @input="handleIsIVRExtensionsEnabled($event)"
          />
          <label for="missed-call-menu__is-enabled-checkbox">
            Enable IVR Extensions
          </label>
          <div v-if="ivr_extensions_enabled">
            <div class="ivr-item-wrapper">
              <div class="ivr-extensions_body-modal" v-for="agent in agents">
                  <label>
                      {{agent.name}}
                  </label>
                  <input class="ivr-extensions_input-number" type="number" @keydown="onPress" v-model="ivr_extensions.find(obj => {return obj.agent === agent.id}).numbers" v-on:change="onInutIvrNumbers($event, agent.id)" min="100" max="999"/>
              </div>
            </div>
          </div> 
      </div>
      <div v-if="isAiAgentEnabled || isEnabled">
          <div class="ivr-form__buttons-wrapper">
            <woot-submit-button
                button-text="Update"
                :loading="updateInProgress"
                @click="updateIVRSettings"
            />
         </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import vSelect from 'vue-select';
  import Multiselect from "vue-multiselect";
  import 'vue-select/dist/vue-select.css';
  import signalwireChannelAPI from "../../../../../../../api/channel/signalwireChannel";
  import {globalAIP} from "../../../../../../../api/globalAIP";
  import {mapGetters} from "vuex";
  import Modal from '../../../../../../../components/Modal';
  import alertMixin from 'shared/mixins/alertMixin';
  import voices from './Languages.json';
  import languages from './FormattedLanguages.json';

  const JUSTSCHED_URL= `${window.chatwootConfig.calendsoLink}api/teams/event-type-info`
  
  export default {
    mixins: [alertMixin],
    name: "IvrForm",
    components: {
      vSelect,
      Multiselect,
      Modal
    },
    props: {
      agents: {
        type: Array,
        default: [],
        required: false
      },
      inbox: {
        type: Object
      }
    },
    data() {
      return {
        prompt_confidence: 0.6,
        prompt_top_p: 1.0,
        prompt_temperature: 1.0,
        post_prompt_top_p: 1.0,
        post_prompt_temperature: 0.0,
        enteredLanguage: {language: null, gender: "", voice: ""},
        pickedLanguage: "",
        pickedGender: "",
        promptExpanded: false,
        postPromptExpanded: false,
        schedulingLinks: [],
        enteredSchedulingLink: null,
        aiFunctions: [],
        isAiFunctionsMenuOpen: false,
        functionArguments: [{name: "", description: ""}],
        functionName: "",
        functionPurpose: "",
        webhookUrl: "",
        webhookAuthUser: "",
        webhookAuthPassword: "",
        selectedLanguages:[{name: "English (US)", code: "en-US", gender: "MALE", voice: "gcloud.en-US-Neural2-A"}],
        supportedLanguages: voices,
        formattedLanguages: languages,
        supportedLanguageCodes: [],
        formattedSupportedLanguages: [],
        supportedGenders: [],
        supportedVoices:[],
        agentPersonality: null,
        agentName: "",
        postPrompt: "",
        postPromptUrl: "",
        isSchedulingEnabled: false,
        isNameAskingEnabled: false,
        isNameAskingEnabled: false,
        isNameAskingEnabled: false,
        isAiLanguagesMenuOpen: false,
        languagesItems: [],
        ivr_extensions_enabled: false,
        ivr_extensions: [],
        updateInProgress: false,
        isAiAgentEnabled: false,
        isEnabled: false,
        linkColor: "#4e80cc",
        intro: "",
        notificationType: "none",
        notificationDomain: [""],
        notificationPhone: [""],
        if_unavailable: "",
        unavailableText: "",
        menuItems: [
          {
            name: "",
            agents: [],
            availability: false
          }
        ],
        faqItems: [
          {
            question: "",
            answer: "",
          }
        ],
        attributesItems: [""],
      }
    },
      async mounted() {
        this.eventData();
        this.isEnabled = this.inbox.is_ai_ivr_enabled;
        this.supportedLanguages = this.supportedLanguages.voices;
        this.formattedLanguages = this.formattedLanguages.languages;
        this.isAiAgentEnabled = this.inbox.is_ai_agent_enabled;
          if (this.inbox.ai_ivr_tree) {
              this.isNameAskingEnabled = this.inbox.ai_ivr_tree.is_name_asking_enabled ? this.inbox.ai_ivr_tree.is_name_asking_enabled : false;
              this.isEmailAskingEnabled = this.inbox.ai_ivr_tree.is_email_asking_enabled ? this.inbox.ai_ivr_tree.is_email_asking_enabled : false;
              this.isMessageAskingEnabled = this.inbox.ai_ivr_tree.is_message_asking_enabled ? this.inbox.ai_ivr_tree.is_message_asking_enabled : false;
              this.isSchedulingEnabled = this.inbox.ai_ivr_tree.is_scheduling_enabled ? this.inbox.ai_ivr_tree.is_scheduling_enabled : false;
              if(this.inbox.ai_ivr_tree.event_link && JSON.stringify(this.inbox.ai_ivr_tree.event_link) !== "{}"){
                this.enteredSchedulingLink = this.inbox.ai_ivr_tree.event_link.name;
              }
              else {
                  this.enteredSchedulingLink = null;
                }
              this.intro = this.inbox.ai_ivr_tree.intro ? this.inbox.ai_ivr_tree.intro : "";
              this.notificationType = this.inbox.ai_ivr_tree.notification_type ? this.inbox.ai_ivr_tree.notification_type : "none";
              this.notificationDomain = this.inbox.ai_ivr_tree.notification_domain ? this.inbox.ai_ivr_tree.notification_domain : [""];
              this.notificationPhone = this.inbox.ai_ivr_tree.notification_phone ? this.inbox.ai_ivr_tree.notification_phone : [""];
              this.attributesItems = this.inbox.ai_ivr_tree.custom_attributes ? this.inbox.ai_ivr_tree.custom_attributes : [""];
              this.faqItems = this.inbox.ai_ivr_tree.questions ? this.inbox.ai_ivr_tree.questions : [{question: "", answer: ""}];
              this.if_unavailable = this.inbox.ai_ivr_tree.if_unavailable ? this.inbox.ai_ivr_tree.if_unavailable : "";
              this.ivr_extensions_enabled = this.inbox.ai_ivr_tree.ivr_extensions_enabled ? this.inbox.ai_ivr_tree.ivr_extensions_enabled : false;
              this.prompt_confidence = this.inbox.ai_ivr_tree.prompt_confidence ? this.inbox.ai_ivr_tree.prompt_confidence : 0.6;
              this.prompt_top_p = this.inbox.ai_ivr_tree.prompt_top_p ? this.inbox.ai_ivr_tree.prompt_top_p : 1.0;
              this.prompt_temperature = this.inbox.ai_ivr_tree.prompt_temperature ? this.inbox.ai_ivr_tree.prompt_temperature : 1.0;
              this.post_prompt_top_p = this.inbox.ai_ivr_tree.post_prompt_top_p ? this.inbox.ai_ivr_tree.post_prompt_top_p : 1.0;
              this.post_prompt_temperature = this.inbox.ai_ivr_tree.post_prompt_temperature ? this.inbox.ai_ivr_tree.post_prompt_temperature : 0.0;
              this.postPrompt = this.inbox.ai_ivr_tree.post_prompt ? this.inbox.ai_ivr_tree.post_prompt : "";
              this.postPromptUrl = this.inbox.ai_ivr_tree.post_prompt_url ? this.inbox.ai_ivr_tree.post_prompt_url : "";
              this.agentName = this.inbox.ai_ivr_tree.agent_name ? this.inbox.ai_ivr_tree.agent_name : "";
              this.agentPersonality = this.inbox.ai_ivr_tree.agent_personality ? this.inbox.ai_ivr_tree.agent_personality : null;
              this.aiFunctions = this.inbox.ai_ivr_tree.functions ? this.inbox.ai_ivr_tree.functions : [];
              this.unavailableText = this.inbox.ai_ivr_tree.unavailable_text ? this.inbox.ai_ivr_tree.unavailable_text : "";
              this.selectedLanguages = this.inbox.ai_ivr_tree.languages ? this.inbox.ai_ivr_tree.languages : [{name: "English (US)", code: "en-US", gender: "MALE", voice: "gcloud.en-US-Neural2-A"}];
              if(this.inbox.ai_ivr_tree.ivr_extensions){
                this.ivr_extensions = this.inbox.ai_ivr_tree.ivr_extensions;
                for(const agent of this.agents){
                  if(!this.ivr_extensions.find(obj => {
                    return obj.agent === agent.id;
                  })){
                    this.ivr_extensions.push({agent: agent.id, numbers: null});
                  }
  
                }
              }
              else{
                for(const agent of this.agents){
                  this.ivr_extensions.push({agent: agent.id, numbers: null})
                }
              }
              this.menuItems = this.inbox.ai_ivr_tree.departments ? this.inbox.ai_ivr_tree.departments.map(menuItem => {return {name: menuItem.name, agents: this.agents.filter(agent => menuItem.agents.includes(agent.id)), availability: menuItem.availability}}) :  [{name: "", agents: [], availability: false}];
          }
      },
      watch: {
        agents(){
          if(this.inbox.ai_ivr_tree.ivr_extensions){
            this.ivr_extensions = this.inbox.ai_ivr_tree.ivr_extensions;
            for(const agent of this.agents){
              if(!this.ivr_extensions.find(obj => {
                return obj.agent === agent.id;
              })){
                this.ivr_extensions.push({agent: agent.id, numbers: null});
              }
  
            }
          }
          else{
            for(const agent of this.agents){
              this.ivr_extensions.push({agent: agent.id, numbers: null})
            }
          }
          this.menuItems = this.inbox.ai_ivr_tree.departments ? this.inbox.ai_ivr_tree.departments.map(menuItem => {return {name: menuItem.name,agents: this.agents.filter(agent => menuItem.agents.includes(agent.id)), availability: menuItem.availability}}) :  [{name: "", agents: [], availability: false}];
        }
      },
      computed: {
        ...mapGetters({
          accountId: 'getCurrentAccountId',
          currentUser: 'getCurrentUser'
        }),
  
      aiIvrTree: function () {
        let ai_ivr_tree = {};
        if(this.isAiAgentEnabled || this.isEnabled){
          ai_ivr_tree.languages = this.selectedLanguages;
          ai_ivr_tree.is_name_asking_enabled = this.isNameAskingEnabled;
          ai_ivr_tree.is_email_asking_enabled = this.isEmailAskingEnabled;
          ai_ivr_tree.is_message_asking_enabled = this.isMessageAskingEnabled;
        }
        if(this.isAiAgentEnabled){
          ai_ivr_tree.event_link = this.enteredSchedulingLink ? this.schedulingLinks.find(obj => obj.name == this.enteredSchedulingLink) : null;
          if (this.intro.length > 0) {
             ai_ivr_tree.intro = this.intro;
          }
          if (this.if_unavailable.length > 0) {
          ai_ivr_tree.if_unavailable = this.if_unavailable;
        }
          ai_ivr_tree.is_scheduling_enabled = this.isSchedulingEnabled;
          ai_ivr_tree.prompt_confidence = this.prompt_confidence;
          ai_ivr_tree.prompt_temperature = this.prompt_temperature;
          ai_ivr_tree.prompt_top_p = this.prompt_top_p;
          ai_ivr_tree.questions = this.faqItems;
          ai_ivr_tree.custom_attributes = this.attributesItems;
          ai_ivr_tree.post_prompt = this.postPrompt;
          ai_ivr_tree.post_prompt_url = this.postPromptUrl;
          ai_ivr_tree.post_prompt_temperature = this.post_prompt_temperature;
          ai_ivr_tree.post_prompt_top_p = this.post_prompt_top_p;
          ai_ivr_tree.functions = this.aiFunctions;
          ai_ivr_tree.agent_name = this.agentName;
          ai_ivr_tree.agent_personality = this.agentPersonality;
          ai_ivr_tree.notification_type = this.notificationType;
          ai_ivr_tree.notification_domain = this.notificationDomain;
          ai_ivr_tree.notification_phone = this.notificationPhone;
        }
        if (this.isEnabled){
          ai_ivr_tree.unavailable_text = this.unavailableText;
          ai_ivr_tree.ivr_extensions_enabled = this.ivr_extensions_enabled;
          ai_ivr_tree.ivr_extensions = this.ivr_extensions.length === 0 ? [] : this.ivr_extensions.filter(obj => obj.numbers !== null);
          ai_ivr_tree.departments = this.menuItems.map(item => { return {name: item.name, agents: item.agents.map(obj => obj.id), availability: item.availability}});
        }
        
          // for (const item of this.menuItems) {
          //     if (item.enabled) {
          //         ai_ivr_tree[`${item.symbol}`] = {
          //             response: item.response,
          //             agents: item.agents.length === 0 ? [] : item.agents.map(agent => agent.id),
          //         }
          //     }
          // }
  
  
        return ai_ivr_tree;
      }
    },
    methods: {
      eventData() {
        let vm = this;
        const encodedString = Buffer.from(this.currentUser.email).toString('base64')
        try {
          fetch(JUSTSCHED_URL+`?email=${encodedString}`, {
            'method': 'GET',
            headers: {'Content-Type': 'application/json'}
          }).then(resp =>  resp.json()).then(event => {
                vm.schedulingLinks = event
          })
        } catch (error) {
          throw new Error(error);
        }
      },
      openPromptConfig(){
        this.promptExpanded = !this.promptExpanded;
      },
      openPostPromptConfig(){
        this.postPromptExpanded = !this.postPromptExpanded;
      },
      onDepartmentNameInput(e, index){
        this.menuItems[index].name = this.menuItems[index].name.replace(/[^\w\s]/gi, '')
      },
      deleteRow(row){
        this.selectedLanguages = this.selectedLanguages.filter((language) => language !== row);
      },
      deleteRowFunction(row){
        this.aiFunctions = this.aiFunctions.filter((aiFunction) => aiFunction !== row);
      },
      deleteRowArgument(row){
        this.functionArguments = this.functionArguments.filter((argument) => argument !== row);
      },
      closeAiLanguagesMenu(){
        this.isAiLanguagesMenuOpen = false;
      },
      closeAiFunctionsMenu(){
        this.isAiFunctionsMenuOpen = false;
      },
      onChangeLanguage(e){
        this.pickedLanguage = this.enteredLanguage.language.languageCode;
        this.supportedGenders = this.supportedLanguages.filter((language) => language.languageCodes[0] === this.pickedLanguage).map((language) => language.ssmlGender).filter((value, index, array) => array.indexOf(value) === index);
      },
      onChangeGender(e){
        this.supportedVoices = this.supportedLanguages.filter((language) => language.languageCodes[0] === this.pickedLanguage).filter((language) => language.ssmlGender === e.target.value).map((language) => language.name);
      },
      onChangeVoice(e){
      },
      enterLanguage(){
        let tempLanguageCode = this.enteredLanguage.language.languageCode;
        let tempLanguageName = this.enteredLanguage.language.language;
        this.selectedLanguages.push({name: tempLanguageName, code: tempLanguageCode, gender: this.enteredLanguage.gender, voice: this.enteredLanguage.voice});
        this.enteredLanguage = {language: null, gender: "", voice: ""};
        this.pickedLanguage = "";
        this.supportedGenders = [];
        this.supportedVoices = [];
        this.closeAiLanguagesMenu();
      },
      enterFunction(){
        this.aiFunctions.push({name: this.functionName, purpose: this.functionPurpose, arguments: this.functionArguments, web_hook_url: this.webhookUrl, web_hook_auth_user: this.webhookAuthUser, web_hook_auth_password: this.webhookAuthPassword});
        this.functionName = "";
        this.functionPurpose = "";
        this.functionArguments = [{name: "", description: ""}];
        this.webhookUrl = "";
        this.webhookAuthPassword = "";
        this.webhookAuthUser = "";
        this.closeAiFunctionsMenu();
      },
      deleteItem(index) {
        this.menuItems.splice(index, 1);
      },
      deleteFaqItem(index) {
        this.faqItems.splice(index, 1);
      },
      deleteAttributesItem(index) {
        this.attributesItems.splice(index, 1);
      },
      addItem(){
        this.menuItems.push({name: "", agents: [], availability: false});
      },
      addFaqItem(){
        this.faqItems.push({question: "", answer: ""});
      },
      addAttributesItem(){
        this.attributesItems.push("");
      },
      addLanguage(){
        this.isAiLanguagesMenuOpen = true;
      },
      AddArgument(){
        this.functionArguments.push({name: "", description: ""});
      },
      addFunction(){
        this.isAiFunctionsMenuOpen = true;
      },
      addNotificationDomain(){
        this.notificationDomain.push("");
      },
      removeNotificationDomain(index){
        this.notificationDomain.splice(index, 1);
      },
      addNotificationPhone(){
        this.notificationPhone.push("");
      },
      removeNotificationPhone(index){
        this.notificationPhone.splice(index, 1);
      },
      onPress(e){
        if(e.key == "-"){
          e.preventDefault();
        }
        if(e.key == "+"){
          e.preventDefault();
        }
        if(e.key == "e"){
          e.preventDefault();
        }
        return true;
      },
      onInutIvrNumbers(e, agent){
                if(e.target.value.toString().length == 3 && !this.ivr_extensions.find(obj => {
                  return obj.numbers === e.target.value && obj.agent !== agent;
                })){
                  this.ivr_extensions.find(obj => {
                    return obj.agent === agent
                  }).numbers = e.target.value;
                }
                else{
                  this.ivr_extensions.find(obj => {
                    return obj.agent === agent
                  }).numbers = null;
                }
      },
        async handleIsEnabled(e) {
          this.isEnabled = e.target.checked;
          if(e.target.checked == true){
            await globalAIP.updateIVREnabled(this.accountId, this.inbox.id, false , this.isEnabled, this.isAiAgentEnabled);
            if(this.inbox.is_new_ivr_enabled){
              this.destroyIVRAgent();
            }
          }
          await globalAIP.updateAIIVREnabled(this.accountId, this.inbox.id, e.target.checked);
          await this.$store.dispatch("inboxes/get");
        },
        async handleIsAiAgentEnabled(e) {
          this.isAiAgentEnabled = e.target.checked;
          if(e.target.checked == true){
            await globalAIP.updateIVREnabled(this.accountId, this.inbox.id, false, this.isEnabled, this.isAiAgentEnabled);
            if(this.inbox.is_new_ivr_enabled){
              this.destroyIVRAgent();
            }
          }
          await globalAIP.updateAIAgentEnabled(this.accountId, this.inbox.id, e.target.checked);
  
          await this.$store.dispatch("inboxes/get");
        },
        async destroyIVRAgent() {
          try {
            const response = await axios.delete(
              `https://agentapi.conversate.us/v1/ivr/delete_agent/${this.inbox.ivr_agent_tree.agent_id}`
            );
            console.log("Destroy response: " + JSON.stringify(response.data));
            this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
          } catch (error) {
              console.log(error);
              this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
          }
          try {
            this.updateInProgress = true;
            await globalAIP.updateNewIVREnabled(this.accountId, this.inbox.id, false);
            const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;
    
            const response = await signalwireChannelAPI.UpdateIVRAgentSettings({
                channel_id: channel_id,
                ivr_agent_tree: {}
            });
            await this.$store.dispatch("inboxes/get");
          }
          catch (e) {
            this.showAlert("Error");
          }
          this.updateInProgress = false;
        },
        handleIsIVRExtensionsEnabled(e) {
          this.ivr_extensions_enabled = e.target.checked;
        },
        handleIsNameAskingEnabled(e) {
          this.isNameAskingEnabled = e.target.checked;
        },
        handleIsEmailAskingEnabled(e) {
          this.isEmailAskingEnabled = e.target.checked;
        },
        handleIsMessageAskingEnabled(e) {
          this.isMessageAskingEnabled = e.target.checked;
        },
        handleIsSchedulingEnabled(e) {
          this.isSchedulingEnabled = e.target.checked;
        },
        onHover(){
            this.linkColor = "#872cd1"
        },
        onLeave(){
          this.linkColor = "#4e80cc"
        },
        setIsMissedCallMenu(){
          this.$store.commit('missedCall/setMissedCallMenuOpen', true);
        },
      async updateIVRSettings() {
        try {
          this.updateInProgress = true;
  
          const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;
  
          const response = await signalwireChannelAPI.UpdateAIIVRSettings({
              channel_id: channel_id,
              ai_ivr_tree: this.aiIvrTree
          });
  
          await this.$store.dispatch("inboxes/get");
  
          this.showAlert("Update Successful");
        }
        catch (e) {
          this.showAlert("Error");
        }
  
        this.updateInProgress = false;
      }
    }
  }
  </script>
  
  <style scoped>
  .ivr-extensions_body-modal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ivr-extensions_input-number{
    width: 200px;
  }
  .ivr-form {
    margin-bottom: 30px;
  }
  
  .ivr-form textarea {
    resize: vertical;
  
    min-height: 45px;
  }
  
  .ivr-form__menu-label {
    font-size: 24px;
  }
  
  .ivr-form__buttons-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .field-description {
    font-size: 12px;
  }
  
  .select {
    margin-bottom: 1.6rem;
  }
  
  .ivr-item-wrapper {
    border: 1px solid #e0e6ed;
  
    padding: 20px;
  
    border-radius: 5px;
  
    margin-bottom: 15px;
  }
  
  .ivr-form__symbol-chooser-wrapper {
      width: 100%;
  
      display: flex;
  
      justify-content: center;
      align-items: center;
      align-content: center;
  
      margin-top: 20px;
  }
  
  .ivr-form__symbol-chooser-wrapper--first {
      margin-top: 0;
  }
  
  .ivr-form__symbol-item {
      display: flex;
  
      justify-content: center;
      align-content: center;
      align-items: center;
  
      width: 60px;
      height: 40px;
  
      font-size: 20px;
      font-weight: bold;
      border-radius: 5px;
  
      color: white;
  
      cursor: pointer;
  
      background-color: #40A2F7;
  
      margin-right: 10px;
  }
  
  .ivr-form__symbol-item--active {
      background-color: #60CE4D;
  }
  
  .textarea-custom-attributes{
    width:90%;
  }
  .delete-icon-custom-attributes{
  padding: 0;
  position: absolute;
  top:10px;
  right:0px;
  border:none;
  background: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
  }
  .delete-icon{
  padding: 0;
  position: absolute;
  top:20px;
  right:-30px;
  border:none;
  background: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.form-block {
  margin-top: 10px;

  position: relative;
}
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .ivr-form__global-chooser-wrapper {
      margin-bottom: 20px;
  
      padding: 25px 20px 25px 20px;
  
      border-radius: 5px;
  
      /*border: 1px solid rgba(224,230,237,0.4);*/
  
      background-color: #F9FAFC;
  
  }

  .ai-language_header {
  font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Tahoma, Arial, sans-serif;

  font-size: 20px;

  display: flex;

  align-items: center;
  align-content: center;

  justify-content: center;

  margin-bottom: 10px;
  margin-top: 10px;
}

.ai-language-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ai-language-menu {
  width: 80%;
}
.ai-language__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px;
}

.ai-functions__button-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px;
}
.range-box{
  margin: 15px;
}

.range-form{
  display: flex;
  padding-right: 10px;
  grid-gap: 10px;
}
.input-range-form{
  width: 100%;
  height: 1.5rem;
  padding: 0;
}
.prompt-textarea{
  height: 150px;
}
.range-label{
  width: 50%
}

.low-input{
  height: 30px;
}

.advanced-config{
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer
}

  </style>
  