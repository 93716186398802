import authAPI from '../../../api/auth';
import { applyPageFilters } from './helpers';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

// getters
const getters = {
  getAllConversations: ({ allConversations }) =>
    allConversations.sort(
      (a, b) => b.messages.last()?.created_at - a.messages.last()?.created_at
    ),
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getMineChats: _state => activeFilters => {
    const currentUserID = authAPI.getCurrentUser().id;

    return _state.allConversations.filter(conversation => {
      const { assignee } = conversation.meta;
      const isAssignedToMe = assignee && assignee.id === currentUserID && conversation.meta.channel !== 'Channel::TeamChat';
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isChatMine = isAssignedToMe && shouldFilter;

      return isChatMine;
    });
  },
  getUnAssignedChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const isUnAssigned = !conversation.meta.assignee && conversation.meta.channel !== 'Channel::TeamChat';
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return isUnAssigned && shouldFilter;
    });
  },
  getAllStatusChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return shouldFilter;
    });
  },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
    getPrivateChats: _state => activeFilters => {
        const currentUserID = authAPI.getCurrentUser().id;
        return _state.allConversations.filter(conversation => {
            const { assignee } = conversation.meta;
            const isPrivateChat = conversation.meta.channel === 'Channel::TeamChat' && conversation.chat_type === "private"  ;
            const shouldFilter = applyPageFilters(conversation, activeFilters);
            return isPrivateChat && shouldFilter;
        });
    },

    getGroupChats: _state => activeFilters => {
        const currentUserID = authAPI.getCurrentUser().id;

        return _state.allConversations.filter(conversation => {
            const { assignee } = conversation.meta;
            const isGroupChat = conversation.meta.channel === 'Channel::TeamChat' && conversation.chat_type === "group"  ;
            const shouldFilter = applyPageFilters(conversation, activeFilters);
            return isGroupChat && shouldFilter;
        });
    },
  getUnreadCount(_state) {
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return _state.allConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationIdBySipUsername: _state => sipUsername => {
    return _state.allConversations.find(
      value => value.meta.sender.sip_username === sipUsername
    ).id;
  },
  getNextChatConversation: _state => {
    const [selectedChat] = getSelectedChatConversation(_state);
    const conversations = getters.getAllStatusChats(_state);
    if (conversations.length <= 1) {
      return null;
    }
    const currentIndex = conversations.findIndex(
      conversation => conversation.id === selectedChat.id
    );
    const nextIndex = (currentIndex + 1) % conversations.length;
    return conversations[nextIndex];
  },
  getStripeUrl($state) {
    return $state.stripeUrl;
  },

  get_transaction_history($state) {
    return $state.transaction_history.data;
  },

  get_calender_details($state) {
    return $state.calender_event.data;
  },

};

export default getters;
