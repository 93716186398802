import { LocalStorage } from 'shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';

export const setColorTheme = isOSOnDarkMode => {
    const selectedColorScheme =
        LocalStorage.get(LOCAL_STORAGE_KEYS.COLOR_SCHEME) || 'auto';
    if (
        (selectedColorScheme === 'auto' && isOSOnDarkMode) ||
        selectedColorScheme === 'dark'
    ) {
        document.body.classList.add('dark');
        document.documentElement.setAttribute('style', 'color-scheme: dark;');
    } else {
        document.body.classList.remove('dark');
        document.documentElement.setAttribute('style', 'color-scheme: light;');
    }
};
