<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="ion-android-add-circle"
      @click="openAddPopup"
    >
      {{ buttonText }}
    </woot-button>
    <campaign />
    <woot-modal :close-on-backdrop-click="false" :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-campaign @on-close="hideAddPopup" @open-add-ids-modal="handleCampaignPayload"/>
    </woot-modal>
    <create-calling-campaign :campaign-obj="campaignObj" :result="campaignPayload.result" :message="campaignPayload.message" :payload="campaignPayload" :show.sync="showAddIdsModal" @close-add-ids-modal="showAddIdsModal = false"/>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import Campaign from './Campaign.vue';
import AddCampaign from './AddCampaign';
import WootButton from '../../../../components/ui/WootButton.vue';
import CreateCallingCampaign from '../../../../components/widgets/modal/CreateCallingCampaign.vue';

export default {
  components: {
    CreateCallingCampaign,
    WootButton,
    Campaign,
    AddCampaign,
  },
  mixins: [campaignMixin],
  data() {
    return { showAddPopup: false,
      showAddIdsModal: false,
      campaignPayload:{},
      campaignObj:{},
    };
  },
  computed: {
    buttonText() {
      if (this.isOngoingType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONGOING');
      }
      else if(this.isEmailType)
      {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.EMAIL')
      }else if(this.isCallingType){
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.OUTBOUND_CALLING')
      }
      else if(this.isCallingType)
      {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.OUTBOUND_CALLING')
      }
      return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONE_OFF');
    },
  },
  mounted() {
    this.$store.dispatch('campaigns/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    handleCampaignPayload(campaignPayload, campaignObj){
      this.campaignPayload=campaignPayload
      this.campaignObj=campaignObj;
      this.showAddIdsModal = true;
    },
  },
};
</script>
