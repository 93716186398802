<template>
  <section class="notification--table-wrap">
    <woot-submit-button
      v-if="notificationMetadata.unreadCount"
      class="button nice success button--fixed-right-top"
      :button-text="$t('NOTIFICATIONS_PAGE.MARK_ALL_DONE')"
      :loading="isUpdating"
      @click="onMarkAllDoneClick"
    >
    </woot-submit-button>

      <woot-submit-button
              v-if="notificationMetadata.unreadCount"
              :disabled="selectedNotificationItems.length === 0"
              :class="`button nice success selected-done-button`"
              button-text="Mark Selected Done"
              :loading="isUpdatingSelected"
              @click="onMarkSelectedDoneClick"
      >
      </woot-submit-button>

    <table class="woot-table notifications-table">
      <tbody v-show="!isLoading">
        <tr
          v-for="notificationItem in notifications"
          :key="notificationItem.id"
          @click="() => onClickNotification(notificationItem)"
        >
            <td>
                <div class="checkbox-wrapper"
                     v-if="!notificationItem.read_at"
                >
                    <Checkbox  :checked="!!isNotificationSelected(notificationItem)"
                               :onClick="(e) => {selectNotificationItem(notificationItem);}"
                               width="18px"
                               height="18px"

                    />
                </div>
            </td>
          <td>
            <div class="">
              <h5 class="notification--title">
                {{
                  `${
                    notificationItem.inbox_name
                  }`
                }}
              </h5>
              <span class="notification--message-title">
                {{ notificationItem.push_message_title }}
              </span>
            </div>
          </td>
          <td class="text-right">
            <span class="notification--type">
              {{
                $t(
                  `NOTIFICATIONS_PAGE.TYPE_LABEL.${notificationItem.notification_type}`
                )
              }}
            </span>
          </td>
          <td class="thumbnail--column">
            <thumbnail
              v-if="!!notificationItem.primary_actor && notificationItem.primary_actor.meta.assignee"
              :src="notificationItem.primary_actor.meta.assignee.thumbnail"
              size="36px"
              :username="notificationItem.primary_actor.meta.assignee.name"
            />
          </td>
          <td>
            <div class="text-right timestamp--column">
              <span class="notification--created-at">
                {{ dynamicTime(notificationItem.created_at) }}
              </span>
            </div>
          </td>
          <td>
            <div
              v-if="!notificationItem.read_at"
              class="notification--unread-indicator"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <empty-state
      v-if="showEmptyResult"
      :title="$t('NOTIFICATIONS_PAGE.LIST.404')"
    />
    <div v-if="isLoading" class="notifications--loader">
      <spinner />
      <span>{{ $t('NOTIFICATIONS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from '../../../../mixins/time';
import { mapGetters } from 'vuex';
import Checkbox from "../../../../components/ui/Checkbox";

export default {
  components: {
    Thumbnail,
    Spinner,
    EmptyState,
      Checkbox
  },
  mixins: [timeMixin],
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    onClickNotification: {
      type: Function,
      default: () => {},
    },
    onMarkAllDoneClick: {
      type: Function,
      default: () => {},
    },
      unreadCount: {
        type: Number
      }
  },
    data: function () {
      return {
          selectedNotificationItems: [],
          isUpdatingSelected: false
      }
    },
  computed: {
    ...mapGetters({
      notificationMetadata: 'notifications/getMeta',
    }),
    showEmptyResult() {
      return !this.isLoading && this.notifications.length === 0;
    },
  },
    methods: {
      selectNotificationItem(notificationItem) {
          const item = this.isNotificationSelected(notificationItem);

          if (item) {
              const index = this.selectedNotificationItems.indexOf(item);

              this.selectedNotificationItems.splice(index, 1);
          } else {
              this.selectedNotificationItems.push(notificationItem);
          }
      },

      isNotificationSelected (notificationItem) {
          return this.selectedNotificationItems.find((item) => notificationItem.id === item.id);
      },
        async onMarkSelectedDoneClick() {
          this.isUpdatingSelected = true;

          const primaryActorIds = this.selectedNotificationItems.map((item) => {return item.primary_actor_id});
          const selectedNotificationIds = this.selectedNotificationItems.map((item) => {return item.id});

          await this.$store.dispatch('notifications/readSelected', {
              primaryActorIds: primaryActorIds,
              unreadCount: this.unreadCount,
              selectedNotificationIds: selectedNotificationIds
          });

          this.isUpdatingSelected = false;

          this.selectedNotificationItems = [];

        }
    }
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.notification--title {
  font-size: var(--font-size-small);
  margin: 0;
}

.notification--table-wrap {
  @include scroll-on-hover;
  flex: 1 1;
  height: 100%;
  padding: var(--space-large) var(--space-larger);
}

.notifications-table {
  > tbody {
    > tr {
      cursor: pointer;

      &:hover {
        background: var(--b-50);
      }

      &.is-active {
        background: var(--b-100);
      }

      > td {
        &.conversation-count-item {
          padding-left: var(--space-medium);
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.notifications--loader {
  font-size: var(--font-size-default);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-big);
}

.notification--unread-indicator {
  width: var(--space-one);
  height: var(--space-one);
  border-radius: 50%;
  background: var(--color-woot);
}

.notification--created-at {
  color: var(--s-700);
  font-size: var(--font-size-mini);
}

.notification--type {
  font-size: var(--font-size-mini);
}

.thumbnail--column {
  width: 5.2rem;
}

.timestamp--column {
  min-width: 13rem;
  text-align: right;
}

.notification--message-title {
  color: var(--s-700);
}

.checkbox-wrapper {
    display: flex;

    justify-content: center;
    align-items: center;
    align-content: center;
}

.selected-done-button {
    position: fixed;
    right: 140px;

    top: 0.8rem;
}
</style>
