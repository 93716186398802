import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import InboxesAPI from '../../api/inboxes';
import WebChannel from '../../api/channel/webChannel';
import FBChannel from '../../api/channel/fbChannel';
// import TwilioChannel from '../../api/channel/twilioChannel';
import SignalwireChannel from '../../api/channel/signalwireChannel';

const buildInboxData = inboxParams => {
  const formData = new FormData();
  const { channel = {}, ...inboxProperties } = inboxParams;
  Object.keys(inboxProperties).forEach(key => {
    formData.append(key, inboxProperties[key]);
  });
  const { selectedFeatureFlags = [], ...channelParams } = channel;
  if (selectedFeatureFlags.length) {
    selectedFeatureFlags.forEach(featureFlag => {
      formData.append(`channel[selected_feature_flags][]`, featureFlag);
    });
  } else {
    formData.append('channel[selected_feature_flags][]', '');
  }
  Object.keys(channelParams).forEach(key => {
    formData.append(`channel[${key}]`, channel[key]);
  });
  return formData;
};

export const state = {
  records: [],
  queues: {},
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isUpdatingAutoAssignment: false,
    isDeleting: false,
    isUpdatingIMAP: false,
    isUpdatingSMTP: false,
  },
  channel_info: [],
  user_credentials: [],
  inboxSignature: [],
};

export const getters = {
  getQueues($state) {
    return $state.queues;
  },
  getInboxes($state) {
    return $state.records;
  },
  getNewConversationInboxes($state) {
    return $state.records.filter(inbox => {
      const {
        channel_type: channelType,
        phone_number: phoneNumber = '',
      } = inbox;

      const isEmailChannel = channelType === INBOX_TYPES.EMAIL;
      const isSmsChannel =
        channelType === INBOX_TYPES.TWILIO &&
        phoneNumber.startsWith('whatsapp');
      return isEmailChannel || isSmsChannel;
    });
  },
  getInbox: $state => inboxId => {
    const [inbox] = $state.records.filter(
      record => record.id === Number(inboxId)
    );
    return inbox || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getWebsiteInboxes($state) {
    return $state.records.filter(item => item.channel_type === INBOX_TYPES.WEB);
  },
  getEmailInboxes($state) {
    return $state.records.filter(item => item.channel_type === INBOX_TYPES.EMAIL);
  },
  getTwilioInboxes($state) {
    return $state.records.filter(
      item => item.channel_type === INBOX_TYPES.TWILIO
    );
  },
  getSignalWireSMSInboxes($state) {
    return $state.records.filter(
      item => item.channel_type === INBOX_TYPES.SIGNALWIRE
    );
  },
  getChannelInfo($state) {
    return $state.channel_info;
  },
  getUserInfo($state) {
    return $state.user_credentials;
  },
  getSignature($state) {
    return $state.inboxSignature.sort((a, b) => b.id - a.id);
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxesAPI.get();
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_INBOXES, response.data.payload);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
    }
  },
  createChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  createWebsiteChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(buildInboxData(params));
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  // createTwilioChannel: async ({ commit }, params) => {
  //   try {
  //     commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
  //     const response = await TwilioChannel.create(params);
  //     commit(types.default.ADD_INBOXES, response.data);
  //     commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
  //     return response.data;
  //   } catch (error) {
  //     commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
  //     throw new Error(error);
  //   }
  // },
  createSignalwireChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await SignalwireChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createFBChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await FBChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },


  updateInbox: async ({ commit }, { id, formData = true, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, {
      isUpdatingAutoAssignment: true,
    });
    try {
      const response = await InboxesAPI.update(
        id,
        formData ? buildInboxData(inboxParams) : inboxParams
      );
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
      throw new Error(error);
    }
  },

  updateInboxIMAP: async ({ commit }, { id, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingIMAP: true });
    try {
      const response = await InboxesAPI.update(id, inboxParams);
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingIMAP: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingIMAP: false });
      throw new Error(error);
    }
  },
  updateInboxSMTP: async ({ commit }, { id, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingSMTP: true });
    try {
      const response = await InboxesAPI.update(id, inboxParams);
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingSMTP: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingSMTP: false });
      throwErrorMessage(error);
    }
  },
  delete: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: true });
    try {
      await InboxesAPI.delete(inboxId);
      commit(types.default.DELETE_INBOXES, inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
      throw new Error(error);
    }
  },
  reauthorizeFacebookPage: async ({ commit }, params) => {
    try {
      const response = await FBChannel.reauthorizeFacebookPage(params);
      commit(types.default.EDIT_INBOXES, response.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  deleteInboxAvatar: async (_, inboxId) => {
    try {
      await InboxesAPI.deleteInboxAvatar(inboxId);
    } catch (error) {
      throw new Error(error);
    }
  },
  getChannelLimits: async ({ commit }) => {
     try {
       const response = await InboxesAPI.get_channel_limit();
       commit(types.default.SET_CHANNEL_LIMITS, response.data);
     } catch (error) {
       console.log('error in channel info');
       throw new Error(error.message);
     }
  },
  getUserCredential: async({ commit }) => {
    try {
        var response = await InboxesAPI.get_user_credentials()
        commit(types.default.SET_USER_CREDENTIALS, response.data.credentials);
    } catch (error) {
        console.log("error in stripe connectivity", error)
    }
  },
  createReviewFBChannel: async ({ commit }, params) => {
    try {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
        const response = await FBChannel.createFacebookReview(params);
        commit(types.default.ADD_INBOXES, response.data);
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        return response.data;
    } catch (error) {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        throw new Error(error);
    }
  },
  fetchFBReviews: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await FBChannel.fetchFacebookReviews(params);
      commit(types.default.ADD_INBOXES, response);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error)
    }
  },
  createGoogleChannel: async ({ commit }, params) => {
    try {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
        const response = await InboxesAPI.createGoogleReview(params);
        commit(types.default.ADD_INBOXES, response.data);
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        return response.data;
    } catch (error) {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        throw new Error(error);
    }
  },
  async createSignature({ commit }, params) {
      const formData = new FormData();
      const { ...signatureProperties } = params;
      Object.keys(signatureProperties).forEach(key => {
          formData.append(key, signatureProperties[key]);
      });
    try {
        const response = await InboxesAPI.inboxSignature(params.id, formData);
        commit("appendSignature", response.data);
    } catch (e) {
        console.error(e);
    }
  },
  async toggleSignature({ commit }, params) {
    try {
        const response = await InboxesAPI.updateSignature(params.inboxId, params);
        commit("appendSignature", response.data);
    } catch (e) {
        console.error(e);
    }
  },
  createYoutubeChannel: async ({ commit }, params) => {
    try {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
        const response = await InboxesAPI.youtubeChannel(params);
        commit(types.default.ADD_INBOXES, response.data);
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        return response.data;
    } catch (error) {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        throw new Error(error);
    }
  },
  getGoogleChannel: async ({ commit }) => {
    try {
        const response = await InboxesAPI.fetchGoogleReviewAccount();
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
  },

  getYoutubeChannel: async ({ commit }) => {
    try {
        const response = await InboxesAPI.fetchGoogleYoutubeAccount();
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
  },

  createLeadChannel: async ({ commit }, params) => {
    try {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
        const response = await FBChannel.createFacebookLead(params);
        commit(types.default.ADD_INBOXES, response.data);
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        return response.data;
    } catch (error) {
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_INBOXES_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_QUEUES]($state, data) {
    $state.queues = data;
  },
  [types.default.SET_INBOXES]: MutationHelpers.set,
  [types.default.SET_INBOXES_ITEM]: MutationHelpers.setSingleRecord,
  [types.default.ADD_INBOXES]: MutationHelpers.create,
  [types.default.EDIT_INBOXES]: MutationHelpers.update,
  [types.default.DELETE_INBOXES]: MutationHelpers.destroy,
  [types.default.SET_CHANNEL_LIMITS]($state, channel_info) {
        $state.channel_info = channel_info;
  },
  [types.default.SET_USER_CREDENTIALS]($state, user_credentials) {
        $state.user_credentials = user_credentials;
  },
    appendSignature: (state, signature) => (state.inboxSignature = [...state.inboxSignature, signature])
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
