<template>
  <div class="settings columns">
    <setting-intro-banner
      :header-image="inbox.avatarUrl"
      :header-title="inboxName"
      :second-header="assignmentOrderStatus"
    >
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
    </setting-intro-banner>

    <div v-if="selectedTabKey === 'IVR'"
         class="ivr-settings-wrapper"
    >
      <settings-section
          title="IVR Settings"
          sub-title="Update Your IVR Settings"
      >
        <IvrForm :agents="inboxMembers"
                 :inbox="inbox"
        />
      </settings-section>

    </div>

    <div v-if="selectedTabKey === 'AI_IVR'"
         class="ivr-settings-wrapper"
    >
      <settings-section
          title="AI IVR Settings"
          sub-title="Update Your AI IVR Settings"
      >
        <AIIvrForm :agents="inboxMembers"
                 :inbox="inbox"
        />
      </settings-section>

    </div>

    <div v-if="selectedTabKey === 'NEW_AI_IVR'"
         class="ivr-settings-wrapper"
    >
      <settings-section
          title="AI IVR Settings"
          sub-title="Update Your AI IVR Settings"
      >
        <NewAIIvrForm :agents="inboxMembers"
                 :inbox="inbox"
        />
      </settings-section>

    </div>

    <!-- <div v-if="selectedTabKey === 'SMS_BOT'"
         class="ivr-settings-wrapper"
    >
      <settings-section
          title="Autoresponse Bot Settings"
          sub-title="Update Your Bot Settings"
      >
        <SmsBotSettings :inbox="inbox"/>
      </settings-section>
    </div> -->

    <div v-if="selectedTabKey === 'signature' && this.isAnEmailChannel" class="settings--content">
      <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_SIGNATURE_TITLE')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_SIGNATURE_SUB_TEXT')"
      >

        <div>
          <input
              id="signature-settings__is-enabled-checkbox"
              v-model="signatureEnable"
              class="sms-bot-settings__checkbox"
              type="checkbox"
              @input="handleIsEnabled"
          />

          <label for="signature-settings__is-enabled-checkbox">
            Enable Signature
          </label>
        </div>

        <label :class="{ error: $v.signatureAvatarUrl.$error }">
          {{ $t('INBOX_MGMT.ADD.SIGNATURE.AVATAR.LABEL') }}

            <woot-thumbnail v-if="signatureUrl" size="80px" :src="signatureUrl" />

          <input
              id="file"
              ref="file"
              type="file"
              :src="signatureAvatarUrl"
              accept="image/*"
              @change="setImage"
              @input="$v.signatureAvatarUrl.$touch"
          />
          <label :class="'avatar_size'">
            Avatar size in pixel:  124 x 140
          </label>
        </label>

        <woot-input
            v-model.trim="signatureName"
            class="medium-9 columns"
            :label="$t('INBOX_MGMT.ADD.SIGNATURE.NAME.LABEL')"
            :placeholder="$t('INBOX_MGMT.ADD.SIGNATURE.NAME.PLACEHOLDER')"
        />
        <woot-input
            v-if=""
            v-model.trim="signatureEmail"
            class="medium-9 columns"
            :label="$t('INBOX_MGMT.ADD.SIGNATURE.SIGNATURE_EMAIL.LABEL')"
            :placeholder="$t('INBOX_MGMT.ADD.SIGNATURE.SIGNATURE_EMAIL.PLACEHOLDER')"
        />
        <woot-input
            v-model.trim="signatureNumber"
            v-if=""
            class="medium-9 columns"
            :label="$t('INBOX_MGMT.ADD.SIGNATURE.NUMBER.LABEL')"
            :placeholder="$t('INBOX_MGMT.ADD.SIGNATURE.NUMBER.PLACEHOLDER')"
        />
        <woot-input
            v-if=""
            v-model.trim="companyUrl"
            class="medium-9 columns"
            :label="$t('INBOX_MGMT.ADD.SIGNATURE.URL.LABEL')"
            :placeholder="$t('INBOX_MGMT.ADD.SIGNATURE.URL.PLACEHOLDER')"
        />
        <woot-input
            v-if=""
            v-model.trim="companyName"
            class="medium-9 columns"
            :label="$t('INBOX_MGMT.ADD.SIGNATURE.COMPANY_NAME.LABEL')"
            :placeholder="$t('INBOX_MGMT.ADD.SIGNATURE.COMPANY_NAME.PLACEHOLDER')"
        />
        <woot-input
            v-if=""
            v-model.trim="location"
            class="medium-9 columns"
            :label="$t('INBOX_MGMT.ADD.SIGNATURE.LOCATION.LABEL')"
            :placeholder="$t('INBOX_MGMT.ADD.SIGNATURE.LOCATION.PLACEHOLDER')"
        />
        <woot-submit-button
            :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
            @click="addSignature"
        />
      </settings-section>

    </div>
    <div v-if="selectedTabKey === 'inbox_settings' && isAdmin" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
      >
        <woot-avatar-uploader
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
          :src="avatarUrl"
          delete-avatar
          @change="handleImageUpload"
          @onAvatarDelete="handleAvatarDelete"
        />
        <woot-input
          v-model.trim="selectedInboxName"
          class="medium-9 columns"
          :label="inboxNameLabel"
          :placeholder="inboxNamePlaceHolder"
        />
        <woot-input
          v-if="isAPIInbox"
          v-model.trim="webhookUrl"
          class="medium-9 columns"
          :class="{ error: $v.webhookUrl.$error }"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.LABEL')
          "
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.PLACEHOLDER')
          "
          :error="
            $v.webhookUrl.$error
              ? $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.ERROR')
              : ''
          "
          @blur="$v.webhookUrl.$touch"
        />
        <woot-input
            v-model.trim="selectedInboxEmail"
            v-if="isAnEmailChannel"
            class="medium-9 columns"
            :label="inboxEmailLabel"
            :placeholder="inboxEmailPlaceholder"
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWebsiteUrl"
          class="medium-9 columns"
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.PLACEHOLDER')
          "
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTitle"
          class="medium-9 columns"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER'
            )
          "
        />

        <!-- <label v-if="isAWebWidgetInbox">
              {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL') }}

              <textarea
                      v-if="isAWebWidgetInbox"
                      v-model.trim="channelWelcomeTagline"
                      class="medium-9 columns fixed-textarea"
                      :placeholder="
                      $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
                      )"
              />
        </label> -->

        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTagline"
          class="medium-9 columns settings-item"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
            )
          "
        />

         <woot-input
                v-if="isAWebWidgetInbox"
                v-model.trim="launcherTitle"
                class="medium-9 columns"
                label="Launcher Title"
                placeholder="eg: Click here to chat!"
        />

        <label v-if="isAWebWidgetInbox" class="medium-9 columns">
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}
          <woot-color-picker v-model="inbox.widget_color" />
        </label>

        <!-- <label class="medium-9 columns" v-if="!isSignalwireChannel">
          {{
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL')
          }}
          <select v-model="greetingEnabled">
            <option :value="true">
              {{
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED'
                )
              }}
            </option>
            <option :value="false">
              {{
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED'
                )
              }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT'
              )
            }}
          </p>
        </label>
        <greetings-editor
          v-if="greetingEnabled"
          v-model.trim="greetingMessage"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER'
            )
          "
          :richtext="!textAreaChannels"
        />
        <label class="medium-9 columns" v-if="!isSignalwireChannel">
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE') }}
          <select v-model="replyTime">
            <option key="in_a_few_minutes" value="in_a_few_minutes">
              {{
                $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES')
              }}
            </option>
            <option key="in_a_few_hours" value="in_a_few_hours">
              {{
                $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS')
              }}
            </option>
            <option key="in_a_day" value="in_a_day">
              {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY') }}
            </option>
          </select>

          <p class="help-text">
            {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT') }}
          </p>
        </label> -->

          <!-- <label class="medium-9 columns"
                 v-if="isSignalwireChannel || isAWebWidgetInbox || isAFacebookInbox || isATwitterInbox"
          >
              Set Unavailable time
              <input type="number" v-model="unavailableTime" min="1" max="60" onkeydown="return false"/>
              <p class="help-text">
                Time before Autoresponse bot sends the message that the agent is unavailable.
              </p>
          </label> -->

        <label class="medium-9 columns"
               v-if="isSignalwireChannel || isAWebWidgetInbox || isAFacebookInbox || isATwitterInbox"
        >
          Set Missed redirect time
          <input type="number" v-model="missedRedirectTime" min="1" max="60" onkeydown="return false"/>
          <p class="help-text">
            Time before bot sends a message when the conversation is assigned but the agent did not respond.
          </p>
        </label>

        <!-- <label v-if="isAWebWidgetInbox" class="medium-9 columns">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX') }}
          <select v-model="emailCollectEnabled">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT')
            }}
          </p>
        </label> -->

        <label class="medium-9 columns">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT') }}
          <select v-model="autoAssignment">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            Enable/disable the automatic assignment of new conversations to the agents added to this inbox.
          </p>
        </label>

        <label class="medium-9 columns">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT') }}
          <select v-model="csatSurveyEnabled">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            Enable/disable the customer satisfaction survey after resolving a conversation.
          </p>
        </label>

        <div v-if="channel_type=='Channel::Lead'" class="columns col-md-12">
          <label class="mb-3 mt-3">
            {{ $t('INBOX_MGMT.ADD.FB.CHAT_SERVICE') }}
          </label>
          <div class="widget--feature-flag ">
                      <span class="lead_chat_services">
                      <input
                        v-model="smsService"
                        type="checkbox"
                        :class="{ error: serviceRequired}"
                        value="sms_service"
                        @input="handleChatTypes"
                      />
                      <label for="sms_service" :class="{ 'error-text': serviceRequired }">
                        {{ $t('INBOX_MGMT.ADD.FB.SMS_SERVICE') }}
                      </label>
                        </span>
            <div v-if="smsService" class="medium-9 columns">
              <div class="row">
                <div class="medium-12 columns">
                  <label :class="{ error: smsInboxRequired}" >
                    <div>
                      <div class="input-group">
                        <select v-model="selectedSmsInbox" @change="handleSmsInboxChange" class="form-control custom-select">
                          <option selected value="" disabled>{{ $t('INBOX_MGMT.ADD.FB.OPTION_LABEL') }}</option>
                          <option v-for="option in availibleSmsInboxes" :key="option.id" :value="option">{{ option.name }}</option>
                        </select>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="widget--feature-flag">
                      <span  >
                      <input
                        v-model="emailService"
                        type="checkbox"
                        value="email_service"
                        onchange="handleChatTypes"
                      />
                      <label for="email_service" :class="{ 'error-text': serviceRequired }">
                        {{ $t('INBOX_MGMT.ADD.FB.EMAIL_SERVICE') }}
                      </label>
                        </span>

            <!--                      <div v-if="emailService" class="row mb-6">-->
            <!--                        <div class="columns col-md-12">-->
            <!--                          <label>Availible Email Inboxes</label>-->
            <!--                          <div>-->
            <!--                            <div class="input-group">-->
            <!--                              <select :options="inboxesList" v-model="selectedEmailInboxes" @change="handleSmsInboxChange" class="form-control custom-select">-->
            <!--                              </select>-->
            <!--                            </div>-->
            <!--                          </div>-->
            <!--                        </div>-->
            <!--                      </div>-->
          </div>
          <label class="medium-9 columns">
            {{ $t('INBOX_MGMT.ADD.FB.AUTOMATED_MESSAGE_LABEL') }}
            <textarea
              :placeholder= "$t('INBOX_MGMT.ADD.FB.AUTOMATED_MESSAGE_PLACEHOLDER')"
              v-model="automatedMessage"
              type="text" />
          </label>
        </div>

        <label v-if="isSignalwireChannel" class="medium-9 columns">
            Enable call queue
          <select v-model="isQueueEnabled">
            <option :value="true">
              Enabled
            </option>
            <option :value="false">
              Disabled
            </option>
          </select>
          <p class="help-text">
            Use queue to handle multiple callers at once and connect them to a live agent whenever it's their turn.
          </p>
          <label class="medium-9 columns" v-if="isQueueEnabled">
            Call queue size
              <input type="number" v-model="queueSize" min="1" max="100" onkeydown="return false"/>
          </label>
        </label>

        <!-- <label v-if="isSignalwireChannel" class="medium-9 columns">
            Enable call recording
          <select v-model="isRecordingEnabled">
            <option :value="true">
              Enabled
            </option>
            <option :value="false">
              Disabled
            </option>
          </select>
          <p class="help-text">
            "This call may be recorded for quality assurance and training purposes".
          </p>
        </label> -->

        <label v-if="isAWebWidgetInbox">
          {{ $t('INBOX_MGMT.FEATURES.LABEL') }}
        </label>
        <div v-if="isAWebWidgetInbox" class="widget--feature-flag">
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="attachments"
            @input="handleFeatureFlag"
          />
          <label for="attachments">
            {{ $t('INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER') }}
          </label>
        </div>

        <div v-if="isAWebWidgetInbox">
          <input
              v-model="selectedFeatureFlags"
              type="checkbox"
              value="emoji_picker"
              @input="handleFeatureFlag"
          />
          <label for="emoji_picker">
            {{ $t('INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER') }}
          </label>
        </div>

        <div v-if="isAWebWidgetInbox"
             @click="toggleIsVideoAvailable"
        >
          <input
              v-model="isVideoAvailable"
              type="checkbox"
              name="IsVideoAvailable"
          />
          <label for="IsVideoAvailable">
            Video available
          </label>
        </div>

        <woot-submit-button
          v-if="isAPIInbox"
          type="submit"
          :disabled="$v.webhookUrl.$invalid"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdatingInbox"
          @click="updateInbox"
        />
        <woot-submit-button
          v-else
          type="submit"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdatingInbox"
          @click="updateInbox"
        />
      </settings-section>
      <facebook-reauthorize v-if="isAFacebookInbox" :inbox-id="inbox.id" />
    </div>

    <!-- update agents in inbox -->
    <div v-if="selectedTabKey === 'collaborators' && isAdmin" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT')"
      >
        <multiselect
          v-model="selectedAgents"
          :options="agentList"
          track-by="id"
          label="name"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :hide-selected="true"
          :max="inbox.agent_limit"
          placeholder="Pick some"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          @select="$v.selectedAgents.$touch"
        />

        <woot-submit-button
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="isAgentListUpdating"
          @click="updateAgents"
        />
      </settings-section>
    </div>
    <div v-if="selectedTabKey === 'configuration'">
      <div v-if="isATwilioChannel" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE')"
          :sub-title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE')"
        >
          <woot-code
            :script="inbox.callback_webhook_url"
            lang="html"
          ></woot-code>
        </settings-section>
      </div>
      <div v-else-if="isALineChannel" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.TITLE')"
          :sub-title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE')"
        >
          <woot-code
            :script="inbox.callback_webhook_url"
            lang="html"
          ></woot-code>
        </settings-section>
      </div>
      <div v-else-if="isAWebWidgetInbox">
        <div class="settings--content">
          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING')"
            :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD')"
          >
            <woot-code :script="inbox.web_widget_script"></woot-code>
          </settings-section>

          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
            :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION')"
          >
            <woot-code :script="inbox.hmac_token"></woot-code>
          </settings-section>
          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION')"
            :sub-title="
              $t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION')
            "
          >
            <div class="enter-to-send--checkbox">
              <input
                id="hmacMandatory"
                v-model="hmacMandatory"
                type="checkbox"
                @change="handleHmacFlag"
              />
              <label for="hmacMandatory">
                {{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }}
              </label>
            </div>
          </settings-section>
        </div>
      </div>
      <div v-else-if="isAPIInbox" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT')"
        >
          <woot-code :script="inbox.inbox_identifier"></woot-code>
        </settings-section>
      </div>
      <div v-else-if="isAnEmailChannel">
        <div class="settings--content">
          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_TITLE')"
            :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_SUB_TEXT')"
          >
            <woot-code :script="inbox.forward_to_email"></woot-code>
          </settings-section>
          <imap-settings :inbox="inbox" />
          <smtp-settings :inbox="inbox" />
        </div>
      </div>
    </div>
    <div v-if="selectedTabKey === 'preChatForm'">
      <pre-chat-form-settings :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'businesshours'">
      <weekly-availability :inbox="inbox" />
    </div>
<!--    <div v-if="selectedTabKey === 'campaign'">-->
<!--      <campaign :selected-agents="selectedAgents" />-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createMessengerScript } from 'dashboard/helper/scriptGenerator';
import { required } from 'vuelidate/lib/validators';
import { shouldBeUrl } from 'shared/helpers/Validators';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner';
import SettingsSection from '../../../../components/SettingsSection';
import inboxMixin from 'shared/mixins/inboxMixin';
import FacebookReauthorize from './facebook/Reauthorize';
import PreChatFormSettings from './PreChatForm/Settings';
import WeeklyAvailability from './components/WeeklyAvailability';
import IvrForm from "./channels/signalwire/IvrForm/IvrForm";
import AIIvrForm from "./channels/signalwire/IvrForm/AIIvrForm";
import NewAIIvrForm from "./channels/signalwire/IvrForm/NewAIIvrForm";
import SmsBotSettings from "./channels/signalwire/SmsBotSettings/SmsBotSettings";
import imapSettings from './ImapSettings.vue';
import smtpSettings from './SmtpSettings.vue';
// import Campaign from './components/Campaign';
import signalwireChannelAPI from "../../../../api/channel/signalwireChannel";
import adminMixin from "../../../../mixins/isAdmin";
import {globalAIP} from "../../../../api/globalAIP";
import GreetingsEditor from 'shared/components/GreetingsEditor';
import {isEmpty} from "jssip/lib/Utils";

export default {
  components: {
    SmsBotSettings,
    SettingIntroBanner,
    SettingsSection,
    FacebookReauthorize,
    PreChatFormSettings,
    WeeklyAvailability,
    imapSettings,
    GreetingsEditor,
    smtpSettings,
    IvrForm,
    AIIvrForm,
    NewAIIvrForm,
    // Campaign,
  },
  mixins: [alertMixin, configMixin, inboxMixin, adminMixin],
  data() {
    return {
      isVideoAvailable: true,
      avatarFile: null,
      avatarUrl: '',
      selectedSmsInbox:'',
      automatedMessage:'',
      autoMessageRequired:false,
      selectedAgents: [],
      availibleSmsInboxes:[],
      serviceRequired:false,
      channel_type:'',
      inboxMembers: [],
      smsInboxRequired: true,
      greetingEnabled: true,
      hmacMandatory: null,
      greetingMessage: '',
      autoAssignment: false,
      isQueueEnabled: false,
      isRecordingEnabled: false,
      emailCollectEnabled: false,
      isAgentListUpdating: false,
      csatSurveyEnabled: false,
      selectedInboxName: '',
      smsService: false,
      emailService: false,
      selectedInboxEmail: '',
      channelWebsiteUrl: '',
      webhookUrl: '',
      channelWelcomeTitle: '',
      channelWelcomeTagline: '',
      launcherTitle: '',
      selectedFeatureFlags: [],
      replyTime: '',
      signatureUrl: '',
      autoAssignmentOptions: [
        {
          value: true,
          label: this.$t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.ENABLED'),
        },
        {
          value: false,
          label: this.$t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.DISABLED'),
        },
      ],
      isMounted: false,
      selectedTabIndex: 0,
      signatureAvatarUrl: '',
      signatureAvatarFile: null,
      signatureEmail: '',
      location: '',
      companyName: '',
      companyUrl: '',
      signatureNumber: '',
      signatureName: '',
      unavailableTime: 10,
      missedRedirectTime: 5,
      queueSize: 1,
      signatureEnable: false,
      fbStatus: '',
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'inboxes/getUIFlags',
      inboxesList: 'inboxes/getInboxes',
    }),
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    tabs() {
      const visibleToAllChannelTabs = [
        {
          key: 'inbox_settings',
          name: this.$t('INBOX_MGMT.TABS.SETTINGS'),
        },
        {
          key: 'collaborators',
          name: this.$t('INBOX_MGMT.TABS.COLLABORATORS'),
        },
        {
          key: 'businesshours',
          name: this.$t('INBOX_MGMT.TABS.BUSINESS_HOURS'),
        },
      ];

      if (this.isAnEmailChannel) {
        visibleToAllChannelTabs.push({
          key: 'signature',
          name: this.$t('INBOX_MGMT.TABS.SIGNATURE'),
        })
      }

      if (this.isAWebWidgetInbox) {
        return [
          ...visibleToAllChannelTabs,
          // {
          //   key: 'campaign',
          //   name: this.$t('INBOX_MGMT.TABS.CAMPAIGN'),
          // },
          {
            key: 'preChatForm',
            name: this.$t('INBOX_MGMT.TABS.PRE_CHAT_FORM'),
          },
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
          },
          // {
          //   key: 'SMS_BOT',
          //   name: 'Autoresponse Bot'
          // }
        ];
      }

      if (
          this.isATwilioChannel ||
          this.isALineChannel ||
          this.isAPIInbox ||
          this.isAnEmailChannel
      ) {
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
          },
        ];
      }

      if ((this.isSignalwireChannel) && this.isMounted && ((this.inbox.communication_type === "voice_new") || (this.inbox.communication_type === "voice_ported"))) {
        return [
            ...visibleToAllChannelTabs,
          {
            key: 'IVR',
            name: 'Interactive Voice Response'
          },
          // {
          //   key: 'AI_IVR',
          //   name: 'AI Interactive Voice Response'
          // },
          {
            key: 'NEW_AI_IVR',
            name: 'AI IVR Agent'
          },
          // ,
          // {
          //   key: 'SMS_BOT',
          //   name: 'Autoresponse Bot'
          // }
        ]
      }

      // if (this.isSignalwireChannel) {
      //   return [
      //     ...visibleToAllChannelTabs,
      //     {
      //       key: 'SMS_BOT',
      //       name: 'Autoresponse Bot'
      //     }
      //   ]
      // }

      // if ((this.isATwitterInbox) || (this.isAFacebookInbox)) {
      //   return [
      //     ...visibleToAllChannelTabs,
      //     {
      //       key: 'SMS_BOT',
      //       name: 'Autoresponse Bot'
      //     }
      //   ]
      // }

      // if ((this.isATelegramChannel) || (this.isALineChannel) || (this.isAWhatsappChannel)) {
      //   return [
      //     ...visibleToAllChannelTabs,
      //     {
      //       key: 'SMS_BOT',
      //       name: 'Autoresponse Bot'
      //     }
      //   ]
      // }

      return visibleToAllChannelTabs;
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    assignmentOrderStatus() {
      if (this.isSignalwireChannel) {
        return `${this.inbox.assignment_order_status}`;
      }
      return "";
    },
    inboxName() {
      if (this.isSignalwireChannel) {
        return `${this.inbox.name} (${this.inbox.phone_number})`;
      }
      if (this.isATwilioSMSChannel || this.isATwilioWhatsappChannel) {
        return `${this.inbox.name} (${this.inbox.phone_number})`;
      }
      if (this.isAnEmailChannel) {
        return `${this.inbox.name} (${this.inbox.email})`;
      }
      return this.inbox.name;
    },
    messengerScript() {
      return createMessengerScript(this.inbox.page_id);
    },
    inboxNameLabel() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL');
    },
    inboxEmailLabel() {
      if (this.isAnEmailChannel) {
        return this.$t('INBOX_MGMT.ADD.EMAIL_NAME.LABEL');
      }
    },
    inboxEmailPlaceholder() {
      if (this.isAnEmailChannel) {
        return this.$t('INBOX_MGMT.ADD.EMAIL_NAME.PLACEHOLDER');
      }
    },
    inboxNamePlaceHolder() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.PLACEHOLDER');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER');
    },
    textAreaChannels() {
      if (
        this.isATwilioChannel ||
        this.isATwitterInbox ||
        this.isAFacebookInbox
      )
        return true;
      return false;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
      }
    },
    smsService(newValue) {
      if (newValue === true || this.emailService === true) {
        this.serviceRequired = false;
      }
    },
    emailService(newValue) {
      if (newValue === true || this.smsService === true) {
        this.serviceRequired = false;
      }
    },
  },
  mounted() {
    this.fetchInboxSettings();
    this.isMounted = true;
  },
  created() {
    this.fetchSmsInboxes();
  },
  methods: {
    fetchSmsInboxes(){
      this.availibleSmsInboxes = this.inboxesList.filter(item => item.channel_type === 'Channel::SignalwireLink').map(({ name, id }) => ({ name, id }));
      return this.availibleSmsInboxes;
    },
    handleSmsInboxChange(){
      if (!this.selectedSmsInbox || Object.keys(this.selectedSmsInbox).length === 0) {
        this.smsInboxRequired = true;
      } else {
        this.smsInboxRequired = false;
      }
    },

    handleChatTypes(type) {
      if (type === 'sms_service') {
        this.smsService = !this.smsService;
      } else if (type === 'email_service') {
        this.emailService = !this.emailService;
      }
    },

    toggleIsVideoAvailable() {
      this.isVideoAvailable = !this.isVideoAvailable;
    },
    handleFeatureFlag(e) {
      this.selectedFeatureFlags = this.toggleInput(
        this.selectedFeatureFlags,
        e.target.value
      );
    },
    handleHmacFlag() {
      this.updateInbox();
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    fetchInboxSettings() {
      this.selectedTabIndex = 0;
      this.selectedAgents = [];
      this.fetchAgentsData();
      this.$store.dispatch('teams/get');
      this.$store.dispatch('labels/get');
      this.$store.dispatch('inboxes/get').then(() => {
        this.fetchAttachedAgents();
        this.avatarUrl = this.inbox.avatar_url;
        this.selectedInboxName = this.inbox.name;
        this.webhookUrl = this.inbox.webhook_url;
        this.greetingEnabled = this.inbox.greeting_enabled || false;
        this.hmacMandatory = this.inbox.hmac_mandatory || false;
        this.greetingMessage = this.inbox.greeting_message || '';
        this.selectedInboxEmail = this.inbox.email;
        this.greetingEnabled = this.inbox.greeting_enabled;
        this.greetingMessage = this.inbox.greeting_message;
        this.autoAssignment = this.inbox.enable_auto_assignment;
        this.isQueueEnabled = this.inbox.is_queue_enabled  ? this.inbox.is_queue_enabled : false;
        this.isRecordingEnabled = this.inbox.is_recording_enabled ? this.inbox.is_recording_enabled : false;
        this.queueSize = this.inbox.queue_size ? this.inbox.queue_size : 1;
        this.emailCollectEnabled = this.inbox.enable_email_collect;
        this.emailCollectEnabled = this.inbox.enable_email_collect;
        this.csatSurveyEnabled = this.inbox.csat_survey_enabled;
        this.channelWebsiteUrl = this.inbox.website_url;
        this.channelWelcomeTitle = this.inbox.welcome_title;
        this.channelWelcomeTagline = this.inbox.welcome_tagline;
        this.selectedFeatureFlags = this.inbox.selected_feature_flags || [];
        this.replyTime = this.inbox.reply_time;
        this.isVideoAvailable = this.inbox.is_video_available;
        this.unavailableTime = this.inbox.unavailable_time ? this.inbox.unavailable_time : 10
        this.missedRedirectTime = this.inbox.missed_redirect_time ? this.inbox.missed_redirect_time : 5
        this.launcherTitle = this.inbox.launcher_title;
        this.channel_type = this.inbox.channel_type;
        if(this.channel_type=='Channel::Lead')
        {
          this.automatedMessage=this.inbox.automated_reply;
          if (this.inbox.sms_service == true) {
            this.smsService=true;
            let inbox = this.$store.getters['inboxes/getInbox'](this.inbox.signalwire_link_inbox);
            this.selectedSmsInbox = {
              id: inbox.id,
              name: inbox.name,
            };
            this.autoMessageRequired=false;
            this.smsInboxRequired=false;
          }
          if (this.inbox.email_service == true) {
            this.emailService=true;
          }
        }
        if(this.inbox.missed_sms_text){
          this.$store.commit('missedCall/setMissedCallSMSText', this.inbox.missed_sms_text);
        }
        else{
          this.$store.commit('missedCall/setMissedCallSMSText', "Unfortunately, we were not able to answer your call. You can start a conversation now by responding to this text message and one of our agents will respond as soon as possible.");
        }
        this.$store.commit('missedCall/setMissedCallSMSEnabled', this.inbox.missed_sms_enabled);
        this.$store.commit('missedCall/setChannelId', this.inbox.channel_id);
        if (this.inbox.hasOwnProperty('inbox_signature')) {
          try {
            this.companyUrl =  this.inbox.inbox_signature.company_url;
            this.signatureName =  this.inbox.inbox_signature?.designation;
            this.signatureEmail =  this.inbox.inbox_signature.email;
            this.location =  this.inbox.inbox_signature.location;
            this.companyName =  this.inbox.inbox_signature.name;
            this.signatureNumber =  this.inbox.inbox_signature.number;
            this.signatureEnable =  this.inbox.inbox_signature.is_signature_enable;
            this.signatureUrl = this.inbox.signature_avatar_url;
          }
          catch(error){}
          }
      });
    },
    async fetchAgentsData(){
      await this.$store.dispatch('agents/get');
      await this.$store.dispatch('agents/getAvailability');
    },
    async fetchAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.currentInboxId,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.selectedAgents = inboxMembers;
        this.inboxMembers = JSON.parse(JSON.stringify(inboxMembers));
      } catch (error) {
        //  Handle error
      }
    },
    async updateAgents() {
      const agentList = this.selectedAgents.map(el => el.id);
      this.isAgentListUpdating = true;
      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.currentInboxId,
          agentList,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));

        try {
          const response = await this.$store.dispatch('inboxMembers/get', {
            inboxId: this.currentInboxId,
          });
          const {data: { payload: inboxMembers }} = response;
          //this.selectedAgents = inboxMembers;
          this.inboxMembers = inboxMembers;
        } catch (error) {
          //  Handle error
        }
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
      this.isAgentListUpdating = false;

        if ((this.isSignalwireChannel) && ((this.inbox.communication_type === "voice_new") || (this.inbox.communication_type === "voice_ported"))) {
            await this.updateIVR();
        }

      window.location.reload();
    },
    async updateInbox() {
      if(this.channel_type== 'Channel::Lead' && !this.smsService && !this.emailService)
      {
        this.serviceRequired=true;
        return;
      }

      if (this.channel_type== 'Channel::Lead' && this.smsService == true) {
        if (!this.selectedSmsInbox || Object.keys(this.selectedSmsInbox).length === 0) {
          this.smsInboxRequired = true;
          return;
        } else {
          this.smsInboxRequired = false;
        }
      }
       else if(this.smsService=false){
        this.selectedSmsInbox=null;
      }
      try {
        const payload = {
          sms_service: this.smsService,
          email_service: this.emailService,
          id: this.currentInboxId,
          name: this.selectedInboxName,
          email: this.selectedInboxEmail || '',
          enable_auto_assignment: this.autoAssignment,
          enable_email_collect: this.emailCollectEnabled,
          csat_survey_enabled: this.csatSurveyEnabled,
          greeting_enabled: this.greetingEnabled,
          greeting_message: this.greetingMessage || '',
          automated_reply:this.automatedMessage,
          channel: {
            widget_color: this.inbox.widget_color,
            website_url: this.channelWebsiteUrl,
            webhook_url: this.webhookUrl,
            welcome_title: this.channelWelcomeTitle || '',
            welcome_tagline: this.channelWelcomeTagline || '',
            selectedFeatureFlags: this.selectedFeatureFlags,
            reply_time: this.replyTime || 'in_a_few_minutes',
              launcher_title: this.launcherTitle,
            hmac_mandatory: this.hmacMandatory,
          },
        };

        if(this.smsService)
        {
          payload.signalwire_link_inbox= this.selectedSmsInbox.id;
        }

        if (this.isAWebWidgetInbox) {
            payload.channel.is_video_available = this.isVideoAvailable;
            payload.channel.unavailable_time = this.unavailableTime;
            payload.channel.missed_redirect_time = this.missedRedirectTime;
        }

        if (this.isSignalwireChannel) {
            payload.channel.unavailable_time = this.unavailableTime;
            payload.channel.missed_redirect_time = this.missedRedirectTime;
            payload.channel.is_queue_enabled = this.isQueueEnabled;
            payload.channel.is_recording_enabled = this.isRecordingEnabled;
            payload.channel.queue_size = this.queueSize;
        }

        if (this.isAFacebookInbox) {
          payload.channel.unavailable_time = this.unavailableTime;
          payload.channel.missed_redirect_time = this.missedRedirectTime;
        }

        if (this.isATwitterInbox) {
          payload.channel.unavailable_time = this.unavailableTime;
          payload.channel.missed_redirect_time = this.missedRedirectTime;
        }

        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      }
    },
    async updateIVR() {
          if (this.inbox.ivr_tree) {
              const intro =  this.inbox.ivr_tree.intro ? this.inbox.ivr_tree.intro : "";
              const if_unavailable =  this.inbox.ivr_tree.if_unavailable ? this.inbox.ivr_tree.if_unavailable : "";
              const agents_array = this.selectedAgents.map(el => el.id)
              const ivr_extensions = this.inbox.ivr_tree.ivr_extensions.filter(obj => agents_array.includes(obj.agent));
              const ivr_extensions_enabled = this.inbox.ivr_tree.ivr_extensions_enabled ? this.inbox.ivr_tree.ivr_extensions_enabled : false;

              const menuItems = [
                  {
                      response: "",
                      agents: [],
                      symbol: "0",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "1",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "2",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "3",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "4",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "5",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "6",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "7",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "8",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "9",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "*",
                      enabled: false
                  },
                  {
                      response: "",
                      agents: [],
                      symbol: "#",
                      enabled: false
                  }
              ];

              for (const menuItem of menuItems) {
                  if (this.inbox.ivr_tree[`${menuItem.symbol}`]) {
                      menuItem.enabled = true;
                      menuItem.response = this.inbox.ivr_tree[`${menuItem.symbol}`].response;

                      if (this.inbox.ivr_tree[`${menuItem.symbol}`].agents.length !== 0) {
                          menuItem.agents = this.inboxMembers.filter(agent => this.inbox.ivr_tree[`${menuItem.symbol}`].agents.includes(agent.id))
                      }
                  }
              }

              let ivr_tree = {};

              if (intro.length > 0) {
                  ivr_tree.intro = intro;
              }
              ivr_tree.ivr_extensions_enabled = ivr_extensions_enabled;
              ivr_tree.ivr_extensions = ivr_extensions;
              if (if_unavailable.length > 0) {
                  ivr_tree.if_unavailable = if_unavailable;
              }

              for (const item of menuItems) {
                  if (item.enabled) {
                      ivr_tree[`${item.symbol}`] = {
                          response: item.response,
                          agents: item.agents.length === 0 ? [] : item.agents.map(agent => agent.id)
                      }
                  }
              }

              const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;

              const response = await signalwireChannelAPI.UpdateIVRSettings({
                  channel_id: channel_id,
                  ivr_tree: ivr_tree
              });

              await this.$store.dispatch("inboxes/get");
          }
      },
    async updateInboxWithReload() {
        await this.updateInbox();
        window.location.reload();
      },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch(
          'inboxes/deleteInboxAvatar',
          this.currentInboxId
        );
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE')
        );
      }
    },
    setImage(event) {
      const [file] = event.target.files;

      this.$emit('change', {
        file,
        url: URL.createObjectURL(file),
      });
      this.signatureUrl = URL.createObjectURL(file)
      this.signatureAvatarUrl = file;
    },
    async addSignature() {
      try {
      const params = {
        email: this.signatureEmail,
        location: this.location,
        name: this.companyName,
        company_url: this.companyUrl,
        number: this.signatureNumber,
        designation: this.signatureName,
        id: this.currentInboxId,
        is_signature_enable: this.signatureEnable
      }
        if(!isEmpty(this.signatureAvatarUrl))
          params.signature_avatar = this.signatureAvatarUrl
        await this.$store.dispatch('inboxes/createSignature', params);
        this.showAlert(this.$t('INBOX_MGMT.ADD.SIGNATURE.MESSAGES.ADD.SUCCESS_MESSAGE'));
      } catch (error) {
          this.showAlert(this.$t('INBOX_MGMT.ADD.SIGNATURE.MESSAGES.ADD.ERROR_MESSAGE'));
       }
    },
    async handleIsEnabled(e) {
      this.signatureEnable = e.target.checked;
      const params = { inboxId: this.currentInboxId, is_signature_enable: this.signatureEnable}
      await this.$store.dispatch('inboxes/toggleSignature', params);
    },
    changeStatus(status) {
      console.log('Status:: ', status)
      this.fbStatus = status;
    },
  },
  validations: {
    webhookUrl: {
      required,
      shouldBeUrl,
    },
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
    signatureAvatarUrl: {
      required
    }
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.settings {
  background: $color-white;

  .settings--content {
    div:last-child {
      border-bottom: 0;
    }
  }

  .tabs {
    padding: 0;
    margin-bottom: -1px;
  }
}

.widget--feature-flag {
  padding-top: var(--space-small);
}

.ivr-settings-wrapper {
  margin: 0.8rem 3.2rem;
}

.fixed-textarea {
  width: 100%;
  height: 66px;

  resize: none;
  padding: 8px;
}
.sms-bot-settings__checkbox {
  cursor: pointer;
}
.avatar_size {
  margin-top: -15px;
  margin-bottom: 5px;
}

.lead_chat_services {
  display: flex;
}

.lead_chat_services input {
  margin-top: 4px;

}

.lead_chat_services label {
  word-wrap: break-word;
  flex-grow: 1;
}

.error-text {
  color: red;
}
</style>
