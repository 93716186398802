export const state = {
    isMissedCallMenuOpen: false,
    missedCallSMSText: "",
    missedCallSMSEnabled: false,
    channelId: 1,
};

export const getters = {
    getMissedCallMenuOpen($state) {
        return $state.isMissedCallMenuOpen;
    },
    getMissedCallSMSText($state) {
        return $state.missedCallSMSText;
    },
    getMissedCallSMSEnabled($state) {
        return $state.missedCallSMSEnabled;
    },
    getChannelId($state) {
        return $state.channelId;
    },
};
export const mutations = {
    setMissedCallMenuOpen($state, value) {
        $state.isMissedCallMenuOpen = value;
    },
    setMissedCallSMSText($state, value) {
      if(value == null || value == "" || value == undefined){
        $state.missedCallSMSText = "Unfortunately, we were not able to answer your call. You can start a conversation now by responding to this text message and one of our agents will respond as soon as possible.";
      }
      else $state.missedCallSMSText = value;
    },
    setMissedCallSMSEnabled($state, value) {
        $state.missedCallSMSEnabled = value;
    },
    setChannelId($state, value) {
        $state.channelId = value;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
