export const getters = {
  getContacts($state) {
    return $state.sortOrder.map(contactId => $state.records[contactId]);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getContact: $state => id => {
    const contact = $state.records[id];
    return contact || {};
  },
  getContactByUsername: $state => sip_username => {
    var contact = {};
    for(var ids in $state.records){
      const record = $state.records[ids];
      if (JSON.stringify(record.sip_username).slice(1, -1) == sip_username) {
          contact = record;
      }
    }
    return contact || {};
  },
  getMeta: $state => {
    return $state.meta;
  },
  getScheduleHistory: $state => {
    return $state.eventList;
  }
};
