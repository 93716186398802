<template>
    <woot-modal :show.sync="show" :closeOnBackdropClick="false">
        <div class="column content-box">
            <woot-modal-header
                    :header-title="'Signaturies Information'"
                    :header-content="'Fill the following form before sign the document'"
            />
            <form class="row" @submit.prevent="submitForm">
                <woot-input
                        v-model.trim="firstName"
                        :class="{ error: $v.firstName.$error }"
                        class="medium-12 columns"
                        :label="'First Name'"
                        :placeholder="'Enter your first name'"
                        @input="$v.firstName.$touch"
                />
                <woot-input
                        v-model.trim="lastName"
                        :class="{ error: $v.lastName.$error }"
                        class="medium-12 columns"
                        :label="'Last Name'"
                        :placeholder="'Enter your last name'"
                        @input="$v.lastName.$touch"
                />
                <woot-input
                        v-model.trim="email"
                        type="email"
                        :class="{ error: $v.email.$error }"
                        class="medium-12 columns"
                        :label="'Email'"
                        :placeholder="'Enter your email address'"
                        @input="$v.email.$touch"
                />
                <div class="modal-footer">
                    <div class="medium-12 columns">
                        <woot-submit-button  :is-disabled="buttonDisabled" :button-text="'Submit'" />
                        <button class="button clear" @click.prevent="onClose" disabled>
                            {{ $t('CODE_MGMT.FORM.CANCEL') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </woot-modal>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import {mapGetters} from "vuex";

export default {
    name: "SyncContact",
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
        };
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        accountId: {
            type: Number,
        },
        documentId: {
            type: Number,
            required: true,
        },
    },
    validations: {
        email: { required, email },
        firstName: { required },
        lastName: { required },
    },
    methods: {
        submitForm() {
            if (this.$v.$invalid) {
                return;
            }
            try {
                const encodedEmail = encodeURIComponent(this.email);
                this.signDocsLink = `${window.chatwootConfig.signDocsUrl}/#/?parent=contact&firstName=${this.firstName}&lastName=${this.lastName}&reference_id=${this.accountId}&email=${encodedEmail}&document_id=${this.documentId}`;
                window.location.href = this.signDocsLink;
                // Perform form submission logic here

                // You can send the form data to an API or perform any other actions
            } catch (error) {
                console.log(error);
                // Ignore error
            }
        },
        onClose() {
            // Reset the form fields
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.show = false;
        },
        buttonDisabled() {
            return (
                this.$v.firstName.$invalid ||
                this.$v.lastName.$invalid ||
                this.$v.email.$invalid
            );
        },
    },
};
</script>
