<template>
    <div class="vault-box">
        <folder-view v-if="selectedFolderId"
        :folder-id="selectedFolderId"
        :folders="folders"
        :folder-shared="selectedFolderShared"
        :on-close="emptySelectedFolder"
        />
        <signature-list-view v-else-if="showSignatureView"
           :on-close="signatureView"
           :user="currentUser"
        />
        <div v-else class="vault-container">
            <div class="display-flex padding_bottom">
                <div class="display-flex icon-position">
                    <span title="upload files"  class="cursor_pointer" @click="showFileModel"> <i class="ion-android-add-circle"></i> </span>
                    <span title="create folder" class="cursor_pointer ml_1" @click="showFolderModal" v-if="!selectedFolderId" > <i class="ion-android-folder"></i> </span>
                </div>
                <div class="columns medium-3 medium-offset-3 search-wrap">
                    <i class="ion-ios-search-strong search-icon" />
                    <input
                            type="text"
                            :placeholder="$t('FOLDER_MGMT.FILE_SEARCH.PLACEHOLDER')"
                            class="contact-search"
                            :value="searchQueryRecord"
                            @keyup.enter="onRecordSearchSubmit"
                            @input="onRecordInputSearch"
                    />
                    <woot-button
                            class="search_btn"
                            :is-loading="false"
                            @click="onRecordSearchSubmit"
                    >
                        {{ $t('FOLDER_MGMT.FILE_SEARCH.TITLE') }}
                    </woot-button>
                </div>
                <div class="columns medium-1 medium-offset-3">
                    <p class="btn_signature" @click="signatureView" title="create signatures">
                        {{ $t('FOLDER_MGMT.SIGNATURE.BTN_TEXT') }}
                    </p>
                </div>
                <div class="columns medium-1" v-if="isAdmin">
                  <p class="btn_plus" @click="showLogsPopup" title="check vault logs">
                      {{ $t('FOLDER_MGMT.LOGS.BTN_TEXT') }}
                  </p>
                </div>
            </div>
            <table class="table-fixed file-vault-table" v-if="folders.length || folderFiles.length">
                <thead>
                  <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Access</th>
                      <th>Note</th>
                      <th>Size</th>
                      <th>Date</th>
                      <th>Shared Person</th>
                      <th>Action</th>
                  </tr>
                </thead>
                <tbody style="background-color: white;">
                <tr v-for="(folder, index) in folders" :key="folder.id">
                    <td width="5%"><i @click="targetFolderFiles(folder)" class="cursor_pointer folder_icon_size ion ion-android-folder-open"></i></td>
                    <td width="13%" class="cursor_pointer" @click="targetFolderFiles(folder)">{{ folder.name }}</td>
                    <td width="3%" class="cursor_pointer" @click="targetFolderFiles(folder)"> {{ folder.folder_type }} </td>
                    <td width="4%">{{ folder.is_shared ? 'shared' : folder.access_type }} </td>
                    <td width="15%">{{ folder.notes }}</td>
                    <td width="10%">{{ formatSize(folder.attachment_sum) }} </td>
                    <td width="15%">{{ formatDate(folder.updated_at) }} </td>
                    <td width="8%"> {{ folder.share_with }}</td>
                    <td width="17%">
                        <div class="display-flex">
                            <woot-button
                                    variant="link"
                                    color-scheme="secondary"
                                    icon="ion-android-folder-open"
                                    class-names="download grey-btn"
                                    @click="targetFolderFiles(folder)"
                                    title="open folder"
                            >
                            </woot-button>
                            <woot-button
                                    variant="link"
                                    color-scheme="secondary"
                                    icon="ion-edit"
                                    class-names="download grey-btn"
                                    @click="openEditPopup(folder, index)"
                                    title="edit"
                                    v-if="!folder.is_shared"
                            >
                            </woot-button>
                            <woot-button
                                    variant="link"
                                    color-scheme="secondary"
                                    icon="ion-android-share-alt"
                                    class-names="download grey-btn cursor-pointer"
                                    @click="showAgentsPopup(folder, 'folder', 'folder')"
                                    title="share"
                                    v-if="!folder.is_shared"
                            >
                            </woot-button>
                            <woot-button
                                    variant="link"
                                    color-scheme="secondary"
                                    icon="ion-close-circled"
                                    class-names="download grey-btn"
                                    @click="openDeletePopup(folder, index)"
                                    title="delete"
                            >
                            </woot-button>
                        </div>
                    </td>
                </tr>
                <tr v-for="(file, index) in folderFiles" :key="file.id">
                    <td width="5%" @click="preview(filePath(file))" >
                        <img :src=filePath(file)  v-if="file.content_type === 'image/png' || file.content_type === 'image/jpg' || file.content_type === 'image/jpeg'" class="first-column"/>
                        <i class="ion-android-document font-3" v-else ></i>
                    </td>
                    <td width="13%">{{ file.name }}</td>
                    <td width="3%" >{{ file.folder_type }}</td>
                    <td width="4%">{{ file.is_shared ? 'shared' : file.access_type }}</td>
                    <td width="15%">{{ file.content_type }}</td>
                    <td width="10%">{{ formatSize(file.size) }}</td>
                    <td width="15%">{{ formatDate(file.updated_at) }}</td>
                    <td width="8%">{{ file.share_with }}</td>
                    <td width="17%">
                        <div>
                            <i title="preview" @click="preview(filePath(file))" class="cursor-pointer preview icon ion-eye"></i>
                            <i title="download" @click="download(filePath(file),  file.name)" class="cursor-pointer download icon ion-android-download"></i>
                            <i v-if="!file.is_shared" title="share" @click="showAgentsPopup(file, 'file', 'baseFolderFile')" class="cursor-pointer ion-android-share-alt download"></i>
                            <i title="delete" @click="openFileDeletePopup(file, index)" class="cursor-pointer remove icon ion-android-delete"></i>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <add-folder v-if="showModelFolder"
                        @close-folder-model="closeFolderModel"
                        :show-folder-modal="showModelFolder"
            />
            <upload-file
                    :on-close="closeFileModel"
                    v-if="showModelFile"
            />

            <woot-delete-modal
                    :show.sync="showDeletePopup"
                    :on-close="closeDeletePopup"
                    :on-confirm="deleteFolder"
                    :title="$t('FOLDER_MGMT.DELETE.CONFIRM.TITLE')"
                    :message="deleteMessage"
                    :confirm-text="deleteConfirmText"
                    :reject-text="deleteRejectText"
            />

            <woot-delete-modal
                    :show.sync="showFileDeletePopup"
                    :on-close="closeFileDeletePopup"
                    :on-confirm="deleteFile"
                    :title="$t('FOLDER_MGMT.FILE_DELETE.CONFIRM.TITLE')"
                    :message="deleteMessage"
                    :confirm-text="deleteConfirmText"
                    :reject-text="deleteRejectText"
            />
            <!-- Edit Folder -->
            <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
                <edit-folder
                        v-if="showEditPopup"
                        :id="currentFolder.id"
                        :name="currentFolder.name"
                        :access_type="currentFolder.access_type"
                        :notes="currentFolder.notes"
                        :on-close="hideEditPopup"
                />
            </woot-modal>

            <share-files  v-if="showAgentModel"
                          :vault-type="vaultType"
                          :on-close="closeAgentsPopup"
                          :vault-id="vaultId"
                          :file-type="fileType"
                          :vault-name="vaultName"
                          :file-url="fileUrl"
            />

            <vault-logs v-if="showLogsModel"
                    :on-close="closeLogsPopup"
            />
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import AddFolder from "./AddFolder";
import EditFolder from "./EditFolder";
import UploadFile from "./UploadFile";
import FolderView from "./FolderView";
import ShareFiles from "./ShareFiles"
import VaultLogs from "./VaultLogs";
import SignatureListView from "../signatures/SignatureListView.vue";
import {isEmpty} from "jssip/lib/Utils";
import adminMixin from "../../../../mixins/isAdmin"
import moment from 'moment';


export default {
    components: {SignatureListView, AddFolder, EditFolder, UploadFile, FolderView, ShareFiles, VaultLogs },
    mixins: [adminMixin],
    computed: {
        ...mapGetters({
            folders: 'getAllFolders',
            folderFiles: 'getFolderLinkedFiles',
            currentUser: "getCurrentUser",
        }),
        deleteConfirmText() {
            return `${this.$t('FOLDER_MGMT.DELETE.CONFIRM.YES')}`;
        },
        deleteRejectText() {
            return `${this.$t('FOLDER_MGMT.DELETE.CONFIRM.NO')}`;
        },
        deleteMessage() {
            return `${this.$t('FOLDER_MGMT.DELETE.CONFIRM.MESSAGE')}`;
        },
    },
    data() {
        return {
            showModelFolder: false,
            showModelFile: false,
            showEditPopup: false,
            showDeletePopup: false,
            showFileDeletePopup: false,
            currentFolder: {},
            currentFileId: '',
            fileName: '',
            selectedFolderId: '',
            selectedFolderShared: false,
            allFiles: [],
            allFolders: [],
            searchQueryRecord: '',
            showAgentModel: false,
            vaultType: '',
            vaultId: '',
            fileType: '',
            vaultName: '',
            isFileShared: false,
            isFolderShared: false,
            showLogsModel: false,
            fileUrl: '',
            showSignatureView: false,
        };
    },
   async mounted() {
       await this.$store.dispatch('get')
    },
   methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        showFolderModal() {
            this.showModelFolder = true;
        },
        closeFolderModel() {
            this.showModelFolder = false;
        },
        showFileModel() {
            this.showModelFile = true;
        },
        closeFileModel() {
            this.showModelFile = false;
        },
        openEditPopup(folder, index) {
            this.currentFolder = folder;
            this.showEditPopup = true;
        },
        hideEditPopup() {
            this.showEditPopup = false;
        },
        // Delete Function
        openDeletePopup(folder) {
            this.showDeletePopup = true;
            this.currentFolder = folder;
            this.isFolderShared = folder.is_shared;
        },
        openFileDeletePopup(file) {
            this.showFileDeletePopup = true;
            this.isFileShared = file?.is_shared;
            this.fileName = file.name;
            this.currentFileId = file.id;
        },
        closeDeletePopup() {
            this.showDeletePopup = false;
        },
        closeFileDeletePopup() {
            this.showFileDeletePopup = false;
        },
        showAgentsPopup(vault, type, fileType) {
            this.vaultId = vault.id;
            this.vaultName = vault.name;
            this.vaultType = type;
            this.fileType = fileType;
            this.showAgentModel = true;
            this.fileUrl = `${window.chatwootConfig.fileCloudUrl}/${vault.short_path}`;
        },
        closeAgentsPopup() {
            this.vaultId = '';
            this.vaultType = '';
            this.fileType = '';
            this.vaultName = '';
            this.showAgentModel = false;
            this.fileUrl = '';
        },
        showLogsPopup() {
            this.showLogsModel = true;
        },
        closeLogsPopup() {
            this.showLogsModel = false;
        },
        async deleteFolder() {
            try {
                this.closeDeletePopup();
                await this.$store.dispatch('delete', { "id": this.currentFolder?.id, "is_shared": this.isFolderShared });
                this.showAlert(this.$t('FOLDER_MGMT.DELETE.API.SUCCESS_MESSAGE'));
                this.$store.dispatch('addLog', {
                    logType: 'folder',
                    id: this.currentFolder?.id,
                    isShared: this.isFolderShared,
                    logAction: 'delete',
                    name: this.currentFolder?.name
                });
                this.currentFolder = {};
                this.isFolderShared = false;
            } catch (error) {
                this.showAlert(this.$t('FOLDER_MGMT.DELETE.API.ERROR_MESSAGE'));
            }
        },
        async deleteFile() {
            try {
                this.closeFileDeletePopup();
                await this.$store.dispatch('delete_folder_files', { "id": this.currentFileId, "is_shared": this.isFileShared });
                this.showAlert(this.$t('FOLDER_MGMT.FILE_DELETE.API.SUCCESS_MESSAGE'));
                this.$store.dispatch('addLog', {
                    logType: 'file',
                    id: this.currentFileId,
                    isShared: this.isFileShared,
                    logAction: 'delete',
                    name: this.fileName,
                });
                this.currentFileId = '';
                this.fileName = '';
                this.isFileShared = false;
            } catch (error) {
                this.showAlert(this.$t('FOLDER_MGMT.FILE_DELETE.API.ERROR_MESSAGE'));
            }
        },
        formatSize(size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size?.toString() + ' B'
        },
        filePath(file) {
            return `${window.chatwootConfig.fileCloudUrl}/${file.short_path}`;
        },
        targetFolderFiles(folder) {
          this.selectedFolderId = folder.id;
          this.selectedFolderShared = folder.is_shared;
        },
        emptySelectedFolder() {
            this.selectedFolderId = '';
            this.selectedFolderShared = false;
        },
        async onRecordSearchSubmit() {
            if (this.searchQueryRecord) {
                this.allFiles = this.folderFiles;
                this.allFolders = this.folders;
                const resp = await  this.$store.dispatch('filter_records', {
                    search: this.searchQueryRecord,
                });

                let searchFiles = [...resp.files, ...resp.shared_files, ...resp.public_files];
                let searchFolders =  [...resp.folders, ...resp.shared_folders, ...resp.public_folders];
                this.$store.dispatch('set_all_files', searchFiles);
                this.$store.dispatch('set_all_folders', searchFolders);
            }
        },
        onRecordInputSearch(event) {
            this.searchQueryRecord = event.target.value;
            if(isEmpty(this.searchQueryRecord) && (this.allFolders.length || this.allFiles)) {
                this.$store.dispatch('set_all_files', this.allFiles);
                this.$store.dispatch('set_all_folders', this.allFolders);
            }
        },
        download(url, name) {
            let vm = this;
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', name);
                document.body.appendChild(fileLink);
                fileLink.click();
            });
            this.$store.dispatch('addLog', {
                logType: 'file',
                logAction: 'download',
                name: name,
                fileUrl: this.fileUrl,
            });
        },
        preview(url) {
           window.open(url)
        },
        formatDate(timed) {
           return moment(timed).format('MM-DD-YYYY hh:mm');
        },
        signatureView() {
           this.showSignatureView = !this.showSignatureView;
        }
    }

}
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.vault-box {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.vault-container {
    position: relative;
}
.display-flex {
  display: flex;
}

.file-vault-table {
  border-collapse: separate;

  td {
    border-bottom: 1px solid #e0e6ed;
    background: white;
    padding: 0.8em 1em;
  }
  th{
    border-bottom: 1px solid #e0e6ed;
    border-top: 1px solid #e0e6ed;
  }
}

.icon-position {
  padding-left: 1rem;
  font-size: 3rem;
  margin-bottom: -1rem;
}
.no-transactions-found {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: var(--font-size-big);
  display: flex;
  align-items: center;
  justify-content: center;
}
.folder_icon_size {
  font-size: 4rem;
}
.font-3 {
  font-size: 3rem;
}
.cursor_pointer {
  cursor: pointer;
}
.ml_1 {
  margin-left: 10%;
}
.padding_bottom {
  padding: 0.4% 0;
}
.preview {
  width: 20px;
  font-size: 20px;
  padding: 0 10px;
}
.download {
  font-size: 20px;
  padding: 0 10px;
  cursor: pointer;
}
.remove {
  font-size: 20px;
  padding: 0 10px;
}
.log_btn {
  border-radius: 50%;
}
.btn_plus {
  width: 7rem;
  padding: 0;
  height: 36px;
  border-radius: 15%;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f93ff;
  color: #fff;
  margin: 0 auto;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 30%);
  margin-left: 3px;
}

.first-column {
  max-height: 7vh;
}

.btn_signature {
  width: 9rem;
  padding: 0;
  height: 36px;
  border-radius: 15%;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f93ff;
  color: #fff;
  margin: 0 auto;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 30%);
  margin-right: 0px;
}
</style>
