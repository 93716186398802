<template>
  <woot-modal
    :show="show"
    :on-close="handleClose"
    :class="['tags-selector--modal', { 'expanded': isMultiselectOpen }]"
    :closeOnBackdropClick="false"
  >
    <div class="column">
      <woot-modal-header
        header-title="Select Tags"
        header-content="Select multiple tags to apply on current contacts"
      />
      <form class="row" @submit.prevent="handleSubmit">
        <div class="medium-12 columns">
          <multiselect
            v-model="selectedTags"
            :options="accountLabels"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            @open="multiselectopened"
            @close="multiselectclosed"
            :preserve-search="true"
            placeholder="Select tags to apply"
            label="title"
            track-by="title"
          >
          </multiselect>
                </div>
        <div class="modal-footer medium-12 columns">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="isSubmitDisabled"
              :button-text="$t('CREATE_ACCOUNT.FORM.SUBMIT')"
              :loading="loading"
              button-class="large expanded"
            />
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from "vue-multiselect";
import alertMixin from 'shared/mixins/alertMixin';
export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Multiselect
  },
  data() {
    return {
      loading: false,
      selectedTags: [],
      isMultiselectOpen: false,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        const result = this.getSearchQueryOrLabel();
      }
    }
  },
  mounted() {
    if (this.show) {
      const result = this.getSearchQueryOrLabel();
    }
  },
  computed: {
    ...mapGetters({
      accountLabels: 'labels/getLabels',
    }),
    isSubmitDisabled() {
      return this.selectedTags.length === 0;
    }
  },
  methods: {
    handleClose() {
      this.resetForm();
      this.$emit('close-multi-tag-modal');
    },
    async handleSubmit() {
      if (this.selectedTags.length > 0) {
        try {
          this.loading = true;
          this.$emit('tags-selected', this.selectedTags);
          let labelParams = {
            query: this.getSearchQueryOrLabel(),
            tags: this.selectedTags
          };
          let response = await this.$store.dispatch('contacts/apply_labels', labelParams);
          this.loading = false;
          let successMessage = `on ${response.data.contacts_updated} contacts ${response.data.message}`
          this.showAlert(successMessage);
          this.handleClose();
        } catch (error) {
          this.showAlert(error);
          this.loading = false;
          this.handleClose();
        }
      }
    },
    resetForm() {
      this.selectedTags=[];
    },
    multiselectopened() {
      this.isMultiselectOpen = true;
    },
    multiselectclosed() {
      this.isMultiselectOpen = false;
    },
    getSearchQueryOrLabel() {
      const route = this.$route;
      const path = route.path;
      const query = route.query;

      if (path.includes('/contacts/search/')) {
        const searchPart = path.split('/contacts/search/')[1];
        let key = searchPart.split('?')[0];
        return {type:'search', query:key};
      }

      if (path.endsWith('/contacts') && query.page) {
        return {type:'nosearch', query:'all'};
      }

      if (path.includes('/labels/') && path.endsWith('/contacts')) {
        const labelPart = path.split('/labels/')[1];
        let key = labelPart.split('/contacts')[0];
        return {type:'label', query:key};
      }

      return {type:'nosearch', query:'all'};
    },
  },
};
</script>
<style lang="scss">
.alert-wrap {
  margin: var(--space-zero) var(--space-large);
  margin-top: var(--space-medium);

  .callout {
    display: flex;
    justify-content: center;
  }
}
.tags-modal{
  min-height: 500px;
}

.tags-selector--modal {
  .tags-modal {
    min-height: 300px;
    transition: min-height 0.3s ease;

    .modal-footer {
      transition: margin-top 0.3s ease;
    }
  }

  &.expanded {
    .tags-modal {
      min-height: 600px;
    }

    .modal-footer {
      margin-top: 300px;
    }
  }
}

.icon-wrap {
  font-size: var(--font-size-big);
  margin-left: var(--space-smaller);
  margin-right: var(--space-slab);
}
</style>
