import CreditHistoryView from "./pages/CreditHistoryView";
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
    {
        path: frontendURL('accounts/:accountId/credit-history'),
        name: 'credit_history',
        roles: ['administrator'],
        component: CreditHistoryView,
    }
];
