<template>
  <div v-if="agents !== []" class="medium-12 columns ivr-form">
    <div style="margin-bottom: 15px">
      <label class="ivr-form__menu-label">
        Interactive Voice Response
      </label>

      <span class="field-description">
        Most business phone systems feature an Interactive Voice Response menu that helps direct callers to the right place. You can collect voicemails and automatically transfer callers to the proper department.
      </span>
    </div>

    <div>
      <input
          id="sms-bot-settings__is-enabled-checkbox"
          v-model="isEnabled"
          class="sms-bot-settings__checkbox"
          type="checkbox"
          @input="handleIsEnabled"
      />

      <label for="sms-bot-settings__is-enabled-checkbox">
        Enable IVR
      </label>
    </div>
    <div v-if="isEnabled">
      <div>
        <label>
          Intro and directions
          <textarea
              v-model.trim="intro"
              type="text"
              placeholder="Enter intro message and directions for the customer"
          />
        </label>
      </div>

      <div>
        <div :style="`float: right;`" @click="setIsMissedCallMenu">
          <label
          :style="`text-align: end; cursor: pointer; width: 12rem; color: ${linkColor}`"
          @mouseover="onHover"
          @mouseleave="onLeave">
            Missed Call SMS
          </label>
        </div>
        <label>
          In case not available
          <textarea
              v-model.trim="if_unavailable"
              type="text"
              placeholder="Enter message in case staff is unavailable"
          />
        </label>
      </div>

        <label>
            Select the button to assign:
        </label>

        <div class="ivr-form__global-chooser-wrapper">
            <div class="ivr-form__symbol-chooser-wrapper ivr-form__symbol-chooser-wrapper--first">
                <div v-for="symbol in symbols_1"
                     :class="`ivr-form__symbol-item ${menuItems.find((item) => item.symbol === symbol).enabled ? 'ivr-form__symbol-item--active' : ''}`"
                     @click="toggleSymbol(symbol)"
                >
                    {{symbol}}
                </div>
            </div>

            <div class="ivr-form__symbol-chooser-wrapper">
                <div v-for="symbol in symbols_2"
                     :class="`ivr-form__symbol-item ${menuItems.find((item) => item.symbol === symbol).enabled ? 'ivr-form__symbol-item--active' : ''}`"
                     @click="toggleSymbol(symbol)"
                >
                    {{symbol}}
                </div>
            </div>

            <div class="ivr-form__symbol-chooser-wrapper">
                <div v-for="symbol in symbols_3"
                     :class="`ivr-form__symbol-item ${menuItems.find((item) => item.symbol === symbol).enabled ? 'ivr-form__symbol-item--active' : ''}`"
                     @click="toggleSymbol(symbol)"
                >
                    {{symbol}}
                </div>
            </div>

            <div class="ivr-form__symbol-chooser-wrapper">
                <div v-for="symbol in symbols_4"
                     :class="`ivr-form__symbol-item ${menuItems.find((item) => item.symbol === symbol).enabled ? 'ivr-form__symbol-item--active' : ''}`"
                     @click="toggleSymbol(symbol)"
                >
                    {{symbol}}
                </div>
            </div>
        </div>
        <input
            id="missed-call-menu__is-enabled-checkbox"
            v-model="ivr_extensions_enabled"
            class="missed-call-menu__checkbox"
            type="checkbox"
            @input="handleIsIVRExtensionsEnabled($event)"
        />
        <label for="missed-call-menu__is-enabled-checkbox">
          Enable IVR Extensions
        </label>
        <div v-if="ivr_extensions_enabled">
          <p class="field-description">
            Customer must dial '9' followed by three digit extension.
          </p>
          <div class="ivr-item-wrapper">
            <div class="ivr-extensions_body-modal" v-for="agent in agents">
                <label>
                    {{agent.name}}
                </label>
                <input class="ivr-extensions_input-number" type="number" @keydown="onPress" v-model="ivr_extensions.find(obj => {return obj.agent === agent.id}).numbers" v-on:change="onInutIvrNumbers($event, agent.id)" min="100" max="999"/>
            </div>
          </div>
        </div>

        <div v-for="item in menuItems">
            <div v-if="item.enabled">
                <label>
                    When customer pressed '{{item.symbol}}'
                </label>

                <div class="ivr-item-wrapper">
                    <label>
                        <span>Forward To</span>
                        <Multiselect
                                v-model="item.agents"
                                :options="agents"
                                :multiple="true"
                                track-by="id"
                                label="name"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :hide-selected="true"
                                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                placeholder="Any Available Agent (By Default)"
                                selected-label
                        >
                        </Multiselect>
                    </label>

                    <textarea
                            v-model.trim="item.response"
                            type="text"
                            placeholder="Response accompanying to customer's redirect, e.g. &quot;Now transferring you to the boss!&quot;"
                    />
                    <!-- <input
                        id="missed-call-menu__is-enabled-checkbox"
                        v-model="item.ivr_extensions_enabled"
                        class="missed-call-menu__checkbox"
                        type="checkbox"
                        @input="handleIsIVRExtensionsEnabled($event, item.symbol)"
                    />
                    <label for="missed-call-menu__is-enabled-checkbox">
                      Enable IVR Extensions
                    </label>
                     <div v-if="item.ivr_extensions_enabled && item.agents.length > 0" class="ivr-extensions_body-modal" v-for="agent in item.agents">
                        <label>
                            {{agent.name}}
                        </label>
                        <input class="ivr-extensions_input-number" type="number" v-model="item.ivr_extensions.find(obj => {return obj.agent === agent.id}).numbers" v-on:change="onInutIvrNumbers($event, agent.id, item.symbol)" min="100" max="999"/>
                     </div>
                    <div v-if="item.ivr_extensions_enabled && item.agents.length == 0" class="ivr-extensions_body-modal" v-for="agent in agents">
                        <label>
                            {{agent.name}}
                        </label>
                        <input class="ivr-extensions_input-number" type="number" v-model="item.ivr_extensions.find(obj => {return obj.agent === agent.id}).numbers" v-on:change="onInutIvrNumbers($event, agent.id, item.symbol)" min="100" max="999"/>
                    </div> -->
                  </div>
                </div>
            </div>
        </div>

      <div class="ivr-form__buttons-wrapper">
        <woot-submit-button
            button-text="Update"
            :loading="updateInProgress"
            @click="updateIVRSettings"
        />
      </div>
  </div>

</template>

<script>
import vSelect from 'vue-select';
import Multiselect from "vue-multiselect";
import signalwireChannelAPI from "../../../../../../../api/channel/signalwireChannel";
import {globalAIP} from "../../../../../../../api/globalAIP";
import {mapGetters} from "vuex";

import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: "IvrForm",
  components: {
    vSelect,
    Multiselect
  },
  mixins: [alertMixin],
  props: {
    agents: {
      type: Array,
      default: [],
      required: false
    },
    inbox: {
      type: Object
    }
  },
  data() {
    return {
      ivr_extensions_enabled: false,
      ivr_extensions: [],
      updateInProgress: false,
      isEnabled: false,
      linkColor: "#4e80cc",

        symbols: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "#"],
        symbols_1: ["1", "2", "3"],
        symbols_2: ["4", "5", "6"],
        symbols_3: ["7", "8", "0"],
        symbols_4: ["*", "#"],

        intro: "",
        if_unavailable: "",
        menuItems: [
            {
                response: "",
                agents: [],
                symbol: "0",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "1",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "2",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "3",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "4",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "5",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "6",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "7",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "8",
                enabled: false
            },
            // {
            //     response: "",
            //     agents: [],
            //     symbol: "9",
            //     enabled: false
            // },
            {
                response: "",
                agents: [],
                symbol: "*",
                enabled: false
            },
            {
                response: "",
                agents: [],
                symbol: "#",
                enabled: false
            }
        ],

    }
  },
    mounted() {
      this.isEnabled = this.inbox.is_ivr_enabled;
        if (this.inbox.ivr_tree) {
            this.intro = this.inbox.ivr_tree.intro ? this.inbox.ivr_tree.intro : "";
            this.if_unavailable = this.inbox.ivr_tree.if_unavailable ? this.inbox.ivr_tree.if_unavailable : "";
            this.ivr_extensions_enabled = this.inbox.ivr_tree.ivr_extensions_enabled ? this.inbox.ivr_tree.ivr_extensions_enabled : false;

            if(this.inbox.ivr_tree.ivr_extensions){
              this.ivr_extensions = this.inbox.ivr_tree.ivr_extensions;
              for(const agent of this.agents){
                if(!this.ivr_extensions.find(obj => {
                  return obj.agent === agent.id;
                })){
                  this.ivr_extensions.push({agent: agent.id, numbers: null});
                }

              }
            }
            else{
              for(const agent of this.agents){
                this.ivr_extensions.push({agent: agent.id, numbers: null})
              }
            }
            for (const menuItem of this.menuItems) {
                if (this.inbox.ivr_tree[`${menuItem.symbol}`]) {
                    menuItem.enabled = true;
                    menuItem.response = this.inbox.ivr_tree[`${menuItem.symbol}`].response;
                    if (this.inbox.ivr_tree[`${menuItem.symbol}`].agents.length !== 0) {
                        menuItem.agents = this.agents.filter(agent => this.inbox.ivr_tree[`${menuItem.symbol}`].agents.includes(agent.id));
                    }
                }
            }
        }
    },
    watch: {
      agents(){
        if(this.inbox.ivr_tree.ivr_extensions){
          this.ivr_extensions = this.inbox.ivr_tree.ivr_extensions;
          for(const agent of this.agents){
            if(!this.ivr_extensions.find(obj => {
              return obj.agent === agent.id;
            })){
              this.ivr_extensions.push({agent: agent.id, numbers: null});
            }

          }
        }
        else{
          for(const agent of this.agents){
            this.ivr_extensions.push({agent: agent.id, numbers: null})
          }
        }
        for (const menuItem of this.menuItems) {
            if (this.inbox.ivr_tree[`${menuItem.symbol}`]) {
                menuItem.enabled = true;
                menuItem.response = this.inbox.ivr_tree[`${menuItem.symbol}`].response;
                if (this.inbox.ivr_tree[`${menuItem.symbol}`].agents.length !== 0) {
                    menuItem.agents = this.agents.filter(agent => this.inbox.ivr_tree[`${menuItem.symbol}`].agents.includes(agent.id));
                }
            }
        }
      }
    },
    computed: {
      ...mapGetters({
        accountId: 'getCurrentAccountId'
      }),

    ivrTree: function () {
      let ivr_tree = {};

      if (this.intro.length > 0) {
        ivr_tree.intro = this.intro;
      }

      if (this.if_unavailable.length > 0) {
        ivr_tree.if_unavailable = this.if_unavailable;
      }
      ivr_tree.ivr_extensions_enabled = this.ivr_extensions_enabled;
      ivr_tree.ivr_extensions = this.ivr_extensions.length === 0 ? [] : this.ivr_extensions.filter(obj => obj.numbers !== null);

        for (const item of this.menuItems) {
            if (item.enabled) {
                ivr_tree[`${item.symbol}`] = {
                    response: item.response,
                    agents: item.agents.length === 0 ? [] : item.agents.map(agent => agent.id),
                }
            }
        }


      return ivr_tree;
    }
  },
  methods: {
    onPress(e){
      if(e.key == "-"){
        e.preventDefault();
      }
      if(e.key == "+"){
        e.preventDefault();
      }
      if(e.key == "e"){
        e.preventDefault();
      }
      return true;
    },
    onInutIvrNumbers(e, agent){
              if(e.target.value.toString().length == 3 && !this.ivr_extensions.find(obj => {
                return obj.numbers === e.target.value && obj.agent !== agent;
              })){
                this.ivr_extensions.find(obj => {
                  return obj.agent === agent
                }).numbers = e.target.value;
              }
              else{
                this.ivr_extensions.find(obj => {
                  return obj.agent === agent
                }).numbers = null;
              }
    },
      async handleIsEnabled(e) {
        this.isEnabled = e.target.checked;
        if(e.target.checked == true){
            await globalAIP.updateAIIVREnabled(this.accountId, this.inbox.id, false);
            if(this.inbox.is_new_ivr_enabled){
              this.destroyIVRAgent();
            }
        }
        await globalAIP.updateIVREnabled(this.accountId, this.inbox.id, e.target.checked, false, false);

        await this.$store.dispatch("inboxes/get");
      },
      async destroyIVRAgent() {
        try {
          const response = await axios.delete(
            `https://agentapi.conversate.us/v1/ivr/delete_agent/${this.inbox.ivr_agent_tree.agent_id}`
          );
          console.log("Destroy response: " + JSON.stringify(response.data));
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        } catch (error) {
            console.log(error);
            this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
        }
        try {
          this.updateInProgress = true;
          await globalAIP.updateNewIVREnabled(this.accountId, this.inbox.id, false);
          const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;
  
          const response = await signalwireChannelAPI.UpdateIVRAgentSettings({
              channel_id: channel_id,
              ivr_agent_tree: {}
          });
          await this.$store.dispatch("inboxes/get");
        }
        catch (e) {
          this.showAlert("Error");
        }
        this.updateInProgress = false;
      },
      handleIsIVRExtensionsEnabled(e) {
        this.ivr_extensions_enabled = e.target.checked;
      },
      onHover(){
          this.linkColor = "#872cd1"
      },
      onLeave(){
        this.linkColor = "#4e80cc"
      },
      setIsMissedCallMenu(){
        this.$store.commit('missedCall/setMissedCallMenuOpen', true);
      },
      toggleSymbol(symbol) {
          for (const item of this.menuItems) {
              if (item.symbol === symbol) {
                  item.enabled = !item.enabled;

                  return;
              }
          }
      },
    async updateIVRSettings() {
      try {
        this.updateInProgress = true;

        const channel_id = this.$store.getters['inboxes/getInbox'](this.$route.params.inboxId).channel_id;

        const response = await signalwireChannelAPI.UpdateIVRSettings({
            channel_id: channel_id,
            ivr_tree: this.ivrTree
        });

        await this.$store.dispatch("inboxes/get");

        this.showAlert("Update Successful");
      }
      catch (e) {
        console.log(e);

        this.showAlert("Error");
      }

      this.updateInProgress = false;
    }
  }
}
</script>

<style scoped>
.ivr-extensions_body-modal{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ivr-extensions_input-number{
  width: 200px;
}
.ivr-form {
  margin-bottom: 30px;
}

.ivr-form textarea {
  resize: vertical;

  min-height: 60px;
}

.ivr-form__menu-label {
  font-size: 24px;
}

.ivr-form__buttons-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.field-description {
  font-size: 12px;
}

.select {
  margin-bottom: 1.6rem;
}

.ivr-item-wrapper {
  border: 1px solid #e0e6ed;

  padding: 20px;

  border-radius: 5px;

  margin-bottom: 15px;
}

.ivr-form__symbol-chooser-wrapper {
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
    align-content: center;

    margin-top: 20px;
}

.ivr-form__symbol-chooser-wrapper--first {
    margin-top: 0;
}

.ivr-form__symbol-item {
    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    width: 60px;
    height: 40px;

    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;

    color: white;

    cursor: pointer;

    background-color: #40A2F7;

    margin-right: 10px;
}

.ivr-form__symbol-item--active {
    background-color: #60CE4D;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ivr-form__global-chooser-wrapper {
    margin-bottom: 20px;

    padding: 25px 20px 25px 20px;

    border-radius: 5px;

    /*border: 1px solid rgba(224,230,237,0.4);*/

    background-color: #F9FAFC;

}
</style>
