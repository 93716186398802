const communicationTypes =  {
    SMS_ONLY_NEW: {label: "SMS Only - New number", code: "sms_new"},
    // SMS_ONLY_BUSINESS_PORTED: {label: "SMS Only - Port existing landline", code: "sms_ported"},
    VOICE_AND_SMS_NEW: {label: "Voice & SMS - New number", code: "voice_new"}
    // VOICE_AND_SMS_BUSINESS_PORTED: {label: "Voice & SMS - Port existing landline", code: "voice_ported"}
}

export default communicationTypes;

export const communicationTypesArray = [
    communicationTypes.SMS_ONLY_NEW,
    // communicationTypes.SMS_ONLY_BUSINESS_PORTED,
    communicationTypes.VOICE_AND_SMS_NEW
    // communicationTypes.VOICE_AND_SMS_BUSINESS_PORTED
];
