export const callsAPI  = {
    async updateCall(callId, payload) {
        return axios.patch(`/api/v1/calls/${callId}`, {
                call: {
                    ...payload
                }
            },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }
        );
    },
    async referCall(callId, payload) {
        return axios.patch(`/signalwire/call_transfer/${callId}`),{
            data: {
                ...payload
            }
        }
    },
    async getReferredCall(from, referred_to) {
        return axios.get(`/signalwire/refer_call`, {
            params: {from: from, referred_to: referred_to}
        })
    },
    async updateCallWebWidget(callId, payload) {
        return axios.patch(`/api/v1/widget/calls/${callId}`, {
                call: {
                    ...payload
                }
            },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }
        );
    },

    async updateAgent(payload) {
        return axios.patch(`/api/v1/calls/update_agent`, {
                call: {
                    ...payload
                },
            },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            });
    },

    async createCall(payload) {
        return axios.post(`/api/v1/calls`, {
                call: {
                    ...payload
                }
            },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            });
    },

    async createCallWebWidget(payload) {
        return axios.post(`/api/v1/widget/calls`, {
                call: {
                    ...payload
                }
            },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            });
    },

    async createCallRecording(payload) {
        return axios.post(`/api/v1/call_recordings`, {
                call_recording: {
                    ...payload
                }
            },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            });
    },
    async updateCallRecording(payload){
        return axios.patch(`/api/v1/call_recordings/update_recording`, {
                call_recording: {
                    ...payload
                }
            },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            });
    }
};
