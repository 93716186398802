import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import LabelsAPI from '../../api/labels';
import ContactAPI from '../../api/contacts';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
  contactLabel: []
};

export const getters = {
  getLabels(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getLabelsOnSidebar(_state) {
    return _state.records
      .filter(record => record.show_on_sidebar)
      .sort((a, b) => a.title.localeCompare(b.title));
  },
  getLabel(_state) {
    return _state.contactLabel;
  },
};

export const actions = {
    get: async function getLabels({commit}) {
        commit(types.SET_LABEL_UI_FLAG, {isFetching: true});
        try {
            const response = await LabelsAPI.get();
            commit(types.SET_LABELS, response.data.payload);
        } catch (error) {
            // Ignore error
        } finally {
            commit(types.SET_LABEL_UI_FLAG, {isFetching: false});
        }
    },

  create: async function createLabels({ commit }, cannedObj) {
    commit(types.SET_LABEL_UI_FLAG, { isCreating: true });
    try {
      const response = await LabelsAPI.create(cannedObj);
      commit(types.ADD_LABEL, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isCreating: false });
    }
  },

  update: async function updateLabels({ commit }, { id, ...updateObj }) {
    commit(types.SET_LABEL_UI_FLAG, { isUpdating: true });
    try {
      const response = await LabelsAPI.update(id, updateObj);
      commit(types.EDIT_LABEL, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isUpdating: false });
    }
  },

    delete: async function deleteLabels({commit}, id) {
        commit(types.SET_LABEL_UI_FLAG, {isDeleting: true});
        try {
            await LabelsAPI.delete(id);
            commit(types.DELETE_LABEL, id);
        } catch (error) {
            throw new Error(error);
        } finally {
            commit(types.SET_LABEL_UI_FLAG, {isDeleting: false});
        }
    },

    get_contact_labels: async function getContactLabels({commit}, id) {
        try {
            const response = await ContactAPI.getContactLabel(id);
            commit(types.CONTACT_LABEL, response.data);
        } catch (error) {
            throw new Error(error);
        }
    },

    label: async ({commit}, param) => {
        return LabelsAPI.exportLabels(param)
            .then(response => {
                let csvContent =  response.data;
                var link = window.document.createElement("a");
                link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent));
                link.setAttribute("download", "export_label.csv");
                window.document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error(error);
            });
    },
};

export const mutations = {
  [types.SET_LABEL_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.CONTACT_LABEL]($state, contactLabel) {
    $state.contactLabel = contactLabel;
  },

  [types.SET_LABELS]: MutationHelpers.set,
  [types.ADD_LABEL]: MutationHelpers.create,
  [types.EDIT_LABEL]: MutationHelpers.update,
  [types.DELETE_LABEL]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
