<template>
  <div class="column content-box">
    <div class="settings-header row">
      <div class="medium-12">
          <h1 class="display page-title">
            <back-button :back-url="backUrl"></back-button>
            <i class="icon ion-arrow-graph-up-right header--icon"></i><span>Transactions History</span>
          </h1>
      </div>
    </div>
    <div class="row" style="display: flex; background-color: #f9fafc;">
      <div class="small-10 columns table-margin-left-3">
        <p v-if="!histories.length" class="no-items-erro-message">
          {{ $t('PAYMENT_HISTORY.LIST.404') }}
        </p>
        <table v-if="histories.length" class="woot-table text-center">
          <!-- Header -->
          <thead>
            <th class="text-center"
                v-for="thHeader in $t('PAYMENT_HISTORY.LIST.TABLE_HEADER')"
                :key="thHeader"
            >
              {{ thHeader}}
            </th>
          </thead>
          <tbody>
          <tr v-for="transaction_history in histories">
            <td>
              <span class="agent-name">{{ transaction_history.id }}</span>
            </td>
<!--            <td>-->
<!--              <span class="agent-name">{{ transaction_history.invoice }}</span>-->
<!--            </td>-->
            <td>
              <span class="agent-name">{{ transaction_history.reason }}</span>
            </td>
            <td>
              <span class="agent-name">${{ transaction_history.amount }}</span>
            </td>
            <td>
              <span class="history-availability">{{ transaction_history.currency }}</span>
            </td>
            <td>
              <span class="history-time">{{ transaction_history.status }}</span>
            </td>
            <td>
              <span class="history-time">{{ transaction_history.payment_intent }}</span>
            </td>
            <td>
              <span class="history-time">{{ transaction_history.date }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import {mapGetters} from 'vuex';
import {frontendURL} from "../../../../helper/URLHelper";
import BackButton from "../../../../components/widgets/BackButton.vue";

export default {
    components: {BackButton},

  computed: {
    ...mapGetters({
      getTransactionHistory: 'get_transaction_history'
    }),
  },

  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    conversationId: {
      type: [Number, String],
      required: true,
    },
    backUrl: {
        type: [String, Object],
        default: '',
    },
  },

  data(){
    return{
      histories: []
    };
  },

  mounted() {
    this.getHistories();
  },

  methods: {
    async getHistories() {
      try {
        let contact_id = this.$route.params.contact_id
        await this.$store.dispatch('transactionsDetails', contact_id)
        this.histories = this.getTransactionHistory
      }catch (e) {
        console.log("error in" , e)
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.label-color--container {
  display: flex;
  align-items: center;
}

.label-color--display {
  border-radius: $space-smaller;
  height: $space-normal;
  margin-right: $space-smaller;
  width: $space-normal;
}

.settings {
  overflow: auto;
}

.display {
  display: flex;
}

.header_style {
  margin-bottom: 10px;
  margin-left: 5%;
}

.table-margin-left-3 {
  margin-left: 3rem;
}
.content-box {
  padding-left: 0rem !important;
}

</style>
