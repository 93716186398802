<template>
    <woot-modal :show.sync="show" :on-close="onClose">
        <woot-modal-header
                :header-title="$t('FOLDER_MGMT.EDIT.BUTTON_TEXT')"
        />
        <form class="row" @submit.prevent="editFolder">
            <woot-input
                    v-model.trim="name"
                    :class="{ error: $v.name.$error }"
                    class="medium-12 columns"
                    :label="$t('FOLDER_MGMT.FORM.NAME.LABEL')"
                    :placeholder="$t('FOLDER_MGMT.FORM.NAME.PLACEHOLDER')"
                    @input="$v.name.$touch"
            />
            <woot-input
                    v-model.trim="notes"
                    class="medium-12 columns"
                    :label="$t('FOLDER_MGMT.FORM.NOTES.LABEL')"
                    :placeholder="$t('FOLDER_MGMT.FORM.NOTES.PLACEHOLDER')"
            />
            <label class="w-full">
                <span style="margin-right: 1rem;"> Access: </span>
                <input
                        id="private"
                        v-model="access_type"
                        type="radio"
                        name="selectedType"
                        value="private"
                        class="label-input"
                        :checked="access_type === 'private'"
                />
                <label class="label-tag" for="private">{{
                    $t('FOLDER_MGMT.FORM.ACCESS.LABEL_PRIVATE')
                    }}</label>
                <span></span><span></span>
                <input
                        id="public"
                        v-model="access_type"
                        type="radio"
                        name="selectedType"
                        value="public"
                        class="label-input"
                        :checked="access_type === 'public'"
                />
                <label class="label-tag" for="public">{{
                    $t('FOLDER_MGMT.FORM.ACCESS.LABEL_PUBLIC')
                    }}</label>
            </label>
            <div class="medium-12 columns footer-margin-top">
                <woot-button>
                    {{ $t('FOLDER_MGMT.FORM.SAVE') }}
                </woot-button>
                <woot-button class="button clear" @click.prevent="onClose">
                    {{ $t('FOLDER_MGMT.FORM.CANCEL') }}
                </woot-button>
            </div>
        </form>
    </woot-modal>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        notes: {
            type: String,
            default: '',
        },
        access_type: {
            type: String,
            default: 'private',
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            name: this.name,
            notes: this.notes,
            access_type: this.access_type,
            id: this.id,
            show: true,
        }
    },
    validations: {
        name: {
            required,
            minLength: minLength(1),
        },
    },
    methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        async editFolder() {
            try {
                await this.$store.dispatch('update', {
                    id: this.id,
                    name: this.name,
                    notes: this.notes,
                    access_type: this.access_type,
                    folder_type: 'folder'
                });
                this.showAlert(this.$t('FOLDER_MGMT.EDIT.API.SUCCESS_MESSAGE'));
                this.onClose();
            } catch (error) {
                this.showAlert(this.$t('FOLDER_MGMT.EDIT.API.ERROR_MESSAGE'));
            }
        },
    }
}
</script>

<style scoped>
.label-footer-button {
    display: inline;
}

.label-input {
    margin-right: 0px !important;
}

.label-tag {
    margin-left: 0px !important;
}

.footer-margin-top {
    margin-top: 2rem !important;
}
</style>
