<template>
  <div class="card">
    <div class="row">
      <div class="columns col-md-12">
        <textarea
          v-model="inputText"
          rows="12"
          cols="135"
          :placeholder="$t('NOTES.ADD.PLACEHOLDER')"
          class="input--note"
          @keydown.enter.shift.exact="onAdd"
        />
      </div>
    </div>
    <div class="row">
      <div class="columns col-md-12">
        <div class="footer">
          <woot-button
            color-scheme="warning"
            :title="$t('NOTES.ADD.TITLE')"
            :is-disabled="buttonDisabled"
            @click="onAdd"
            class="w-40"
          >
            {{ $t('NOTES.ADD.BUTTON') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputText: '',
    };
  },

  computed: {
    buttonDisabled() {
      return this.inputText === '';
    },
  },

  methods: {
    onAdd() {
      if (this.inputText !== '') {
        this.$emit('add', this.inputText);
      }
      this.inputText = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.input--note {
  font-size: var(--font-size-mini);
  border-color: transparent;
  margin-bottom: var(--space-small);
  padding: 0;
  resize: none;
  min-height: var(--space-larger);
}

.footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.card-width {
  width: 100rem;
  height: 50vh;
}
</style>
