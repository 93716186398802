const regionTypes =  {
    ANY: {label: "Any", code: null},
    ALASKA: {label: "Alaska", code: "AK"},
    ALABAMA: {label: "Alabama", code: "AL"},
    ARKANSAS: {label: "Arkansas", code: "AR"},
    ARIZONA: {label: "Arizona", code: "AZ"},
    CALIFORNIA: {label: "California", code: "CA"},
    COLORADO: {label: "Colorado", code: "CO"},
    CONNECTICUT: {label: "Connecticut", code: "CT"},
    COLUMBIA: {label: "District of Columbia", code: "DC"},
    DELAWARE: {label: "Delaware", code: "DE"},
    FLORIDA: {label: "Florida", code: "FL"},
    GEORGIA: {label: "Georgia", code: "GA"},
    HAWAII: {label: "Hawaii", code: "HI"},
    IOWA: {label: "Iowa", code: "IA"},
    IDAHO: {label: "Idaho", code: "ID"},
    ILLINOIS: {label: "Illinois", code: "IL"},
    INDIANA: {label: "Indiana", code: "IN"},
    KANSAS: {label: "Kansas", code: "KS"},
    KENTUCKY: {label: "Kentucky", code: "KY"},
    LOUISIANA: {label: "Louisiana", code: "LA"},
    MASSACHUSETTS: {label: "Massachusetts", code: "MA"},
    MARYLAND: {label: "Maryland", code: "MD"},
    MAINE: {label: "Maine", code: "ME"},
    MICHIGAN: {label: "Michigan", code: "MI"},
    MINNESOTA: {label: "Minnesota", code: "MN"},
    MISSOURI: {label: "Missouri", code: "MO"},
    MISSISSIPPI: {label: "Mississippi", code: "MS"},
    MONTANA: {label: "Montana", code: "MT"},
    NORTH_CAROLINA: {label: "North Carolina", code: "NC"},
    NORTH_DAKOTA: {label: "North Dakota", code: "ND"},
    NEBRASKA: {label: "Nebraska", code: "NE"},
    NEW_HAMPSHIRE: {label: "New Hampshire", code: "NH"},
    NEW_JERSEY: {label: "New Jersey", code: "NJ"},
    NEW_MEXICO: {label: "New Mexico", code: "NM"},
    NEVADA: {label: "Nevada", code: "NV"},
    NEW_YORK: {label: "New York", code: "NY"},
    OHIO: {label: "Ohio", code: "OH"},
    OKLAHOMA: {label: "Oklahoma", code: "OK"},
    OREGON: {label: "Oregon", code: "OR"},
    PENNSYLVANIA: {label: "Pennsylvania", code: "PA"},
    RHODE_ISLAND: {label: "Rhode Island", code: "RI"},
    SOUTH_CAROLINA: {label: "South Carolina", code: "SC"},
    SOUTH_DAKOTA: {label: "South Dakota", code: "SD"},
    TENNESSEE: {label: "Tennessee", code: "TN"},
    TEXAS: {label: "Texas", code: "TX"},
    UTAH: {label: "Utah", code: "UT"},
    VIRGINIA: {label: "Virginia", code: "VA"},
    VERMONT: {label: "Vermont", code: "VT"},
    WASHINGTON: {label: "Washington", code: "WA"},
    WISCONSIN: {label: "Wisconsin", code: "WI"},
    WEST_VIRGINIA: {label: "West Virginia", code: "WV"},
    WYOMING: {label: "Wyoming", code: "WY"},
    ALBERTA: {label: "Alberta", code: "AB"},
    BRITISH_COLUMBIA: {label: "British Columbia", code: "BC"},
    MANITOBA: {label: "Manitoba", code: "MB"},
    NEW_BRUNSWICK: {label: "New Brunswick", code: "NB"},
    NEWFOUNDLAND_AND_LABRADOR: {label: "Newfoundland and Labrador", code: "NL"},
    NOVA_SCOTIA: {label: "Nova Scotia", code: "NS"},
    NORTHWEST_TERRITORIES: {label: "Northwest Territories", code: "NT"},
    NUNAVUT: {label: "Nunavut", code: "NU"},
    ONTARIO: {label: "Ontario", code: "ON"},
    PRINCE_EDWARD_ISLAND: {label: "Prince Edward Island", code: "PE"},
    QUEBEC: {label: "Quebec", code: "QC"},
    SASKATCHEWAN: {label: "Saskatchewan", code: "SK"},
    YUKON: {label: "Yukon", code: "YT"}
}

export default regionTypes;

export const regionTypesArray = [
    regionTypes.ANY,
    regionTypes.ALASKA,
    regionTypes.ALABAMA,
    regionTypes.ARKANSAS,
    regionTypes.ARIZONA,
    regionTypes.CALIFORNIA,
    regionTypes.COLORADO,
    regionTypes.CONNECTICUT,
    regionTypes.COLUMBIA,
    regionTypes.DELAWARE,
    regionTypes.FLORIDA,
    regionTypes.GEORGIA,
    regionTypes.HAWAII,
    regionTypes.IOWA,
    regionTypes.IDAHO,
    regionTypes.ILLINOIS,
    regionTypes.INDIANA,
    regionTypes.KANSAS,
    regionTypes.KENTUCKY,
    regionTypes.LOUISIANA,
    regionTypes.MASSACHUSETTS,
    regionTypes.MARYLAND,
    regionTypes.MAINE,
    regionTypes.MICHIGAN,
    regionTypes.MINNESOTA,
    regionTypes.MISSOURI,
    regionTypes.MISSISSIPPI,
    regionTypes.MONTANA,
    regionTypes.NORTH_CAROLINA,
    regionTypes.NORTH_DAKOTA,
    regionTypes.NEBRASKA,
    regionTypes.NEW_HAMPSHIRE,
    regionTypes.NEW_JERSEY,
    regionTypes.NEW_MEXICO,
    regionTypes.NEVADA,
    regionTypes.NEW_YORK,
    regionTypes.OHIO,
    regionTypes.OKLAHOMA,
    regionTypes.OREGON,
    regionTypes.PENNSYLVANIA,
    regionTypes.RHODE_ISLAND,
    regionTypes.SOUTH_CAROLINA,
    regionTypes.SOUTH_DAKOTA,
    regionTypes.TENNESSEE,
    regionTypes.TEXAS,
    regionTypes.UTAH,
    regionTypes.VIRGINIA,
    regionTypes.VERMONT,
    regionTypes.WASHINGTON,
    regionTypes.WISCONSIN,
    regionTypes.WEST_VIRGINIA,
    regionTypes.WYOMING,
    regionTypes.ALBERTA,
    regionTypes.BRITISH_COLUMBIA,
    regionTypes.MANITOBA,
    regionTypes.NEW_BRUNSWICK,
    regionTypes.NEWFOUNDLAND_AND_LABRADOR,
    regionTypes.NOVA_SCOTIA,
    regionTypes.NORTHWEST_TERRITORIES,
    regionTypes.NUNAVUT,
    regionTypes.ONTARIO,
    regionTypes.PRINCE_EDWARD_ISLAND,
    regionTypes.QUEBEC,
    regionTypes.SASKATCHEWAN,
    regionTypes.YUKON
];
