export const dappAdress = {
    async getDapp() {
        const response = await axios.get(`/signalwire/dapp_transfer`);

        return response;
    },
    async getDappOutboundCall(number){
      const response = await axios.get(`signalwire/initiate_call`, { params: {number: number }});

      return response;
    }
}
