export const globalAIP = {
    async updateForwardingSettings(accountId, inboxId, userId, payload) {
        const response = axios.patch(`/api/v1/accounts/${accountId}/inbox_members/${userId}/${inboxId}`, {
            inbox_member: {
                ...payload
            }
        },
            {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            });
    },

    async getForwardingSettings(accountId, inboxId, userId) {
        const response = await axios.get(`/api/v1/accounts/${accountId}/inbox_members/${userId}/${inboxId}`);

        return response;
    },

    async updateSmsBotEnabled(accountId, inboxId, isEnabled) {
        const response = await axios.patch(`/api/v1/accounts/${accountId}/inboxes/${inboxId}`, {
            sms_bot_channel: {
                is_sms_bot_enabled: isEnabled
            }
        });
    },
    async updateIVREnabled(accountId, inboxId, isEnabled, isAiIvrEnabled, isAiAgentEnabled) {
        const response = await axios.patch(`/api/v1/accounts/${accountId}/inboxes/${inboxId}`, {
            ivr_form_channel: {
                is_ivr_enabled: isEnabled
            },
            ai_ivr_form_channel: {
                is_ai_ivr_enabled: isAiIvrEnabled
            },
            ai_agent_form_channel: {
                is_ai_agent_enabled: isAiAgentEnabled
            }
        });
    },
    async updateAIIVREnabled(accountId, inboxId, isEnabled) {
        const response = await axios.patch(`/api/v1/accounts/${accountId}/inboxes/${inboxId}`, {
            ai_ivr_form_channel: {
                is_ai_ivr_enabled: isEnabled
            }
        });
    },
    async updateAIAgentEnabled(accountId, inboxId, isEnabled) {
        const response = await axios.patch(`/api/v1/accounts/${accountId}/inboxes/${inboxId}`, {
            ai_agent_form_channel: {
                is_ai_agent_enabled: isEnabled
            }
        });
    },
    async updateNewIVREnabled(accountId, inboxId, isEnabled) {
        const response = await axios.patch(`/api/v1/accounts/${accountId}/inboxes/${inboxId}`, {
            new_ivr_form_channel: {
                is_new_ivr_enabled: isEnabled
            }
        });
    }
}
