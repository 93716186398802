import * as types from "../mutation-types";

export const state = {
    dashboardAction: null,
    isForwardingModalOpen: false,
};

export const getters = {
    getDashboardAction($state) {
        return $state.dashboardAction;
    },
    getIsForwardingModalOpen($state) {
      return $state.isForwardingModalOpen;
    }
};

export const mutations = {
    [types.default.SET_IS_FORWARDING_MODAL_OPEN]($state, payload) {
        $state.isForwardingModalOpen = payload;
    },
    [types.default.SET_DASHBOARD_ACTION]($state, payload) {
        $state.dashboardAction = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
