<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box model-80">
      <woot-modal-header
          :header-title="'Create Event'"
          :header-content="'Add Event details'"
      />
    </div>
    <div class="row margin_right_3">
      <div class="medium-6 medium-offset-6">
        <button class="btn btn-outline-secondary btn_toggle"  @click="isAuto()">Use existing schedule</button>
        <button class="btn btn-outline-secondary btn_toggle" @click="isManual()">Set custom hours</button>
      </div>
    </div>

    <div class="row">
      <div :class="auto ? 'medium-7 column' : 'medium-5 column'">
        <form class="create-event--form" @submit.prevent="createEvent">
          <div class="row">
            <div class="medium-12 columns">
              <label :class="{ error: $v.calendar.$error }">
                <select name="calender" class="form-control"  v-model="calendar" @input="$v.calendar.$touch">
                  <option value="" disabled hidden>Choose Calendar</option>
                  <option :value="getCalendar.id"  v-for="getCalendar in getCalendars" >
                    {{getCalendar.name}}
                  </option>
                </select>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="medium-12 columns">
              <label :class="{ error: $v.summary.$error }">
                {{ $t('EVENT.FORM.SUMMARY.LABEL') }}
                <textarea
                    v-model="summary"
                    :label="$t('EVENT.FORM.SUMMARY.LABEL')"
                    :placeholder="$t('EVENT.FORM.SUMMARY.PLACEHOLDER')"
                    @input="$v.summary.$touch"
                />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="medium-12 columns">
              <label :class="{ error: $v.description.$error }">
                {{ $t('EVENT.FORM.DESCRIPTION.LABEL') }}
                <textarea
                    v-model="description"
                    :label="$t('EVENT.FORM.DESCRIPTION.LABEL')"
                    :placeholder="$t('EVENT.FORM.DESCRIPTION.PLACEHOLDER')"
                    @input="$v.description.$touch"
                />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="medium-12 columns">
              <label :class="{ error: $v.duration.$error }">
                {{ $t('EVENT.FORM.DURATION.LABEL') }}
                <select v-model="duration" @input="$v.duration.$touch">
                  <option value="15">15 min</option>
                  <option value="30" selected>30 min</option>
                  <option value="40">40 min</option>
                  <option value="60">60 min</option>
                  <option value="custom">Custom</option>
                </select>
              </label>
            </div>
            <div class="row">
              <div class="medium-8 columns"><input type="text" v-model="custom_date" v-if="this.duration === 'custom'"/></div>
              <div class="medium-3  medium-offset-1 columns">
                <select v-model="type" v-if="this.duration === 'custom'">
                  <option value="hrs">hrs</option>
                  <option value="min">min</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-submit-button
                  :button-text="$t('EVENT.FORM.SUBMIT')"
              />
              <button class="button clear" @click.prevent="onCancel">
                {{ $t('EVENT.FORM.CANCEL') }}
              </button>
            </div>

          </div>
        </form>
      </div>

      <div class="medium-5 column container_margin dynamic_div" v-if="auto">
        <div class="row">
          <div class="medium-12">
          </div>
          <div class="medium-12">
            <label class="weekly_text"> WEEKLY HOURS </label>
          </div>
        </div>
        <div class="row padding_1" v-for="(value, key) in days">
          <div class="medium-3 align-center medium-offset-2">
            <label>{{ key }}:</label>
          </div>
          <div class="medium-6 columns align-center">
            <label>{{ value }} </label>
          </div>
        </div>
      </div>

      <div class="medium-7 column container_margin manual_row" v-if="manual">
        <div class="row margin_left_2">
          <div class="medium-1 margin_10">
            <label><b>Mon: </b></label>
          </div>
          <div class="medium-11 border_bottom">
            <div class="row" v-for="(div, index) in monday" :key="`monday-${index}`">
              <div class="medium-4 columns">
                <vue-timepicker format="hh:mm A" :minute-interval="10" placeholder="Select Time" close-on-complete
                                v-model="div.start_time" manual-input></vue-timepicker>

              </div>
              <div class="medium-1 center">
                <label><b>to </b></label>
              </div>
              <div class="medium-4">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.end_time"
                                close-on-complete manual-input></vue-timepicker>

              </div>
              <div class="medium-3 center">
                <svg
                    @click="addField(div, monday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!--          Remove Svg Icon-->
                <svg
                    v-show="monday.length > 1"
                    @click="removeField(index, monday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin_left_2">
          <div class="medium-1 margin_10">
            <label><b>Tue: </b></label>
          </div>
          <div class="medium-11 border_bottom">
            <div class="row" v-for="(div, index) in tuesday" :key="`tuesday-${index}`">
              <div class="medium-4 columns">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.start_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-1 center margin_5">
                <label><b>to </b></label>
              </div>
              <div class="medium-4">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.end_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-3 center margin_5">
                <svg
                    @click="addField(div, tuesday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!--          Remove Svg Icon-->
                <svg
                    v-show="tuesday.length > 1"
                    @click="removeField(index, tuesday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin_left_2">
          <div class="medium-1 margin_10">
            <label><b>Wed: </b></label>
          </div>
          <div class="medium-11 border_bottom">
            <div class="row" v-for="(div, index) in wednesday" :key="`wednesday-${index}`">
              <div class="medium-4 columns">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.start_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-1 center margin_5">
                <label><b>to </b></label>
              </div>
              <div class="medium-4">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.end_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-3 center margin_5">
                <svg
                    @click="addField(div, wednesday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!--          Remove Svg Icon-->
                <svg
                    v-show="wednesday.length > 1"
                    @click="removeField(index, wednesday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin_left_2">
          <div class="medium-1 margin_10">
            <label><b>Thu: </b></label>
          </div>
          <div class="medium-11 border_bottom">
            <div class="row" v-for="(div, index) in thursday" :key="`thursday-${index}`">
              <div class="medium-4 columns">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.start_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-1 center margin_5">
                <label><b>to </b></label>
              </div>
              <div class="medium-4">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.end_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-3 center margin_5">
                <svg
                    @click="addField(div, thursday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!--          Remove Svg Icon-->
                <svg
                    v-show="thursday.length > 1"
                    @click="removeField(index, thursday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin_left_2">
          <div class="medium-1 margin_10">
            <label><b>Fri: </b></label>
          </div>
          <div class="medium-11 border_bottom">
            <div class="row" v-for="(div, index) in friday" :key="`friday-${index}`">
              <div class="medium-4 columns">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.start_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-1 center margin_5">
                <label><b>to </b></label>
              </div>
              <div class="medium-4">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.end_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-3 center margin_5">
                <svg
                    @click="addField(div, friday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!--          Remove Svg Icon-->
                <svg
                    v-show="friday.length > 1"
                    @click="removeField(index, friday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin_left_2">
          <div class="medium-1 margin_10">
            <label><b>Sat: </b></label>
          </div>
          <div class="medium-11 border_bottom">
            <div class="row" v-for="(div, index) in saturday" :key="`saturday-${index}`">
              <div class="medium-4 columns">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.start_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-1 center margin_5">
                <label><b>to </b></label>
              </div>
              <div class="medium-4">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.end_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-3 center margin_5">
                <svg
                    @click="addField(div, saturday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!--          Remove Svg Icon-->
                <svg
                    v-show="saturday.length > 1"
                    @click="removeField(index, saturday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin_left_2">
          <div class="medium-1 margin_10">
            <label><b>Sun: </b></label>
          </div>
          <div class="medium-11 border_bottom">
            <div class="row" v-for="(div, index) in sunday" :key="`sunday-${index}`">
              <div class="medium-4 columns">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.start_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-1 center margin_5">
                <label><b>to </b></label>
              </div>
              <div class="medium-4">
                <vue-timepicker format="hh:mm A" :minute-interval="10"  placeholder="Select Time" v-model="div.end_time"
                                close-on-complete manual-input></vue-timepicker>
              </div>
              <div class="medium-3 center margin_5">
                <svg
                    @click="addField(div, sunday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!--          Remove Svg Icon-->
                <svg
                    v-show="sunday.length > 1"
                    @click="removeField(index, sunday)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer">

                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </woot-modal>

</template>

<script>
import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
// Array of API discovery doc URLs for APIs used by the quickstart
import {isEmpty} from "jssip/lib/Utils";
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import alertMixin from "../../../../../shared/mixins/alertMixin";

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly profile email';

export default {
  name: "CreateEvent",
  mixins: [alertMixin],
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      getCalendars: 'agents/getCalendars',
    }),
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },

    conversationId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    DatePicker,
    vSelect,
    VueTimepicker
  },
  data() {
    return {
      summary: '',
      description: '',
      duration: '',
      custom_date: '',
      calendar: '',
      api: undefined,
      auto: true,
      manual: false,
      monday: [{start_time: "", end_time: ""}],
      tuesday: [{start_time: "", end_time: ""}],
      wednesday: [{start_time: "", end_time: ""}],
      thursday: [{start_time: "", end_time: ""}],
      friday: [{start_time: "", end_time: ""}],
      saturday: [{start_time: "", end_time: ""}],
      sunday: [{start_time: "", end_time: ""}],
      days: {SUN: "Unavailable", MON: "9:00am - 5:00pm", TUE: "9:00am - 5:00pm",
        WED: "9:00am - 5:00pm",THU: "9:00am - 5:00pm", FRI: "9:00am - 5:00pm", SAT: "Unavailable"},
      connected: 'false',
      only_time: true,
      type: '',
      jwt: ''
    }
  },
  created() {
    this.currentUser;
    this.api = gapi;
    this.handleClientLoad();
    this.userCalendarsInfo();
  },
  validations: {
    duration: {required},
    summary: {required},
    description: {required},
    calendar: {required}
  },
  methods: {
    handleClientLoad() {
      this.api.load('client:auth2', this.initClient);
    },
    initClient() {
      let vm = this;
      vm.api.client.init({
        apiKey: vm.currentUser.google_api_key,
        clientId: vm.currentUser.client_id,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(async function () {
        const auth2 = vm.api.auth2.getAuthInstance()
        if (auth2.isSignedIn.get()) {
          vm.authorized = true;
          vm.connected = 'true'
        } else {
          let token = vm.parseUser(vm.currentUser)
          if(token.error) {
            vm.connected = 'false'
          }else {
            vm.api.client.setToken({ access_token:  token.access_token})
            vm.connected = 'true'
          }
        }
        // Listen for sign-in state changes.
        // console.log(vm.api.auth2.getAuthInstance().isSignedIn.get())
      });
    },
    handleAuthClick(event) {
      Promise.resolve(this.api.auth2.getAuthInstance().signIn())
          .then(data => {
            this.authorized = true;
          });
    },
    handleSignoutClick(event) {
      Promise.resolve(this.api.auth2.getAuthInstance().signOut())
          .then(_ => {
            this.authorized = false;
          });
    },
    parseUser(user) {
      var data = JSON.parse(user.google_auth_tokens);
      return data;
    },
    createEvent() {
      let vm = this;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      if(isEmpty(vm.getCalendars)) {
        vm.showAlert(vm.$t('EVENT.CALENDAR_CONNECTIVITY'));
        return;
      }
      if(vm.connected === 'false') {
        vm.showAlert(vm.$t('EVENT.RECONNECT_CALENDAR'));
        return;
      }
      var email = vm.api.auth2.getAuthInstance().currentUser.get().getBasicProfile();
      email = email.getEmail();
      const event = {
        'summary': vm.summary,
        'organizer_email': email ? email : vm.currentUser.email,
        'description': vm.description,
        'recurrence': [
          'RRULE:FREQ=DAILY;COUNT=1'
        ],
        'attendees': [
          {'email': vm.contact.email }
        ],
        'reminders': {
          'useDefault': false,
          'overrides': [
            {'method': 'email', 'minutes': 24 * 60},
            {'method': 'popup', 'minutes': 10}
          ]
        },
        'conversation_id': vm.conversationId,
        'contact_id': vm.contact.id,
        'guestsCanModify': true,
        'duration': vm.duration,
        'type': vm.type,
        'custom_date': vm.custom_date,
        'calendar': vm.calendar,
        'schedule': { mon: vm.monday, tue: vm.tuesday, wed: vm.wednesday, thu: vm.thursday,
          fri: vm.friday, sat: vm.saturday, sun: vm.sunday },
        'auto_schedule': vm.auto
      };
      let event_function = vm.event_calendar(event);
      // let event_function  = console.log(event);
      if(event_function) {
        vm.showAlert(vm.$t('EVENT.SUCCESS_MESSAGE'));
        vm.show = false;
        vm.$emit('success');
        this.$emit('cancel');
      }else {
        vm.showAlert(vm.$t('EVENT.ERROR_MESSAGE'));
      }
    },
    async event_calendar(data){
      try {
        await this.$store.dispatch('agents/saveEvent', data)
      } catch (error) {
        console.log(error)
      }
    },
    async userCalendarsInfo() {
      try {
        await this.$store.dispatch('agents/getUserCalendars')
      } catch (error) {
        console.log(error)
      }
    },
    addField(value, fieldType) {
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    isAuto(){
      this.manual = false;
      this.auto = true;
    },
    isManual(){
      this.auto = false;
      this.manual = true;
    },
    onCancel() {
      this.$emit('cancel');
    },
  }
}
</script>

<style scoped>
.model-80 {
  width: 80rem;
}
.margin_5 {
  margin-top: 5px;
}

.margin_10 {
  margin-top: 10px;
}

.center {
  text-align: center;
}

.border_bottom {
  border-bottom: 1px solid #e6ebea;
  margin-top: 10px;
}
.container_margin {
  margin-top: 2.5rem;
}
.btn_toggle {
  border-radius: 4px;
  border: 1px solid rgb(0, 162, 255);
  padding: 8px;
  color: rgb(0, 107, 255);
  display: inline-block;
  cursor: pointer;
}
.btn_toggle:hover {
  border: 2px solid #006bff;
}
.padding_1 {
  padding: 1rem;
}
.dynamic_div {
  margin-bottom: 7rem;
  border-left: 1px solid #cad0d4;
}
.manual_row {
  border-left: 1px solid #cad0d4;
  margin-bottom: 6rem;
}
.margin_left_2 {
  margin-left: 2rem;
}
.margin_right_3 {
  text-align: right;
  margin-right: 3rem;
}
.weekly_text {
  margin-left: 1rem;
  font-weight: 600;
}
</style>
