import Facebook from './channels/Facebook';
import Website from './channels/Website';
import Twitter from './channels/Twitter';
// import Twilio from './channels/Twilio';
import Signalwire from './channels/signalwire/Signalwire';
import Api from './channels/Api';
import Email from './channels/Email';
import Sms from './channels/Sms';
import Whatsapp from './channels/Whatsapp';
import Line from './channels/Line';
import Telegram from './channels/Telegram';
import TeamChat from "./channels/TeamChat";
import Review from "./channels/Review";
import Youtube from "./channels/Youtube";
import Lead from "./channels/Lead";

// const channelViewList = {
//   facebook: Facebook,
//   website: Website,
//   twitter: Twitter,
//   twilio: Twilio,
//   signalwire: Signalwire,
//   api: Api,
//   email: Email,
//   team_chat: TeamChat,
// };
const channelViewList = {
  facebook: Facebook,
  website: Website,
  twitter: Twitter,
  signalwire: Signalwire,
  api: Api,
  email: Email,
  sms: Sms,
  whatsapp: Whatsapp,
  line: Line,
  telegram: Telegram,
  team_chat: TeamChat,
  review: Review,
  youtube: Youtube,
  lead: Lead,
};

export default {
  create() {
    return {
      props: {
        channel_name: {
          type: String,
          required: true,
        },
      },
      name: 'new-channel-view',
      render(h) {
        return h(channelViewList[this.channel_name] || null);
      },
    };
  },
};
