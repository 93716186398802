<template>
  <div class="conversations-list-wrap">
    <slot></slot>
    <div class="chat-list__top">
      <div v-if="conversationInbox !== 0 && isCog" class="chat-list--with-inbox">
        <modal :on-close="onModalClose" :show="isModalOpen">
          <ForwardSettings :inbox="inbox" />
        </modal>

        <div
          style="width: 100%; justify-content: space-between;"
          class="cog-wrapper"
        >
          <div class="inbox_name_position">
            <h1 class="page-title text-truncate" :title="pageTitle">
              {{ pageTitle }}
            </h1>

            <span
              class="chat-list_button-wrapper"
              @click="
                () => {
                  this.$store.commit(
                    'dashboard/SET_DASHBOARD_ACTION',
                    'CLOSE_SIDEBAR'
                  );
                  this.$store.commit(
                    'dashboard/SET_IS_FORWARDING_MODAL_OPEN',
                    true
                  );
                }
              "
            >
              <img
                class="chat-list_button"
                src="~dashboard/assets/images/cog.svg"
                alt="settings"
              />
            </span>
          </div>

          <div style="display: flex; gap: 10px;">
            <select
              v-model="chatDuration"
              class="status--filter"
              @change="onTabChange()"
            >
              <option
                v-for="item in $t('CHAT_LIST.CHAT_ITEM_FILTER')"
                :key="item['VALUE']"
                :value="item['VALUE']"
              >
                {{ item['TEXT'] }}
              </option>
            </select>
            <chat-filter @statusFilterChange="updateStatusType" />
          </div>
        </div>
      </div>
      <div v-else class="chat-list--without-inbox">
        <h1 class="page-title text-truncate" :title="pageTitle">
          <woot-sidemenu-icon />
          {{ pageTitle }}
        </h1>
        <div class="chat-list-filters">
          <select
            v-model="chatDuration"
            class="status--filter"
            @change="onTabChange()"
          >
            <option
              v-for="item in $t('CHAT_LIST.CHAT_ITEM_FILTER')"
              :key="item['VALUE']"
              :value="item['VALUE']"
            >
              {{ item['TEXT'] }}
            </option>
          </select>
          <chat-filter @statusFilterChange="updateStatusType" />
        </div>
      </div>
    </div>

    <div v-if="isSignalwirePhoneNumber" class="signalwire-phone-number">
      {{ formatPhoneNumber(this.inbox.phone_number) }}
    </div>
    <div
      v-if="isSignalwirePhoneNumber && showQueueCallButton"
      class="call-the-queue"
    >
      <div class="call-the-queue-text">
        Clients in Queue: {{ usersInQueue }}
      </div>
      <div class="call-to-button" @click="callTheQueue">
        Call the Queue
      </div>
    </div>

    <chat-type-tabs
      :items="assigneeTabItems"
      :active-tab="activeAssigneeTab"
      class="tab--chat-type"
      @chatTabChange="updateAssigneeTab"
    />

    <p v-if="!chatListLoading && !conversationList.length" class="content-box">
      {{ $t('CHAT_LIST.LIST.404') }}
    </p>

    <div ref="activeConversation" class="conversations-list">
      <conversation-card
        v-for="chat in conversationList"
        :key="chat.id"
        :active-label="label"
        :team-id="teamId"
        :chat="chat"
        :show-assignee="showAssigneeInConversationCard"
      />

      <div v-if="chatListLoading" class="text-center">
        <span class="spinner"></span>
      </div>

      <woot-button
        v-if="!hasCurrentPageEndReached && !chatListLoading"
        variant="clear"
        size="expanded"
        @click="fetchConversations"
      >
        {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
      </woot-button>

      <p
        v-if="
          conversationList.length &&
            hasCurrentPageEndReached &&
            !chatListLoading
        "
        class="text-center text-muted end-of-list-text"
      >
        {{ $t('CHAT_LIST.EOF') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionTypes } from './TheSIPEndpoint/constants/actionTypes';
import ChatFilter from './widgets/conversation/ChatFilter';
import ChatTypeTabs from './widgets/ChatTypeTabs';
import ConversationCard from './widgets/conversation/ConversationCard';
import timeMixin from '../mixins/time';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import conversationMixin from '../mixins/conversations';
import wootConstants from '../constants';
import Modal from './Modal';
import ForwardSettings from '../routes/dashboard/settings/profile/ForwardSettings';

import { phoneNumberHelper } from '../../shared/helpers/PhoneNumberHelper';
import {
  hasPressedAltAndJKey,
  hasPressedAltAndKKey,
  hasPressedAltAndBKey,
} from 'shared/helpers/KeyboardHelpers';
import { isEmpty } from 'jssip/lib/Utils';

export default {
  components: {
    ForwardSettings,
    Modal,
    ChatTypeTabs,
    ConversationCard,
    ChatFilter,
  },
  mixins: [timeMixin, conversationMixin, eventListenerMixins],
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      usersInQueue: 0,
      showQueueCallButton: false,
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ME,
      activeStatus: wootConstants.STATUS_TYPE.ALL,
      chatDuration: wootConstants.CHAT_DURATION.TODAY,
      contactsSearched: null,
      searchKey: '',
      defaultTabsFlag: false,

      fetchFlag: true,

      selectedAgents: null,

      isCog: false,
      isSignalwirePhoneNumber: false,
    };
  },
  computed: {
    ...mapGetters({
      queues: 'inboxes/getQueues',
      isModalOpen: 'dashboard/getIsForwardingModalOpen',
      showNotification: 'showNotificationAlert/getShowNotification',
      currentChat: 'getSelectedChat',
      uiSettings: 'getUISettings',
      chatLists: 'getAllConversations',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      currentUserID: 'getCurrentUserID',
      activeInbox: 'getSelectedInbox',
      conversationStats: 'conversationStats/getStats',
      getPrivateChat: 'getPrivateChats',
      getGroupChat: 'getGroupChats',
    }),
    assigneeTabItems() {
      let tabs = [];
      for (let data in this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS')) {
        const item = this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS')[data];
        if (
          !isEmpty(this.activeInbox) &&
          (item.KEY === 'group' || item.KEY === 'private') &&
          this.inbox.channel_type !== 'Channel::TeamChat'
        ) {
        } else if (
          !isEmpty(this.activeInbox) &&
          this.inbox.channel_type === 'Channel::TeamChat'
        ) {
          if (item.KEY === 'group') {
            const count = this.conversationStats[item.COUNT_KEY] || 0;
            tabs.push({
              key: item.KEY,
              name: item.NAME,
              count,
            });
          }
        } else if (isEmpty(this.activeInbox) && item.KEY === 'group') {
          const count = this.conversationStats[item.COUNT_KEY] || 0;
          tabs.push({
            key: item.KEY,
            name: item.NAME,
            count,
          });
        } else if (item.KEY !== undefined) {
          const count = this.conversationStats[item.COUNT_KEY] || 0;
          tabs.push({
            key: item.KEY,
            name: item.NAME,
            count,
          });
        }
      }
      return tabs;
    },
    showAssigneeInConversationCard() {
      return this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ALL;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.activeInbox);
    },
    currentPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.activeAssigneeTab
      );
    },
    hasCurrentPageEndReached() {
      if (this.showNotification && this.fetchFlag) {
        this.fetchConversations();
        this.fetchFlag = false;
      } else {
        this.fetchFlag = true;
      }
      return this.$store.getters['conversationPage/getHasEndReached'](
        this.activeAssigneeTab
      );
    },
    conversationFilters() {
      return {
        inboxId: this.conversationInbox ? this.conversationInbox : undefined,
        assigneeType: this.activeAssigneeTab,
        status: this.activeStatus,
        chatDuration: this.chatDuration,
        page: this.currentPage + 1,
        labels: this.label ? [this.label] : undefined,
        teamId: this.teamId ? this.teamId : undefined,
      };
    },
    pageTitle() {
      if (this.inbox.name) {
        return this.inbox.name;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      return this.$t('CHAT_LIST.TAB_HEADING');
    },
    conversationList() {
      let conversationList = [];
      const filters = this.conversationFilters;
      if (this.activeAssigneeTab === 'me') {
        if (this.inbox.c_type === 'group') {
          this.activeAssigneeTab = 'group';
          conversationList = [...this.getGroupChat(filters)];
        } else {
          conversationList = [...this.mineChatsList(filters)];
        }
      } else if (this.activeAssigneeTab === 'unassigned') {
        conversationList = [...this.unAssignedChatsList(filters)];
      } else if (this.activeAssigneeTab === 'private') {
        conversationList = [...this.getPrivateChat(filters)];
      } else if (this.activeAssigneeTab === 'group') {
        conversationList = [...this.getGroupChat(filters)];
      } else {
        conversationList = [...this.allChatList(filters)];
      }

      return conversationList;
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters['teams/getTeam'](this.teamId);
      }
      return {};
    },
  },
  watch: {
    queues() {
      try {
        const isQueueEnabled = this.queues.find(
          obj => obj.id === this.activeInbox
        );
        if (isQueueEnabled) {
          if (isQueueEnabled.users_in_queue > 0) {
            this.usersInQueue = isQueueEnabled.users_in_queue;
            this.showQueueCallButton = true;
          } else {
            this.usersInQueue = 0;
            this.showQueueCallButton = false;
          }
        } else {
          this.usersInQueue = 0;
          this.showQueueCallButton = false;
        }
      } catch (e) {
        //
      }
    },
    activeInbox() {
      try {
        const isQueueEnabled = this.queues.find(
          obj => obj.id === this.activeInbox
        );
        if (isQueueEnabled) {
          if (isQueueEnabled.users_in_queue > 0) {
            this.usersInQueue = isQueueEnabled.users_in_queue;
            this.showQueueCallButton = true;
          } else {
            this.usersInQueue = 0;
            this.showQueueCallButton = false;
          }
        } else {
          this.usersInQueue = 0;
          this.showQueueCallButton = false;
        }
      } catch (e) {
        //
      }
    },
    uiSettings(value) {
      const {
        default_assignee_type: chatType = wootConstants.ASSIGNEE_TYPE.ME,
      } = value;
      const {
        default_status_type: chatStatus = wootConstants.STATUS_TYPE.ALL,
      } = value;
      const {
        default_chat_duration: chatDuration = wootConstants.CHAT_DURATION.TODAY,
      } = value;
      if (value.default_assignee_type) {
        if (value.default_status_type) {
          this.activeStatus = chatStatus;
          this.chatDuration = chatDuration;
          this.updateAssigneeTab(chatType);
          this.defaultTabsFlag = true;
        }
        this.activeStatus = wootConstants.STATUS_TYPE.ALL;
        this.chatDuration = wootConstants.CHAT_DURATION.TODAY;
        this.updateAssigneeTab(chatType);
        this.defaultTabsFlag = true;
      }
    },
    async inbox() {
      if (this.activeInbox && !this.isCog) {
        if (
          this.inbox.channel_type === 'Channel::SignalwireLink' &&
          (this.inbox.communication_type === 'voice_new' ||
            this.inbox.communication_type === 'voice_ported')
        ) {
          const response = await this.$store.dispatch('inboxMembers/get', {
            inboxId: this.activeInbox,
          });
          const {
            data: { payload: inboxMembers },
          } = response;
          this.selectedAgents = inboxMembers;

          if (
            this.selectedAgents.find(inbox_member => {
              return inbox_member.id === this.currentUserID;
            })
          ) {
            this.isCog = true;
          }
        }

        if (this.inbox.channel_type === 'Channel::SignalwireLink') {
          this.isSignalwirePhoneNumber = true;
        }
      }
    },
    activeTeam() {
      this.resetAndFetchData();
    },
    conversationInbox() {
      this.resetAndFetchData();
    },
    label() {
      this.resetAndFetchData();
    },
  },
  async mounted() {
    const {
      default_assignee_type: chatType = wootConstants.ASSIGNEE_TYPE.ME,
    } = this.uiSettings;
    const {
      default_status_type: chatStatus = wootConstants.STATUS_TYPE.ALL,
    } = this.uiSettings;
    const {
      default_chat_duration: chatDuration = wootConstants.CHAT_DURATION.TODAY,
    } = this.uiSettings;
    if (this.uiSettings.default_assignee_type) {
      if (this.uiSettings.default_status_type) {
        this.activeStatus = chatStatus;
        this.chatDuration = chatDuration;
        this.updateAssigneeTab(chatType);
        this.defaultTabsFlag = true;
      }
      this.activeStatus = wootConstants.STATUS_TYPE.ALL;
      this.chatDuration = wootConstants.CHAT_DURATION.TODAY;
      this.updateAssigneeTab(chatType);
      this.defaultTabsFlag = true;
    }
    window.setTimeout(() => {
      if (!this.defaultTabsFlag) {
        this.$store.dispatch('setChatFilter', this.activeStatus);
        this.$store.dispatch('setChatDurationFilter', this.chatDuration);
        this.resetAndFetchData();
      }
    }, 5000);

    this.$nextTick(async function() {
      if (this.activeInbox) {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.activeInbox,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.selectedAgents = inboxMembers;
      }
    });
  },
  methods: {
    onTabChange() {
      this.$store.dispatch('setChatDurationFilter', this.chatDuration);
      this.$emit('statusFilterChange', this.chatDuration);
      this.updateChatDuration(this.chatDuration);
    },

    callTheQueue() {
      this.$store.commit('sipEndpoint/SET_CURRENT_ACTION', {
        inbox: this.inbox,
        type: actionTypes.CALL_THE_QUEUE,
      });
    },
    onModalClose() {
      this.$store.commit('dashboard/SET_IS_FORWARDING_MODAL_OPEN', false);
    },
    getKeyboardListenerParams() {
      const allConversations = this.$refs.activeConversation.querySelectorAll(
        'div.conversations-list div.conversation'
      );
      const activeConversation = this.$refs.activeConversation.querySelector(
        'div.conversations-list div.conversation.active'
      );
      const activeConversationIndex = [...allConversations].indexOf(
        activeConversation
      );
      const lastConversationIndex = allConversations.length - 1;
      return {
        allConversations,
        activeConversation,
        activeConversationIndex,
        lastConversationIndex,
      };
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndJKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[0].click();
        }
        if (activeConversationIndex >= 1) {
          allConversations[activeConversationIndex - 1].click();
        }
      }
      if (hasPressedAltAndKKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
          lastConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[lastConversationIndex].click();
        } else if (activeConversationIndex < lastConversationIndex) {
          allConversations[activeConversationIndex + 1].click();
        }
      }

      if (hasPressedAltAndBKey(e)) {
        if (this.chatDuration === wootConstants.CHAT_DURATION.TODAY) {
          this.chatDuration = wootConstants.CHAT_DURATION.ONE_WEEK;
        } else if (this.chatDuration === wootConstants.CHAT_DURATION.ONE_WEEK) {
          this.chatDuration = wootConstants.CHAT_DURATION.TWO_WEEKS;
        } else if (
          this.chatDuration === wootConstants.CHAT_DURATION.TWO_WEEKS
        ) {
          this.chatDuration = wootConstants.CHAT_DURATION.THREE_WEEKS;
        } else if (
          this.chatDuration === wootConstants.CHAT_DURATION.THREE_WEEKS
        ) {
          this.chatDuration = wootConstants.CHAT_DURATION.ONE_MONTH;
        } else if (
          this.chatDuration === wootConstants.CHAT_DURATION.ONE_MONTH
        ) {
          this.chatDuration = wootConstants.CHAT_DURATION.TWO_MONTHS;
        } else if (
          this.chatDuration === wootConstants.CHAT_DURATION.TWO_MONTHS
        ) {
          this.chatDuration = wootConstants.CHAT_DURATION.THREE_MONTHS;
        } else if (
          this.chatDuration === wootConstants.CHAT_DURATION.THREE_MONTHS
        ) {
          this.chatDuration = wootConstants.CHAT_DURATION.ALL;
        }
      }
      this.onTabChange();
    },
    resetAndFetchData() {
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.fetchConversations();
    },
    fetchConversations() {
      this.$store
        .dispatch('fetchAllConversations', this.conversationFilters)
        .then(() => this.$emit('conversation-load'));
    },
    updateAssigneeTab(selectedTab) {
      if (this.activeAssigneeTab !== selectedTab) {
        bus.$emit('clearSearchInput');
        this.activeAssigneeTab = selectedTab;
        if (!this.currentPage) {
          this.resetAndFetchData();
        }
      }
    },
    updateStatusType(index) {
      if (this.activeStatus !== index) {
        this.activeStatus = index;
        this.resetAndFetchData();
      }
    },
    updateChatDuration(index) {
      this.chatDuration = index;
      this.resetAndFetchData();
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumberHelper.format(phoneNumber);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/woot';
.spinner {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}
.conversations-list-wrap {
  flex-shrink: 0;
  width: 34rem;
  height: 100%;
  overflow-y: auto;
  @include breakpoint(large up) {
    width: 36rem;
  }
  @include breakpoint(xlarge up) {
    width: 35rem;
  }
  @include breakpoint(xxlarge up) {
    width: 38rem;
  }
  @include breakpoint(xxxlarge up) {
    flex-basis: 46rem;
  }

  .chat-list__top {
    padding: 0 10px;
  }

  .chat-list--with-inbox {
    width: 100%;

    display: flex;

    align-content: center;
    align-items: center;

    justify-content: space-between;
  }

  .chat-list_button-wrapper {
    margin-bottom: 3px;
    margin-right: 5px;

    cursor: pointer;
  }

  .status--filter {
    margin: 0 !important;
  }
}

.chat-list--without-inbox {
  display: flex;

  align-content: center;
  align-items: center;

  justify-content: space-between;

  width: 100%;
}

.chat-list-filters {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  gap: 2px;
}

.cog-wrapper {
  display: flex;

  justify-content: flex-start;
  align-items: flex-end;
  align-content: center;
}

.page-title {
  margin: 0 !important;
  font-size: 22px;

  padding-right: 5px;
}

.signalwire-phone-number {
  padding: 0 10px;

  margin-top: 5px;

  font-size: 14px;
}
.call-the-queue {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.call-the-queue-text {
  margin: 5px;
  padding: 5px;
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.call-to-button {
  margin: 5px;
  padding: 5px;
  background-color: #60ce4d;

  width: 50%;

  border-radius: 5px;

  text-align: center;

  color: white;

  font-size: 16px;
  font-weight: bold;

  cursor: pointer;
}
.inbox_name_position {
  display: flex;
  max-width: 55%;
}
</style>
