<template>
    <div class="medium-4 columns medium-offset-4 no-transactions-found m_t" v-if="expireLink">
        Link Expired, please contact with agent!
    </div>
    <div class="vault-container" v-else>
        <sync-contact :show="this.show" :account-id="this.account_id" :document-id="this.document_id" />
        <div class="row" v-if="folder">
            <div class="medium-2 columns list-table" v-if="folder">
                <ul class="table_component_ul">
                    <li>
                        {{folder.name}}
                    </li>
                </ul>
            </div>
            <div class="medium-8 columns medium-offset-1" v-if="folder.files.length">
                <div class="main-body body_margin">
                    <div class="body-component" v-for="(file, index) in folder.files" :key="file.id"  @click="preview(filePath(file))" title="Click to open the file">
                        <ul>
                            <li>
                                <img  :src=filePath(file)  v-if="file.content_type === 'image/png' || file.content_type === 'image/jpg'">
                                <img  src="/google-docs.png" class="docs" v-else />
                            </li>
                            <li class="list-name">
                                <p>
                                  <b>Name:</b> {{ file.name }}
                                </p>
                            </li>
                            <li class="list-size">
                                <p>
                                  <b>Size:</b> {{ formatSize(file.size) }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="medium-4 columns medium-offset-4 no-transactions-found m_t" v-else>
                Empty Folder
            </div>
        </div>
        <div class="row position_alignment" v-else-if="files">
            <div class="columns medium-offset-3 medium-6 border_img"  @click="preview(filePath(file))" title="Click to open the file">
                <ul>
                    <li @click="preview(filePath(files))">
                        <img  :src=filePath(files)  v-if="files.content_type === 'image/png' || files.content_type === 'image/jpg' || files.content_type === 'image/jpeg'">
                        <img  src="/google-docs.png" class="docs" v-else />
                    </li>
                    <li class="list-name">
                        <p>
                          <b>Name:</b> {{ files.name }}
                        </p>
                    </li>
                    <li class="list-size">
                        <p>
                          <b>Size:</b> {{ formatSize(files.size) }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>


</template>

<script>
import moment from "moment/moment";
import SyncContact from "./SyncContact.vue";
import {mapGetters} from "vuex";

export default {
    name: "CustomerVaultView",
    components: {
        SyncContact
    },
    computed: {
        ...mapGetters({
            accountId: 'getCurrentAccountId',
        }),
    },
    data() {
        return {
          vaultType: '',
          vaultId: '',
          vaultDate: '',
          folder: '',
          files: '',
          expireLink: false,
          show: false,
          document_id: '',
          account_id: '',
        }
    },
    async mounted() {
        let params = this.$route.query?.params
        let decoded = JSON.parse(atob(params));
        if(decoded.vaultId) {
            const response = await this.$store.dispatch('conversation_vault_details', {
                "vault_id": decoded.vaultId,
                "vault_type": decoded.vaultType,
            });
            if(response.folder_type === 'file') {
                this.files = response
            }else if(response.folder_type === 'folder') {
                this.folder = response
            }
        }
        this.show = decoded.is_signDocs && !decoded?.is_conversate;
        this.vaultId = decoded.vaultId;
        this.vaultType = decoded.vaultType;
        this.vaultDate = decoded.date;
        this.document_id = decoded.document_id;
        this.account_id = decoded.account_id;
        const currentTime = new Date();
        const timeDifference = parseInt((currentTime - new Date(decoded.date)) / (1000 * 60 * 60));
        if (timeDifference >= 24 ) {
            this.expireLink = true;
        }
    },
    methods: {
        formatDate(date) {
            let duration = moment.duration(moment().diff(moment(date)));
            return duration.asHours();
        },
        filePath(file) {
            return `${window.chatwootConfig.fileCloudUrl}/${file.short_path}`;
        },
        preview(url) {
            window.open(url)
        },
        formatSize(size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size?.toString() + ' B'
        },
        download(url, name) {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', name);
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        hideContactModel() {
            return this.show = false;
        }
    }
}
</script>

<style scoped>
.docs {
    width: 141px;
}
.list-table {
    border-top: 1px solid #e0e6ed;
}
.table_component_ul {
    list-style: none;
    background-color: white;
    margin-top: 3%;
    cursor: pointer;
}
.table_component_ul li {
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 3%;
    border-radius: 0.4rem;
}
.list-name {
    word-break: normal;
    flex-wrap: wrap;
}
.list-name p {
    word-break: break-all;
    padding: 10px 0 0 0;
}
.list-size p {
    font-size: 1.2rem;
    margin-bottom: 3px;
}
.body_margin {
    margin-top: 10rem;
}
.position_alignment {
    margin-top: 13rem;
}
.border_img {
  border: 2px solid #ccc; /* Set the border width and color */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: box-shadow 0.3s ease;
  padding-top: 1.25rem;
  padding-right: 1.25rem;
}

.border_img:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase the shadow on hover */
}

ul {
  list-style: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
}

li{
  border-top: 1.25rem;
}

/* Style the list name and list size */
.list-name,
.list-size {
  margin: 5px 0; /* Adjust the spacing within each list item */
}

/* Add some styling to the images within the list items */
li img {
  max-width: 100%; /* Ensure images don't exceed their container */
  border-radius: 5px; /* Optional: Add rounded corners to images */
}

/* Add this to your existing CSS styles */
.main-body {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.body-component {
  word-break: break-all;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  max-width: 172px;
  background-color: #fff; /* Set background color */
  border: 1px solid #ddd; /* Set border color */
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle outer shadow */
  margin: 10px; /* Adjust spacing between elements */
  overflow: hidden; /* Hide overflow content if any */
  transition: box-shadow 0.3s ease; /* Optional: Add a transition for a smooth effect */
}

.body-component:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase the outer shadow on hover */
}

/* Style the list items within .body-component */
.body-component ul {
  list-style: none;
  padding: 10px;
}

.body-component li {
  margin-bottom: 10px;
}

/* Style the images within the list items */
.body-component li img {
  max-width: 100%;
  min-height: 10rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle inner shadow to images */
  transition: box-shadow 0.3s ease; /* Optional: Add a transition for a smooth effect */
}

.body-component li img:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase the inner shadow on hover */
}

/* Style the list name and list size */
.body-component .list-name,
.body-component .list-size {
  margin: 5px 0;
}

</style>
