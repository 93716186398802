<template>
    <div v-if="availableTypes.length > 0 || availableStatus.length > 0">
        <label v-if="availableTypes.length > 0">
            Assignee Type
            <select name="type"
                    id="type-select"
                    @change="onChangeType"
                    v-model="selectedType"
            >
                <option v-for="availableType in availableTypes"
                        :key="availableType.value"
                        :value="availableType.value"
                >
                    {{availableType.label}}
                </option>
            </select>
        </label>

        <label v-if="availableStatus.length > 0">
            Status Type
            <select name="status"
                    id="status-select"
                    @change="onChangeStatus"
                    v-model="selectedStatus"
            >
                <option v-for="availableStatus in availableStatus"
                        :key="availableStatus.value"
                        :value="availableStatus.value"
                >
                    {{ availableStatus.label }}
                </option>
            </select>
        </label>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import wootConstants from '../constants';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
export default {
    mixins: [alertMixin, configMixin, uiSettingsMixin],
    name: "TypeAndStatusSettings",
    data: function () {
        return {
            selectedType: "",
            selectedStatus: "",
            availableTypes: [],
            availableStatus: []
        }
    },
    computed: {
      ...mapGetters({
        uiSettings: 'getUISettings',
      }),
    },
    watch: {
      uiSettings(value) {
        const { default_assignee_type: chatType = wootConstants.ASSIGNEE_TYPE.ME } = value;
        this.selectedType = chatType;
        const { default_status_type: chatStatus = wootConstants.STATUS_TYPE.ALL } = value;
        this.selectedStatus = chatStatus;
      },
    },
    async mounted() {
        navigator.mediaDevices.addEventListener('devicechange', this.onMounted);
        const { default_assignee_type: chatType = wootConstants.ASSIGNEE_TYPE.ME } = this.uiSettings;
        this.selectedType = chatType;
        const { default_status_type: chatStatus = wootConstants.STATUS_TYPE.ALL } = this.uiSettings;
        this.selectedStatus = chatStatus;
        await this.onMounted();
    },
    methods: {
        onChangeType(e) {
            if (window.localStorage) {
                window.localStorage.setItem("selectedType", e.target.value);
            }
            this.updateUISettings({
              default_assignee_type: this.selectedType,
            });
            this.showAlert(this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS'));
        },

        onChangeStatus(e) {
            if (window.localStorage) {
                window.localStorage.setItem("selectedStatus", e.target.value);
                this.updateUISettings({
                  default_status_type: this.selectedStatus,
                });
                this.showAlert(this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS'));
            }
        },

        async onMounted() {
            const availableTypes = [];
            const availableStatus = [];

            availableStatus.push({value: wootConstants.STATUS_TYPE.OPEN, label: wootConstants.STATUS_TYPE.OPEN.charAt(0).toUpperCase() + wootConstants.STATUS_TYPE.OPEN.slice(1)});
            availableStatus.push({value: wootConstants.STATUS_TYPE.RESOLVED, label: wootConstants.STATUS_TYPE.RESOLVED.charAt(0).toUpperCase() + wootConstants.STATUS_TYPE.RESOLVED.slice(1)});
            availableStatus.push({value: wootConstants.STATUS_TYPE.PENDING, label: wootConstants.STATUS_TYPE.PENDING.charAt(0).toUpperCase() + wootConstants.STATUS_TYPE.PENDING.slice(1)});
            availableStatus.push({value: wootConstants.STATUS_TYPE.SNOOZED, label: wootConstants.STATUS_TYPE.SNOOZED.charAt(0).toUpperCase() + wootConstants.STATUS_TYPE.SNOOZED.slice(1)});
            availableStatus.push({value: wootConstants.STATUS_TYPE.ALL, label: wootConstants.STATUS_TYPE.ALL.charAt(0).toUpperCase() + wootConstants.STATUS_TYPE.ALL.slice(1)});

            availableTypes.push({value: wootConstants.ASSIGNEE_TYPE.ME, label: wootConstants.ASSIGNEE_TYPE.ME.charAt(0).toUpperCase() + wootConstants.ASSIGNEE_TYPE.ME.slice(1)});
            availableTypes.push({value: wootConstants.ASSIGNEE_TYPE.UNASSIGNED, label: wootConstants.ASSIGNEE_TYPE.UNASSIGNED.charAt(0).toUpperCase() + wootConstants.ASSIGNEE_TYPE.UNASSIGNED.slice(1)});
            availableTypes.push({value: wootConstants.ASSIGNEE_TYPE.ALL, label: wootConstants.ASSIGNEE_TYPE.ALL.charAt(0).toUpperCase() + wootConstants.ASSIGNEE_TYPE.ALL.slice(1)});
            availableTypes.push({value: wootConstants.ASSIGNEE_TYPE.PRIVATE, label: wootConstants.ASSIGNEE_TYPE.PRIVATE.charAt(0).toUpperCase() + wootConstants.ASSIGNEE_TYPE.PRIVATE.slice(1)});
            availableTypes.push({value: wootConstants.ASSIGNEE_TYPE.GROUP, label: wootConstants.ASSIGNEE_TYPE.GROUP.charAt(0).toUpperCase() + wootConstants.ASSIGNEE_TYPE.GROUP.slice(1)});

            this.availableTypes = availableTypes;
            this.availableStatus = availableStatus;

            if (window.localStorage) {
                const selectedType = window.localStorage.getItem("selectedType");
                const selectedStatus = window.localStorage.getItem("selectedStatus");

                if (selectedType) {
                    const isAvailableTypesIncludeSelected = availableTypes.find((item) => {return item.value === selectedType});

                    this.selectedType = isAvailableTypesIncludeSelected ? selectedType : (availableTypes.length > 0 ? availableTypes[0].value : "");

                } else {
                    this.selectedType = availableTypes.length > 0 ? availableTypes[0].value : "";
                }

                if (selectedStatus) {
                    const isAvailableStatusIncludeSelected = availableStatus.find((item) => {return item.value === selectedStatus});

                    this.selectedStatus = isAvailableStatusIncludeSelected ? selectedStatus : (availableStatus.length > 0 ? availableStatus[0].value : "");
                } else {
                    this.selectedStatus = availableStatus.length > 0 ? availableStatus[0].value : "";
                }
            } else {
                this.selectedType = availableTypes.length > 0 ? availableTypes[0].value : "";
                this.selectedStatus = availableStatus.length > 0 ? availableStatus[0].value : "";
            }
        }
    }
}
</script>

<style scoped>
#type-select {
    cursor: pointer;
}

#status-select {
    cursor: pointer;
}
</style>
