<template>
    <div>
        <transition name="fade">
            <div class="incoming-call-wrapper"
                 v-if="!isEstablished && isIncoming && !isEnding"
            >
                <div class="incoming-call-box">
                    <div class="incoming-call-box__header">
                        Incoming Call
                    </div>


                    <div class="incoming-call-box__from">
                        <div class="incoming-call-box__from-inside">
                            {{ from }}
                        </div>
                        <div class="incoming-call-box__inbox-name">
                            {{inboxName}}
                        </div>
                    </div>

                    <div class="incoming-call-box__actions">
                        <div class="incoming-call-box__button incoming-call-box__accept-button"
                             @click="answer"
                        >
                            <img src="~dashboard/assets/images/phone.svg" alt="Accept" width="32px" height="32px">
                        </div>

                        <div class="incoming-call-box__button incoming-call-box__decline-button"
                             @click="terminate"
                        >
                            <img src="~dashboard/assets/images/phone-hangup.svg" alt="Decline" width="32px" height="32px">
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="fade">
            <div :class="`sip-endpoint__controls-wrapper ${isControlsHidden && !isMaximizedState ? 'sip-endpoint__controls-wrapper--hidden' : ''} ${isMaximizedState ? 'sip-endpoint__controls-wrapper--maximized' : ''}`"
                 v-if="(isEstablished || isOutgoing) && currentContact && availabilityStatus"
            >
                <div class="sip-endpoint-dialog__established-controls">

                    <div class="established-controls__from">
                        {{isIncoming ? from : to}}
                    </div>

                    <div class="established-controls__time">
                        {{new Date(this.seconds * 1000).toISOString().substr(11, 8)}}
                    </div>

                    <div class="established-controls__buttons-wrapper">
                        <div :class="`established-controls__mute-icon-wrapper ${isAudioMuted ? 'established-controls__mute-icon-wrapper--active' : ''}`"
                             @click="toggleAudioMute"
                        >
              <img :src="isAudioMuted ? require(`../../assets/images/microphone-off.svg`) : require(`../../assets/images/microphone.svg`)" alt="Microphone Icon" width="32" height="32">
                        </div>

                        <div :class="`established-controls__mute-icon-wrapper ${(isVideoMuted || !isEstablished) ? 'established-controls__mute-icon-wrapper--active' : ''}`"
                             @click="toggleVideoMute"
                        >
                            <img :src="(isVideoMuted || !isEstablished) ? require(`../../assets/images/video-off.svg`) : require(`../../assets/images/video.svg`)"
                                 alt="Video Icon" width="32" height="32"
                                 v-if="!isScreenShare"
                            >

                            <img :src="(isVideoMuted || !isEstablished) ? require(`../../assets/images/monitor-off.svg`) : require(`../../assets/images/monitor-share.svg`)"
                                 alt="Screen share Icon" width="32" height="32"
                                 v-if="isScreenShare"
                            >
                        </div>

                        <div class="established-controls__decline-icon-wrapper"
                             @click="terminate"
                        >
                            <img src="~dashboard/assets/images/phone-hangup.svg" alt="Decline" width="32px" height="32px">
                        </div>

                    </div>
                </div>

                <div :class="`sip-endpoint__hide-controls-button ${isControlsHidden ? 'sip-endpoint__hide-controls-button--hidden' : ''} ${isMaximizedState ? 'sip-endpoint__hide-controls-button--disabled' : ''}`"
                     @click="isControlsHiddenToggle"
                >
                    <img src="~dashboard/assets/images/triangle.svg"
                         alt="hide icon"
                         class="hide-image"
                         :style="!isControlsHidden ? 'transform: rotate(180deg)' : ''"
                    >
                </div>
            </div>
        </transition>

        <audio id="remote-audio"
               autoplay="autoplay"
        >
        </audio>


        <transition name="fade">
            <div :class="`${isMaximizedState ? 'self-video-wrapper--maximized' : ''} ${isSelfNormalState ? 'self-video-wrapper--normal' : ''} ${isSelfMinimizedState ? 'self-video-wrapper--minimized' : ''}`"
                 v-show="!isVideoMuted"
                 :style="!isMaximizedState && isInnerSelf ? {bottom: `20px`, right: `20px`} : !isMaximizedState ? {top: `${selfVideoTop}px`, left: `${selfVideoLeft}px`} : {}"
                 @mousedown="moveSelfVideo"
            >
                <video id="self-view"
                       :class="`sip-endpoint__self-view ${isSelfMinimizedState ? 'sip-endpoint__self-view--minimized' : ''}`"
                       :style="isSelfNormalState ? {height: '150px'} : isSelfMinimizedState ? {display: 'none'} : {}"
                       autoplay="autoplay"
                       muted="muted"
                       playsinline="playsinline"
                >
                </video>
                <div class="self-video__actions"
                     :style="isSelfMinimizedState || isSelfNormalState ? {justifyContent: 'flex-end'} : {}"
                >
                    <div class="self-video__action"
                         :style="isSelfMinimizedState ? {display: 'none'} : {}"
                         @click="minimizeSelfVideo"
                    >
                        <img src="~dashboard/assets/images/window-minimize.svg" alt="minimize">
                    </div>

                    <div class="self-video__action"
                         :style="isSelfNormalState ? {display: 'none'} : {}"
                         @click="toNormalSelfVideo"
                    >
                        <img src="~dashboard/assets/images/window-maximize.svg" alt="maximize">
                    </div>
                </div>
            </div>
        </transition>


<!--        <transition name="fade">-->
            <div :class="`${isNormalState ? 'remote-video-wrapper--normal' : ''} ${isMinimizedState ? 'remote-video-wrapper--minimized' : ''} ${isMaximizedState ? 'remote-video-wrapper--maximized' : ''}`"
                 v-show="!isEnding && remoteStream"
                 :style="!isMaximizedState && isInnerRemote ? {top: `40px`, left: `25%`} : !isMaximizedState ? {top: `${remoteVideoTop}px`, left: `${remoteVideoLeft}px`} : {}"
                 @mousedown="moveRemoteVideo"
            >
                <video id="remote-view"
                       :class="`sip-endpoint__remote-view ${isMinimizedState ? 'sip-endpoint__remote-view--minimized' : ''} ${isMaximizedState ? 'sip-endpoint__remote-view--maximized' : ''}`"
                       :style="isMaximizedState ? {width: `${innerWidth}px`, height: `${innerHeight}px`} : isNormalState ? {height: '480px'} : isMinimizedState ? {display: 'none'} : {}"
                       autoplay="autoplay"
                       muted="muted"
                       playsinline="playsinline"
                >
                </video>

                <div class="remote-video__actions"
                     :style="isMinimizedState ? {justifyContent: 'flex-end'} : {}"
                >
                    <div class="remote-video__action"
                         :style="isMinimizedState ? {display: 'none'} : {}"
                         @click="minimizeRemoteVideo"
                    >
                        <img src="~dashboard/assets/images/window-minimize.svg" alt="minimize">
                    </div>

                    <div class="remote-video__action"
                         @click="maximizedRemoteVideo"
                    >
                        <img src="~dashboard/assets/images/window-maximize.svg" alt="maximize">
                    </div>
                </div>

                <div :class="`sip-endpoint__remote-view-off ${isMinimizedState ? 'sip-endpoint__remote-view-off--minimized' : ''} ${isMaximizedState ? 'sip-endpoint__remote-view-off--maximized' : ''}`"
                     :style="isAnyoneOnCamera ? {display : 'none'} : isMaximizedState ? {width: `${innerWidth}px`, height: `${innerHeight}px`} : isNormalState ? {height: '480px'} : isMinimizedState ? {display: 'none'} : {}">
                  <img src="~dashboard/assets/images/account.svg"  alt="video-off" :style="isAnyoneOnCamera ? {display : 'none'} : {}">
                    {{isIncoming ? from : to}}
                </div>
            </div>
<!--        </transition>-->

    </div>
</template>
<script>
import adapter from 'webrtc-adapter';
import {Relay} from '@signalwire/js'
import ConversationApi from '../../api/inbox/conversation';
import {sipEndpointAPI} from "../../api/SipEndpointAPI";
import {ringtoneBase64} from "./ringtoneBase64";
import {beepsBase64} from "./beepsBase64";
import {mapGetters} from "vuex";
import {callsAPI} from "../../api/callsAPI";
import {actionTypes} from "./constants/actionTypes";
import WootButton from "../ui/WootButton";
import {frontendURL} from "../../helper/URLHelper";
import {callStatusTypes} from "./constants/callStatusTypes";
import {remoteVideoStateTypes} from "./constants/remoteVideoStateTypes";
import {selfVideoStateTypes} from "./constants/selfVideoStateTypes";
import alertMixin from "../../../shared/mixins/alertMixin";

export default {
    mixins: [alertMixin],
    name: "TheRelaySIPEndpoint",
    props: {},
    components: {
        WootButton
    },
    data() {
        return {
            clients: [],
            call: null,

            from: null,
            to: null,

            ringtone: null,
            beeps: null,

            intervalId: null,
            seconds: 0,

            callId: null,
            callSID: null,

            isIncoming: false,
            isOutgoing: false,

            callStatusType: null,

            isRegistered: false,

            inboxId: null,
            conversationId: null,

            isAudioMuted: false,
            isVideoMuted: true,

            localStream: null,
            remoteStream: null,

            remoteVideoState: null,
            selfVideoState: null,

            innerHeight: 0,
            innerWidth: 0,

            isScreenShare: false,
            isEnding: false,
            isControlsHidden: false,

            availableCameras: [],
            availableMicrophones: [],

            contactId: 0,
            contactSipUsername: "",

            selectedCamera: "",
            selectedMicrophone: "",

            inboxName: "",

            isInnerSelf: true,
            isInnerRemote: true,
            innerVideoTop: 0,
            innerVideoLeft: 0,
            remoteVideoTop: 45,
            remoteVideoLeft: 0,
            selfVideoTop: 0,
            selfVideoLeft: 0,
            activeRemote:false,
            activeSelf:false
        }
    },
    async mounted() {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;

        window.addEventListener('mousemove', event=>{
          if(this.activeRemote){
            if(this.isInnerRemote){
              this.remoteVideoLeft = this.innerWidth/4 + event.clientX - this.innerVideoLeft;
              this.remoteVideoTop = this.remoteVideoTop + (event.clientY - this.innerVideoTop);
              this.innerVideoLeft = event.clientX;
              this.innerVideoTop = event.clientY;
              this.isInnerRemote = false;
            }
            else{
              this.remoteVideoLeft = this.remoteVideoLeft + event.clientX - this.innerVideoLeft;
              this.remoteVideoTop = this.remoteVideoTop + event.clientY - this.innerVideoTop;
              this.innerVideoLeft = event.clientX;
              this.innerVideoTop = event.clientY;
            }
          }
          if(this.activeSelf){
            if(this.isInnerSelf){
              this.selfVideoLeft = this.innerWidth - 220 + (event.clientX - this.innerVideoLeft);
              this.selfVideoTop = this.innerHeight - 170 + (event.clientY - this.innerVideoTop);
              this.innerVideoLeft = event.clientX;
              this.innerVideoTop = event.clientY;
              this.isInnerSelf = false;
            }
            else{
              this.selfVideoLeft = this.selfVideoLeft + (event.clientX - this.innerVideoLeft);
              this.selfVideoTop = this.selfVideoTop + (event.clientY - this.innerVideoTop);
              this.innerVideoLeft = event.clientX;
              this.innerVideoTop = event.clientY;
            }
          }
        });
        window.addEventListener('mouseup', event=>{
          this.activeRemote = false;
          this.activeSelf = false;
        });

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });

        await this.setMediaDevices();

        navigator.mediaDevices.addEventListener('devicechange', this.setMediaDevices);


        this.ringtone = new Audio(ringtoneBase64);
        this.ringtone.loop = true;
        this.beeps = new Audio(beepsBase64);
        this.beeps.loop = true;

        const response = await sipEndpointAPI.getSipEndpoints(this.accountId);
        const sipEndpoints = response.data.sip_endpoints;
        const PROJECT_ID = "d5ed93de-1616-4886-9f61-3eb0323c3150";

        for (const sipEndpoint of sipEndpoints) {
            const response = await sipEndpointAPI.getToken(`${sipEndpoint.username}`);

            const JWT_TOKEN = response.data.jwt_token;
            const client = new Relay({project: PROJECT_ID, token: JWT_TOKEN});
            client.sip_username = `${sipEndpoint.username}_RELAY`;
            client.my_refresh_token = response.data.refresh_token;

            client.on('signalwire.ready', this.onSignalwireReady);
            client.on('signalwire.error', this.onSignalwireError);
            client.on('signalwire.notification', async (e) => {

                console.log("_________signalwire.notification____________");
                console.log("\n");

                switch (e.type) {
                    case 'callUpdate':
                        console.log("\n");
                        console.log(e);
                        console.log("\n");

                        await this.onCallUpdate(e);

                        break;
                    case 'userMediaError':
                        this.onUserMediaError(e);

                        break;
                    case "refreshToken":
                        await this.onRefreshToken(client, e);

                        break;
                    case "participantData":
                        this.onParticipantData(e);

                        break;
                }

                console.log("_________signalwire.notification____________");
                console.log("\n");
            });

            client.on('signalwire.socket.open', this.onSignalwireSocketOpen);
            client.on('signalwire.socket.error', this.onSignalwireSocketError);
            client.on('signalwire.socket.message', this.onSignalwireSocketMessage);
            client.on('signalwire.socket.close', this.onSignalwireSocketClose);

            client.localElement = "self-view";
            client.remoteElement = "remote-view";

            client.enableMicrophone();
            client.enableWebcam();

            client.iceServers = [{
                urls: "turn:turn.conversate.us:5349",
                username: "conversate",
                credential: "2261f857"
            }];

            await client.connect();
            this.clients.push(client);
        }
    },
    watch: {
        currentAction: function (action) {
            if (action) {
                if (action.type === actionTypes.SIP_ENDPOINT_CALL && this.checkBalance()) {
                    console.log(action);

                    this.inboxId = action.inboxId;
                    this.conversationId = action.conversationId;

                    this.to = `${action.contactSipUsername}_RELAY`;
                    this.from = `${action.agentSipUsername}_RELAY`;
                    this.contactId = action.contactId

                    this.makeCall(`${action.contactSipUsername}_RELAY`, `${action.agentSipUsername}_RELAY`, false);

                    this.$store.commit("sipEndpoint/SET_CURRENT_ACTION", null);
                }

                if (action.type === actionTypes.SIP_ENDPOINT_CALL_SCREEN_SHARE && this.checkBalance()) {
                    console.log(action);

                    this.inboxId = action.inboxId;
                    this.conversationId = action.conversationId;
                    this.to = `${action.contactSipUsername}_RELAY`;
                    this.from = `${action.agentSipUsername}_RELAY`;
                    this.contactId = action.contactId

                    this.makeCall(`${action.contactSipUsername}_RELAY`, `${action.agentSipUsername}_RELAY`, true);

                    this.$store.commit("sipEndpoint/SET_CURRENT_ACTION", null);
                }
            }
        },
        refreshDevicesRelay: async function (value) {
            if (value) {
                await this.setMediaDevices();

                this.$store.commit("sipEndpoint/SET_REFRESH_DEVICES_RELAY", false);
            }
        }
    },
    computed: {
        ...mapGetters({
            accountId: 'getCurrentAccountId',
            agentId: 'getCurrentUserID',
            currentAction: 'sipEndpoint/getCurrentAction',
            currentChat: 'getSelectedChat',
            currentUser: 'getCurrentUser',
            isSession: "sipEndpoint/getIsSession",
            refreshDevicesRelay: "sipEndpoint/getRefreshDevicesRelay",
            getAccount: 'accounts/getAccount',
        }),
        availabilityStatus() {
            if(this.currentContact.availability_status != "offline"){
              return true;
            }
            else{
              this.terminate();
              callsAPI.updateAgent({
                  agent_id: this.agentId,
                  on_call: false
              });
              return false;
            }
        },
        cameraOnUsersList() {
          const userList = this.$store.getters[
            'signalwireCameraStatus/getUserList'
          ](this.conversationId);
          return userList;
        },
        isAnyoneOnCamera() {
          if(this.isEstablished){
            const userList = this.cameraOnUsersList;
            return userList.length !== 0;
          }
        },

        currentContact() {
          if(this.contactId){
            return this.$store.getters['contacts/getContact'](
                this.contactId
            );
          }
          else{
            this.terminate();
            callsAPI.updateAgent({
                agent_id: this.agentId,
                on_call: false
            });
            return {};
          }
        },
        isInProgress() {
            if (this.call) {
                return ((this.call.state === "trying") || (this.call.state === "ringing"));
            }
            return false;
        },
        isEstablished() {
            if (this.call) {
                return this.call.state === "active";
            }
            return false;
        },
        dashboardPath() {
            return frontendURL(`accounts/${this.accountId}/dashboard`);
        },
        isSelfNormalState() {
          return this.selfVideoState === selfVideoStateTypes.NORMAL;
        },
        isSelfMinimizedState() {
            return this.selfVideoState === selfVideoStateTypes.MINIMIZED;
        },
        isNormalState() {
          return this.remoteVideoState === remoteVideoStateTypes.NORMAL;
        },
        isMinimizedState() {
            return this.remoteVideoState === remoteVideoStateTypes.MINIMIZED;
        },
        isMaximizedState() {
            return this.remoteVideoState === remoteVideoStateTypes.MAXIMIZED;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);

        for (const client of this.clients) {
            client.disconnect();
        }
    },
    methods: {
        onCameraOn() {
          this.toggleCamera('on');
        },
        onCameraOff() {
          this.toggleCamera('off');
        },
        toggleCamera(status) {
          const conversationId = this.conversationId;
          this.$store.dispatch('signalwireCameraStatus/toggleCamera', {
            status,
            conversationId,
          });
        },
        checkBalance() {
            let account = this.getAccount(this.accountId);
            let balance = account ? parseFloat(account.credit_balance) : 0;

            if (balance <= 0) {
                this.showAlert("Please add credits to your balance to make calls");

                return false;
            }  else {
                return true;
            }
        },
        onSignalwireReady(e) {
            console.log("_________signalwire.ready____________");
            console.log(e);
            console.log("_________signalwire.ready____________");
            console.log("\n");

            this.$store.commit("sipEndpoint/SET_IS_RELAY_REGISTERED", true);
            this.isRegistered = true;
        },
        onSignalwireError(e) {
            console.log("_________signalwire.error____________");
            console.log(e);
            console.log("_________signalwire.error____________");
            console.log("\n");
        },
        onSignalwireSocketOpen(e) {
            console.log("_________signalwire.socket.open____________");
            console.log(e);
            console.log("_________signalwire.socket.open____________");
            console.log("\n");
        },
        onSignalwireSocketError(e) {
            console.log("_________signalwire.socket.error____________");
            console.log(e);
            console.log("_________signalwire.socket.error____________");
            console.log("\n");
        },
        onSignalwireSocketMessage(e) {
            console.log("_________signalwire.socket.message____________");
            console.log(e);
            console.log("_________signalwire.socket.message____________");
            console.log("\n");
        },
        onSignalwireSocketClose(e) {
            console.log("_________signalwire.socket.close____________");
            console.log(e);
            console.log("_________signalwire.socket.close____________");
            console.log("\n");
        },
        async onCallUpdate(e) {
            console.log("_________Call Update____________");
            switch (e.call.state) {
                case 'new':
                    console.log("_____call.new_____");

                    if (this.call || this.isSession) {
                        break;
                    }

                    this.$store.commit("sipEndpoint/SET_IS_SESSION", true);

                    this.call = e.call;

                    this.callStatusType = callStatusTypes.IN_PROGRESS;

                    callsAPI.updateAgent({
                        agent_id: this.agentId,
                        on_call: true
                    });

                    break;
                case 'trying': // You are trying to call someone and he's ringing now

                    console.log("_____call.trying_____");
                    this.beeps.play();

                    this.call = e.call;
                    this.isOutgoing = true;
                    this.callSID = this.call.id;

                    const response = await callsAPI.createCallWebWidget({
                        from: this.from,
                        to: this.to,
                        source_id: this.callSID,
                        status: callStatusTypes.IN_PROGRESS,
                        agent_id: this.agentId,
                        inbox_id: this.inboxId,
                        call_type: "outgoing",
                        conversation_id: this.conversationId
                    });
                    this.callId = response.data.id;
                    this.contactSipUsername = this.to.slice(0, -6);
                    try{
                      const response = await ConversationApi.conversationIdBySipUsername({sip_username: this.contactSipUsername});
                      this.conversationId = response.data.conversation_id;
                      this.contactId = response.data.contact_id;
                      const currentContact = this.$store.getters['contacts/getContact'](
                          this.contactId
                      );
                      this.to = currentContact.name;
                    }
                    catch(e){
                      console.log(e);
                    }
                    this.callId = response.data.id;

                    break;
                case 'requesting':
                    console.log("_____call.requesting_____");

                    this.call = e.call;

                    break;
                case 'recovering':
                    console.log("_____call.recovering_____");

                    this.call = e.call;

                    break;
                case 'ringing': // Someone is calling you
                    console.log("_____call.ringing_____");
                    if (this.call.id !== e.call.id) {
                        break;
                    }

                    this.from = this.call.options.remoteCallerNumber;
                    this.contactSipUsername = this.call.options.remoteCallerNumber.slice(0, -6);
                    try{
                      const response = await ConversationApi.conversationIdBySipUsername({sip_username: this.contactSipUsername});
                      this.conversationId = response.data.conversation_id;
                      this.contactId = response.data.contact_id;
                      const currentContact = this.$store.getters['contacts/getContact'](
                          this.contactId
                      );
                      this.from = currentContact.name;
                    }
                    catch(e){
                      console.log(e);
                    }
                    this.inboxName = this.currentUser.sip_endpoints.find((sip_endpoint) => {return sip_endpoint.username == this.call.options.callerNumber.slice(0, -6)}).inbox_name;
                    this.to = this.call.options.callerNumber;

                    this.call = e.call;
                    this.isIncoming = true;

                    this.ringtone.play();
                    if (this.$route.path !== this.dashboardPath) this.$router.push(this.dashboardPath)

                    break;
                case 'answering':
                    console.log("_____call.answering_____");

                    this.call = e.call;

                    break;
                case 'early':
                    console.log("_____call.early_____");

                    if (this.call.id !== e.call.id) {
                        break;
                    }

                    this.call = e.call;
                    break;
                case 'active': // Call has become active
                    console.log("_____call.active_____");
                    this.beeps.pause();

                    this.call = e.call;

                    this.localStream = e.call.localStream;
                    this.remoteStream = e.call.remoteStream;

                    this.remoteVideoState = remoteVideoStateTypes.NORMAL;
                    this.selfVideoState = selfVideoStateTypes.NORMAL;

                    this.intervalId = setInterval(this.updateSeconds, 1000);
                    if (this.isScreenShare) {
                      this.onCameraOn();
                      this.isVideoMuted = false;
                    } else {
                      this.call.muteVideo();
                    }



                    if (this.call.remoteStream) {
                        const remoteAudio = document.getElementById("remote-audio");
                        remoteAudio.srcObject = null;
                        remoteAudio.srcObject = this.call.remoteStream;
                    }

                    if (this.isOutgoing) {
                        this.updateCallWebWidget({
                            status: callStatusTypes.ANSWERED
                        });
                    }

                    break;
                case 'held':
                    console.log("_____call.held_____");

                    this.call = e.call;


                    break;
                case 'hangup': // Call is over
                    this.isEnding = true;
                    console.log("_____call.hangup_____");


                    if (this.call.id !== e.call.id) {
                        break;
                    }

                    this.call = e.call;

                    break;
                case 'destroy': // Call has been destroyed
                    this.isEnding = true;

                    console.log("_____call.destroy_____");


                    if (this.call.id !== e.call.id) {
                        break;
                    }

                    this.call = e.call;
                    this.callStatusType = callStatusTypes.COMPLETED;

                    if (this.isOutgoing) {
                        try {
                            await this.updateCallWebWidget({
                                status: callStatusTypes.COMPLETED,
                                duration: this.seconds
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    }

                    callsAPI.updateAgent({
                        agent_id: this.agentId,
                        on_call: false
                    });

                    this.cleanUp();
                    break;


                case 'purge':
                    console.log("_____call.purge_____");
                    this.call = e.call;
                    break;
            }

            console.log("_________Call Update____________");
            console.log("\n");
        },
        onUserMediaError(e) {
            console.log("_________User Media Error____________");
            console.log(e);
            console.log("_________User Media Error____________");
            console.log("\n");
        },
        async onRefreshToken(client, e) {
            console.log("_________Refresh Token____________");
            console.log(e);
            console.log("_________Refresh Token____________");
            console.log("\n");

            const response = await sipEndpointAPI.refreshToken(client.my_refresh_token);
            await client.refreshToken(response.data.jwt_token);

            client.my_refresh_token = response.data.refresh_token;
        },
        onParticipantData(e) {
            console.log("__________participant Data___________");
            console.log(e);
            console.log("__________participant Data___________");
            console.log("\n");

            this.call = e.call;
        },
        onSignalwireNotification(e) {
            console.log("_________signalwire.notification____________");
            console.log("\n");
            console.log(e);
            console.log("\n");

            switch (e.type) {
                case 'callUpdate':
                    this.onCallUpdate(e);
                    break;
                case 'userMediaError':
                    this.onUserMediaError(e);
                    break;
                case "refreshToken":
                    this.onRefreshToken(e);
                    break;
                case "participantData":
                    this.onParticipantData(e);
                    break;
            }

            console.log("_________signalwire.notification____________");
            console.log("\n");
        },
        cleanUp() {
            this.ringtone.pause();
            this.beeps.pause();
            this.$store.commit("sipEndpoint/SET_IS_SESSION", false);
            this.$store.dispatch("signalwireCameraStatus/destroyAll");
            this.contactId = 0;
            this.contactSipUsername = "";
            this.call = null;
            this.inboxId = null;

            this.from = null;
            this.to = null;

            this.seconds = 0;

            this.isIncoming = false;
            this.isOutgoing = false;

            if (this.intervalId) {
                clearInterval(this.intervalId);
            }

            this.intervalId = null;

            this.callStatusType = null;
            this.callId = null;
            this.callSID = null;

            this.conversationId = null;

            this.isAudioMuted = false;
            this.isVideoMuted = true;

            this.localStream = null;
            this.remoteStream = null;
            this.remoteVideoState = null;
            this.selfVideoState =  null;
            this.isControlsHidden = false;

            this.isScreenShare = false;

            this.isEnding = false;


            this.remoteVideoTop = 45;
            this.remoteVideoLeft = this.innerWidth/4;
            this.selfVideoTop = this.innerHeight - 170;
            this.selfVideoLeft = this.innerWidth - 220;

            const remoteAudio = document.getElementById("remote-audio");
            const remoteVideo = document.getElementById("remote-view");
            const selfVideo = document.getElementById("self-view");

            remoteAudio.srcObject = null;
            remoteVideo.srcObject = null;
            selfVideo.srcObject = null;
        },
        startIceGatheringTimer(session){
      			let timeoutFn = null;
      			let sdp = false;

      			session.on('icecandidate', (icecandidatedata) =>
      			{
      				if (!timeoutFn)
      				{
      					setTimeout(timeoutFn = function()
      					{
      						if (!sdp)
      						{
                    console.log("GatheringTimer completed every 5 seconds");
      							icecandidatedata.ready();
      						}
      					}, 5000);
      				}
      			});

      			session.on('sdp', (sdpdata) =>
      			{
              console.log("GatherTimer " + sdpdata.originator);
      				if (sdpdata.originator == 'local')
      				{
      					sdp = true;
      				}
      			});
      	},
        async answer() {
            if (this.call) {
                for (const client of this.clients) {
                  this.startIceGatheringTimer(client.connection.session);
                }
                this.call.answer();
                this.callStatusType = callStatusTypes.ANSWERED;
            }
            this.ringtone.pause();
        },
        terminate() {
            this.isEnding = true;

            if (this.call) {
                this.call.hangup();
            }
            this.beeps.pause();
            this.ringtone.pause();
        },
        muteAudio() {
            if (this.call) {
                this.call.muteAudio();
                this.isAudioMuted = true;
            }
        },
        unmuteAudio() {
            if (this.call) {
                this.call.unmuteAudio();
                this.isAudioMuted = false;
            }
        },
        muteVideo() {
            if (this.call) {
                this.call.muteVideo();
                this.isVideoMuted = true;
            }
        },
        unmuteVideo() {
            if (this.call) {
                this.call.unmuteVideo();
                this.isVideoMuted = false;
            }
        },
        toggleVideoMute() {
            if (this.isEstablished) {
                if (this.isVideoMuted) {
                    this.onCameraOn();
                    this.unmuteVideo();
                } else {
                    this.onCameraOff();
                    this.muteVideo();
                }
            }
        },
        toggleAudioMute() {
            if (this.isAudioMuted) {
                this.unmuteAudio();
            } else {
                this.muteAudio();
            }
        },
        updateSeconds() {
            this.seconds = this.seconds + 1;
        },
        async updateCallWebWidget(payload) {
            if(this.callId){
              const response = await callsAPI.updateCallWebWidget(this.callId, payload);
            }
        },
        async makeCall(destination, userAgentSipEndpoint, isScreenShare) {
            const client = this.clients.find((client) => {
                return client.sip_username === userAgentSipEndpoint;
            });
            if (client) {
                this.from = client.sip_username;
                this.startIceGatheringTimer(client.connection.session);
                if (isScreenShare) {
                    this.localStream = await navigator.mediaDevices.getDisplayMedia({
                        audio: true,
                        video: true
                    })
                    const audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
                    this.localStream.addTrack(audioStream.getAudioTracks()[0]);

                    this.isScreenShare = true;

                    this.call = await client.newCall({
                        localStream: this.localStream,
                        destinationNumber: destination,
                        audio: true,
                        video: true,
                    });
                } else {
                    this.call = await client.newCall({
                        destinationNumber: destination,
                        audio: true,
                        video: true,
                    })
                }
            }
        },

        minimizeSelfVideo(){
            this.selfVideoState = selfVideoStateTypes.MINIMIZED;
        },
        toNormalSelfVideo() {
            this.selfVideoState = selfVideoStateTypes.NORMAL;
        },
        minimizeRemoteVideo(){
            this.remoteVideoState = remoteVideoStateTypes.MINIMIZED;
        },
        toNormalRemoteVideo() {
            this.remoteVideoState = remoteVideoStateTypes.NORMAL;
        },
        maximizedRemoteVideo() {
            if (this.isMaximizedState || this.isMinimizedState) {
                this.remoteVideoState = remoteVideoStateTypes.NORMAL;
            } else {
                this.remoteVideoState = remoteVideoStateTypes.MAXIMIZED;
            }
        },

        onResize() {
            this.innerHeight = window.innerHeight
            this.innerWidth = window.innerWidth;
        },

        isControlsHiddenToggle() {
            this.isControlsHidden = !this.isControlsHidden;
        },

        async setMediaDevices() {
            let mediaDevicesInfo = null;

            try {
                mediaDevicesInfo = await navigator.mediaDevices.enumerateDevices();
            } catch (e) {
                console.log(e);
            }

            const availableCameras = [];
            const availableMicrophones = [];

            for (const mediaInfo of mediaDevicesInfo) {
                if (mediaInfo.kind === "videoinput") {
                    availableCameras.push({value: mediaInfo.deviceId, label: mediaInfo.label});
                }

                if (mediaInfo.kind === "audioinput") {
                    availableMicrophones.push({value: mediaInfo.deviceId, label: mediaInfo.label});
                }
            }

            this.availableCameras = availableCameras;
            this.availableMicrophones = availableMicrophones;

            if (window.localStorage) {
                const selectedCamera = window.localStorage.getItem("selectedCamera");
                const selectedMicrophone = window.localStorage.getItem("selectedMicrophone");

                if (selectedCamera) {
                    const isAvailableCamerasIncludeSelected = availableCameras.find((item) => {return item.value === selectedCamera});

                    this.selectedCamera = isAvailableCamerasIncludeSelected ? selectedCamera : (availableCameras.length > 0 ? availableCameras[0].value : "");

                } else {
                    this.selectedCamera = availableCameras.length > 0 ? availableCameras[0].value : "";
                }

                if (selectedMicrophone) {
                    const isAvailableMicrophoneIncludeSelected = availableMicrophones.find((item) => {return item.value === selectedMicrophone});

                    this.selectedMicrophone = isAvailableMicrophoneIncludeSelected ? selectedMicrophone : (availableMicrophones.length > 0 ? availableMicrophones[0].value : "");
                } else {
                    this.selectedMicrophone = availableMicrophones.length > 0 ? availableMicrophones[0].value : "";
                }
            } else {
                this.selectedCamera = availableCameras.length > 0 ? availableCameras[0].value : "";
                this.selectedMicrophone = availableMicrophones.length > 0 ? availableMicrophones[0].value : "";
            }

            await this.setMediaSettingOnClients();
        },
        getMicrophoneLabelById(deviceId) {
            const selectedMicrophone = this.availableMicrophones.find((mic) => {return mic.value === deviceId});

            return selectedMicrophone ? selectedMicrophone.label : null
        },
        getCameraLabelById(deviceId) {
            const selectedCamera = this.availableCameras.find((cam) => {return cam.value === deviceId});

            return selectedCamera ? selectedCamera.label : null;
        },
        async setMediaSettingOnClients() {
            // for (const client of this.clients) {
            //
            //     await client.setAudioSettings({
            //         deviceId: this.selectedMicrophone,
            //         micId: this.selectedMicrophone,
            //         micLabel: this.getMicrophoneLabelById(this.selectedMicrophone)
            //     });
            //
            //     await client.setVideoSettings({
            //         deviceId: this.selectedCamera,
            //         camId: this.selectedCamera,
            //         camLabel: this.getCameraLabelById(this.selectedCamera)
            //     });
            //
            // }
        },
        moveRemoteVideo(e) {
          if (!this.isMaximazedState){
            this.innerVideoTop = e.clientY;
            this.innerVideoLeft = e.clientX;
            e.preventDefault();
            this.activeRemote = true;
          }
        },
        moveSelfVideo(e) {
          if (!this.isMaximazedState){
            this.innerVideoTop = e.clientY;
            this.innerVideoLeft = e.clientX;
            e.preventDefault();
            this.activeSelf = true;
          }
        }
    }
}
</script>
<style scoped>
.sip-endpoint-dialog__established-controls {
    width: 100%;

    display: flex;

    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.established-controls__mute-icon-wrapper {
    padding: 10px;

    background-color: #40A2F7;

    margin-right: 20px;

    border-radius: 50%;

    cursor: pointer;
}

.established-controls__mute-icon-wrapper:active {
    transform: scale(0.9);
}

.established-controls__mute-icon-wrapper.established-controls__mute-icon-wrapper--active {
    background-color: #ED4948;
}

.established-controls__buttons-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.established-controls__decline-icon-wrapper {
    padding: 5px;

    width: 100px;

    border-radius: 10px;

    display: flex;

    align-items: center;
    align-content: center;
    justify-content: center;

    cursor: pointer;

    background-color: #ED4948;
}

.established-controls__decline-icon-wrapper:active {
    transform: scale(0.9);
}

.established-controls__from {
    margin-right: 20px;

    width: 150px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    text-align: center;
}

.established-controls__time {
    width: 90px;

    margin-right: 20px;
}

.sip-endpoint__self-view {
    width: 200px;

    height: 150px;

    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    background-color: transparent;
}

.sip-endpoint__self-view--minimized {
    width: 200px;

    height: 60px;

    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    background-color: transparent;
}

.self-video-wrapper {
    position: fixed;

    border-radius: 10px;

    width: 200px;
    max-height: 150px;

    background-color: black;

    z-index: 2003;
}

.self-video-wrapper--maximized {
    bottom: 20px;
    right: 20px;
}
.self-video-wrapper--normal {
  position: fixed;

  border-radius: 10px;

  width: 200px;
  height: 150px;

  background-color: black;

  z-index: 2003;
}

.self-video-wrapper--minimized {
  position: fixed;

  border-radius: 10px;

  width: 200px;
  height: 60px;

  background-color: black;

  z-index: 2003;
}

.sip-endpoint__remote-view {
    width: 640px;
    z-index: 2001;
    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    background-color: transparent;
}

.sip-endpoint__remote-view--minimized {
    width: 200px;
    height: 60px;
    z-index: 2001;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.sip-endpoint__remote-view--maximized {
    z-index: 2001;

    border-radius: 0;
}

.sip-endpoint__remote-view-off {
    width: 640px;
    position: absolute;
    border-radius: 10px;
    z-index: 2002;
    display: flex;
    text-align: center;
    flex-direction: column;
    color: white;
    font-size: 20pt;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    background-color: #a5c3c9;
}

.sip-endpoint__remote-view-off--minimized {
    width: 200px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    color: white;
    font-size: 20pt;
    height: 60px;
    z-index: 2002;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    background-color: #a5c3c9;
}

.sip-endpoint__remote-view-off--maximized {
    z-index: 2002;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-size: 20pt;
    background-color: #a5c3c9;
    border-radius: 0;
}

.remote-video-wrapper--normal {
    position: fixed;
    display:flex;
    flex-direction: column;
    border-radius: 10px;

    z-index: 2000;

    background-color: black;

    height: 480px;
    width: 640px;
}

.remote-video-wrapper--minimized {
    position: fixed;
    display:flex;
    flex-direction: column;
    border-radius: 10px;

    width: 200px;
    height: 60px;

    z-index: 2000;

    background-color: black;
}

.remote-video-wrapper--maximized {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display:flex;
    flex-direction: column;
    max-width: 100vw;
    max-height: 100vh;

    background-color: black;

    z-index: 2000;
}


.incoming-call-wrapper {
    position: fixed;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.4);

    z-index: 2000;
}

.incoming-call-box {
    width: 300px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;
    align-items: center;

    border-radius: 10px;
    overflow: hidden;
}

.incoming-call-box__header {
    width: 100%;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    font-size: 20px;

    background-color: #3C3D40;

    padding: 10px 0;

    color: white;
}

.incoming-call-box__from {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;
    align-items: center;

    background-color: #ffffff;

    color: black;

    height: 100px;


}

.incoming-call-box__inbox-name {
  width: 300px;
  padding: 0 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 16px;
  color: #3C3D40;

  text-align: center;
}

.incoming-call-box__from-inside {
    width: 300px;
    padding: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 24px;
    color: #3C3D40;

    text-align: center;
}

.incoming-call-box__actions {
    background-color: #E9EAEF;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 15px;
}

.incoming-call-box__button {
    border-radius: 10px;

    padding: 8px;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    cursor: pointer;
}

.incoming-call-box__button:active {
    transform: scale(0.9);
}


.incoming-call-box__accept-button {
    background-color: #60CE4D;
    width: 45%;
}

.incoming-call-box__decline-button {
    background-color: #ED4948;
    width: 45%;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.fade-remote-video-enter-active, .fade-remote-video-leave-active {
    transition: opacity .5s;
}

.fade-remote-video-enter-active {
    transition-delay: 3s;
}

.fade-remote-video-enter, .fade-remote-video-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.sip-endpoint__controls-wrapper {
    position: fixed;

    bottom: 20px;
    left: calc(50% - 300px);

    width: 600px;

    background-color: #1F2D3D;

    color: white;

    border-radius: 15px;


    font-size: 16px;

    font-weight: 600;

    padding: 15px;

    z-index: 2005;
}

.sip-endpoint__controls-wrapper--hidden {
    bottom: -80px;
}

.remote-video__actions {
    display: none;
    width: 100px;

    justify-content: space-between;
    align-items: center;
    align-content: center;

    position: absolute;

    right: 10px;
    top: 10px;

    cursor: pointer;

    z-index: 2004;
}

.remote-video-wrapper--normal:hover .remote-video__actions{
    display: flex;
}

.remote-video-wrapper--minimized:hover .remote-video__actions{
    display: flex;
}

.remote-video-wrapper--maximized:hover .remote-video__actions{
    display: flex;
}

.remote-video__action {
    padding: 10px;

    border-radius: 50%;

    background-color: rgba(0, 0, 0, 0.2);

    margin-right: 10px;
}

.remote-video__action:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
.self-video__actions {
    display: none;
    width: 100px;

    justify-content: space-between;
    align-items: center;
    align-content: center;

    position: absolute;

    right: 10px;
    top: 10px;

    cursor: pointer;

    z-index: 2003;
}

.self-video-wrapper--normal:hover .self-video__actions{
    display: flex;
}

.self-video-wrapper--minimized:hover .self-video__actions{
    display: flex;
}

.self-video__action {
    padding: 10px;

    border-radius: 50%;

    background-color: rgba(0, 0, 0, 0.2);

    margin-right: 10px;
}

.self-video__action:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.sip-endpoint__controls-wrapper--maximized {
    opacity: 0;

    transition: opacity;

    transition-duration: 1s;
}

.sip-endpoint__controls-wrapper--maximized:hover {
    opacity: 1;
}

.sip-endpoint__hide-controls-button {
    cursor: pointer;

    position: fixed;

    width: 100px;

    bottom: 80px;
    left: calc(50% - 50px);


    padding: 5px 2px 15px;
    background-color: #1F2D3D;
    border-radius: 15px;


    z-index: 5000;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    transition: bottom;
    transition-duration: 0.3s;

}

.sip-endpoint__hide-controls-button--hidden {
    bottom: -13px;
}

.sip-endpoint__hide-controls-button--disabled {
    display: none;
}

.hide-image {
    margin-top: -2px
}

</style>
