import { frontendURL } from '../helper/URLHelper';

import common from './sidebarItems/common';
import contacts from './sidebarItems/contacts';
import reports from './sidebarItems/reports';
import campaigns from './sidebarItems/campaigns';
import settings from './sidebarItems/settings';

export const getSidebarItems = accountId => ({
  common: common(accountId),
  contacts: contacts(accountId),
  reports: reports(accountId),
  campaigns: campaigns(accountId),
  settings: settings(accountId),
});

// export const getSidebarItems = accountId => ({
//   common: {
//     routes: [
//       'home',
//       'inbox_dashboard',
//       'inbox_conversation',
//       'conversation_through_inbox',
//       'credit_history',
//       'contacts_dashboard',
//       'calendars_dashboard',
//       'contacts_dashboard_manage',
//       'notifications_dashboard',
//       'settings_account_reports',
//       'settings_account_conversation_reports',
//       'profile_settings',
//       'profile_settings_index',
//       'label_conversations',
//       'conversations_through_label',
//       'team_conversations',
//       'conversations_through_team',
//       'notifications_index',
//       'transactions_history',
//       'schedule_history',
//     ],
//     menuItems: {
//       assignedToMe: {
//         icon: 'ion-chatbox-working',
//         label: 'CONVERSATIONS',
//         hasSubMenu: false,
//         key: '',
//         toState: frontendURL(`accounts/${accountId}/dashboard`),
//         toolTip: 'Conversation from all subscribed inboxes',
//         toStateName: 'home',
//       },
//       contacts: {
//         icon: 'ion-person',
//         label: 'CONTACTS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/contacts`),
//         toStateName: 'contacts_dashboard',
//       },
//       calendars: {
//         icon: 'ion-android-calendar',
//         label: 'CALENDARS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/calendars`),
//         toStateName: 'calendars_dashboard',
//       },
//       notifications: {
//         icon: 'ion-ios-bell',
//         label: 'NOTIFICATIONS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/notifications`),
//         toStateName: 'notifications_dashboard',
//       },
//       report: {
//         icon: 'ion-arrow-graph-up-right',
//         label: 'REPORTS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/reports`),
//         toStateName: 'settings_account_reports',
//       },
//       conversationReport: {
//         icon: 'ion-arrow-graph-up-right',
//         label: 'CONVERSATION REPORTS ',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/conversation/reports`),
//         toStateName: 'settings_account_conversation_reports',
//       },
//       settings: {
//         icon: 'ion-settings',
//         label: 'SETTINGS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings`),
//         toStateName: 'settings_home',
//       },
//       creditHistory: {
//         icon: 'ion-cash',
//         label: 'CREDIT_HISTORY',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/credit-history`),
//         toStateName: 'credit_history',
//       },
//     },
//   },
//   settings: {
//     routes: [
//       'agent_list',
//       'agent_histories',
//       'canned_list',
//       'labels_list',
//       'codes_list',
//       'settings_inbox',
//       'settings_inbox_new',
//       'settings_inbox_list',
//       'settings_inbox_show',
//       'settings_inboxes_page_channel',
//       'settings_inboxes_add_agents',
//       'settings_inbox_finish',
//       'settings_integrations',
//       'settings_integrations_webhook',
//       'settings_integrations_integration',
//       'settings_applications',
//       'settings_applications_webhook',
//       'settings_applications_integration',
//       'general_settings',
//       'general_settings_index',
//       'settings_teams_list',
//       'settings_teams_new',
//       'settings_teams_add_agents',
//       'settings_teams_finish',
//       'settings_teams_edit',
//       'settings_teams_edit_members',
//       'settings_teams_edit_finish',
//       'agent_status_conversations',
//       'account_conversations-status',
//     ],
//     menuItems: {
//       back: {
//         icon: 'ion-ios-arrow-back',
//         label: 'HOME',
//         hasSubMenu: false,
//         toStateName: 'home',
//         toState: frontendURL(`accounts/${accountId}/dashboard`),
//       },
//       agents: {
//         icon: 'ion-person-stalker',
//         label: 'AGENTS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/agents/list`),
//         toStateName: 'agent_list',
//       },
//       teams: {
//         icon: 'ion-ios-people',
//         label: 'TEAMS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/teams/list`),
//         toStateName: 'settings_teams_list',
//       },
//       inboxes: {
//         icon: 'ion-archive',
//         label: 'INBOXES',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/inboxes/list`),
//         toStateName: 'settings_inbox_list',
//       },
//       labels: {
//         icon: 'ion-pricetags',
//         label: 'LABELS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/labels/list`),
//         toStateName: 'labels_list',
//       },
//       codes: {
//         icon: 'ion-flag',
//         label: 'BUSYCODES',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/busycodes/list`),
//         toStateName: 'codes_list',
//       },
//       cannedResponses: {
//         icon: 'ion-chatbox-working',
//         label: 'CANNED_RESPONSES',
//         hasSubMenu: false,
//         toState: frontendURL(
//           `accounts/${accountId}/settings/canned-response/list`
//         ),
//         toStateName: 'canned_list',
//       },
//       settings_integrations: {
//         icon: 'ion-flash',
//         label: 'INTEGRATIONS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/integrations`),
//         toStateName: 'settings_integrations',
//       },
//       settings_applications: {
//         icon: 'ion-asterisk',
//         label: 'APPLICATIONS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/applications`),
//         toStateName: 'settings_applications',
//       },
//       general_settings_index: {
//         icon: 'ion-gear-a',
//         label: 'ACCOUNT_SETTINGS',
//         hasSubMenu: false,
//         toState: frontendURL(`accounts/${accountId}/settings/general`),
//         toStateName: 'general_settings_index',
//       },
//     },
//   },
// });
