<template>
  <div class="transactions-history-box">
    <div class="transactions-history-container" v-if="transactions.length > 0">
      <div class="loader" :class="{ active: isLoading }"></div>

      <TransactionsHistoryHeader v-model="params" :total_pages="total_pages" />
      <table class="transactions-history">
        <thead>
          <tr>
            <th></th>
            <th>Inbox</th>
            <th>
              <SortableTableHeader
                field="amount"
                :params="params"
                :handler="orderBy"
              >
                Amount
              </SortableTableHeader>
            </th>
            <th>
              <SortableTableHeader
                field="transaction_type"
                :params="params"
                :handler="orderBy"
              >
                Transaction type
              </SortableTableHeader>
            </th>
            <th>
              <SortableTableHeader
                field="charge_type"
                :params="params"
                :handler="orderBy"
              >
                Charge type
              </SortableTableHeader>
            </th>
            <th>Notes</th>
            <th>
              <SortableTableHeader
                field="created_at"
                :params="params"
                :handler="orderBy"
              >
                Date
              </SortableTableHeader>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="t in transactions" :key="t.id">
            <td class="transaction-id">#{{ t.id }}</td>
            <td>{{ t.inbox.name }}</td>
            <td>{{ t.amount }}</td>
            <td>{{ t.transaction_type }}</td>
            <td>{{ t.charge_type }}</td>
            <td>{{ t.notes }}</td>
            <td>{{ formatDate(t.created_at) }}</td>
          </tr>
        </tbody>
      </table>
      <TransactionsHistoryHeader v-model="params" :total_pages="total_pages" />
    </div>
    <div class="no-transactions-found" v-else>No transactions found</div>
  </div>
</template>

<script>
import CreditTransactionsAPI from './../../../../api/creditTransactions.js';
import SortableTableHeader from './SortableTableHeader.vue';
import moment from 'moment';
import TransactionsHistoryHeader from './TransactionsHistoryHeader.vue';
import { pick } from 'lodash';

export default {
  components: { SortableTableHeader, TransactionsHistoryHeader },
  data() {
    return {
      total_pages: 10,
      params: {
        per_page: 20,
        page: 1,
        order_by: 'created_at',
        order_type: 'DESC',
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
      transactions: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadTransactionsHistory();
  },
  methods: {
    orderBy(field) {
      if (this.params.order_by === field) {
        this.params.order_type =
          this.params.order_type === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.params.order_by = field;
        this.params.order_type = 'ASC';
      }
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    },
    loadTransactionsHistory: async function () {
      this.isLoading = true;

      let params = pick(this.params, [
        'page',
        'per_page',
        'order_by',
        'order_type',
      ]);

      if (this.params.dateRange.startDate !== null) {
        params = {
          ...params,
          since: this.params.dateRange.startDate.toISOString(),
          before: this.params.dateRange.endDate.toISOString(),
        };
      }

      CreditTransactionsAPI.getTransactionsHistory(params)
        .then(
          ({
            data: {
              data: {
                meta: { total_pages },
                payload,
              },
            },
          }) => {
            this.total_pages = total_pages;
            this.transactions = payload;
          }
        )
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    params: {
      deep: true,
      handler() {
        if (
          !this.isLoading &&
          (this.params.dateRange.startDate === null ||
            this.params.dateRange.endDate !== null)
        ) {
          this.loadTransactionsHistory();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.transactions-history-box {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.no-transactions-found {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: var(--font-size-big);
  display: flex;
  align-items: center;
  justify-content: center;
}

.transactions-history-container {
  position: relative;
  .loader {
    position: absolute;
    z-index: 999;
    pointer-events: none;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f9fafc;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: 'Loading...';
      color: #3c4858;
      font-size: var(--font-size-large);
    }

    &.active {
      pointer-events: all;
      opacity: 0.8;
    }
  }
}

.transactions-history {
  border-collapse: separate;
  border-spacing: 0 1em;
  margin: var(--space-small) 0;

  th,
  td {
    &:first-child {
      border-top-left-radius: var(--border-radius-normal);
      border-bottom-left-radius: var(--border-radius-normal);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius-normal);
      border-bottom-right-radius: var(--border-radius-normal);
    }
    padding: 0 1em;
    font-size: var(--font-size-small);
  }
  th {
    background: #1f93ff;
    color: white;
    line-height: 3em;
    font-weight: var(--font-weight-black);
  }
  tr {
    border: none;
  }

  td {
    background: white;
    padding: 0.8em 1em;
    color: #3c4858;
  }

  .transaction-id {
    color: #a5a5a5;
  }
}
</style>
