<template>
  <div class="row sortable-header align-middle" @click="handler(field)">
    <div>
      <slot></slot>
    </div>
    <div class="arrow-container">
      <template v-if="params.order_by === field">
        <i v-if="params.order_type === 'ASC'" class="icon ion-arrow-down-b" />
        <i v-else class="icon ion-arrow-up-b" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['field', 'params', 'handler'],
  data() {
    return {};
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
.sortable-header {
  cursor: pointer;
  user-select: none;
}
.arrow-container {
  width: 12px;
  box-sizing: border-box;
  padding-left: 5px;
}
</style>
