<template>
  <transition name="network-notification-fade" tag="div">
    <div v-show="show && !close_flag && isAdmin" class="ui-notification-container">
      <div class="ui-notification"  v-on:click="showNotifications">
        <div class="ui-icon-bell">
          <img class="ui-icon-bell" src="~dashboard/assets/images/alert.svg" alt="Alert" width="20px" height="20px">
        </div>
        <div class="ui-notification-text">
          Your balance is low: {{this.balance}}
        </div>
        <button class="ui-close-button" v-on:click.stop="closeNotification">
          <i class="ui-close-icon icon ion-ios-close-outline" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      start: false,
      mute_audio_flag: false,
      close_flag: false,
      audio_name: "low_balance.mp3",
      agent: {},
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
      agentList: 'agents/getAgents',
    }),
    balance() {
      let account = this.getAccount(this.accountId);
      return account ? account.credit_balance : '1000.0';
    },
    isAdmin() {
        this.agent = this.agentList.filter(agent => this.accountId == agent.id)[0];
        if(this.agent){
          if(this.agent.role == "administrator"){
            return true;
          }
        }
        return false;
    },
    show(){
      if(this.balance <= 100 && this.isAdmin && !this.close_flag && this.start){
        this.enableAudioAlert();
        this.mute_audio_flag = true;
        return true;
      }
      else if(this.close_flag){
        this.mute_audio_flag = false;
        return false;
      }
    },
  },
  methods: {
    enableAudioAlert(){
      if(!this.mute_audio_flag && this.start){
        var audio = new Audio(`/dashboard/audios/${this.audio_name}`);
        audio.play();
      }
    },
    closeNotification() {
      this.close_flag = true;
    },
    showNotifications(){
      this.close_flag = true;
      this.$router.push(`/app/accounts/${this.accountId}/credit-history`);
    }
  },
  mounted(){
    if(this.accountId){
      window.addEventListener('click', event=>{
        if(!this.start){
          this.start = true;
          window.removeEventListener('click', event);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/mixins';

.ui-notification-container {
  max-width: 40rem;
  position: absolute;
  right: var(--space-normal);
  top: var(--space-normal);
  width: 100%;
  z-index: 9999;
}

.ui-notification {
  &:hover {
    cursor: default;
  }
  @include shadow;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  border-radius: var(--space-one);
  display: flex;
  justify-content: space-between;
  max-width: 40rem;
  min-height: 3rem;
  min-width: 24rem;
  padding: var(--space-normal) var(--space-two);
  text-align: left;
}
.ui-icon-bell {
  font-size: var(--font-size-large);
}

.ui-notification-text {
  margin: 0;
  font-size: var(--font-size-small);
}

.ui-close-icon {
  color: var(--b-600);
  font-size: var(--font-size-large);
}

.ui-close-button {
  z-index: 2001;
}
</style>
