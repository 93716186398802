/* global axios */
import ApiClient from '../ApiClient';

class PaymentApi extends ApiClient {
  constructor() {
    super('payment', { accountScoped: true });
  }

  payment_intent_stripe(data) {
    return axios.post(`webhooks/payment_intent_stripe`, {
       data
    });
  }
}

export default new PaymentApi();
