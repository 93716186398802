<template>
    <div>
        <modal :on-close="closeCallMenu" :show="isCallMenuOpen">
            <div class="call-menu-wrapper">
                <div class="call-menu">

                    <div class="call-menu_header">
                        Call To PSTN
                    </div>

                    <div class="v-select-wrapper" v-if="inboxPhoneOptions ? (inboxPhoneOptions.length > 0) : false">
                        <v-select :options="inboxPhoneOptions"
                                  appendToBody
                                  v-model="selectedInbox"
                                  placeholder="Choose Inbox"
                        >
                        </v-select>
                    </div>

                    <div class="call-menu__controls-wrapper">
                        <div class="phone-input-wrapper" style="width:83%">
                            <VuePhoneNumberInput v-model="enteredPhoneNumber"
                                                 default-country-code="US"
                                                 :no-country-selector="true"
                                                 @update="onNumberUpdate"
                                                 size="lg"
                            />
                        </div>

                        <div class="call-menu__button-wrapper">
                            <div :class="`sip-endpoint-call-menu-widget__button sip-endpoint-widget__call-button ${(!isRegistered || RTCSession || !selectedInbox || !isEnteredNumberCorrect) ? 'sip-endpoint-widget__call-button--disable' : ''}`"
                                 @click="callFromCallMenu"
                            >
                                <img src="~dashboard/assets/images/phone.svg" alt="Call">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <transition name="fade">
            <div class="dial-pad-box" v-if="isDialPadOpen && isEstablished">
                <div class="call-menu__input-number-buttons">
                    <input class="dial-pad-input" v-model="dialPadInput"/>
                </div>
                <div class="call-menu__input-number-buttons">
                    <button class="dial-pad-button" @click="inputNumber(1)">
                        1
                    </button>
                    <button class="dial-pad-button" @click="inputNumber(2)">
                        2
                    </button>
                    <button class="dial-pad-button" @click="inputNumber(3)">
                        3
                    </button>
                </div>
                <div class="call-menu__input-number-buttons">
                    <button class="dial-pad-button" @click="inputNumber(4)">
                        4
                    </button>
                    <button class="dial-pad-button" @click="inputNumber(5)">
                        5
                    </button>
                    <button class="dial-pad-button" @click="inputNumber(6)">
                        6
                    </button>
                </div>
                <div class="call-menu__input-number-buttons">
                    <button class="dial-pad-button" @click="inputNumber(7)">
                        7
                    </button>
                    <button class="dial-pad-button" @click="inputNumber(8)">
                        8
                    </button>
                    <button class="dial-pad-button" @click="inputNumber(9)">
                        9
                    </button>
                </div>
                <div class="call-menu__input-number-buttons">
                    <button class="dial-pad-button" @click="inputNumber('*')">
                        *
                    </button>
                    <button class="dial-pad-button" @click="inputNumber(0)">
                        0
                    </button>
                    <button class="dial-pad-button" @click="inputNumber('#')">
                        #
                    </button>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="incoming-call-wrapper"
                 v-if="!isEstablished && isIncoming && !isEnding"
            >
                <div class="incoming-call-box">
                    <div class="incoming-call-box__header">
                        Incoming Call
                    </div>

                    <div class="incoming-call-box__from">
                        <div class="incoming-call-box__from-inside">
                            {{from}}
                        </div>
                        <div class="incoming-call-box__inbox-name">
                            {{inboxName}}
                        </div>
                    </div>

                    <div class="incoming-call-box__actions">
                        <div class="incoming-call-box__button incoming-call-box__accept-button"
                             @click="answer"
                        >
                            <img src="~dashboard/assets/images/phone.svg" alt="Accept" width="32px" height="32px">
                        </div>

                        <div class="incoming-call-box__button incoming-call-box__decline-button"
                             @click="terminate"
                        >
                            <img src="~dashboard/assets/images/phone-hangup.svg" alt="Decline" width="32px" height="32px">
                        </div>
                    </div>
                </div>
            </div>
        </transition>

      <transition name="fade">
          <div :class="`sip-endpoint__controls-wrapper ${isControlsHidden ? 'sip-endpoint__controls-wrapper--hidden' : ''}`"
               v-if="isEstablished || isOutgoing"
          >
              <div class="sip-endpoint-dialog__established-controls">
                  <div class="established-controls__from">
                      {{isIncoming ? from : to}}
                  </div>
                  <div class="established-controls__time">
                      {{new Date(this.seconds * 1000).toISOString().substr(11, 8)}}
                  </div>
                  <div class="established-controls__buttons-wrapper">
                    <div :class="`established-controls__recording-icon-wrapper ${!isRecordingPaused ? 'established-controls__recording-icon-wrapper--active' : ''}`" v-if="isEstablished"
                         @click="enableRecording"
                    >
                        <img :src="!isRecordingPaused ? require(`../../assets/images/controller-record.svg`) : require(`../../assets/images/controller-record-white.svg`)" width="32" height="32">
                    </div>
                      <div :class="`established-controls__dialpad-icon-wrapper ${isDialPadOpen ? 'established-controls__dialpad-icon-wrapper--active' : ''}`" v-if="isEstablished"
                           @click="openDialPad"
                      >
                          <img :src="isDialPadOpen ? require(`../../assets/images/dial-pad.svg`) : require(`../../assets/images/dial-pad-white.svg`)" width="32" height="32">
                      </div>
                      <!-- <div :class="`established-controls__help-icon-wrapper`" v-if="isEstablished && isOutgoing"
                           @click="openHelpForm"
                      >
                          <img src="https://icongr.am/material/help-circle.svg?size=128&color=ffffff" width="32" height="32">
                      </div> -->
                      <div :class="`established-controls__refer-icon-wrapper`" v-if="isEstablished"
                           @click="openReferForm"
                      >
                          <img src="~dashboard/assets/images/phone-forward.svg" width="32" height="32">
                      </div>

                        <div :class="`established-controls__mute-icon-wrapper ${isAudioMuted ? 'established-controls__mute-icon-wrapper--active' : ''}`"
                             @click="toggleAudioMute"
                        >
                            <img :src="isAudioMuted ? require(`../../assets/images/microphone-off.svg`) : require(`../../assets/images/microphone.svg`)" alt="Microphone Icon" width="32" height="32">
                        </div>

                        <div class="established-controls__decline-icon-wrapper"
                             @click="terminate"
                        >
                            <img src="~dashboard/assets/images/phone-hangup.svg" alt="Decline" width="32px" height="32px">
                        </div>
                    </div>
                </div>

                <div :class="`sip-endpoint__hide-controls-button ${isControlsHidden ? 'sip-endpoint__hide-controls-button--hidden' : ''}`"
                     @click="isControlsHiddenToggle"
                >
                    <img src="~dashboard/assets/images/triangle.svg"
                         alt="hide icon"
                         class="hide-image"
                         :style="!isControlsHidden ? 'transform: rotate(180deg)' : ''"
                    >
                </div>


            </div>
        </transition>


        <modal :on-close="closeReferForm" :show="isReferFormOpen">
            <div class="refer-form-global-wrapper">
                <div class="refer-form-wrapper">
                    <div class="call-menu_header">
                        Redirect
                    </div>

                    <div>
                        <div class="refer-form_select">
                            <v-select :options="inboxPhoneOptions_2"
                                      v-model="selectedInbox_2"
                                      placeholder="Choose Inbox"
                                      appendToBody
                                      :calculate-position="withPopper"
                            >
                            </v-select>
                        </div>

                        <div class="refer-form_select-agent"
                             v-if="(referAgentsOption !== null) && (selectedInbox_2 !== null)"
                        >
                            <v-select :options="referAgentsOption"
                                      v-model="selectedReferAgent"
                                      placeholder="Choose Agent"
                                      appendToBody
                                      :calculate-position="withPopper"
                            >
                            </v-select>
                        </div>

                        <div v-if="((selectedInbox_2 !== null) && (selectedReferAgent !== null))"
                             class="transfer-button-wrapper"
                        >
                            <div class="transfer-button"
                                 @click="refer"
                            >
                                Transfer
                            </div>
                        </div>
                    </div>

                    <div>

                    </div>

                    <div>
                        <div>

                        </div>
                    </div>
                </div>

            </div>
        </modal>

        <audio id="remote-audio"
               autoplay="autoplay"
        >
        </audio>
    </div>
</template>

<script>

import adapter from 'webrtc-adapter';

import {UA} from "jssip";
import {WebSocketInterface} from "jssip";
import WootButton from "../ui/WootButton";
import {ringtoneBase64} from "./ringtoneBase64";

import {debug} from "jssip";

import {frontendURL} from "../../helper/URLHelper";
import {mapGetters} from "vuex";
import {callStatusTypes} from "./constants/callStatusTypes";
import {dappAdress} from "../../api/dappAdress";
import {callsAPI} from "../../api/callsAPI";
import * as types from "../../store/mutation-types";
import {actionTypes} from "./constants/actionTypes";

import VuePhoneNumberInput from "vue-phone-number-input";
import vSelect from 'vue-select';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {sipEndpointAPI} from "../../api/SipEndpointAPI";
import { createPopper } from '@popperjs/core';

import contactsAPI from "./../../api/contacts";

import Modal from "../Modal";
import alertMixin from "../../../shared/mixins/alertMixin";

RTCPeerConnection.prototype.addStream = function(stream) {
    stream.getTracks().forEach((track) => this.addTrack(track, stream));
};

const OPTIONS = (localStream, microphoneId) => {
    const options = {
        mediaConstraints: {
            video: false,
            audio: {
                deviceId: microphoneId
            }
        },
        pcConfig: {
            iceServers: [{urls: "stun:stun.l.google.com:19302"}]
        },
        rtcOfferConstraints: {
            offerToReceiveAudio: true,
            offerToReceiveVideo: false
        },
        mediaStream: localStream
    };

    return options;
}

export default {
    mixins: [alertMixin],
    name: "TheSIPEndpoint",
    components: {
        WootButton,
        vSelect,
        VuePhoneNumberInput,
        Modal
    },
    props: {},
    data() {
        return {
            userAgents: [],
            RTCSession: null,
            dialPadInput: "",

            remoteStream: null,
            localStream: null,

            inboxId: null,

            from: null,
            to: null,

            inboxName: "",

            ringtone: null,
            intervalId: null,
            seconds: 0,

            callStatusType: null,

            callId: null,
            callSID: null,

            isIncoming: false,
            isOutgoing: false,

            conversationId: null,

            inboxPhoneOptions: null,
            inboxPhoneOptions_2: null,

            selectedInbox: null,
            selectedInbox_2: null,

            enteredPhoneNumber: "",
            isEnteredNumberCorrect: false,
            formattedPhoneNumber: "",
            isRegistered: false,

            isReferFormOpen: false,
            isDialPadOpen: false,
            isRecordingEnabled: false,
            isRecordingPaused: true,

            referAgentsOption: null,
            selectedReferAgent: null,

            isEnding: false,

            isRefered: false,
            created_at: null,
            placement: 'bottom',

            isControlsHidden: false,

            availableCameras: [],
            availableMicrophones: [],

            selectedCamera: "",
            selectedMicrophone: ""

        }
    },
    watch: {
        selectedInbox_2: async function(value) {
            if (value) {
                try {
                    const response =  await sipEndpointAPI.getSipInboxMembers(this.accountId, value.code.inbox_id);

                    this.referAgentsOption = response.data.sip_agents.map((obj) => {return {code: obj, label: obj.name}});
                } catch (error) {
                    console.log(error);
                }
            }
        },
        call_SID: async function(value){
            console.log("CALL_SID_WAS_CHANGE");
            console.log(value);
            console.log("CALL_SID_WAS_CHANGE");
            if(this.isOutgoing && value !== ""){
                let response = null;

                try {
                    response = await callsAPI.createCall({
                        from: this.from,
                        to: this.to,
                        source_id: value,
                        status: callStatusTypes.IN_PROGRESS,
                        agent_id: this.agentId,
                        inbox_id: this.inboxId,
                        call_type: "outgoing",
                    });

                    this.callId = response.data.id;
                } catch (e) {
                    console.log(e);
                }
            }
        },
        currentUser: function (value) {
            if (value && value.sip_endpoints) {
                this.inboxPhoneOptions_2 = value.sip_endpoints.map((item) => {
                    return {
                        label: `${item.inbox_name}`,
                        code: item
                    }
                });

                this.inboxPhoneOptions = value.sip_endpoints.filter((item) => {return item.phone_number !== null  && item.account_id == this.accountId}).map((item) => {
                    return {
                        label: `${item.inbox_name}`,
                        code: item
                    }
                });
            }
        },
        currentAction: async function (action) {
            if (action) {
                if (action.type === actionTypes.PHONE_NUMBER_CALL && this.checkBalance()) {
                    console.log(action);

                    this.inboxId = action.inbox.inbox_id;
                    const dapp_adress = await dappAdress.getDappOutboundCall(action.contactPhoneNumber.replace('+', ''));
                    this.call(dapp_adress.data, action.inbox.username);

                    this.$store.commit("sipEndpoint/SET_CURRENT_ACTION", null);
                }
                if (action.type === actionTypes.CALL_THE_QUEUE && this.checkBalance()) {
                    const username = this.currentUser.sip_endpoints.find(obj => {return obj.inbox_id == action.inbox.id}).username;
                    const dapp_adress = await dappAdress.getDapp();
                    this.call(dapp_adress.data, username);
                    this.$store.commit("sipEndpoint/SET_CURRENT_ACTION", null);
                }

            }
        },
        refreshDevicesJSSIP: async function (value) {
            if (value) {
                await this.setMediaDevices();

                this.$store.commit("sipEndpoint/SET_REFRESH_DEVICES_JSSIP", false);
            }
        }
    },
    computed: {
        ...mapGetters({
            accountId: 'getCurrentAccountId',
            agentId: 'getCurrentUserID',
            currentAction: 'sipEndpoint/getCurrentAction',
            isCallMenuOpen: 'sipEndpoint/getCallMenuOpen',
            currentUser: 'getCurrentUser',
            isSession: "sipEndpoint/getIsSession",
            refreshDevicesJSSIP: "sipEndpoint/getRefreshDevicesJSSIP",
            getAccount: 'accounts/getAccount',
            call_SID: 'signalwireCallCreated/getCallSid',
        }),

        isInProgress() {
            if (this.RTCSession) {
                return this.RTCSession.isInProgress();
            }

            return false;
        },
        isEstablished() {
            if (this.RTCSession) {
                return this.RTCSession.isEstablished();
            }

            return false;
        },

        isAudioMuted() {
            if (this.RTCSession) {
                return this.RTCSession.isMuted().audio;
            }

            return false;
        },

        dashboardPath() {
            return frontendURL(`accounts/${this.accountId}/dashboard`);
        },

    },
    async mounted() {
        if (this.currentUser && this.currentUser.sip_endpoints) {
            this.inboxPhoneOptions_2 = this.currentUser.sip_endpoints.map((item) => {
                return {
                    label: `${item.inbox_name}`,
                    code: item
                }
            });

            this.inboxPhoneOptions = this.currentUser.sip_endpoints.filter((item) => {return item.phone_number !== null && item.account_id == this.accountId}).map((item) => {
                return {
                    label: `${item.inbox_name}`,
                    code: item
                }
            });
        }

        debug.enable('*');


        await this.setMediaDevices();

        navigator.mediaDevices.addEventListener('devicechange', this.setMediaDevices);


        // const sipEndpoints = Cookies.getJSON("user").sip_endpoints;

        try {
            const response = await sipEndpointAPI.getSipEndpoints(this.accountId);
            const sipEndpoints = response.data.sip_endpoints;

            for (const sipEndpoint of sipEndpoints) {
                const socket = new WebSocketInterface("wss://aeonian-conversate.sip.signalwire.com");

                const configuration = {
                    sockets: [socket],
                    uri: `sip:${sipEndpoint.username}@aeonian-conversate.sip.signalwire.com`,
                    password: sipEndpoint.password
                };

                const userAgent = new UA(configuration)

                userAgent.on("connected", this.onConnected);
                userAgent.on("disconnected", this.onDisconnected);

                userAgent.on("registered", this.onRegistered);
                userAgent.on("unregistered", this.onUnregistered);
                userAgent.on("registrationFailed", this.onRegistrationFailed);

                userAgent.on("newRTCSession", this.onNewRTCSession);

                userAgent.on("sipEvent", this.onSipEvent);

                userAgent.start();

                this.userAgents.push(userAgent);
            }
        } catch (error) {
            console.log(error);
        }
        this.ringtone = new Audio(ringtoneBase64);
        this.ringtone.loop = true;
    },
    beforeDestroy() {
        for (const userAgent of this.userAgents) {
            userAgent.stop();
        }
    },
    methods: {
        async enableRecording(){
            if(this.isRecordingEnabled && !this.isRecordingPaused){
                this.isRecordingPaused = true;
                try {
                    await callsAPI.updateCallRecording({
                        call_id: this.callId,
                        status: 'paused',
                    });
                } catch (e) {
                    console.log(e);
                }
            }
            else if(this.isRecordingEnabled && this.isRecordingPaused){
                this.isRecordingPaused = false;
                try {
                    await callsAPI.updateCallRecording({
                        call_id: this.callId,
                        status: 'in-progress',
                    });
                } catch (e) {
                    console.log(e);
                }
            }
            else {
                this.isRecordingEnabled = true;
                this.isRecordingPaused = false;
                try {
                    await callsAPI.createCallRecording({
                        call_id: this.callId,
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        },
        inputNumber(value){
            this.dialPadInput = this.dialPadInput + value;
            this.RTCSession.sendDTMF(value);
        },
        checkBalance() {
            let account = this.getAccount(this.accountId);
            let balance = account ? parseFloat(account.credit_balance) : 0;

            if (balance <= 0) {
                this.showAlert("Please add credits to your balance to make calls");

                return false;
            }  else {
                return true;
            }
        },
        closeReferForm() {
            this.isReferFormOpen = false;
        },
        openReferForm() {
            this.isReferFormOpen = true;
        },
        openDialPad(){
            if(!this.isDialPadOpen){
                this.isDialPadOpen = true;
            }
            else {
                this.isDialPadOpen = false;
                this.dialPadInput = "";
            }
        },
        onSipEvent(e) {
            console.log("________SIP EVENT_____________");
            console.log(e);
            console.log("________SIP EVENT_____________");
        },
        refer() {
            this.isRefered = true;

            this.updateCall({
                status: callStatusTypes.REFERRED_COMPLETED,
                referred_to: this.selectedReferAgent.code.sip_username,
                duration: this.seconds
            });

            this.referCall({
                referred_to: this.selectedReferAgent.code.sip_username,
            });

            // this.RTCSession.refer(this.selectedReferAgent.code.sip_username, {
            //   eventHandlers: {
            //     requestSucceeded: function (e) {
            //       console.log("!!!!!!!!!!!!!___requestSucceeded___!!!!!!!!!!!!!");
            //
            //       console.log(e.response);
            //     },
            //     requestFailed: function (e) {
            //       console.log("!!!!!!!!!!!!!___requestFailed___!!!!!!!!!!!!!");
            //
            //       console.log(e.response);
            //       console.log(e.cause);
            //     },
            //     trying: function (e) {
            //       console.log("!!!!!!!!!!!!!___trying___!!!!!!!!!!!!!");
            //
            //       console.log(e.request);
            //       console.log(e.status_line);
            //     },
            //     progress: function (e) {
            //       console.log("!!!!!!!!!!!!!___progress___!!!!!!!!!!!!!");
            //
            //       console.log(e.request);
            //       console.log(e.status_line);
            //     },
            //     accepted: function (e) {
            //       console.log("!!!!!!!!!!!!!___accepted___!!!!!!!!!!!!!");
            //
            //       console.log(e.request);
            //       console.log(e.status_line);
            //     },
            //     failed: function (e) {
            //       console.log("!!!!!!!!!!!!!___failed___!!!!!!!!!!!!!");
            //
            //       console.log(e.request);
            //       console.log(e.status_line);
            //     },
            //   },
            //     extraHeaders: [ `X-call_id: ${this.callSID}`, `X-refer: true`, `X-from: ${this.from}`, `X-to: ${this.to}`]
            // })
        },
        async onReplaces(e) {
            console.log("!!!!!!!!!!!on Replace!!!!!!!!!!!!!!!!!!!!!!!");
            console.log(e.request);

            e.accept((session) => {console.log("on Replace")});
        },
        async onRefer(e) {
            console.log("__________REFER__________");

            console.log("___REQUEST___");
            console.log(e.request);
            console.log("___REQUEST___");

            console.log("__________REFER__________");

            await this.setLocalStream(false, {deviceId: this.selectedMicrophone});

            e.accept((session) => {console.log("!!!!!!!!!!!!_____REFER_______!!!!!!!!!!!!")}, OPTIONS(this.localStream, this.selectedMicrophone));
        },
        async callFromCallMenu(){
            if (this.isRegistered && !this.RTCSession && this.selectedInbox && this.isEnteredNumberCorrect) {
                this.inboxId = this.selectedInbox.code.inbox_id;

                if (this.checkBalance()) {
                    const dapp_adress = await dappAdress.getDappOutboundCall(this.formattedPhoneNumber.replace('+', ''));
                    await this.call(dapp_adress.data, this.selectedInbox.code.username);
                }

                this.closeCallMenu();
            }
        },
        closeCallMenu() {
            this.$store.commit("sipEndpoint/SET_IS_CALL_MENU_OPEN", false);

            this.selectedInbox = null;

            this.enteredPhoneNumber = "";
            this.isEnteredNumberCorrect =  false;
            this.formattedPhoneNumber = "";
        },
        onNumberUpdate(data) {
            this.isEnteredNumberCorrect = data.isValid;
            this.formattedPhoneNumber = data.e164;
        },
        onConnected(e) {
            console.log("_______connected_______");

            console.log(`socket: ${e.socket}`);

            console.log("_______connected_______");
        },
        onDisconnected(e) {
            console.log("_______disconnected_______");

            console.log(`error: ${e.error}`);
            console.log(`code: ${e.code}`);
            console.log(`reason: ${e.reason}`);

            console.log("_______disconnected_______");
        },
        onRegistered(e) {
            console.log("_______registered_______");

            console.log(`response: ${e.response}`);

            console.log("_______registered_______");

            console.log(adapter.browserDetails);
            console.log(adapter.browserShim);
            console.log(adapter.commonShim)

            this.$store.commit("sipEndpoint/SET_IS_REGISTERED", true);
            this.isRegistered = true;
        },
        onUnregistered(e) {
            console.log("_______unregistered_______");

            console.log(`response: ${e.response}`);
            console.log(`cause: ${e.cause}`);

            console.log("_______unregistered_______");

            this.$store.commit("sipEndpoint/SET_IS_REGISTERED", false);
        },
        onRegistrationFailed(e) {
            console.log("_______registration failed_______");

            console.log(`response: ${e.response}`);
            console.log(`cause: ${e.cause}`);

            console.log("_______registration failed_______");
        },
        async onEnded(e) {
            this.isRecordingEnabled = false;
            this.isRecordingPaused = true;
            this.isEnding = true;

            console.log("_______Call Ended_______");

            console.log(`originator: ${e.originator}`);
            console.log(`message: ${e.message}`);
            console.log(`cause: ${e.cause}`);

            console.log("_______Call Ended_______");

            await this.onReferCreateCall();

            this.callStatusType = callStatusTypes.COMPLETED;

            if (!this.isRefered) {
                await this.updateCall({
                    status: callStatusTypes.COMPLETED,
                    duration: this.seconds
                });
            }

            this.ringtone.pause();

            try {
                await callsAPI.updateAgent({
                    agent_id: this.agentId,
                    on_call: false
                });
            } catch (e) {
                console.log(e);
            }

            this.cleanUp();
        },
        async onFailed(e) {
            this.isEnding = true;

            console.log("_______Call Failed_______");

            console.log(`originator: ${e.originator}`);
            console.log(`message: ${e.message}`);
            console.log(`cause: ${e.cause}`);

            console.log("_______Call Failed_______");

            await this.onReferCreateCall();

            this.callStatusType = callStatusTypes.FAILED;

            await this.updateCall({
                status: `failed_${e.cause.toLocaleLowerCase().replace(" ", "_")}`,
                duration: this.seconds
            });

            this.ringtone.pause();

            try {
                await callsAPI.updateAgent({
                    agent_id: this.agentId,
                    on_call: false
                });
            } catch (e) {
                console.log(e)
            }

            this.cleanUp();
        },
        async onConfirmed(e){
            await this.onReferCreateCall();

            this.callStatusType = callStatusTypes.ANSWERED;

            this.updateCall({
                status: callStatusTypes.ANSWERED
            });

            if (this.RTCSession.direction === "incoming") {

            } else {
                this.intervalId = setInterval(this.updateSeconds, 1000);

            }
        },

        onAddStream(e) {
            const remoteAudio = document.getElementById("remote-audio");

            remoteAudio.srcObject = null;
            remoteAudio.srcObject = e.stream;
        },
        onUpdate(e) {
            console.log("_______Update_______");
            console.log(e);
            console.log("_______Update_______");

            this.RTCSession.renegotiate({useUpdate: true});
        },
        async onProgress(e) {
            console.log("_______progress_______");
            console.log(e);
            console.log("_______progress_______");

            if (this.isOutgoing) {
                // this.callSID = e.response.headers["X-Relay-Call-Id"][0].raw;

                // let response = null;
                //
                // try {
                //     response = await callsAPI.createCall({
                //         from: this.from,
                //         to: this.to,
                //         source_id: "",
                //         status: callStatusTypes.IN_PROGRESS,
                //         agent_id: this.agentId,
                //         inbox_id: this.inboxId,
                //         call_type: "outgoing",
                //     });
                //
                //     this.callId = response.data.id;
                // } catch (e) {
                //     console.log(e);
                // }
            }
        },
        onReInvite(e) {
            console.log("_______ReInvite_______");
            console.log(e);
            console.log("_______ReInvite_______");

            this.RTCSession.renegotiate();
        },
        onRemoveTrack(e) {
            console.log("OnRemoveTrack");

            console.log(e);
        },
        onNegotiationNeeded(e) {
            this.RTCSession.renegotiate({useUpdate: true});
        },
        onIceConnectionStateChange() {
            console.log('ICE state: ', this.RTCSession.connection.iceConnectionState);
        },

        async onNewRTCSession(e) {
            console.log("_______new RTC Session_______");

            console.log(`originator: ${e.originator}`);
            console.log(`session: ${e.session}`);
            console.log(`request: ${e.request}`);

            console.log("_______new RTC Session_______");

            if ((this.RTCSession === null) && (!this.isSession)) {
                this.$store.commit("sipEndpoint/SET_IS_SESSION", true);

                this.created_at = new Date().getTime();

                this.RTCSession = e.session;

                this.RTCSession.on("ended", this.onEnded);
                this.RTCSession.on("failed", this.onFailed);
                this.RTCSession.on("confirmed", this.onConfirmed);
                this.RTCSession.on("refer", this.onRefer);
                this.RTCSession.on("replaces", this.onReplaces);

                this.RTCSession.on("reinvite", this.onReInvite);
                this.RTCSession.on("update", this.onUpdate);

                this.RTCSession.on("progress", this.onProgress)


                this.RTCSession.on('peerconnection', function(data) {
                    console.log(data);
                });

                this.RTCSession.on('getusermediafailed', function(error) {
                    console.log(error);
                });

                this.RTCSession.on('peerconnection:createofferfailed', function(error) {
                    console.log(error);
                });

                this.RTCSession.on('peerconnection:createanswerfailed', function(error) {
                    console.log(error);
                });

                this.RTCSession.on('peerconnection:setlocaldescriptionfailed', function(error) {
                    console.log(error);
                });

                this.RTCSession.on('peerconnection:setremotedescriptionfailed', function(error) {
                    console.log(error);
                });

                this.callStatusType = callStatusTypes.IN_PROGRESS;

                this.from = e.request.from.uri.toString().split(':')[1].split('@')[0];
                this.to = e.request.to.uri.toString().split(':')[1].split('@')[0];
                console.log("FROOOM AND TOO " + this.from + " " + this.to);

                if (this.RTCSession.direction === "incoming") {
                    if(this.from.split("+")[1] != undefined){
                        const incomingPhoneNumber = `+${this.from.split("+")[1]}`;
                        this.from = incomingPhoneNumber;
                        try {
                            this.inboxName = this.currentUser.sip_endpoints.find((sip_endpoint) => {return sip_endpoint.username == this.to}).inbox_name;
                        } catch(e) {
                            console.log(e);
                        }


                        try {
                            const response = await contactsAPI.getNameByPhoneNumber(incomingPhoneNumber);

                            if (response.data.name) {
                                this.from = response.data.name;
                            }
                        } catch (e) {
                            console.log(e);
                        }

                        this.isIncoming = true;

                        this.callId = e.request.getHeader("X-call_id");
                        this.callSID = e.request.call_id;


                        this.ringtone.play();
                        this.$router.push(this.dashboardPath);
                    }
                    else{
                        try {
                            const response = await callsAPI.getReferredCall(this.from, this.to);
                            console.log("After getting referred call " + response.data);
                            if (response.data.to) {
                                this.from = response.data.to;
                                try {
                                    this.inboxName = this.currentUser.sip_endpoints.find((sip_endpoint) => {return sip_endpoint.username == this.to}).inbox_name;
                                } catch(e) {
                                    console.log(e);
                                }


                                try {
                                    const response = await contactsAPI.getNameByPhoneNumber(incomingPhoneNumber);

                                    if (response.data.name) {
                                        this.from = response.data.name;
                                    }
                                } catch (e) {
                                    console.log(e);
                                }

                                this.isIncoming = true;
                            }
                            if(response.data.source_id) {
                                this.callSID = response.data.source_id
                                this.callId = response.data.id
                                this.ringtone.play();
                                this.$router.push(this.dashboardPath);
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } else {
                    this.isOutgoing = true;
                }

                try {
                    await callsAPI.updateAgent({
                        agent_id: this.agentId,
                        on_call: true
                    });
                } catch (e) {
                    console.log(e);
                }
            } else {
                e.session.terminate();
            }
        },
        async setLocalStream(isVideoEnabled, isAudioEnabled) {
            try {
                this.localStream = await navigator.mediaDevices.getUserMedia({video: isVideoEnabled, audio: isAudioEnabled});
            } catch(err) {
                console.log(err);
            }
        },
        async call(destination, userAgentSipEndpoint) {
            const userAgent = this.userAgents.find((userAgent) => {
                return userAgent._configuration.authorization_user === userAgentSipEndpoint
            });

            if (userAgent) {
                await this.setLocalStream(false, {deviceId: this.selectedMicrophone});

                userAgent.call(destination, OPTIONS(this.localStream, this.selectedMicrophone));

                if (this.RTCSession.connection) {
                    this.RTCSession.connection.onnegotiationneeded = this.onNegotiationNeeded;
                    this.RTCSession.connection.onaddstream = this.onAddStream;

                    this.RTCSession.connection.oniceconnectionstatechange = this.onIceConnectionStateChange;
                }
            }
        },
        async onReferCreateCall() {
            try {
                if (!this.callId) {
                    const agentSIPEndpoint = this.currentUser.sip_endpoints.find((sip) => {return sip.username === this.to});
                    this.inboxId = agentSIPEndpoint.inbox_id;

                    let response = null;

                    response = await callsAPI.createCall({
                        from: this.to,
                        to: `+${this.from.split("+")[1]}`,
                        source_id: "",
                        status: callStatusTypes.IN_PROGRESS,
                        agent_id: this.agentId,
                        inbox_id: this.inboxId,
                        call_type: "referred",
                        created_at: this.created_at
                    });

                    this.callId = response.data.id;
                }
            } catch (e) {

            }
        },

        async answer() {
            await this.setLocalStream(false, {deviceId: this.selectedMicrophone});

            if (this.RTCSession) {
                this.RTCSession.answer(OPTIONS(this.localStream, this.selectedMicrophone));

                if (this.RTCSession.connection) {
                    this.RTCSession.connection.onnegotiationneeded = this.onNegotiationNeeded;
                    this.RTCSession.connection.onaddstream = this.onAddStream;

                    this.RTCSession.connection.oniceconnectionstatechange = this.onIceConnectionStateChange;
                }

                this.intervalId = setInterval(this.updateSeconds, 1000);
            }

            this.ringtone.pause();
        },
        async terminate() {
            this.isEnding = true;

            if (this.RTCSession) {
                this.RTCSession.terminate();
            }

            this.ringtone.pause();
        },
        cleanUp() {
            this.$store.commit("sipEndpoint/SET_IS_SESSION", false);
            this.isRecordingEnabled = false;
            this.isRecordingPaused = true;
            this.RTCSession = null;

            this.inboxId = null;

            this.from = null;
            this.to = null;


            this.seconds = 0;

            this.isIncoming = false;
            this.isOutgoing =  false;

            if (this.intervalId) {
                clearInterval(this.intervalId);
            }

            this.intervalId = null;
            this.callStatusType = null;

            this.callId = null;
            this.$store.dispatch('signalwireCallCreated/destroy');
            this.callSID = null;
            this.conversationId = null;

            this.selectedInbox_2 = null;
            this.selectedReferAgent = null;

            this.isReferFormOpen = false;

            this.isRefered = false;
            this.created_at = null;
            this.isControlsHidden = false;

            if(this.localStream) {
                this.localStream.getTracks().forEach(t => t.stop());
                console.log("STREAM ENDED");
                this.localStream = null;
            }

            this.remoteStream = null;
            this.isEnding = false;

            const remoteAudio = document.getElementById("remote-audio");

            remoteAudio.srcObject = null;
        },

        muteAudio() {
            if (this.RTCSession) {
                this.RTCSession.mute({audio: true});
            }
        },

        unmuteAudio() {
            if (this.RTCSession) {
                this.RTCSession.unmute({audio:true});
            }
        },

        toggleAudioMute() {
            if (this.isAudioMuted) {
                this.unmuteAudio();
            } else {
                this.muteAudio();
            }
        },

        updateSeconds() {
            this.seconds = this.seconds + 1;
        },

        async updateCall(payload) {
            try {
                const response = await callsAPI.updateCall(this.callId, payload);
            } catch (e) {
                console.log(e);
            }
        },
        async referCall(payload) {
            try{
                const response = await callsAPI.referCall(this.callId, payload);
            }
            catch(e){
                console.log(e);
            }
        },
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width

            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                        },
                    },
                ],
            })

            return () => popper.destroy()
        },

        isControlsHiddenToggle() {
            this.isControlsHidden = !this.isControlsHidden;
        },

        async setMediaDevices() {
            let mediaDevicesInfo = null;

            try {
                mediaDevicesInfo = await navigator.mediaDevices.enumerateDevices();
            } catch (e) {
                console.log(e);
            }

            const availableCameras = [];
            const availableMicrophones = [];

            for (const mediaInfo of mediaDevicesInfo) {
                if (mediaInfo.kind === "videoinput") {
                    availableCameras.push({value: mediaInfo.deviceId, label: mediaInfo.label});
                }

                if (mediaInfo.kind === "audioinput") {
                    availableMicrophones.push({value: mediaInfo.deviceId, label: mediaInfo.label});
                }
            }

            this.availableCameras = availableCameras;
            this.availableMicrophones = availableMicrophones;

            if (window.localStorage) {
                const selectedCamera = window.localStorage.getItem("selectedCamera");
                const selectedMicrophone = window.localStorage.getItem("selectedMicrophone");

                if (selectedCamera) {
                    const isAvailableCamerasIncludeSelected = availableCameras.find((item) => {return item.value === selectedCamera});

                    this.selectedCamera = isAvailableCamerasIncludeSelected ? selectedCamera : (availableCameras.length > 0 ? availableCameras[0].value : "");

                } else {
                    this.selectedCamera = availableCameras.length > 0 ? availableCameras[0].value : "";
                }

                if (selectedMicrophone) {
                    const isAvailableMicrophoneIncludeSelected = availableMicrophones.find((item) => {return item.value === selectedMicrophone});

                    this.selectedMicrophone = isAvailableMicrophoneIncludeSelected ? selectedMicrophone : (availableMicrophones.length > 0 ? availableMicrophones[0].value : "");
                } else {
                    this.selectedMicrophone = availableMicrophones.length > 0 ? availableMicrophones[0].value : "";
                }
            } else {
                this.selectedCamera = availableCameras.length > 0 ? availableCameras[0].value : "";
                this.selectedMicrophone = availableMicrophones.length > 0 ? availableMicrophones[0].value : "";
            }

            // if (this.localStream) {
            //     const audioTracks = this.localStream.getAudioTracks();
            //
            //     for (const track of audioTracks) {
            //         await track.applyConstraints({
            //             deviceId: this.selectedMicrophone
            //         });
            //     }
            // }
        }
    }
}
</script>

<style >
.dial-pad-box {
    margin: 10px;
    width: 26rem;
    height: 33rem;
    position: fixed;

    z-index: 5000;

    bottom: 20%;
    left: calc(50% - 14rem);
    background-color: var(--s-900);

    color: white;

    border-radius: 15px;
}
.call-menu__input-number-buttons {
    display: flex;

    align-items: center;
    align-content: center;

    justify-content: center;
    margin-top: 20px;
}
.dial-pad-button {
    display: flex;

    align-items: center;
    align-content: center;

    justify-content: center;
    width: 60px;
    height: 40px;

    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;

    color: white;

    cursor: pointer;

    background-color: #40A2F7;
    margin-right: 10px;
    margin-left: 10px;
}
.dial-pad-input{
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
    width: 220px;
    height: 42px;
    font-size: 20px;
    color: #40A2F7;
    text-align: center;
    border-color: #40A2F7;
    border-radius: 5%;
}
.sip-endpoint-dialog__established-controls {
    width: 100%;

    display: flex;

    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.established-controls__mute-icon-wrapper {
    padding: 10px;

    background-color: #40A2F7;

    margin-right: 20px;

    border-radius: 50%;

    cursor: pointer;
}

.established-controls__mute-icon-wrapper:active {
    transform: scale(0.9);
}
.established-controls__recording-icon-wrapper{
    padding: 10px;

    background-color: var(--s-900);

    margin-right: 20px;

    border-radius: 50%;

    cursor: pointer;
}
.established-controls__recording-icon-wrapper--active{
    color: red;
}
.established-controls__recording-icon-wrapper:active {
    transform: scale(0.9);
}
.established-controls__dialpad-icon-wrapper{
    padding: 10px;

    background-color: var(--s-900);

    margin-right: 20px;

    border-radius: 50%;

    cursor: pointer;
}
.established-controls__dialpad-icon-wrapper--active{
    background-color: var(--b-50);
    color: var(--s-900);
}
.established-controls__dialpad-icon-wrapper:active {
    transform: scale(0.9);
}
.established-controls__refer-icon-wrapper {
    padding: 10px;

    background-color: #F9C546;

    margin-right: 20px;

    border-radius: 50%;

    cursor: pointer;
}

.established-controls__refer-icon-wrapper:active {
    transform: scale(0.9);
}

.established-controls__mute-icon-wrapper.established-controls__mute-icon-wrapper--active {
    background-color: #ED4948;
}

.established-controls__buttons-wrapper {
    display: flex;

    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.established-controls__decline-icon-wrapper {
    padding: 5px;

    width: 100px;

    border-radius: 10px;

    display: flex;

    align-items: center;
    align-content: center;
    justify-content: center;

    cursor: pointer;

    background-color: #ED4948;
}

.established-controls__decline-icon-wrapper:active {
    transform: scale(0.9);
}

.established-controls__from {
    margin-right: 20px;

    width: 150px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    text-align: center;
}

.established-controls__time {
    width: 90px;

    margin-right: 20px;
}

.call-menu-wrapper {
    font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Tahoma, Arial, sans-serif;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.call-menu {
    min-width: 400px;
    width: 100%;


    background-color: white;


    padding: 32px;
}

.refer-form-wrapper {

    width: 100%;
    height: 250px;


    background-color: white;

    padding: 25px 25px 25px 50px;
}

.v-select-wrapper {
    margin-top: 20px;

    padding-right: 25px;
}

.phone-input-wrapper {
    margin-top: 10px;
}

.sip-endpoint-call-menu-widget__button {
    display: flex;

    justify-content: center;
    align-items: center;
    align-content: center;

    width: 100%;

    margin: 0;

    padding: 10px;

    border-radius: 5px;

    cursor: pointer;

    height: 48px;
}

.sip-endpoint-call-menu-widget__button:active {
    transform: scale(0.8);
}

.sip-endpoint-widget__call-button {
    background-color: #60CE4D;
}

.sip-endpoint-widget__call-button--disable {
    background-color: gray;
}

.call-menu__controls-wrapper {
    display: flex;

    justify-content: space-between;
    align-items: center;
    align-content: center;

}

.call-menu__button-wrapper {
    width: 15%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin-top: 10px;
}

.refer-form-global-wrapper {
    display: flex;

    justify-content: center;
    align-items: center;
    align-content: center;
}

.refer-form_select {
    font-size: 16px;

    margin-top: 30px;

    padding-right: 25px;
}

.refer-form_select-agent {
    font-size: 16px;

    margin-top: 15px;

    padding-right: 25px;
}

.transfer-button-wrapper {
    padding-right: 25px;

    display: flex;

    justify-content: flex-end;
    align-items: center;
    align-content: center;
}

.transfer-button {
    width: auto;

    padding: 10px;

    margin-top: 15px;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    color: white;

    font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Tahoma, Arial, sans-serif;

    font-size: 16px;
    font-weight: bold;

    cursor: pointer;

    background-color: #44ce4b;

    border-radius: 5px;

    height: 40px;
}

.transfer-button:active {
    transform: scale(0.9);
}

.call-menu_header {
    font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Tahoma, Arial, sans-serif;

    font-size: 20px;

    display: flex;

    align-items: center;
    align-content: center;

    justify-content: center;

    margin-bottom: 10px;
}


/*!!!!!!!!!!!!!!!!!!___NEW___!!!!!!!!!!!!!!!!!!*/
.incoming-call-wrapper {
    position: fixed;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.4);

    z-index: 2000;
}

.incoming-call-box {
    width: 300px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;
    align-items: center;

    border-radius: 10px;
    overflow: hidden;
}

.incoming-call-box__header {
    width: 100%;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    font-size: 20px;

    background-color: #3C3D40;

    padding: 10px 0;

    color: white;
}

.incoming-call-box__from {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;
    align-items: center;

    background-color: #ffffff;

    color: black;

    height: 100px;


}

.incoming-call-box__inbox-name {
    width: 300px;
    padding: 0 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 16px;
    color: #3C3D40;

    text-align: center;
}

.incoming-call-box__from-inside {
    width: 300px;
    padding: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 24px;
    color: #3C3D40;

    text-align: center;
}

.incoming-call-box__actions {
    background-color: #E9EAEF;
    width: 100%;

    display: flex;

    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 15px;
}

.incoming-call-box__button {
    border-radius: 10px;

    padding: 8px;

    display: flex;

    justify-content: center;
    align-content: center;
    align-items: center;

    cursor: pointer;
}

.incoming-call-box__button:active {
    transform: scale(0.9);
}


.incoming-call-box__accept-button {
    background-color: #60CE4D;
    width: 45%;
}

.incoming-call-box__decline-button {
    background-color: #ED4948;
    width: 45%;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.sip-endpoint__hide-controls-button {
    cursor: pointer;

    position: fixed;

    width: 100px;

    bottom: 85px;
    left: calc(50% - 50px);


    padding: 5px 2px 15px;
    background-color: #1F2D3D;
    border-radius: 15px;


    z-index: 5000;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    transition: bottom;
    transition-duration: 0.3s;

}

.sip-endpoint__hide-controls-button--hidden {
    bottom: -13px;
}

.sip-endpoint__controls-wrapper {
    position: fixed;

    z-index: 5000;

    bottom: 20px;
    left: calc(50% - 300px);

    width: 600px;

    background-color: #1F2D3D;

    color: white;

    border-radius: 15px;


    font-size: 16px;

    font-weight: 600;

    padding: 15px;

    transition: bottom;
    transition-duration: 0.3s;
}

.sip-endpoint__controls-wrapper--hidden {
    bottom: -80px;
}

.vs__dropdown-menu {
    z-index: 9998 !important;

    font-size: 16px;
}

.hide-image {
    margin-top: -2px
}

</style>
