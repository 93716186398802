/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AccountAPI from '../../api/account';
import PaymentApi from '../../api/stripe/payment';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isUpdating: false,
  },
  limits: ''
};

export const getters = {
  getAccount: $state => id => {
    return $state.records.find(record => record.id === Number(id));
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAllLimits($state) {
      return $state.limits;
  },
  isFeatureEnabledonAccount:
    ($state, _, __, rootGetters) => (id, featureName) => {
        // If a user is SuperAdmin and has access to the account, then they would see all the available features
        const isUserASuperAdmin =
            rootGetters.getCurrentUser?.type === 'SuperAdmin';
        if (isUserASuperAdmin) {
            return true;
        }

        const { features = {} } =
        $state.records.find(record => record.id === Number(id)) || {};
        return features[featureName] || false;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await AccountAPI.get();
      commit(types.default.ADD_ACCOUNT, response.data);
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  update: async ({ commit }, updateObj) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: true });
    try {
      await AccountAPI.update('', updateObj);
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
      throw new Error(error);
    }
  },
  create: async ({ commit }, accountInfo) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: true });
    try {
      const response = await AccountAPI.createAccount(accountInfo);
      const account_id = response.data.data.account_id;
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: false });
      return account_id;
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  getExtraLimit: async ({ commit },  id) => {
      try {
          const response = await AccountAPI.getLimitInfo(id);
          commit(types.default.SET_EXTRA_LIMIT, response.data);
          return response.data;
      }catch (error) {
          throw error
      }
  },
  paymentIntent: async ({ commit }, payload) =>{
    try {
      const payment = await PaymentApi.payment_intent_stripe(payload);
      return payment
    } catch (error) {
      throw error;
    }
  },
    removeExtraLimit: async ({ commit }, data) => {
        try {
            await AccountAPI.removeLimits(data);
        }catch (error) {
            throw error
        }
    }
};

export const mutations = {
  [types.default.SET_ACCOUNT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.ADD_ACCOUNT]: MutationHelpers.setSingleRecord,
  [types.default.EDIT_ACCOUNT]: MutationHelpers.update,
  [types.default.SET_EXTRA_LIMIT](_state, limit) {
    _state.limits = limit;
  },
  [types.default.UPDATE_ACCOUNT]($state, updatedAccount) {
    const index = $state.records.findIndex(record => record.id === updatedAccount.id);
    if (index !== -1) {
      $state.records.splice(index, 1, updatedAccount);
    }
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
