import ApiClient from './ApiClient';

class FileVaultApi extends ApiClient {
    constructor() {
        super('files_vault', { accountScoped: true });
    }
    addFolders(data) {
      return axios.post(`${this.url}/create_folder`, data)
    }
    addFolderAttachment(data) {
      return axios.post(`${this.url}/create_folder_attachment`, data)
    }
    getSignedUrl(data) {
    return axios.post(`${this.url}/get_signed_url`, data, { headers: { 'Content-Type': 'multipart/form-data' }})
   }
    getFolderAttachment(folder_id) {
        return axios.get(`${this.url}/${folder_id}/folder_attachments`)
    }
    deleteFolderFile(data) {
        return axios.post(`${this.url}/delete_folder_attachment`, data)
    }
    fetchVaultRecords(data) {
        return axios.post(`${this.url}/search_folder_attachments`, data)
    }
    fetchFilterRecords(data) {
        return axios.post(`${this.url}/search_folder`, data)
    }
    shareWithAgents(data) {
        return axios.put(`${this.url}/vault_share`, data)
    }
    deleteFolder(data) {
        return axios.post(`${this.url}/delete_folder`, data)
    }
    createVaultLogs(data) {
        return axios.post(`${this.url}/add_vault_logs`, data)
    }
    vaultLogs() {
        return axios.get(`${this.url}/vault_logs`)
    }
    vaultCollection() {
        return axios.get(`${this.url}/conversation_vault`)
    }
    conversationVault(data) {
        return axios.get(`app/vault/customer_vault?vault_id=${data.vault_id}&vault_type=${data.vault_type}`)
    }

}
export default new FileVaultApi();
