<template>
  <header class="header">
    <div class="table-actions-wrap">
      <div class="left-aligned-wrap">
        <h1 class="page-title">
          <woot-sidemenu-icon /> {{ headerTitle ? `#${headerTitle}` :  $t('CONTACTS_PAGE.HEADER') }}
        </h1>
      </div>
      <div class="margin-left-auto">
        <div class="search-wrap">
          <i class="ion-ios-search-strong search-icon" />
          <input
            type="text"
            :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search"
            :value="searchQuery"
            @keyup.enter="onSearchSubmit"
            @input="onInputSearch"
          />
          <woot-button
            :is-loading="false"
            :class-names="searchButtonClass"
            @click="onSearchSubmit"
          >
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>
      </div>

<!--      <div class="margin-left-auto">-->
<!--        <select-->
<!--          v-model="tag"-->
<!--          class="bottom-margin"-->
<!--          @change="exportLabel"-->
<!--        >-->
<!--          <option value="" disabled hidden>-->
<!--            {{ $t('CONTACTS_PAGE.EXPORT_LABEL') }}-->
<!--          </option>-->
<!--          <option-->
<!--            v-for="label in accountLabels"-->
<!--            :key="label.id"-->
<!--            :value="label.title"-->
<!--            :style="{ background: label.color, color: '#FFFFFF' }"-->
<!--          >-->
<!--            {{ label.title}}-->
<!--          </option>-->
<!--        </select>-->
<!--      </div>-->
      <woot-button
        color-scheme="success"
        icon="ion-pricetags"
        @click="openFilterModal = true"
      >
        Select Tags to filter
<!--        {{ $t('CONTACTS_PAGE.APPLY_LABELS') }}-->
      </woot-button>
      <div class="right-aligned-wrap" >
        <woot-button
          color-scheme="success"
          icon="ion-pricetags"
          @click="openmodal = true"
        >
          {{ $t('CONTACTS_PAGE.APPLY_LABELS') }}
        </woot-button>
        <woot-button
          class="button-margins"
          color-scheme="success"
          icon="ion-android-upload"
          @click="onToggleImport"
        >
          {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>
<!--        <button class="button nice icon success button-margins" @click="exportContact">-->
<!--          <i class="icon ion-android-download" style="margin-right: 5px"></i>-->
<!--          <span class="text-margin">{{ $t('CONTACTS_PAGE.EXPORT_CONTACTS') }}</span>-->
<!--        </button>-->

        <button class="button nice icon success button-margins" @click="openExportModal=true">
          <i class="icon ion-android-download" style="margin-right: 5px"></i>
          <span class="text-margin">{{ $t('CONTACTS_PAGE.EXPORT_CONTACTS') }}</span>
        </button>

        <woot-button
          color-scheme="success"
          icon="ion-android-add-circle"
          class="margin-right-small"
          data-testid="create-new-contact"
          @click="onToggleCreate"
        >
          {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
        </woot-button>
      </div>
      <multiple-tags-select-modal
        :show="openmodal"
        @close-multi-tag-modal="closeModal"
      />
      <filter-contacts-multiselect
      :show="openFilterModal"
      @close-filter-tags-modal="filterTags"
      @close-tags-filer="openFilterModal = false"
      />

      <export-contacts-modal :show="openExportModal" @close-export-modal="openExportModal = false" />
    </div>
  </header>
</template>

<script>
import {mapGetters} from "vuex"
import Multiselect from "vue-multiselect";
import MultipleTagsSelectModal from '../../../../components/widgets/modal/MultipleTagsSelectModal.vue';
import FilterContactsMultiselect from '../../../../components/widgets/modal/FilterContactsMultiselect.vue';
import ExportContactsModal from '../../../../components/widgets/modal/ExportContactsModal.vue';

export default {
  components: {
    Multiselect,
    MultipleTagsSelectModal,
    FilterContactsMultiselect,
    ExportContactsModal
  },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    onInputSearch: {
      type: Function,
      default: () => {},
    },
    onSearchSubmit: {
      type: Function,
      default: () => {},
    },
    onToggleCreate: {
      type: Function,
      default: () => {},
    },
    onToggleImport: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      openmodal: false,
      openFilterModal: false,
      showCreateModal: false,
      openExportModal: false,
      showImportModal: false,
      selectedTags: [],
      tag: ''
    };
  },
  computed: {
    ...mapGetters({
      accountLabels: 'labels/getLabels',
    }),
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
  },
  methods: {
    exportContact() {
      this.$store.dispatch('contacts/downloadContactReports')
    },

    exportLabel() {
      if (this.tag != null) {
        this.$store.dispatch('labels/label', this.tag);

      }
    },
    closeModal(){
      this.openmodal=false
    },
    filterTags(tags){
      this.$emit('filter-tags',tags);
      this.openFilterModal = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  margin: 0;
}
.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-small) var(--space-normal) var(--space-small)
  var(--space-normal);
}

.left-aligned-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-margins{
  margin-left: 5px;
  margin-right: 5px;
}
.margin-left-auto {
  margin-left: auto;
}

.right-aligned-wrap {
  padding-left: 10px;
  display: flex;
}

.search-wrap {
  width: 400px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: var(--space-small);

  .search-icon {
    position: absolute;
    top: 1px;
    left: var(--space-one);
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: var(--font-size-medium);
    color: var(--b-700);
  }
  .contact-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-left: var(--space-large);
    padding-right: 6rem;
    border-color: var(--s-100);
  }

  .button {
    margin-left: var(--space-small);
    height: 3.2rem;
    right: var(--space-smaller);
    position: absolute;
    padding: 0 var(--space-small);
    transition: transform 100ms linear;
    opacity: 0;
    transform: translateX(-1px);
    visibility: hidden;
  }

  .button.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  .bottom-margin {
    margin-bottom: 0px ;
  }

  .multiselect-wrap {
    max-width: 300px;
    flex: 1;
    overflow: hidden;
    margin-right: var(--space-small);

    .multiselect__tags {
      flex-wrap: wrap;
    }

    .multiselect__tag {
      margin: 0 5px 5px 0;
    }
  }
}
</style>
