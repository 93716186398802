<template>
    <woot-modal :show.sync="showFileModel" :on-close="onClose">
        <div class="example-full">
            <woot-modal-header
                    :header-title="'Select Files'"
            />
            <div class="column-10 column ml5">
                <span class="access_label"> Access: </span>
                <input
                        id="private"
                        v-model="access"
                        type="radio"
                        name="selectedType"
                        value="private"
                        checked
                        class="label-input"
                />
                <label class="label-tag" for="private">{{
                    $t('FOLDER_MGMT.FORM.ACCESS.LABEL_PRIVATE')
                    }}</label>
                <span></span><span></span>
                <input
                        id="public"
                        v-model="access"
                        type="radio"
                        name="selectedType"
                        value="public"
                        class="label-input"
                />
                <label class="label-tag" for="public">{{
                    $t('FOLDER_MGMT.FORM.ACCESS.LABEL_PUBLIC')
                    }}</label>
            </div>
            <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                <h3>Drop files to upload</h3>
            </div>
            <div class="upload" v-show="!isOption">
                <div class="column-10 column ml5">
                    <table class="woot-table" @dragover.prevent @drop.prevent>
                        <tbody class="table-hover" @drop="dragFile">
                        <tr v-if="!files.length">
                            <td colspan="9">
                                <div class="text-center p-5">
                                    <h4>Drop files anywhere to upload<br/>or</h4>
                                    <label :for="name" class="button">Select Files</label>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="(file, index) in files" :key="file.id">
                            <td>
                                <img class="td-image-thumb" v-if="file.thumb" :src="file.thumb"/>
                                <span v-else>No Image</span>
                            </td>
                            <td>
                                <div class="filename">
                                    {{ file.name }}
                                </div>
                                <div class="progress" v-if="file.active || file.progress !== '0.00'">
                                    <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}"
                                         role="progressbar" :style="{width: file.progress + '%'}">{{ file.progress }}%
                                    </div>
                                </div>
                            </td>
                            <td>{{ formatSize(file.size) }}</td>
                            <td>
                                <i class="ion-android-close" @click.prevent="$refs.upload.remove(file)"></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="column-12 column reverse-row">
                    <file-upload
                            class="button primary margin-1"
                            :extensions="extensions"
                            :accept="accept"
                            :multiple="multiple"
                            :directory="directory"
                            :create-directory="createDirectory"
                            :size="size || 0"
                            :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                            :headers="headers"
                            :data="data"
                            :drop="drop"
                            :drop-directory="dropDirectory"
                            :add-index="addIndex"
                            v-model="files"
                            @input-filter="inputFilter"
                            @input-file="inputFile"
                            ref="upload">
                        <span> <i class="ion ion-plus"></i> Add files </span>
                    </file-upload>
                    <woot-button class="success margin-upload" @click=singleUpload>
                        <i class="ion-ios-cloud-upload-outline icon"></i>
                        Start Upload
                    </woot-button>
<!--                    <pulse-loader :loading="true" v-show="fileLoader" :color="color" :class="'loader_position'" ></pulse-loader>-->
                </div>
            </div>
            <div class="option" v-show="isOption">
                <div class="form-group">
                    <label for="accept">Accept:</label>
                    <input type="text" id="accept" class="form-control" v-model="accept">
                    <small class="form-text text-muted">Allow upload mime type</small>
                </div>
                <div class="form-group">
                    <label for="extensions">Extensions:</label>
                    <input type="text" id="extensions" class="form-control" v-model="extensions">
                    <small class="form-text text-muted">Allow upload file extension</small>
                </div>
                <div class="form-group">
                    <label>PUT Upload:</label>
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="put-action" id="put-action" value=""
                                   v-model="putAction"> Off
                        </label>
                    </div>
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="put-action" id="put-action"
                                   value="/upload/put" v-model="putAction"> On
                        </label>
                    </div>
                    <small class="form-text text-muted">After the shutdown, use the POST method to upload</small>
                </div>
                <div class="form-group">
                    <label for="thread">Thread:</label>
                    <input type="number" max="5" min="1" id="thread" class="form-control" v-model.number="thread">
                    <small class="form-text text-muted">Also upload the number of files at the same time (number of
                        threads)</small>
                </div>
                <div class="form-group">
                    <label for="size">Max size:</label>
                    <input type="number" min="0" id="size" class="form-control" v-model.number="size">
                </div>
                <div class="form-group">
                    <label for="minSize">Min size:</label>
                    <input type="number" min="0" id="minSize" class="form-control" v-model.number="minSize">
                </div>
                <div class="form-group">
                    <label for="autoCompress">Automatically compress:</label>
                    <input type="number" min="0" id="autoCompress" class="form-control" v-model.number="autoCompress">
                    <small class="form-text text-muted" v-if="autoCompress > 0">More than {{ formatSize(autoCompress) }}
                        files are automatically compressed</small>
                    <small class="form-text text-muted" v-else>Set up automatic compression</small>
                </div>

                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" id="add-index" class="form-check-input" v-model="addIndex"> Start
                            position to add
                        </label>
                    </div>
                    <small class="form-text text-muted">Add a file list to start the location to add</small>
                </div>

                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" id="drop" class="form-check-input" v-model="drop"> Drop
                        </label>
                    </div>
                    <small class="form-text text-muted">Drag and drop upload</small>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" id="drop-directory" class="form-check-input" v-model="dropDirectory">
                            Drop directory
                        </label>
                    </div>
                    <small class="form-text text-muted">Not checked, filter the dragged folder</small>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" id="create-directory" class="form-check-input"
                                   v-model="createDirectory"> Create Directory
                        </label>
                    </div>
                    <small class="form-text text-muted">The directory file will send an upload request. The mime type is
                        <code>text/directory</code></small>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" id="upload-auto" class="form-check-input" v-model="uploadAuto"> Auto
                            start
                        </label>
                    </div>
                    <small class="form-text text-muted">Automatically activate upload</small>
                </div>
                <div class="form-group">
                    <button type="button" class="btn btn-primary btn-lg btn-block"
                            @click.prevent="isOption = !isOption">Confirm
                    </button>
                </div>
            </div>
            <div :class="{'modal-backdrop': true, 'fade': true, show: addData.show}"></div>
            <woot-modal :class="{fade: true, show: addData.show}" id="modal-add-data" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <woot-modal-header class="modal-header">
                            <h5 class="modal-title">Add data</h5>
                            <button type="button" class="close" @click.prevent="addData.show = false">
                                <span>&times;</span>
                            </button>
                        </woot-modal-header>
                        <form @submit.prevent="onAddData">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="data-name">Name:</label>
                                    <input type="text" class="form-control" required id="data-name"
                                           placeholder="Please enter a file name" v-model="addData.name">
                                    <small class="form-text text-muted">Such as <code>filename.txt</code></small>
                                </div>
                                <div class="form-group">
                                    <label for="data-type">Type:</label>
                                    <input type="text" class="form-control" required id="data-type"
                                           placeholder="Please enter the MIME type" v-model="addData.type">
                                    <small class="form-text text-muted">Such as <code>text/plain</code></small>
                                </div>
                                <div class="form-group">
                                    <label for="content">Content:</label>
                                    <textarea class="form-control" required id="content" rows="3"
                                              placeholder="Please enter the file contents"
                                              v-model="addData.content"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <woot-button type="button" class="btn btn-secondary"
                                             @click.prevent="addData.show = false">Close
                                </woot-button>
                                <woot-button type="submit" class="btn btn-primary">Save</woot-button>
                            </div>
                        </form>
                    </div>
                </div>
            </woot-modal>
            <div :class="{'modal-backdrop': true, 'fade': true, show: editFile.show}"></div>
            <woot-modal :class="{modal: true, fade: true, show: editFile.show}" id="modal-edit-file" tabindex="-1"
                        role="dialog">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <woot-modal-header class="modal-header">
                            <h5 class="modal-title">Edit file</h5>
                            <woot-button type="button" class="close" @click.prevent="editFile.show = false">
                                <span>&times;</span>
                            </woot-button>
                        </woot-modal-header>
                        <form @submit.prevent="onEditorFile">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="name">Name:</label>
                                    <input type="text" class="form-control" required id="name"
                                           placeholder="Please enter a file name" v-model="editFile.name">
                                </div>
                                <div class="form-group"
                                     v-if="editFile.show && editFile.blob && editFile.type && editFile.type.substr(0, 6) === 'image/'">
                                    <label>Image: </label>
                                    <div class="edit-image">
                                        <img :src="editFile.blob" ref="editImage"/>
                                    </div>

                                    <div class="edit-image-tool">
                                        <div class="btn-group" role="group">
                                            <woot-button type="button" class="btn btn-primary"
                                                         @click="editFile.cropper.rotate(-90)"
                                                         title="cropper.rotate(-90)"><i class="fa fa-undo"
                                                                                        aria-hidden="true"></i>
                                            </woot-button>
                                            <woot-button type="button" class="btn btn-primary"
                                                         @click="editFile.cropper.rotate(90)"
                                                         title="cropper.rotate(90)"><i class="fa fa-repeat"
                                                                                       aria-hidden="true"></i>
                                            </woot-button>
                                        </div>
                                        <div class="btn-group" role="group">
                                            <woot-button type="button" class="btn btn-primary"
                                                         @click="editFile.cropper.crop()" title="cropper.crop()"><i
                                                    class="fa fa-check" aria-hidden="true"></i></woot-button>
                                            <woot-button type="button" class="btn btn-primary"
                                                         @click="editFile.cropper.clear()" title="cropper.clear()"><i
                                                    class="fa fa-remove" aria-hidden="true"></i></woot-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" @click.prevent="editFile.show = false">
                                    Close
                                </button>
                                <button type="submit" class="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </woot-modal>
        </div>
    </woot-modal>
</template>

<script>
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import alertMixin from 'shared/mixins/alertMixin';
import * as aws from 'aws-sdk';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


export const s3 = new aws.S3({
    signatureVersion: 'v4',
    region: window.chatwootConfig.awsRegion,
})

export default {
    mixins: [alertMixin],
    components: {
        FileUpload,
        PulseLoader
    },
    computed: {
        config(){
            return {
                bucketName: window.chatwootConfig.s3BucketName,
                dirName: window.chatwootConfig.awsDirectoryName,
                region: window.chatwootConfig.awsRegion,
                awsBaseUrl:  window.chatwootConfig.awsBaseUrl,
            }
        },
        S3Client(){
            return new S3(this.config);
        },
        newFileName(){
            return Math.random().toString().slice(2)
        },
    },
    data() {
        return {
            showFileModel: true,
            files: [],
            accept: 'image/png,image/jpeg,image/webp,.pdf,.doc,.docx,.txt,.rtf,.wpd,.tiff,.tif,.gif,.png,.mp3,.wav,' +
                '.aac,.aiff,.m4a,.wma,.mp4,.avi,.mov,.mpg,.flv,.zip,.ppt,.xlsx,.xls,.pptx,.psd,.eps,.vsd,.vst,.vss',
            extensions: 'jpg,jpeg,png,webp,pdf,doc,docx,txt,rtf,wpd,tiff,tif,gif,png,mp3,wav,' +
                'aac,aiff,m4a,wma,mp4,avi,mov,mpg,flv,zip,ppt,xlsx,xls,pptx,psd,eps,vsd,vst,vss',
            minSize: 1024,
            size: 1024 * 1024 * 10,
            multiple: true,
            directory: false,
            drop: true,
            dropDirectory: true,
            createDirectory: false,
            addIndex: false,
            thread: 3,
            name: 'file',
            postAction: '/upload/post',
            putAction: '/upload/put',
            headers: {
                'X-Csrf-Token': 'xxxx',
            },
            data: {
                '_csrf_token': 'xxxxxx',
            },
            autoCompress: 1024 * 1024,
            uploadAuto: false,
            isOption: false,
            addData: {
                show: false,
                name: '',
                type: '',
                content: '',
            },
            editFile: {
                show: false,
                name: '',
            },
            S3Folder: '',
            access: 'private',
            color: 'black',
            fileLoader: false,
        }
    },
    watch: {
        'editFile.show'(newValue, oldValue) {
            if (!newValue && oldValue) {
                this.$refs.upload.update(this.editFile.id, { error: this.editFile.error || '' })
            }

            if (newValue) {
                this.$nextTick( () => {
                    if (!this.$refs.editImage) {
                        return
                    }
                    let cropper = new Cropper(this.$refs.editImage, {
                        autoCrop: false,
                    })
                    this.editFile = {
                        ...this.editFile,
                        cropper
                    }
                })
            }
        },

        'addData.show'(show) {
            if (show) {
                this.addData.name = ''
                this.addData.type = ''
                this.addData.content = ''
            }
        },
    },
    props: {
      onClose: {
          type: Function,
          required: true,
      },
      folderId: {
          type: String,
          required: false,
      }
    },
    methods: {

      async fileUploads() {
        let vm =  this;
        vm.S3Folder = vm.config.dirName;
        const formData = new FormData();
        const bucketUrl = vm.config.awsBaseUrl;
        formData.append('bucket_url', bucketUrl);
        formData.append('access', vm.access);
        formData.append('s3_folder', vm.S3Folder);
        formData.append('folder_id', vm.folderId);

        this.files.forEach((file, index) => {
          formData.append(`files[${index}][size]`, file.size);
          formData.append(`files[${index}][name]`, file.name);
          formData.append(`files[${index}][type]`, file.type);
          formData.append(`files[${index}][active]`, file.active);
          formData.append(`files[${index}][error]`, file.error);
          formData.append(`files[${index}][success]`, file.success);
          formData.append(`files[${index}][timeout]`, file.timeout);
          formData.append(`files[${index}][file]`, file.file);
          formData.append(`files[${index}][id]`, file.id);
          formData.append(`files[${index}][blob]`, file.blob);
          formData.append(`files[${index}][thumb]`, file.thumb);
          formData.append(`files[${index}][height]`, file.height);
          formData.append(`files[${index}][width]`, file.width);
          formData.append(`files[${index}][data][_csrf_token]`, file.data._csrf_token);
          formData.append(`files[${index}][headers][X-Csrf-Token]`, file.headers["X-Csrf-Token"]);
        });

          await this.$store.dispatch('get_signed_url', formData
          ).then(result => {
            if(result.status=='200')
            {
              let files = result.data.data
              for (let file of files)
              {
                file.vault_folder_id = vm.folderId
                vm.saveFile(file)
              }
            }
            })
            .catch(err => {
              // TODO: error handling
              this.showAlert(err);
              console.log(err)
            })
      },
      singleUpload() {
            let vm =  this;
            vm.S3Folder = vm.config.dirName;
            this.fileUploads();
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Before adding a file
                // Filter system files or hide files
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                }

                // Filter php html js file
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                }

                // Automatic compression
                if (newFile.file && newFile.error === "" && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
                    newFile.error = 'compressing'
                }
            }

            if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
                // Create a blob field
                newFile.blob = ''
                let URL = (window.URL || window.webkitURL)
                if (URL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }

                // Thumbnails
                newFile.thumb = ''
                if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                    newFile.thumb = newFile.blob
                }
            }

            // image size
            if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
                newFile.error = 'image parsing'
                let img = new Image();
                img.onload = () => {
                    this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
                }
                img.οnerrοr = (e) => {
                    this.$refs.upload.update(newFile, { error: 'parsing image size'})
                }
                img.src = newFile.blob
            }
        },
        dragFile(e) {
            this.File = e.dataTransfer.files;
        },
        formatSize(size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size.toString() + ' B'
        },
        // add, update, remove File Event
        inputFile(newFile, oldFile) {
            if (newFile && oldFile) {
                // update
                if (newFile.active && !oldFile.active) {
                    // beforeSend
                    // min size
                    if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
                        this.$refs.upload.update(newFile, { error: 'size' })
                    }
                }

                if (newFile.progress !== oldFile.progress) {
                    // progress
                }

                if (newFile.error && !oldFile.error) {
                    // error
                }

                if (newFile.success && !oldFile.success) {
                    // success
                }
            }

            if (!newFile && oldFile) {
                // remove
                if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/upload/delete?id=' + oldFile.response.id,
                    // })
                }
            }
            // Automatically activate upload
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (this.uploadAuto && !this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        alert(message) {
            alert(message)
        },
        onEditFileShow(file) {
            this.editFile = { ...file, show: true }
            this.$refs.upload.update(file, { error: 'edit' })
        },
        onEditorFile() {
            if (!this.$refs.upload.features.html5) {
                this.alert('Your browser does not support')
                this.editFile.show = false
                return
            }

            let data = {
                name: this.editFile.name,
                error: '',
            }
            if (this.editFile.cropper) {
                let binStr = atob(this.editFile.cropper.getCroppedCanvas().toDataURL(this.editFile.type).split(',')[1])
                let arr = new Uint8Array(binStr.length)
                for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }
                data.file = new File([arr], data.name, { type: this.editFile.type })
                data.size = data.file.size
            }
            this.$refs.upload.update(this.editFile.id, data)
            this.editFile.error = ''
            this.editFile.show = false
        },
        // add folder
        onAddFolder() {
            if (!this.$refs.upload.features.directory) {
                this.alert('Your browser does not support')
                return
            }
            let input = document.createElement('input')
            input.style = "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;"
            input.type = 'file'
            input.setAttribute('allowdirs', true)
            input.setAttribute('directory', true)
            input.setAttribute('webkitdirectory', true)
            input.multiple = true
            document.querySelector("body").appendChild(input)
            input.click()
            input.onchange = (e) => {
                this.$refs.upload.addInputFile(input).then(function() {
                    document.querySelector("body").removeChild(input)
                })
            }
        },
        onAddData() {
            this.addData.show = false
            if (!this.$refs.upload.features.html5) {
                this.alert('Your browser does not support')
                return
            }

            let file = new window.File([this.addData.content], this.addData.name, {
                type: this.addData.type,
            })
            this.$refs.upload.add(file)
        },
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        async saveFile(params) {
            try {
                await this.$store.dispatch('save_attachment', params);
                this.showAlert(`${params?.name} upload successfully`)
                this.onClose();
                this.$store.dispatch('addLog', {
                    logType: 'file',
                    logAction: 'upload',
                    name: params?.name,
                    fileUrl: `${window.chatwootConfig.fileCloudUrl}/${params.short_path}`
                });
            } catch (error) {
                this.showAlert(`error in upload file ${params?.name}`);
            }
        },
    }
}
</script>
<style scoped>
.example-full .btn-group .dropdown-menu {
    display: block;
    visibility: hidden;
    transition: all .2s
}
.example-full .btn-group:hover > .dropdown-menu {
    visibility: visible;
}
.example-full label.dropdown-item {
    margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
    margin-right: .6rem
}
.td-image-thumb {
    max-width: 4em;
    max-height: 4em;
}
.example-full .filename {
    margin-bottom: .3rem
}
.example-full .btn-is-option {
    margin-top: 0.25rem;
}
.example-full .example-foorer {
    padding: .5rem 0;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
}
.example-full .edit-image img {
    max-width: 100%;
}
.example-full .edit-image-tool {
    margin-top: .6rem;
}
.example-full .edit-image-tool .btn-group{
    margin-right: .6rem;
}
.example-full .footer-status {
    padding-top: .4rem;
}
.example-full .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: .6;
    text-align: center;
    background: #000;
}
.example-full .drop-active h3 {
    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
}
.margin-1 {
    margin: 1rem;
}
.margin-upload {
    margin: 1rem 0rem 1rem;
}

.reverse-row {
    display: flex;
    flex-direction: row-reverse;
}
.file-uploads {
    display: inline-grid !important;
}
.ml5 {
    margin-left: 5%;
}
.table-hover:hover {
    background-color: #f8f8f8 !important;
}
.label-input {
    margin-right: 0px !important;
}
.label-tag {
    margin-left: 0px !important;
}
.access_label {
    font-size: 18px;
    font-weight: 500;
    margin-right: 1rem;
}
.loader_position {
    align-self: center;
    margin-right: 15%;
}
</style>
