<template>
  <div class="row app-wrapper">
    <sidebar :route="currentRoute" :class="sidebarClassName"></sidebar>
    <section class="app-content columns" :class="contentClassName">
      <router-view></router-view>
      <command-bar />
    </section>
    <TheSIPEndpoint />
    <MissedCallMenu />
    <TheRelaySIPEndpoint />
    <div class="flex row hidden-iframe">
      <div class="columns medium-12">
        <pulse-loader :loading="true" :color="color"></pulse-loader>
        <iframe
          :key="iframeKey"
          :src="generateDigitalSign"
          allow="clipboard-read; clipboard-write"
          sandbox="allow-scripts allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation"
          style="height: calc(100vh - 66px);border: none;"
          width="100%"
          height="80%"
        ></iframe>
      </div>
    </div>
    <div
      v-if="
        all_limits.account_user &&
          all_limits.account_user.popup &&
          user_role.role === 'administrator'
      "
    >
      <woot-modal
        :show="showLimitModel"
        :on-close="closeDeletePopup"
        class="account-selector--modal"
      >
        <div class="column content-box">
          <woot-modal-header
            :header-title="'Credit issue'"
            :header-content="
              'Please pay for services or remove extra agents and inboxes'
            "
          />
          <div class="row mt-2">
            <div class="medium-8 columns medium-offset-1">
              <label :class="{ error: $v.selectedAgents.$error }">
                <multiselect
                  v-model="selectedAgents"
                  :options="this.agent_list"
                  track-by="id"
                  label="name"
                  :multiple="true"
                  :close-on-select="false"
                  open-direction="bottom"
                  :clear-on-select="false"
                  :hide-selected="true"
                  selected-label
                  :min="agentSubtract"
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                  :placeholder="'Pick agents for remove'"
                  @select="$v.selectedAgents.$touch"
                >
                </multiselect>
                <span v-if="$v.selectedAgents.$error" class="message">
                  {{
                    $t('FORMS.MULTISELECT.AGENT_REMOVE_VALIDATION_ERROR', {
                      min_agent: agentSubtract,
                    })
                  }}
                </span>
              </label>
            </div>
          </div>
          <div class="row mt-2">
            <div class="medium-8 columns medium-offset-1">
              <label :class="{ error: $v.selectedInboxes.$error }">
                <multiselect
                  v-model="selectedInboxes"
                  :options="this.inbox_list"
                  track-by="id"
                  label="name"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="true"
                  selected-label
                  open-direction="bottom"
                  :min="inboxSubtract"
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                  :placeholder="'Pick inboxes for remove'"
                  @select="$v.selectedInboxes.$touch"
                >
                </multiselect>
                <span v-if="$v.selectedInboxes.$error" class="message">
                  {{
                    $t('FORMS.MULTISELECT.INBOX_REMOVE_VALIDATION_ERROR', {
                      min_inbox: inboxSubtract,
                    })
                  }}
                </span>
              </label>
            </div>
          </div>
          <div class="row mt-2">
            <div class="medium-4 medium-offset-1 columns">
              <p>
                Remove minimum agents:
                {{ agentSubtract > 0 ? agentSubtract : 0 }}
              </p>
              <p>
                Remove minimum inboxes:
                {{ inboxSubtract > 0 ? inboxSubtract : 0 }}
              </p>
            </div>
            <div class="medium-4 columns text-right">
              <woot-button :is-disabled="false" @click="confirmDeletion">
                {{ 'Remove' }}
              </woot-button>
            </div>
          </div>
        </div>
      </woot-modal>
    </div>
    <div
      v-else-if="
        all_limits.account_user &&
          all_limits.account_user.popup &&
          user_role.role !== 'administrator'
      "
    >
      <woot-modal
        :show="showLimitModel"
        :on-close="closeDeletePopup"
        class="account-selector--modal-agent"
      >
        <div class="column content-box">
          <woot-modal-header
            :header-title="'Credit issue'"
            :header-content="'Contact with admin to pay for extra services.'"
          />
        </div>
      </woot-modal>
    </div>
  </div>
</template>

<script>
import Sidebar from '../../components/layout/Sidebar';
import TheSIPEndpoint from '../../components/TheSIPEndpoint/TheSIPEndpoint';
import MissedCallMenu from '../../components/MissedCallMenu';
import TheRelaySIPEndpoint from '../../components/TheSIPEndpoint/TheRelaySIPEndpoint';
import { mapGetters } from 'vuex';
import CommandBar from './commands/commandbar.vue';

import { minLength } from 'vuelidate/lib/validators';

export default {
  components: {
    Sidebar,
    CommandBar,
    MissedCallMenu,
    TheSIPEndpoint,
    TheRelaySIPEndpoint,
  },
  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
    selectedInboxes: {
      isEmpty() {
        return !!this.selectedInboxes.length;
      },
    },
  },
  data() {
    return {
      isSidebarOpen: false,
      isOnDesktop: true,
      agent_list: {},
      inbox_list: {},
      selectedAgents: [],
      selectedInboxes: [],
      account_user: '',
      user_limits: '',
      user_role: '',
      closePopup: false,
      color: 'black',
      signDocsLink: '',
      iframeKey: 1,
      activeUser: '',
    };
  },
  computed: {
    ...mapGetters({
      all_limits: 'accounts/getAllLimits',
      dashboardAction: 'dashboard/getDashboardAction',
      currentUser: 'getCurrentUser',
    }),

    generateDigitalSign() {
      const encodedEmail = encodeURIComponent(this.currentUser?.email);
      this.signDocsLink = `${window.chatwootConfig.signDocsUrl}/#/?parent=conversate&name=${this.currentUser?.name}&reference_id=${this.currentUser?.account_id}&email=${encodedEmail}&digest=${encodedEmail}`;
      return this.signDocsLink;
    },
    currentRoute() {
      return ' ';
    },
    sidebarClassName() {
      if (this.isOnDesktop) {
        return '';
      }
      if (this.isSidebarOpen) {
        return 'off-canvas is-open ';
      }
      return 'off-canvas position-left is-transition-push is-closed';
    },
    contentClassName() {
      if (this.isOnDesktop) {
        return '';
      }
      if (this.isSidebarOpen) {
        return 'off-canvas-content is-open-left has-transition-push has-position-left';
      }
      return 'off-canvas-content';
    },
    inboxSubtract() {
      let inbox = this.user_limits;
      return inbox.current_inbox_count - inbox.total_inbox_limit;
    },
    agentSubtract() {
      let agent = this.user_limits;
      return agent.current_agent_count - agent.total_agent_limit;
    },
  },
  watch: {
    dashboardAction: async function(value) {
      if (value) {
        if (value === 'CLOSE_SIDEBAR') {
          this.isSidebarOpen = false;
        }

        this.$store.commit('dashboard/SET_DASHBOARD_ACTION', null);
      }
    },
    currentUser(newValue, oldValue) {
      // Handle changes to currentUser here
      this.activeUser = newValue;
    },
  },
  mounted() {
    this.$store.dispatch('setCurrentAccountId', this.$route.params.accountId);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    bus.$on('sidemenu_icon_click', () => {
      this.isSidebarOpen = !this.isSidebarOpen;
    });
    this.getLimitDetails();
    this.showLimitModel();
    this.$store.dispatch('inboxes/get');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isOnDesktop = window.innerWidth > 1200;
    },
    // Delete Function
    showLimitModel() {
      return this.agentSubtract > 0 || this.inboxSubtract > 0;
    },
    closeDeletePopup() {
      return !(
        this.selectedAgents.length < this.agentSubtract ||
        this.selectedInboxes.length < this.inboxSubtract
      );
    },
    confirmDeletion() {
      if (
        this.selectedAgents.length < this.agentSubtract ||
        this.selectedInboxes.length < this.inboxSubtract
      ) {
        bus.$emit('newToastMessage', 'Please select agent and inboxes.');
      } else {
        var agent_ids = this.selectedAgents.map(agent => agent.id);
        var inbox_ids = this.selectedInboxes.map(inbox => inbox.id);
        this.deleteExtraLimits(agent_ids, inbox_ids);
      }
    },
    async deleteExtraLimits(agent_ids, inbox_ids) {
      let data = { agent_ids: agent_ids, inbox_ids: inbox_ids };
      try {
        await this.$store
          .dispatch('accounts/removeExtraLimit', data)
          .then(() => {
            bus.$emit('newToastMessage', 'updated successfully.');
            window.location.reload();
          });
      } catch (error) {
        throw error;
      }
    },
    async getLimitDetails() {
      await this.$store
        .dispatch('accounts/getExtraLimit', this.$route.params.accountId)
        .then(response => {
          this.agent_list = response.agents;
          this.inbox_list = response.inboxes;
          this.account_user = response.account_user;
          this.user_limits = response.limits;
          this.user_role = response.user_role;
        });
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.account-selector--modal .modal-container {
  width: 90rem !important;
  height: 38rem !important;
}
.mt-2 {
  margin-top: 2rem;
}

.multiselect__content-wrapper {
  max-height: 600px !important;
}
.account-selector--modal-agent {
  .modal-container {
    width: 55rem !important;
    height: 12rem !important;
  }
}
.hidden-iframe {
  display: none;
}
.account-selector--modal
  .modal-container
  .tree-level1
  .tree-level2
  .treeselectClass
  .vue-treeselect__control
  .vue-treeselect__value-container
  .vue-treeselect__input-container
  .vue-treeselect__input {
  border: none !important;
}
</style>
