<template>
  <woot-modal
    :show.sync="show"
    :on-close="onClose"
    modal-type="right-aligned"
    :close-on-backdrop-click="false"
  >
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.AI_AGENT.HEADING')"
        :header-content="$t('AGENT_MGMT.AI_AGENT.SUB_HEADING')"
      />
      <agent-form
        :in-progress="uiFlags.isUpdating"
        :on-submit="onSubmit"
        @success="onSuccess"
        @cancel="onClose"
      />
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import AgentForm from './AgentForm.vue';

export default {
  components: { AgentForm },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: 'agent',
      agentConversationLimits: 1,
      vertical: 'bottom',
      horizontal: 'center',
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'infinite'],
      show: true,
      inboxes: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      inboxesList: 'inboxes/getInboxes',
    }),

    filteredInbox() {
      return this.inboxesList.filter(inbox => {
        return inbox.agent_limit > inbox.inbox_members_count;
      });
    },
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
    agentConversationLimits: {
      required,
    },
  },
  async mounted() {
    await this.$store.dispatch('inboxes/get');
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAgent() {
      const selectedInboxes = this.inboxes?.map(x => x.id);
      try {
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType,
          limits: this.agentConversationLimits,
          inboxes: selectedInboxes,
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        if (error.response.status === 422) {
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE'));
        } else {
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
        }
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
    async onSubmit(contactItem) {
      await this.$store.dispatch('contacts/create', contactItem);
    },
  },
};
</script>
<style scoped>
.modal-container--full-width {
  width: 100rem !important;
}

.multiselect__content-wrapper {
  max-height: 300px !important;
}

.modal-container {
  min-width: 100rem !important;
}

.modal-mask.right-aligned .modal-container {
  width: 100rem !important;
}

.modal-mask.right-aligned {
  justify-content: flex-end;

  .modal-container {
    border-radius: 0;
    width: 90rem !important;
  }
}

.page-top-bar {
  padding: 1rem 3.2rem 0px 2rem !important;
}
</style>
