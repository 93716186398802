<template>
  <button
    class="custom-button"
    @click="$emit('click')"
  >
    <img v-if = "title=='Microsoft'" src="../assets/images/channels/microsoft.png" :alt="title" class="img-class" />
    <img v-if = "title=='Google'" src="../assets/images/channels/google.png" :alt="title" class="img-class" />
    <img v-if = "title=='Other Providers'" src="../assets/images/channels/email.png" :alt="title" class="img-class" />
    <h3
      class="text-slate-800 dark:text-slate-100 text-base text-center capitalize"
    >
      {{ title }}
    </h3>
  </button>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.inactive {
  img {
    filter: grayscale(100%);
    max-height: 200px;
  }
}

.custom-button {
  width: 30%;
  padding: 20px;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-in;
  margin: -1px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}

.custom-button:hover {
  border-color: #2b6cb0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.custom-button:disabled {
  opacity: 0.6;
}

//@media (prefers-color-scheme: dark) {
//  .custom-button {
//    background-color: #1a202c;
//  }
//  .custom-button:hover {
//    border-color: #2b6cb0;
//  }
//}

.img-class {
  max-height: 300px;
  width: 60%;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: auto;
  margin-top: auto;
}
</style>
