import * as types from '../mutation-types';
import signalwireChannel from "../../api/channel/signalwireChannel";
import {requestStatusTypes} from "../../routes/dashboard/settings/inbox/channels/signalwire/constants/RequestStatusType";

export const state = {
    availablePhoneNumbers: [],
    requestStatus: requestStatusTypes.IDLE,
    errorMessage: null
};

export const getters = {
  getAvailablePhoneNumbers($state) {
      return $state.availablePhoneNumbers;
  },
  getRequestStatus($state) {
      return $state.requestStatus;
  }
};

export const mutations = {
    [types.default.SET_AVAILABLE_PHONE_NUMBERS]($state, payload) {
        $state.availablePhoneNumbers = payload;
    },
    [types.default.SET_REQUEST_STATUS]($state, payload) {
        $state.requestStatus = payload;
    },
    [types.default.SET_ERROR_MESSAGE]($state, payload) {
        $state.errorMessage = payload;
    }
}

export const actions = {
    getAvailablePhoneNumbersAsync: async ({commit}, payload) => {
        commit(types.default.SET_REQUEST_STATUS, requestStatusTypes.LOADING);

        try {
            const response = await signalwireChannel.getAvailableNumbers(payload);

            if (response.status === 200) {
                commit(types.default.SET_AVAILABLE_PHONE_NUMBERS, response.data)

                commit(types.default.SET_REQUEST_STATUS, requestStatusTypes.SUCCEEDED);
            } else {
                commit(types.default.SET_REQUEST_STATUS, requestStatusTypes.FAILED);

                commit(types.default.SET_AVAILABLE_PHONE_NUMBERS, [])
            }

            //console.log(response);
        }
        catch (e) {
            commit(types.default.SET_REQUEST_STATUS, requestStatusTypes.FAILED);

            commit(types.default.SET_AVAILABLE_PHONE_NUMBERS, []);

            //console.log(e);
        }
    },

    postBusinessInformationAsync: async ({commit}, payload) => {
        try {
            const response = await signalwireChannel.postBusinessInformation(payload);

            //console.log(response);
        }
        catch (e) {
            //console.log(e);
        }
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


