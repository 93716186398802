<template>
  <transition name="network-notification-fade" tag="div">
    <div v-show="show" class="ui-notification-container">
      <div class="ui-notification" v-on:click="showNotifications">
        <div class="ui-icon-bell">
          <span class="notifications icon ion-ios-bell"></span>
        </div>
        <div class="ui-notification-text">
          New notification
        </div>
        <button class="ui-close-button" v-on:click.stop="closeNotification">
          <i class="ui-close-icon icon ion-ios-close-outline" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      mute_audio_flag: false,
    }
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
      showNotification: 'showNotificationAlert/getShowNotification',
      unreadFlag: 'showNotificationAlert/getUnreadFlag',
      newUnreadCount: 'showNotificationAlert/getNewUnreadCount'
    }),
    isAuthorized(){
      if(this.accountId){
        return true
      }
      else{
        return false
      }
    },
    show(){
      if(this.showNotification && this.isAuthorized){
        if(!this.mute_audio_flag){
          this.enableAudioAlert();
          this.mute_audio_flag = true;
        }
        return true;
      }
      else return false
    }
  },
  methods: {
    enableAudioAlert(){
      const {
        audio_name: audioName = "ding.mp3",
      } = this.$store.getters.getUISettings;
      var audio = new Audio(`/dashboard/audios/${audioName}`);
      audio.play();
    },
    closeNotification() {
      this.$store.commit("showNotificationAlert/toogleShowNotification", false);
      this.mute_audio_flag = false;
    },
    showNotifications(){
      this.$store.commit("showNotificationAlert/toogleShowNotification", false);
      if(this.$route.path === `/app/accounts/${this.accountId}/notifications`){
        this.$router.go();
      }
      else{
        this.$router.push(`/app/accounts/${this.accountId}/notifications`);
      }
      this.mute_audio_flag = false;
    }
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/mixins';

.ui-notification-container {
  max-width: 40rem;
  position: absolute;
  right: var(--space-normal);
  top: var(--space-normal);
  width: 100%;
  z-index: 9999;
}

.ui-notification {
  &:hover {
    cursor: default;
  }
  @include shadow;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  border-radius: var(--space-one);
  display: flex;
  justify-content: space-between;
  max-width: 40rem;
  min-height: 3rem;
  min-width: 24rem;
  padding: var(--space-normal) var(--space-two);
  text-align: left;
}
.ui-icon-bell {
  font-size: var(--font-size-large);
}

.ui-notification-text {
  margin: 0;
  font-size: var(--font-size-small);
}

.ui-close-icon {
  color: var(--b-600);
  font-size: var(--font-size-large);
}

.ui-close-button {
  &:hover {
    cursor: pointer;
  }
}
</style>
