import types from '../mutation-types';
import FileVaultApi from "../../api/fileVault";
import Vue from "vue";

export const state = {
    folders: [],
    folderFiles: [],
    folderAttachments: [],
    vaultLogs: [],
    vaultCollection: [],
};

export const getters = {
    getFolder: $state => id => {
        return $state.folders.find(folder => folder.id === Number(id));
    },
    getAllFolders($state) {
        return $state.folders;
    },
    getFolderLinkedFiles($state) {
        return $state.folderFiles;
    },
    getFoldersAttachments($state) {
        return $state.folderAttachments;
    },
    getVaultLogs($state) {
        return $state.vaultLogs;
    },
    getConversationVault($state) {
        return $state.vaultCollection;
    }
};

export const actions = {
    get: async ({ commit }) => {
        try {
            const response = await FileVaultApi.get();
            commit(types.SET_FOLDER, response.data.filter(folder => folder.folder_type !== 'file'));
            commit(types.SET_FOLDER_LINKED_FILE, response.data.filter(file => file.folder_type === 'file'));
        } catch (error) {
            throw new Error(error);
        }
    },
    delete: async ({ commit }, data) => {
        try {
            await FileVaultApi.deleteFolder(data);
            commit(types.DELETE_FOLDER, data.id);
        } catch (error) {
            throw new Error(error);
        }
    },
    update: async ({ commit }, { id, ...folderParams }) => {
        try {
            const response = await FileVaultApi.update(id, folderParams);
            commit(types.EDIT_FOLDER, response.data);
        } catch (error) {
            throw new Error(error);
        }
    },
    add_folder: async  ({commit}, obj) => {
        try {
            const response = await FileVaultApi.addFolders(obj);
            commit(types.ADD_FOLDER, response.data)
        }catch (e) {
            throw new Error(error);
        }
    },
    save_attachment: async  ({commit}, obj) => {
        try {
            const response = await FileVaultApi.addFolderAttachment(obj);
            if(response.data?.vault_folder_id) {
                commit(types.ADD_FOLDER_FILE, response.data)
            }else {
                commit(types.ADD_FOLDER_LINKED_FILE, response.data)
            }
        }catch (e) {
            throw new Error(error);
        }
    },
  get_signed_url: async  ({commit}, file) => {
    try {
      const response = await FileVaultApi.getSignedUrl(file);
      if(response) {
        return  response
      }
    }catch (e) {
      throw new Error(error);
    }
  },
    get_attachments: async ({ commit }, id) => {
        try {
            const response = await FileVaultApi.getFolderAttachment(id);
            commit(types.SET_FOLDER_FILE, response.data);
        } catch (error) {
            throw new Error(error);
        }
    },
    delete_folder_files: async ({ commit }, data) => {
        try {
            const response =  await FileVaultApi.deleteFolderFile(data);
            if(response.data?.vault_folder_id) {
                commit(types.DELETE_FOLDER_FILE, response.data?.id)
            }else {
                commit(types.DELETE_FOLDER_LINKED_FILE, response.data?.id)
            }
        } catch (error) {
            throw new Error(error);
        }
    },
    search_records: async ({ commit }, data) => {
        try {
            const response = await FileVaultApi.fetchVaultRecords(data);
            return response.data;
        } catch (error) {
            throw new Error(error);
        }
    },
    filter_records: async ({ commit }, data) => {
        try {
            const response = await FileVaultApi.fetchFilterRecords(data);
            return response.data;
        } catch (error) {
            throw new Error(error);
        }
    },
    set_files_record: ({ commit }, data) => {
        try {
            commit(types.SET_FOLDER_FILE, data);
        } catch (e) {
            throw new Error(error);
        }
    },
    set_all_folders: ({ commit }, data) => {
        try {
            commit(types.SET_FOLDER, data);
        } catch (e) {
            throw new Error(error);
        }
    },
    set_all_files: ({ commit }, data) => {
        try {
            commit(types.SET_FOLDER_LINKED_FILE, data);
        } catch (e) {
            throw new Error(error);
        }
    },
    share_agents: async ({commit}, data) => {
        try {
            await FileVaultApi.shareWithAgents(data);
        } catch (e) {
            throw new Error(error);
        }
    },
    addLog: async ({commit}, data) => {
        try {
            const resp = await FileVaultApi.createVaultLogs(data);
            commit(types.ADD_VAULT_LOGS, resp.data)
            return resp.data;
        } catch (e) {
            throw new Error(error);
        }
    },
    getLogs: async ({commit}) => {
        try {
            const resp = await FileVaultApi.vaultLogs();
            commit(types.SET_VAULT_LOGS, resp.data)
            return resp.data;
        } catch (e) {
            throw new Error(error);
        }
    },
    conversation_vault: async ({commit}) => {
        try {
            const resp = await FileVaultApi.vaultCollection();
            commit(types.SET_VAULT_CONVERSATION_COLLECTION, resp.data)
            return resp.data;
        } catch (e) {
            throw new Error(error);
        }
    },
    conversation_vault_details: async ({commit}, data) => {
        try {
            const resp = await FileVaultApi.conversationVault(data);
            return resp.data;
        } catch (e) {
            throw new Error(error);
        }
    }

};

export const mutations = {

    [types.SET_FOLDER]($state, data) {
        $state.folders = data;
    },
    [types.ADD_FOLDER]($state, folders) {
        $state.folders.push(folders);
    },
    [types.DELETE_FOLDER]($state, id) {
        let index = $state.folders.findIndex(folder => folder.id === id);
        $state.folders.splice(index, 1)
    },
    [types.EDIT_FOLDER]($state, data) {
        $state.folders.forEach((folder, index) => {
            if (folder.id === data.id) {
                Vue.set($state.folders, index, { ...$state.folders[index], ...data });
            }
        });
    },
    [types.EDIT_FOLDER_SHARE]($state, data) {
        $state.folders.forEach((element, index) => {
            if (element.id === data.id) {
                Vue.set($state.folders, index, { ...$state.folders[index], share_with: data.share_with });
            }
        });
    },

    // inside folder attachments
    [types.ADD_FOLDER_FILE]($state, files) {
        $state.folderAttachments.push(files);
    },
    [types.DELETE_FOLDER_FILE]($state, id) {
        let index = $state.folderAttachments.findIndex(file => file.id === id);
        $state.folderAttachments.splice(index, 1)
    },
    [types.SET_FOLDER_FILE]($state, data) {
        $state.folderAttachments = data;
    },
    [types.EDIT_FOLDER_FILE]($state, data) {
        $state.folderAttachments.forEach((element, index) => {
            if (element.id === data.id) {
                Vue.set($state.folderAttachments, index, { ...$state.folderAttachments[index], share_with: data.share_with });
            }
        });
    },


    // outside folder attachments or attachment at base level
    [types.SET_FOLDER_LINKED_FILE]($state, data) {
        $state.folderFiles = data;
    },
    [types.DELETE_FOLDER_LINKED_FILE]($state, id) {
        let index = $state.folderFiles.findIndex(file => file.id === id);
        $state.folderFiles.splice(index, 1)
    },
    [types.ADD_FOLDER_LINKED_FILE]($state, file) {
        $state.folderFiles.push(file);
    },
    [types.EDIT_FOLDER_LINKED_FILE]($state, data) {
        $state.folderFiles.forEach((element, index) => {
            if (element.id === data.id) {
                Vue.set($state.folderFiles, index, { ...$state.folderFiles[index], share_with: data.share_with });
            }
        });
    },

    // add logs for vault logs
    [types.SET_VAULT_LOGS]($state, data) {
        $state.vaultLogs = data;
    },
    [types.ADD_VAULT_LOGS]($state, data) {
        $state.vaultLogs.push(data);
    },
    [types.SET_VAULT_CONVERSATION_COLLECTION]($state, data) {
        $state.vaultCollection = data;
    },
};

export default {
    namespace: true,
    state,
    getters,
    mutations,
    actions,
};
