<template>
  <div class="reply-box" :class="replyBoxClass">
    <reply-top-panel
      :mode="replyType"
      :set-reply-mode="setReplyMode"
      :is-message-length-reaching-threshold="isMessageLengthReachingThreshold"
      :characters-remaining="charactersRemaining"
      :popout-reply-box="popoutReplyBox"
      @click="$emit('click')"
      @vault="vaultModel"
    />
    <div class="reply-box__top">
      <canned-response
        v-if="showMentions && hasSlashCommand"
        v-on-clickaway="hideMentions"
        :search-key="mentionSearchKey"
        @click="replaceText"
      />
      <emoji-input
        v-if="showEmojiPicker"
        v-on-clickaway="hideEmojiPicker"
        :on-click="emojiOnClick"
      />
      <reply-email-head
        v-if="showReplyHead"
        :clear-mails="clearMails"
        @set-emails="setCcEmails"
      />
      <resizable-text-area
        ref="messageInput"
        v-model="message"
        class="input"
        spellcheck="true"
        :placeholder="messagePlaceHolder"
        :min-height="4"
        @typing-off="onTypingOff"
        @typing-on="onTypingOn"
        @focus="onFocus"
        @blur="onBlur"
      />
<!--      <woot-message-editor-->
<!--        v-else-->
<!--        v-model="message"-->
<!--        class="input"-->
<!--        :is-private="isOnPrivateNote"-->
<!--        :placeholder="messagePlaceHolder"-->
<!--        :min-height="4"-->
<!--        @typing-off="onTypingOff"-->
<!--        @typing-on="onTypingOn"-->
<!--        @focus="onFocus"-->
<!--        @blur="onBlur"-->
<!--        @toggle-user-mention="toggleUserMention"-->
<!--        @toggle-canned-menu="toggleCannedMenu"-->
<!--      />-->
    </div>
    <div v-if="hasAttachments" class="attachment-preview-box">
      <attachment-preview
        :attachments="attachedFiles"
        :remove-attachment="removeAttachment"
      />
    </div>
    <reply-bottom-panel
      :conversation-id="conversationId"
      :mode="replyType"
      :send-button-text="replyButtonLabel"
      :on-file-upload="onFileUpload"
      :show-file-upload="showFileUpload"
      :toggle-emoji-picker="toggleEmojiPicker"
      :show-emoji-picker="showEmojiPicker"
      :on-send="sendMessage"
      :is-send-disabled="isReplyButtonDisabled"
      :set-format-mode="setFormatMode"
      :is-on-private-note="isOnPrivateNote"
      :is-format-mode="showRichContentEditor"
      :enable-rich-editor="isRichEditorEnabled"
      :enter-to-send-enabled="enterToSendEnabled"
      @toggleEnterToSend="toggleEnterToSend"
      @closePannel="closePannel($event)"
      :message="message"
      @replace-text="replaceText"
    />
      <woot-modal :show.sync="vaultPopup" :on-close="onClose" class="account-selector--modal">
          <woot-modal-header
                  class="select-vault-file-heading"
                  :header-title="'Select from the Vault'"
          />
          <div class="columns medium-10 medium-offset-1 tree-level1">
            <div class="flex flex-row option-container tree-level2">

              <treeselect
                      :multiple="false"
                      :options="transformedOptions(conversationVault)"
                      placeholder="Select file and folder"
                      :clearable="true"
                      v-model="selectedVault"
                      class="treeselectClass"
                      :disable-branch-nodes="true" />
              <treeselect-value :value="selectedVault" />
            </div>

              <div class="modal-footer columns medium-10 float-right">
                  <button
                          class="button primary"
                          @click="sendVaultLink"
                  >
                      {{ $t('FOLDER_MGMT.LOGS.SEND_BTN') }}
                  </button>
                  <woot-button class="button clear" @click.prevent="onClose">
                      {{ $t('FOLDER_MGMT.AGENTS.CANCEL') }}
                  </woot-button>
              </div>
          </div>
      </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';

import EmojiInput from 'shared/components/emoji/EmojiInput';
import CannedResponse from './CannedResponse';
import ResizableTextArea from 'shared/components/ResizableTextArea';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import ReplyTopPanel from 'dashboard/components/widgets/WootWriter/ReplyTopPanel';
import ReplyEmailHead from './ReplyEmailHead';
import ReplyBottomPanel from 'dashboard/components/widgets/WootWriter/ReplyBottomPanel';
import { REPLY_EDITOR_MODES } from 'dashboard/components/widgets/WootWriter/constants';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { MAXIMUM_FILE_UPLOAD_SIZE } from 'shared/constants/messages';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import {
  isEscape,
  isEnter,
  hasPressedShift,
} from 'shared/helpers/KeyboardHelpers';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';
import inboxMixin from 'shared/mixins/inboxMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';
import debounce from "lodash/debounce";
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  components: {
    EmojiInput,
    CannedResponse,
    ResizableTextArea,
    AttachmentPreview,
    ReplyTopPanel,
    ReplyEmailHead,
    ReplyBottomPanel,
    WootMessageEditor,
    Treeselect,
  },
  mixins: [clickaway, inboxMixin, uiSettingsMixin, alertMixin],
  props: {
    selectedTweet: {
      type: [Object, String],
      default: () => ({}),
    },
    isATweet: {
      type: Boolean,
      default: false,
    },
    popoutReplyBox: {
      type: Boolean,
      default: false,
    },
    commentData: {
      type: Object,
      default(){
        return {}
      },
    },
  },
  data() {
    return {
      message: '',
      isFocused: false,
      showEmojiPicker: false,
      showMentions: false,
      attachedFiles: [],
      isUploading: false,
      replyType: REPLY_EDITOR_MODES.REPLY,
      mentionSearchKey: '',
      hasUserMention: false,
      hasSlashCommand: false,
      clearMails: false,
      vaultPopup: false,
      selectedVault: null,
      value: [],
      options: [],
      doAutoSaveDraft: () => {},
    };
  },
  computed: {
      showRichContentEditor() {
      if (this.isOnPrivateNote) {
        return true;
      }

      if (this.isRichEditorEnabled) {
        const {
          display_rich_content_editor: displayRichContentEditor,
        } = this.uiSettings;

        return displayRichContentEditor;
      }
      return false;
    },
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      conversationVault: 'getConversationVault',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    enterToSendEnabled() {
      return !!this.uiSettings.enter_to_send_enabled;
    },
    isPrivate() {
      if (this.currentChat.can_reply || this.isAWhatsappChannel) {
        return this.isOnPrivateNote;
      }
      return true;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    conversationId() {
      return this.currentChat.id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    messagePlaceHolder() {
      return this.isPrivate
        ? this.$t('CONVERSATION.FOOTER.PRIVATE_MSG_INPUT')
        : this.$t('CONVERSATION.FOOTER.MSG_INPUT');
    },
    isMessageLengthReachingThreshold() {
      return this.message ? this.message.length > this.maxLength - 50 : false;
    },
    charactersRemaining() {
      return this.message ? this.maxLength - this.message.length : 0;
    },
    isReplyButtonDisabled() {
      if (this.isATweet && !this.inReplyTo) {
        return true;
      }

      if (this.hasAttachments) return false;

      return (
        this.isMessageEmpty ||
        this.message.length === 0 ||
        this.message.length > this.maxLength
      );
    },
    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    maxLength() {
      if (this.isPrivate) {
        return MESSAGE_MAX_LENGTH.GENERAL;
      }
      if (this.isAFacebookInbox) {
        return MESSAGE_MAX_LENGTH.FACEBOOK;
      }
      if (this.isAWhatsappChannel) {
        return MESSAGE_MAX_LENGTH.TWILIO_WHATSAPP;
      }
      if (this.isATwilioSMSChannel) {
        return MESSAGE_MAX_LENGTH.TWILIO_SMS;
      }
      if (this.isATwitterInbox) {
        if (this.conversationType === 'tweet') {
          return MESSAGE_MAX_LENGTH.TWEET - this.replyToUserLength - 2;
        }
      }
      return MESSAGE_MAX_LENGTH.GENERAL;
    },
    showFileUpload() {
      return (
        this.isAWebWidgetInbox ||
        this.isAFacebookInbox ||
        this.isAPIInbox ||
        this.isAnEmailChannel ||
        this.isSignalwireChannel ||
        this.isATelegramChannel ||
          !this.isALeadInbox
      );
    },
    replyButtonLabel() {
      if (this.isPrivate) {
        return this.$t('CONVERSATION.REPLYBOX.CREATE');
      }
      if (this.conversationType === 'tweet') {
        return this.$t('CONVERSATION.REPLYBOX.TWEET');
      }
      return this.$t('CONVERSATION.REPLYBOX.SEND');
    },
    replyBoxClass() {
      return {
        'is-private': this.isPrivate,
        'is-focused': this.isFocused || this.hasAttachments,
      };
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    isRichEditorEnabled() {
      return this.isAWebWidgetInbox || this.isAnEmailChannel;
    },
    isOnPrivateNote() {
      return this.replyType === REPLY_EDITOR_MODES.NOTE;
    },
    inReplyTo() {
      const selectedTweet = this.selectedTweet || {};
      return selectedTweet.id;
    },
    replyToUserLength() {
      const selectedTweet = this.selectedTweet || {};
      const {
        sender: {
          additional_attributes: { screen_name: screenName = '' } = {},
        } = {},
      } = selectedTweet;
      return screenName ? screenName.length : 0;
    },
    isMessageEmpty() {
      if (!this.message) {
        return true;
      }
      return !this.message.trim().replace(/\n/g, '').length;
    },
    showReplyHead() {
      return !this.isOnPrivateNote && this.isAnEmailChannel;
    },
    conversationIdByRoute() {
      return this.conversationId;
    },
      editorStateId() {
          return `draft-${this.conversationIdByRoute}-${this.replyType}`;
      },
      replyType(updatedReplyType, oldReplyType) {
          this.setToDraft(this.conversationIdByRoute, oldReplyType);
          this.getFromDraft();
      },
  },
  watch: {
    currentChat(conversation) {
      const { can_reply: canReply } = conversation;
      if (this.isOnPrivateNote) {
        return;
      }

      if (canReply || this.isAWhatsappChannel) {
        this.replyType = REPLY_EDITOR_MODES.REPLY;
      } else {
        this.replyType = REPLY_EDITOR_MODES.NOTE;
      }

      this.fetchAndSetReplyTo();
    },
      conversationIdByRoute(conversationId, oldConversationId) {
          if (conversationId !== oldConversationId) {
              this.setToDraft(oldConversationId, this.replyType);
              this.getFromDraft();
          }
      },
    message(updatedMessage) {
      if(updatedMessage !== undefined) {
        this.hasSlashCommand =
          updatedMessage[0] === '/' && !this.showRichContentEditor;
        const hasNextWord = updatedMessage.includes(' ');
        const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
        if (isShortCodeActive) {
          this.mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
          this.showMentions = true;
        } else {
          this.mentionSearchKey = '';
          this.showMentions = false;
        }
      }
      this.doAutoSaveDraft();
    },
  },

  mounted() {
      this.getFromDraft();
    // Donot use the keyboard listener mixin here as the events here are supposed to be
    // working even if input/textarea is focussed.
    document.addEventListener('keydown', this.handleKeyEvents);
    this.$store.dispatch('conversation_vault')
      this.doAutoSaveDraft = debounce(
          () => {
              this.saveDraft(this.conversationIdByRoute, this.replyType);
          },
          500,
          true
      );
      this.fetchAndSetReplyTo();
      bus.$on(BUS_EVENTS.TOGGLE_REPLY_TO_MESSAGE, this.fetchAndSetReplyTo);

  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
      fetchAndSetReplyTo() {
          const replyStorageKey = LOCAL_STORAGE_KEYS.MESSAGE_REPLY_TO;
          const replyToMessageId = LocalStorage.getFromJsonStore(
              replyStorageKey,
              this.conversationId
          );

          this.inReplyTo = this.currentChat?.messages?.find(message => {
              if (message.id === replyToMessageId) {
                  return true;
              }
              return false;
          });
      },
      resetReplyToMessage() {
          const replyStorageKey = LOCAL_STORAGE_KEYS.MESSAGE_REPLY_TO;
          LocalStorage.deleteFromJsonStore(replyStorageKey, this.conversationId);
          bus.$emit(BUS_EVENTS.TOGGLE_REPLY_TO_MESSAGE);
      },
      toggleSignatureForDraft(message) {
          if (this.isPrivate) {
              return message;
          }
      },
      getFromDraft() {
          if (this.conversationIdByRoute) {
              const key = `draft-${this.conversationIdByRoute}-${this.replyType}`;
              const messageFromStore =
                  this.$store.getters['draftMessages/get'](key) || '';

              // ensure that the message has signature set based on the ui setting
              this.message = this.toggleSignatureForDraft(messageFromStore);
          }
      },
      removeFromDraft() {
          if (this.conversationIdByRoute) {
              const key = `draft-${this.conversationIdByRoute}-${this.replyType}`;
              this.$store.dispatch('draftMessages/delete', { key });
          }
      },
      transformedOptions(conversationVault) {
          const options = [];
          for (const vault of conversationVault) {
              const option = {
                id: vault.id,
                short_path: vault.short_path ? vault.short_path : null,
                document_id: vault.document_id ? vault.document_id : null,
                folder_type: vault.folder_type ? vault.folder_type : null,
                is_signDocs: vault.is_signDocs ? vault.is_signDocs : null,
              };
              if(vault.content_type === "image/png" || vault.content_type === "image/jpg" || vault.content_type === "image/jpeg"){
                  option.label = ' \u{1F5BC} ' + vault.name;
              } else if (vault.content_type === 'application/pdf' || vault.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || vault.content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || vault.content_type === 'text/plain' || vault.content_type === 'text/html' || vault.content_type === 'application/zip'){
                  option.label = ' \u{1F4C3} ' + vault.name;
              } else if (vault.folder_type === 'folder'){
                  option.label = ' \u{1F4C1} ' + vault.name;
              } else if (vault.folder_type === 'file'){
                  option.label = ' \u{1F4C3} ' + vault.name;
              }
                  if (vault.files && vault.files.length > 0) {

                  option.children = [];
                      for (const file of vault.files) {
                          const child = {
                            id: file.id,
                            short_path: file.short_path ? file.short_path : null,
                            document_id: file.document_id ? file.document_id : null,
                            folder_type: file.folder_type ? file.folder_type : null,
                            is_signDocs: file.is_signDocs ? file.is_signDocs : null,
                          };
                          if(file.content_type === "image/png" || file.content_type === "image/jpg" || file.content_type === "image/jpeg"){
                              child.label = ' \u{1F5BC} ' + file.name;
                          } else if (file.content_type === 'application/pdf' || file.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.content_type === 'text/plain' || file.content_type === 'text/html' || file.content_type === 'application/zip'){
                              child.label = ' \u{1F4C3} ' + file.name;
                          } else if (file.folder_type === 'folder'){
                              child.label = ' \u{1F4C1} ' + file.name;
                          } else if (file.folder_type === 'file'){
                              child.label = ' \u{1F4C3} ' + file.name;
                          }

                          option.children.push(child);
                      }
              }
              options.push(option);
          }
          this.options = options
          return options;
      },

      getImageUrl(short_path) {
          if (short_path) {
              return `${window.chatwootConfig.fileCloudUrl}/${short_path}`;
          }
          return '';
      },
    toggleUserMention(currentMentionState) {
      this.hasUserMention = currentMentionState;
    },
    toggleCannedMenu(value) {
      this.showCannedMenu = value;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.hideEmojiPicker();
        this.hideMentions();
      } else if (isEnter(e)) {
        const hasSendOnEnterEnabled =
          (this.showRichContentEditor &&
            this.enterToSendEnabled &&
            !this.hasUserMention &&
            !this.showCannedMenu) ||
          !this.showRichContentEditor;
        const shouldSendMessage =
          hasSendOnEnterEnabled && !hasPressedShift(e) && this.isFocused;
        if (shouldSendMessage) {
          e.preventDefault();
          this.sendMessage();
        }
      }
    },
    toggleEnterToSend(enterToSendEnabled) {
      this.updateUISettings({ enter_to_send_enabled: enterToSendEnabled });
    },
    async sendMessage() {
      if (this.isReplyButtonDisabled) {
        return;
      }
      if (!this.showMentions) {
        const newMessage = this.message;
        const messagePayload = this.getMessagePayload(newMessage);
        this.clearMessage();
          this.removeFromDraft();
          try {
          if (this.inbox.channel_type === 'Channel::FacebookReview') {
            await this.$store.dispatch('ReplyFBReviews', {
              reviewer_id: this.currentChat.reviewer_id,
              access_token: this.currentChat.page_access_token,
              message: newMessage
            });
          }
          if (this.inbox.channel_type === 'Channel::Youtube') {
            await this.$store.dispatch('ReplyComment', {
              commentData: this.commentData,
              access_token: this.currentChat.page_access_token,
              app_id: this.currentUser.google_api_key,
              conversation_id: this.currentChat.id,
              message: newMessage
            });
            this.$emit('checkBox', false);
            this.isYoutube = false;
            this.clearMessage();
          }else {
            await this.$store.dispatch('sendMessage', messagePayload);
          }
          this.$emit('scrollToMessage');


        } catch (error) {
          const errorMessage =
            error?.response?.data?.error ||
            this.$t('CONVERSATION.MESSAGE_ERROR');
          this.showAlert(errorMessage);
        }
        this.hideEmojiPicker();
        this.clearMails = false;
      }
    },
    replaceText(message) {
      setTimeout(() => {
        this.message = message;
      }, 100);
    },
    setReplyMode(mode = REPLY_EDITOR_MODES.REPLY) {
      const { can_reply: canReply } = this.currentChat;
        this.$store.dispatch('draftMessages/setReplyEditorMode', {
            mode,
        });
      if (canReply || this.isAWhatsappChannel) this.replyType = mode;
      if (this.showRichContentEditor) {
        return;
      }
      this.$nextTick(() => this.$refs.messageInput.focus());
    },
    emojiOnClick(emoji) {
      this.message = `${this.message}${emoji} `;
    },
    findvaultObjectById(data, targetId) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === targetId) {
          return data[i];
        } else if (data[i].children) {
          const childResult = this.findvaultObjectById(data[i].children, targetId);
          if (childResult) {
            return childResult;
          }
        }
      }
      return null;
    },
    clearMessage() {
      this.message = '';
      this.attachedFiles = [];
      this.clearMails = true;
      this.resetReplyToMessage();
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    hideMentions() {
      this.showMentions = false;
    },
    onTypingOn() {
      this.toggleTyping('on');
    },
    onTypingOff() {
      this.toggleTyping('off');
    },
    onBlur() {
      this.isFocused = false;
      this.saveDraft(this.conversationIdByRoute, this.replyType);
    },
    onFocus() {
      this.isFocused = true;
    },
    toggleTyping(status) {
      const conversationId = this.currentChat.id;
      this.$store.dispatch('conversationTypingStatus/toggleTyping', {
        status,
        conversationId,
      });
    },
    onFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachedFiles = [];
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onloadend = () => {
          this.attachedFiles.push({
            currentChatId: this.currentChat.id,
            resource: file,
            isPrivate: this.isPrivate,
            thumb: reader.result,
          });
        };
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    getMessagePayload(message) {
      const [attachment] = this.attachedFiles;
      const messagePayload = {
        conversationId: this.currentChat.id,
        message,
        private: this.isPrivate,
      };

      if (this.inReplyTo) {
        messagePayload.contentAttributes = { in_reply_to: this.inReplyTo };
      }

      if (attachment) {
        messagePayload.file = attachment.resource.file;
      }

      if (this.ccEmails) {
        messagePayload.ccEmails = this.ccEmails;
      }

      if (this.bccEmails) {
        messagePayload.bccEmails = this.bccEmails;
      }

      return messagePayload;
    },
    setFormatMode(value) {
      this.updateUISettings({ display_rich_content_editor: value });
    },
    setCcEmails(value) {
      this.bccEmails = value.bccEmails;
      this.ccEmails = value.ccEmails;
    },
    vaultModel(vault) {
        this.vaultPopup = vault;
    },
    onClose() {
       this.vaultPopup = false;
    },
    async sendVaultLink() {
        try {
            this.clearMessage();
            const vault = this.findvaultObjectById(this.options, this.selectedVault)

            let text = {
                  vaultId: vault.id,
                  vaultType: vault?.folder_type,
                  date: new Date(),
                  is_signDocs: vault?.is_signDocs,
                  document_id: vault?.document_id,
                  account_id: vault?.account_id,
                  is_conversate: true
              }
              let encoded = btoa(JSON.stringify(text));
              const messagePayload = {
                  message: `${window.chatwootConfig.hostURL}/app/vault/?params=${encoded}`,
                  conversationId: this.currentChat.id,
                  private: this.isPrivate,
              };
              await this.$store.dispatch('sendMessage', messagePayload);
              this.clearMessage();
              this.vaultPopup = false;
              this.$emit('scrollToMessage');
          } catch (error) {
              const errorMessage =
                  error?.response?.data?.error ||
                  this.$t('CONVERSATION.MESSAGE_ERROR');
              this.showAlert(errorMessage);
          }
      },
      setToDraft(conversationId, replyType) {
          this.saveDraft(conversationId, replyType);
          this.message = '';
      },
      trimContent(content = '', maxLength = 1024, ellipsis = false) {
          let trimmedContent = content;
          if (content.length > maxLength) {
              trimmedContent = content.substring(0, maxLength);
          }
          if (ellipsis) {
              trimmedContent = trimmedContent + '...';
          }
          return trimmedContent;
      },
      saveDraft(conversationId, replyType) {
          if (this.message || this.message === '') {
              const key = `draft-${conversationId}-${replyType}`;
              const draftToSave = this.trimContent(this.message || '');

              this.$store.dispatch('draftMessages/set', {
                  key,
                  message: draftToSave,
              });
          }
      },
  },
};
</script>

<style lang="scss" scoped>
.send-button {
  margin-bottom: 0;
}

.option-container {
  display: flex;
  align-items: center; /* Vertical alignment */
}

.option-container div {
  margin-right: 10px; /* Margin between image/paragraph and option name */
}

.icon-size {
  font-size: 17px; /* Adjust the size as needed to match the image size */
}


.attachment-preview-box {
  padding: 0 var(--space-normal);
  background: transparent;
}

.reply-box {
  border-top: 1px solid var(--color-border);
  background: white;

  &.is-private {
    background: var(--y-50);
  }
}
.send-button {
  margin-bottom: 0;
}

.reply-box__top {
  padding: 0 var(--space-normal);
  border-top: 1px solid var(--color-border);
  margin-top: -1px;
}

.emoji-dialog {
  top: unset;
  bottom: 12px;
  left: -320px;
  right: unset;

  &::before {
    right: -16px;
    bottom: 10px;
    transform: rotate(270deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  }
}

.select-vault-file-heading{
  margin-top: 10px;
  margin-left: 6rem;
  margin-bottom: 1rem;
}

.tree-level1{
  margin-left: 9rem;
}
</style>
