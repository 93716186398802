<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-header">
        <woot-modal-header :header-title="headerTitle" />
        <button type="button" class="close" @click.prevent="onClose">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="modal-content flex flex-col w-full" @submit.prevent="applyText">
        <div v-if="content" class="w-full">
          <h4 class="h4-text-font text-base mt-1 text-slate-700 dark:text-slate-100">
            {{ $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.DRAFT_TITLE') }}
          </h4>
          <p class="scrollable-content" v-dompurify-html="formatMessage(content, false)" />
          <h4 class="h4-text-font text-base mt-1 text-slate-700 dark:text-slate-100">
            {{
              $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.GENERATED_TITLE')
            }}
          </h4>
        </div>
        <div>
          <AILoader v-if="isGenerating" />
          <p v-else class="scrollable-content" v-dompurify-html="formatMessage(generatedContent, false)" />
        </div>
        <div class="modal-footer">
          <woot-button variant="clear" @click.prevent="onClose">
            {{
              $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.BUTTONS.CANCEL')
            }}
          </woot-button>
          <woot-button :disabled="!generatedContent">
            {{
              $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.BUTTONS.APPLY')
            }}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import messageFormatterMixin from '../../../../shared/mixins/messageFormatterMixin';
import AILoader from '../AILoader.vue';
import aiMixin from '../../../mixins/aiMixin';
import OpenAPI from '../../../api/integrations/openapi';

export default {
  components: {
    AILoader,
  },
  mixins: [aiMixin, messageFormatterMixin],
  props: {
    aiOption: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    hookid: {
      type: [String, Number],
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generatedContent: '',
      isGenerating: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      appIntegrations: 'integrations/getAppIntegrations',
      getAccount: 'accounts/getAccount',
    }),
    headerTitle() {
      const translationKey = this.aiOption?.toUpperCase();
      return translationKey
        ? this.$t(`INTEGRATION_SETTINGS.OPEN_AI.WITH_AI`, {
          option: this.$t(
            `INTEGRATION_SETTINGS.OPEN_AI.OPTIONS.${translationKey}`
          ),
        })
        : '';
    }
  },
  mounted() {
    if (this.show) {
      this.onShow();
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.onShow();
      }
    },
  },
  methods: {
    onShow() {
      this.headerTitle = '';
      this.generateAIContent(this.aiOption);
    },
    onClose() {
      this.$emit('close-content-modal');
    },
    async generateAIContent(type = 'rephrase') {
      this.isGenerating = true;
      try {
        const result = await OpenAPI.processAiAgentEvent({
          type,
          content: this.content,
          tone: this.aiOption,
          hookid: this.hookid,
        });
        const {
          data: { message: generatedMessage },
        } = result;
        this.generatedContent = generatedMessage;
      } catch (error) {
        console.log(error, error);
        this.showAlert('Error');
        this.isGenerating = false;
        return '';
      }
      this.isGenerating = false;
      let account = this.getAccount(this.accountId);
      if (this.generatedContent.trim() === '') {
        return;
      } else {
        account.credit_balance = account.credit_balance - account.ai_assistant_cost;
        this.$store.commit(types.default.UPDATE_ACCOUNT, account);
      }
    },
    applyText() {
      this.$emit('apply-text', this.generatedContent);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-dialog {
  background: white;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 900px;
  max-width: 100%;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
}

.close {
  background: none;
  border: none;
  font-size: 4rem;
  color: #000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: background 0.3s, opacity 0.3s;
}

.close:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  opacity: 0.75;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-top: 20px;
}

.h4-text-font {
  font-size: 1.6rem !important;
}

.scrollable-content {
  max-height: calc(6 * 1.5em);
  overflow-y: auto;
  margin-top: 10px;
}

.text-align {
  text-align: right;
}
</style>
