<template>
  <div class="wizard-body small-9 columns">
    <page-header
        :header-title="$t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.TITLE')"
        :header-content="$t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
              v-model.trim="channelName"
              type="text"
              :placeholder="
              $t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
              @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
              $t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.CHANNEL_NAME.ERROR')
            }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.chatType.$error }" class="medium-12 columns">
          {{ $t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.TYPE.LABEL') }}
          <select v-model="chatType"  @blur="$v.chatType.$touch">
<!--            <option :value="'private'">-->
<!--              {{-->
<!--                $t(-->
<!--                    'INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.TYPE.OPTIONS.PRIVATE'-->
<!--                )-->
<!--              }}-->
<!--            </option>-->
            <option :value="'group'">
              {{
                $t(
                    'INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.TYPE.OPTIONS.GROUP'
                )
              }}
            </option>
          </select>

          <span v-if="$v.chatType.$error" class="message">{{
              $t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.CHANNEL_NAME.ERROR')
            }}</span>
        </label>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
            :loading="uiFlags.isCreating"
            :button-text="$t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';


export default {
  comments: {
    PageHeader
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      chatType: ''
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: {required},
    chatType: {required},
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      try {
        const chatChannel = await this.$store.dispatch(
            'inboxes/createChannel',
            {
              name: this.channelName,
              c_type: this.chatType,
              channel: {
                type: 'team_chat'
              },
            }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: chatChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
            this.$t('INBOX_MGMT.ADD.TEAM_CHAT_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    }
  }
}
</script>

<style scoped>

</style>
