<template>
  <div :style="{ padding: '15px' }" class="card note-wrap">
    <div class="footer" :style="{ marginBottom:'8px' }">
      <div class="meta">
        <div :title="userName">
          <thumbnail :src="thumbnail" :username="userName" size="20px" />
        </div>
        <p :style="{ 'font-weight': 'bold', 'margin-left': '5px'}">{{ userName }}</p>
        <p style="margin-left: 5px">{{ readableTime }}</p>
      </div>
      <div class="actions">
        <woot-button
          variant="smooth"

          icon="ion-trash-b"
          color-scheme="secondary"
          @click="openDeleteModal"
        />
      </div>
    </div>
    <p class="note__content">
      {{ note }}
    </p>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import timeMixin from 'dashboard/mixins/time';


export default {
  components: {
    Thumbnail,
  },

  mixins: [timeMixin],

  props: {
    id: {
      type: Number,
      default: 0,
    },
    note: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    timeStamp: {
      type: Number,
      default: 0,
    },
    thumbnail: {
      type: String,
      default: '',
    },
  },

  computed: {
    readableTime() {
      return this.dynamicTime(this.timeStamp);
    },
  },

  methods: {
    openDeleteModal() {
      this.$emit('open-delete-modal', this.id,this.note);
    },
  },
};
</script>

<style lang="scss" scoped>
.note__content {
  margin-bottom: var(--space-smaller);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .meta {
    display: flex;
    padding: var(--space-smaller) 0;
  }
  .actions {
    display: flex;
    visibility: hidden;

    .button {
      margin-left: var(--space-small);
    }
  }
}

.note-wrap:hover {
  .actions {
    visibility: visible;
  }
}
</style>
