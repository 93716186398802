<template>
  <div class="settings columns">
    <setting-intro-banner
      :header-image="selectedAgent.thumbnail"
      :header-title="selectedAgent.name"
    >
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
    </setting-intro-banner>

    <div v-if="selectedTabKey == '0'" class="ivr-settings-wrapper">
      <settings-section
        :title="$t('AGENT_MGMT.EDIT.WEB_CONTENT.TITLE')"
        :sub-title="$t('AGENT_MGMT.EDIT.WEB_CONTENT.SUBTITLE')"
        :web-title="title"
      >
        <div class="row">
          <div class="columns col-md-12">
            <label>{{ $t('AGENT_MGMT.EDIT.WEB_CONTENT.HEADING') }} :</label>
            <textarea
              v-model="formattedContent"
              rows="16"
              cols="135"
              placeholder="Web Content Goes here"
              class="input--note"
              @keydown.enter.shift.exact="onUpdate"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="columns col-md-12">
            <div class="footer">
              <woot-button
                color-scheme="primary"
                title="Update content"
                class="w-40"
                @click="onUpdate"
              >
                {{ $t('AGENT_MGMT.EDIT.UPDATE') }}
              </woot-button>
            </div>
          </div>
        </div>
      </settings-section>
    </div>

    <div v-if="selectedTabKey == '1'" class="ivr-settings-wrapper">
      <settings-section
        :title="$t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.TITLE')"
        :sub-title="$t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.SUBTITLE')"
        :web-title="title"
      >
        <div class="columns col-md-12">
          <div class="widget--feature-flag">
            <input
              v-model="active"
              type="checkbox"
              value="active_agent"
              @input="handleActive"
            />
            <label for="conversation_alert">
              {{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.ACTIVE_AGENT') }}
            </label>
          </div>
        </div>
        <div class="row mb-6">
          <div class="columns col-md-12">
            <label>{{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.DELAY') }}</label>
            <div class="custom-delay-container">
              <div class="input-group">
                <select v-model="delay" @change="handleDelayChange" class="form-control custom-select">
                  <option value="0">{{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.NO_DELAY') }}</option>
                  <option value="custom">{{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.CUSTOM') }}</option>
                  <option v-for="(label, index) in delaySeconds" :key="index" :value="label">
                    {{ label }} Seconds
                  </option>
                </select>
                <input v-if="isCustomDelay" type="number" v-model="customDelayValue" min="0" placeholder="Enter value" class="form-control custom-input">
                <select v-if="isCustomDelay" v-model="customDelayUnit" class="form-control custom-select">
                  <option value="minutes">{{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.MINUTES') }}</option>
                  <option value="seconds">{{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.SECONDS') }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="columns col-md-12">
          <label>
            {{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.HEADING') }}
          </label>
          <div class="widget--feature-flag">
            <input
              v-model="selectedAlertTypes"
              type="checkbox"
              value="conversation_alert"
              @input="handleAlerts"
            />
            <label for="conversation_alert">
              {{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.ALERT') }}
            </label>
          </div>
          <div class="widget--feature-flag">
            <input
              v-model="selectedAlertTypes"
              type="checkbox"
              value="conversation_summary"
              @input="handleAlerts"
            />
            <label for="conversation_summary">
              {{ $t('AGENT_MGMT.EDIT.ADDITIONAL_SETTINGS.SUMMARY') }}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="columns col-md-12">
            <div class="footer">
              <woot-button
                color-scheme="primary"
                title="Update content"
                class="w-40"
                @click="onAddionalSettings"
              >
              {{ $t('AGENT_MGMT.EDIT.UPDATE') }}
              </woot-button>
            </div>
          </div>
        </div>
      </settings-section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner';
import SettingsSection from '../../../../components/SettingsSection';
import inboxMixin from 'shared/mixins/inboxMixin';
import adminMixin from '../../../../mixins/isAdmin';
import Label from '../../../../components/ui/Label.vue';

export default {
  components: {
    Label,
    SettingIntroBanner,
    SettingsSection,
  },
  mixins: [alertMixin, configMixin, inboxMixin, adminMixin],
  data() {
    return {
      isCustomDelay: false,
      customDelayValue: 0,
      active: false,
      customDelayUnit: 'seconds',
      title: '',
      selectedTabIndex: 0,
      isMounted: false,
      delay: 0,
      selectedAlertTypes: [],
      delaySeconds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      webContent: {
        title: '',
        content: '',
      },
      agentId: this.$route.params.agentId,
      selectedAgent: {},
      formattedContent: '',
      assistantId: '',
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_agent_show') {
        this.fetchAgentSettings();
      }
    },
  },
  methods: {
    handleDelayChange() {
      if (this.delay === 'custom') {
        this.isCustomDelay = true;
      } else {
        this.isCustomDelay = false;
      }
    },
    handleActive(status) {
  if (status === 'active_agent') {
    this.active = !this.active;
  }
},
    handleAlerts(e) {
      this.selectedAlertTypes = this.toggleInput(
        this.selectedAlertTypes,
        e.target.value
      );
    },
    setCustomDelay() {
      if (this.customDelayUnit === 'minutes') {
        this.delay = this.customDelayValue * 60;
      } else {
        this.delay = this.customDelayValue;
      }
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    async onUpdate() {
      const text = this.formattedContent.replace(/\n\n/g, '');
      const newWebContent = {
        title: this.webContent.title,
        content: text,
      };
      const contentObj = { content: text };
      try {
        const EDIT_AI_ASSISTANT_WEB_CONTENT = `${window.chatwootConfig.editAssistantWebData}`;
        const aiAssistantModified = await axios.patch(
          `${EDIT_AI_ASSISTANT_WEB_CONTENT}/${this.assistantId} `,
          contentObj
        );
        if (aiAssistantModified) {
          await this.$store.dispatch('agents/updateWebContent', {
            id: this.$route.params.agentId,
            content: newWebContent,
          });
          this.showAlert(this.$t('AGENT_MGMT.EDIT.API.WEB_CONTENT_UPDATED'));
        }
      } catch (e) {
        console.error('error', e);
      }
    },
    async onAddionalSettings() {
      if (this.isCustomDelay == true) {
        this.setCustomDelay();
      }
      try {
        await this.$store.dispatch('agents/additionalSettings', {
          id: this.$route.params.agentId,
          delay: this.delay,
          alerts: this.selectedAlertTypes,
          active_agent: this.active,
          bot_id: this.selectedAgent.bot.id,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ADDITIONAL_SETTINGS_UPDATED'));
      } catch (e) {
        console.error('error', e);
      }
    },
    formatTextIntoParagraphs(text) {
      if (text) {
        text = text.replace(/(?<!www)\.(?!\s*com)/g, '.\n\n');
        text = text.replace(/;/g, ';\n\n');
        return text;
      }

      text = '';
      return text;
    },
    fetchAgentSettings() {
      this.selectedAgent = this.$store.getters['agents/getAiAgentById'](
        this.$route.params.agentId
      );
      this.customDelayValue = 0;
      this.isCustomDelay = false;
      this.active = false;
      this.customDelayUnit = 'seconds';
      this.selectedTabIndex = 0;
      this.selectedAlertTypes = [];
      this.webContent = this.selectedAgent.web_content;
      this.assistantId = this.selectedAgent.assistant_id;
      this.active = this.selectedAgent.active_agent;

      this.delay = this.selectedAgent.delay;
      if (this.delay > 10) {
        this.delay = 'custom';
        this.isCustomDelay = true;
        this.customDelayValue = this.selectedAgent.delay;
      }

      if (this.webContent) {
        this.formattedContent = this.formatTextIntoParagraphs(
          this.webContent.content
        );
        this.title = this.webContent?.title;
      } else {
        this.formattedContent = '';
        this.title = '';
      }

      if (this.selectedAgent.conversation_summary == true) {
        this.selectedAlertTypes.push('conversation_summary');
      }

      if (this.selectedAgent.conversation_alert == true) {
        this.selectedAlertTypes.push('conversation_alert');
      }
    },
  },
  computed: {
    ...mapGetters({
      aiAgentList: 'agents/getAiAgents',
    }),
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    tabs() {
      const allTabs = [
        { key: 0, name: 'Web Content' },
        { key: 1, name: 'Additional Settings' },
      ];
      return allTabs;
    },
  },
  mounted() {
    this.isMounted = true;
    this.fetchAgentSettings();
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.settings {
  background: $color-white;

  .settings--content {
    div:last-child {
      border-bottom: 0;
    }
  }

  .tabs {
    padding: 0;
    margin-bottom: -1px;
  }
}

.widget--feature-flag {
  padding-top: var(--space-small);
}

.ivr-settings-wrapper {
  margin: 0.8rem 3.2rem;
}

.fixed-textarea {
  width: 100%;
  height: 66px;

  resize: none;
  padding: 8px;
}
.sms-bot-settings__checkbox {
  cursor: pointer;
}
.avatar_size {
  margin-top: -15px;
  margin-bottom: 5px;
}
.ivr-settings-wrapper {
  margin: 0.8rem 3.2rem;
}
.title {
  font-size: 1.8em;
}

.custom-delay-container {
  display: flex;
  align-items: center;

  .input-group {
    display: flex;
    flex-wrap: nowrap;

    .custom-select {
      flex: 1;
      margin-right: 8px;
    }

    .custom-input {
      width: 200px;
      margin-right: 8px;
    }
  }
}
</style>
