import Vue from 'vue';
import * as types from '../mutation-types';
const state = {
  callSid: "",
};

export const getters = {
  getCallSid: $state => {
    return $state.callSid;
  },
};

export const actions = {
  create: ({ commit }, callSid) => {
    commit(types.default.ADD_CALL_SID, callSid);
  },
  destroy: ({ commit }) => {
    commit(types.default.REMOVE_CALL_SID);
  },
};

export const mutations = {
  [types.default.ADD_CALL_SID]: ($state, callSid) => {
  $state.callSid = callSid;
  },
  [types.default.REMOVE_CALL_SID]: ( $state) => {
    $state.callSid = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
