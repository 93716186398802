<template>
  <div class="columns small-9 g-btn">
    <div class='g-sign-in-button' @click="handleAuthClick" v-if="google_tag">
      <div class=content-wrapper>
        <div class='logo-wrapper'>
          <img src='https://developers.google.com/identity/images/g-logo.png'>
        </div>
        <span class='text-container'>
      <span>Sign in with Google</span>
        </span>
      </div>
    </div>

    <label v-if="!google_tag" :class="'account_style'">
      Select account
    <select v-model="account"  @change="onAccountChange()">
      <option
          v-for="account in accountUsers"
          :value="account"
      >
        {{ account.accountName}}
      </option>
    </select>
    </label>

    <label v-if="account" :class="'location_style'">
      Choose location
    <select v-model="account_location"   @change="onLocationChange()">
      <option
          v-for="location in locations"
          :value="location"
      >
        {{ location.title}}
      </option>
    </select>
    </label>



  </div>
</template>

<script>
import {mapGetters} from "vuex";
import InboxesAPI from "../../../../../api/inboxes";
import router from "../../../../index";
import moment from "moment";
import {isEmpty} from "jssip/lib/Utils";
import alertMixin from 'shared/mixins/alertMixin';

const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage';
const URL = 'https://accounts.google.com/o/oauth2/token';
export default {
  name: "GoogleReview",
  mixins: [alertMixin],
  props: {
    inbox_name: {
      type: String,
      default: '',
    },
    accountType: {
      type: String,
      default: 'google',
    },
    start_date: {
      type: Date,
      default: ''
    },
    end_date: {
      type: Date,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
  },
  data() {
    return {
      items: undefined,
      api: undefined,
      authorized: false,
      review_auth_token: '',
      account_name: '',
      account: '',
      review_obj: '',
      state: '',
      type: '',
      profile_url: '',
      place_id: '',
      location_name: '',
      account_location: '',
      next_page: '',
      rating: '',
      next_url: '',
      total_review: 1,
      custom_review: [],
      accountUsers: [],
      access_token: '',
      locations: [],
      google_tag: true,
      gReviewsObj: [],
    };
  },
  created() {
    this.api = gapi;
    this.handleClientLoad();
    this.getReviewAccounts();
  },
  methods: {
    handleClientLoad() {
      this.api.load('client:auth2', this.initClient);
    },
    handleAuthClick(event) {
      var vm = this;
      var googleAuth = this.api.auth2.getAuthInstance();
      Promise.resolve(googleAuth.grantOfflineAccess())
          .then(data => {
            var credentials = {
              client_id: this.currentUser.client_id,
              code: data.code,
              grant_type: "authorization_code",
              client_secret: this.currentUser.client_secret,
              redirect_uri: this.currentUser.frontend_url
            };
            fetch(URL, {
              'method': 'POST',
              'body': JSON.stringify(credentials),
              headers: {'Content-Type': 'application/json'}
            }).then(resp =>  resp.json()).then(async token => {
              vm.review_auth_token = token;
              vm.authorized = true;
              vm.access_token = token.access_token;
              await vm.getAccountList(token.access_token);
            })
          })
    },
    async onAccountChange() {
      let vm = this;
      vm.account_name = vm.account?.accountName
      vm.profile_url = vm.account?.profilePhotoUrl;
      let nextPageToken = '';
      vm.locations = [];
      do {
        const url = `https://mybusinessbusinessinformation.googleapis.com/v1/${vm.account?.name}/locations?readMask=name,storefrontAddress,title,languageCode&access_token=${vm.access_token}&pageToken=${nextPageToken}`;
        const response = await fetch(url, {
          method: 'GET',
        });

        const data = await response.json();
        vm.locations.push(...data.locations);
        nextPageToken = data.nextPageToken;
      } while (nextPageToken);
    },
    async onLocationChange() {
      let vm = this;
      const existReview = vm.gReviewsObj.filter(review => review.account_location === vm.account_location?.name )
      if(existReview.length) {
        vm.showAlert("Account already connected.");
        return;
      }else {
        vm.location_name = vm.account_location?.title;
        vm.place_id = vm.account_location?.locationKey?.placeId;
        if(isEmpty(vm.start_date) || isEmpty(vm.end_date)) {
          console.log(vm.start_date, 'is empty', isEmpty(vm.start_date))
          await fetch(`https://mybusiness.googleapis.com/v4/${vm.account?.name}/${vm.account_location?.name}/reviews?access_token=${vm.access_token}`, {
            method: 'GET',
          }).then(resp => resp.json()).then((res) => {
            console.log(res, 'res google ')
            vm.next_page = res.nextPageToken;
            vm.review_obj = res.reviews;
            vm.rating = res.starRating;
          })
        }else {
          let counter = 0
          let is_loaded = true;
          while(counter < vm.total_review && is_loaded) {
            await fetch(`https://mybusiness.googleapis.com/v4/${vm.account?.name}/${vm.account_location?.name}/reviews?access_token=${vm.access_token}&pageToken=${vm.next_url}`, {
              method: 'GET',
            }).then(resp => resp.json()).then((res) => {
              vm.total_review = res.totalReviewCount
              vm.rating = res.starRating;
              vm.next_url = res.nextPageToken;
              counter += res.reviews.length
              if(res.reviews.length > 0) {
                for (let i = 0; i < res.reviews.length; i++){
                  if(isEmpty(res.reviews[i])) {
                    is_loaded = false;
                    break;
                  }
                  if(moment(moment(res.reviews[i].createTime)).isSameOrBefore(vm.end_date) && moment(moment(res.reviews[i].createTime)).isSameOrAfter(vm.start_date)) {
                    vm.custom_review.push(res.reviews[i]);
                  }else {
                    is_loaded = false;
                    break;
                  }
                }
              }
            }).catch((err) => { // handle error
              console.log(err);
            })
          }
          vm.review_obj = vm.custom_review
        }
          await vm.createChannel();
      }
    },

    async getAccountList(token) {
      var vm = this;
      await fetch(`https://mybusinessaccountmanagement.googleapis.com/v1/accounts?access_token=${token}`, {
        method: 'GET'
      }).then(resp =>  resp.json()).then(async (res) => {
        // handle response
        vm.accountUsers = res.accounts;
        vm.google_tag = false;
      }).catch((err) => { // handle error
        console.log(err);
      })
    },
    initClient() {
      let vm = this;
      vm.api.client.init({
        apiKey: vm.currentUser.google_api_key,
        clientId: vm.currentUser.client_id,
        // discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(async function () {
        const auth2 = vm.api.auth2.getAuthInstance()
        if (auth2.isSignedIn.get()) {
          vm.authorized = true;
        } else {
          var tokens = await vm.parseUser(vm.currentUser);
            if(tokens.error) {
                vm.authorized = '';
            }else {
                if(vm.currentUser.google_auth_tokens) {
                    var credentials = {
                        client_id: vm.currentUser.client_id,
                        client_secret: vm.currentUser.client_secret,
                        refresh_token: tokens.refresh_token,
                        grant_type: "refresh_token",
                        redirect_uri: vm.currentUser.frontend_url
                    };
                    fetch(URL, {
                        'method': 'POST',
                        'body': JSON.stringify(credentials),
                        headers: {'Content-Type': 'application/json'}
                    }).then(resp => resp.json()).then(token => {
                        token.refresh_token = tokens.refresh_token
                        token.user_id = vm.currentUser.id
                        vm.updateGoogleAuth(token)
                        vm.api.client.setToken({access_token: token.access_token});
                        // Listen for sign-in state changes.
                        console.log(vm.api.auth2.getAuthInstance().isSignedIn.get())
                    });
                }
            }
        }
      });
    },
    ReviewParams() {
      return {
        review_auth_token: this.review_auth_token,
        account_name: this.account_name,
        account: this.account?.name,
        review_obj: this.review_obj,
        profile_url: this.profile_url,
        place_id: this.place_id,
        location_name: this.location_name,
        account_location: this.account_location?.name,
        next_page: this.next_page,
        inbox_name: this.inbox_name,
        channel_type: this.accountType,
        rating: this.rating
      };
    },
    async createChannel() {
      var vm = this;
      let params = await vm.ReviewParams();
      this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
      this.isCreating = true;
      await this.$store.dispatch('inboxes/createGoogleChannel', params)
          .then(inbox => {
            console.log(inbox, 'inbox')
            router.replace({
              name: 'settings_inboxes_add_agents',
              params: {page: 'new', inbox_id: inbox.id},
            });
          }).catch((err) => {
            console.log(err.response);
            vm.isCreating = false;
          });
    },
    getReviewAccounts() {
      let vm = this;
      this.$store.dispatch('inboxes/getGoogleChannel').then(res => {
        vm.gReviewsObj = res?.gchannel;
      })
    },
    parseUser(user) {
        const auth_data = JSON.parse(user.google_auth_tokens);
        return auth_data;
    },
      async updateGoogleAuth(data){
          try {
              await this.$store.dispatch("agents/updateGoogleAuth", data)
          }catch (error) {
              console.log(error)
          }
      },
  }
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
}

.g-sign-in-button {
  margin: 10px;
  display: inline-block;
  width: 240px;
  height: 50px;
  background-color: #4285f4;
  color: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.g-sign-in-button:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-sign-in-button:active {
  background-color: #3367D6;
  transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.g-sign-in-button img {
  width: 18px;
  height: 18px;
}

.g-sign-in-button .logo-wrapper {
  padding: 15px;
  background: #fff;
  width: 48px;
  height: 100%;
  border-radius: 1px;
  display: inline-block;
}

.g-sign-in-button .text-container {
  font-family: Roboto,arial,sans-serif;
  font-weight: 500;
  letter-spacing: .21px;
  font-size: 16px;
  line-height: 48px;
  vertical-align: top;
  border: none;
  display: inline-block;
  text-align: center;
  width: 180px;
}
.g-btn {
  padding-top: 30%;
  padding-left: 3%;
}

.account_style {
  margin-top: -1.3rem;
  margin-left: 3rem;
  width: 20rem
}

.location_style {
  margin-left: 3rem;
  width: 20rem;
}
</style>
