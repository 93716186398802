<template>
    <div class="column app-content-background">
        <div class="header page-title">
            <div class="row">
                <h1 class="page-title_vault">
                    <woot-sidemenu-icon />
                    <i class="icon ion-android-document mr_1-6"></i>
                    {{ $t('FOLDER_MGMT.HEADER') }}
                </h1>
            </div>
        </div>
       <VaultList />
    </div>
</template>

<script>
import VaultList from './VaultList'
export default {
    components: {
        VaultList
    }
}
</script>

<style scoped lang="scss">
.app-content-background {
  background-color: #f9fafc;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.page-title {
  margin: 0;
  border-bottom: 1px solid #e0e6ed;
  background-color: #ffffff;
}
.page-title_vault {
  display: flex;
  margin: 0;
  padding: 1rem;
  font-size: 2.4rem;
}
.mr_1-6 {
  margin-right: 1.6rem
}
</style>
