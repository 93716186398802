/* global axios */
import ApiClient from './ApiClient';

class CreditTransactionsAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getTransactionsHistory(params) {
    return axios.get(`${this.url}credit_transactions`, {
      params
    })
  }

}

export default new CreditTransactionsAPI();
