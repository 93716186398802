<template>
  <div class="settings-header row">
    <div class="columns">
      <div class="row">
        <div class="col-md-4 columns">
          <h1 class="page-title">
            <woot-sidemenu-icon />
            <back-button
              v-if="showBackButton"
              :back-url="backUrl"
            ></back-button>
            <i :class="iconClass"></i>
            <span>{{ headerTitle }}</span>
          </h1>
        </div>
        <div class="col-md-8 columns">
          <button
            v-if="
              isAdmin &&
                currentUser.accounts[0].stripe &&
                !currentUser.accounts[0].stripe_account &&
                headerTitle === 'Account settings'
            "
            class="button icon success nice"
            @click.prevent="connectStripe()"
          >
            <i class="ion-android-add-circle icon-margin"></i>
            {{ $t('PROFILE_SETTINGS.STRIPE_BTN_TEXT') }}
          </button>

          <button
            v-if="
              isAdmin &&
                currentUser.accounts[0].stripe_account &&
                headerTitle === 'Account settings'
            "
            class="button icon success nice"
          >
            {{ $t('PROFILE_SETTINGS.STRIPE_CONNECTED_BTN_TEXT') }}
          </button>
        </div>
      </div>
    </div>
    <div class="medium-3 columns">
      <router-link
        v-if="showNewButton && isAdmin"
        :to="buttonRoute"
        class="button icon success nice button--fixed-right-top"
      >
        <i class="icon ion-android-add-circle icon-margin"></i>
        {{ buttonText }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../components/widgets/BackButton';
import adminMixin from '../../../mixins/isAdmin';
import alertMixin from '../../../../shared/mixins/alertMixin';
import Button from '../../../../shared/components/Button';

export default {
  components: {
    Button,
    BackButton,
  },
  mixins: [adminMixin, alertMixin],
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    buttonRoute: {
      default: '',
      type: String,
    },
    buttonText: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    showBackButton: { type: Boolean, default: false },
    showNewButton: { type: Boolean, default: false },
    backUrl: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      url: 'getStripeUrl',
    }),
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
  },
  methods: {
    async connectStripe() {
      try {
        await this.$store.dispatch('stripePayment');
        window.location = this.url.data.object_link.url;
      } catch (error) {
        this.showAlert(error?.response?.data?.message);
      }
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.margin-right {
  margin-right: 12%;
}

.icon-margin {
  margin-right: 5px;
}
</style>
