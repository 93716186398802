/* global axios */
import ApiClient from '../ApiClient';
import {isEmpty} from "jssip/lib/Utils";
import moment from "moment";

class FBChannel extends ApiClient {
  constructor() {
    super('facebook_indicators', { accountScoped: true });
  }

  create(params) {
    return axios.post(
      `${this.url.replace(this.resource, '')}callbacks/register_facebook_page`,
      params
    );
  }

  reauthorizeFacebookPage({ omniauthToken, inboxId }) {
    return axios.post(`${this.baseUrl()}/callbacks/reauthorize_page`, {
      omniauth_token: omniauthToken,
      inbox_id: inboxId,
    });
  }

  createFacebookReview(params) {
    return axios.post(
        `${this.url.replace(this.resource, '')}callbacks/create_facebook_review`,
        params
    );
  }

  async fetchFacebookReviews(params) {
      let vm = this;
      let reviews = [];
      if (isEmpty(params.range.start_date) || isEmpty(params.range.end_date)) {
          let res = await axios.get(`https://graph.facebook.com/v2.9/${params.page_id}/ratings?fields=open_graph_story,reviewer{id,name,picture}&access_token=${params.page_access_token}`)
          return res.data.data;
      } else {
          let counter = 0;
          let is_loaded = true;
          let total_review = 1;
          let review_url = `https://graph.facebook.com/v2.9/${params.page_id}/ratings?fields=open_graph_story,reviewer{id,name,picture}&access_token=${params.page_access_token}&limit=100`
          while (counter < total_review && is_loaded) {
              await fetch(`${review_url}`, {
                  'method': 'GET',
                  headers: {'Content-Type': 'application/json'}
              }).then(resp => resp.json()).then((res) => {
                  vm.total_review = res.data.length
                  counter += res.data.length
                  total_review += res.data.length
                  if (res.data.length > 0) {
                      for (let i = 0; i < res.data.length; i++) {
                          if (isEmpty(res.data[i])) {
                              is_loaded = false;
                              break;
                          }

                          if(moment(moment(res.data[i].open_graph_story.start_time)).isSameOrBefore(params.range.end_date) && moment(moment(res.data[i].open_graph_story.start_time)).isSameOrAfter(params.range.start_date)) {
                              reviews.push(res.data[i]);
                          } else {
                              is_loaded = false;
                              break;
                          }
                      }
                  }
                  if (isEmpty(res.paging)) {
                      is_loaded = false;
                  } else {
                      review_url = res.paging.next
                  }
              })
          }
          return reviews;
      }
  }

  createFacebookLead(params) {
    return axios.post(
        `${this.url.replace(this.resource, '')}callbacks/create_facebook_lead`,
        params
    );
  }

}

export default new FBChannel();
