<template>
  <div class="wizard-body columns content-box small-9">
    <form class="row" @submit.prevent="addAgents()">
      <div class="medium-12 columns">
        <page-header
          :header-title="$t('INBOX_MGMT.ADD.AGENTS.TITLE')"
          :header-content="$t('INBOX_MGMT.ADD.AGENTS.DESC') + $t('INBOX_MGMT.ADD.AGENTS.LIMIT') + ' '+ this.agentLimit.limit"
        />
      </div>
      <div class="medium-7 columns">
        <div class="medium-12 columns">
          <label :class="{ error: $v.selectedAgents.$error }">
            {{ $t('INBOX_MGMT.ADD.AGENTS.TITLE') }}

            <multiselect
              v-model="selectedAgents"
              :options="agentList"
              track-by="id"
              label="name"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              selected-label
              :max="this.currentInbox.c_type === 'private' ? 1 : this.agentLimit.limit"
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              :placeholder="$t('INBOX_MGMT.ADD.AGENTS.PICK_AGENTS')"
              @select="$v.selectedAgents.$touch"
            >
            </multiselect>
            <span v-if="$v.selectedAgents.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.AGENTS.VALIDATION_ERROR') }}
            </span>
          </label>
        </div>

        <div class="medium-12 columns">
          <woot-submit-button
            :button-text="$t('INBOX_MGMT.AGENTS.BUTTON_TEXT')"
            :loading="isCreating"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';

import InboxMembersAPI from '../../../../api/inboxMembers';
import ConversationApi from '../../../../api/conversations';
import Inboxes from '../../../../api/inboxes'
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
  },

  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },

  data() {
    return {
      selectedAgents: [],
      isCreating: false,
      inboxType: '',
    };
  },

  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      agentLimit: 'agents/getAgentLimit',
    }),
    currentInbox() {
      return this.$store.getters['inboxes/getInbox'](
          this.$route.params.inbox_id
      );
    },
  },

  mounted() {
    this.loadInitValues();
  },

  methods: {
    async loadInitValues() {
      await this.$store.getters['inboxes/getInbox'](
        this.$route.params.inbox_id
      );
      await this.$store.dispatch('agents/get');
      await this.checkInboxType(this.currentInbox.channel_type);
      await this.$store.dispatch('agents/getLimits', this.inboxType);
      await this.$store.dispatch('agents/getAvailability');
    },
    async addAgents() {
      this.isCreating = true;
      const inboxId = this.$route.params.inbox_id;
      const selectedAgents = this.selectedAgents.map(x => x.id);

      try {
        await InboxMembersAPI.update({ inboxId, agentList: selectedAgents });
        if (this.currentInbox.channel_type === "Channel::TeamChat"){
          await Inboxes.TeamChannel({ inboxId });
        }

        router.replace({
          name: 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: this.$route.params.inbox_id,
          },
        });

        if ((this.inboxType === "SIGNALWIRE LINK") || (this.inboxType === "WEB WIDGET")) {
          window.location.reload();
        }
      } catch (error) {
        bus.$emit('newToastMessage', error.message);
      }
      this.isCreating = false;
    },
    checkInboxType(selectedInbox) {
      switch (selectedInbox) {
        case "Channel::Email":
          this.inboxType = 'EMAIL'
          break;
        case "Channel::WebWidget":
          this.inboxType = 'WEB WIDGET'
          break;
        case "Channel::FacebookPage":
          this.inboxType = 'FACEBOOK'
          break;
        case "Channel::TwitterProfile":
          this.inboxType = 'TWITTER';
          break;
        // case "Channel::TwilioSms":
        //   this.inboxType = 'TWILIO';
        //   break;
        case "Channel::Api":
          this.inboxType = 'API';
          break;
        case "Channel::SignalwireLink":
          this.inboxType = 'SIGNALWIRE LINK';
          break;
        case "Channel::TeamChat":
          this.inboxType = 'TEAM CHAT';
          break;
        case "Channel::FacebookReview":
          this.inboxType = 'REVIEW';
          break;
        case "Channel::GoogleReview":
          this.inboxType = 'REVIEW';
          break;
        case "Channel::Youtube":
          this.inboxType = 'YOUTUBE';
          break;
        case "Channel::Lead":
          this.inboxType = 'LEAD';
          break;
      }
    },
  },
};
</script>
