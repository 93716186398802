import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export const getInboxClassByType = (type, phoneNumber) => {
  switch (type) {
    case INBOX_TYPES.WEB:
      return 'ion-earth';

    case INBOX_TYPES.FB:
      return 'ion-social-facebook';

    case INBOX_TYPES.TWITTER:
      return 'ion-social-twitter';
    case INBOX_TYPES.YOUTUBE:
      return 'ion-social-youtube';
    // case INBOX_TYPES.TWILIO:
    //   return phoneNumber.startsWith('whatsapp')
    //     ? 'ion-social-whatsapp-outline'
    //     : 'ion-android-textsms';

    case INBOX_TYPES.SIGNALWIRE:
      return 'ion-ios-telephone';

    case INBOX_TYPES.WHATSAPP:
      return 'ion-social-whatsapp-outline';

    case INBOX_TYPES.API:
      return 'ion-cloud';

    case INBOX_TYPES.EMAIL:
      return 'ion-ios-email';

    case INBOX_TYPES.TELEGRAM:
      return 'ion-ios-navigate';

    case INBOX_TYPES.TEAM_CHAT:
      return 'ion-chatbubbles';

    case INBOX_TYPES.REVIEW:
      return 'ion-ios-star-half';

    case INBOX_TYPES.GOOGLE_REVIEW:
      return 'ion-ios-star-half';

    default:
      return 'ion-ios-chatbubble';
  }
};
