import { isActiveElementTypeable, isEscape } from '../helpers/KeyboardHelpers';

export default {
  mounted() {
    document.addEventListener('keydown', this.onKeyDownHandler);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDownHandler);
  },
  methods: {
    onKeyDownHandler(e) {
      const isTypeable = isActiveElementTypeable(e);

      if (isTypeable) {
        if (isEscape(e)) {
          e.target.blur();
        }
        return;
      }

      if (
        ['Control', 'Shift', 'Alt', 'Tab', 'Command'].includes(e.key) ||
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ')', '(', '!', '@', '#', '%', '&', ',', '.', '?', ':'].includes(e.key) ||
        /[a-z0-9]/i.test(e.key)
      ) {
        // Key is within the allowed characters and not in the excluded keys list
        return;
      }

      this.handleKeyEvents(e);
    },
  },
};
