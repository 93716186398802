<template>
  <div class="wizard-body small-9 columns">
    <div class="row">
      <div class="small-8">
        <page-header
            :header-title="$t('INBOX_MGMT.ADD.REVIEW.TITLE')"
            :header-content="$t('INBOX_MGMT.ADD.REVIEW.DESC')"
        />
        <form
            v-if="!uiFlags.isCreating"
            class="row"
            @submit.prevent="createChannel"
        >

          <div class="medium-12 columns">
            <div class="inbox-conversation__date-range-wrapper">
              <label>Select Range</label>
              <date-range-picker @select="onDateChange" v-model="dateRange" class="range_picker">
                <!--    header slot-->
                <div slot="header" slot-scope="header" class="slot">
                  <h3>Date Range</h3> <span v-if="header.in_selection"> - in selection</span>
                </div>
                <!--    input slot (new slot syntax)-->
                <template #input="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <!--    ranges (new slot syntax) -->
                <template #ranges="ranges">
                  <div class="ranges">
                    <ul>
                      <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                        <b>{{name}}</b> <small class="text-muted">{{range[0].toDateString()}} - {{range[1].toDateString()}}</small>
                      </li>
                    </ul>
                  </div>
                </template>
                <!--    footer slot-->
                <div slot="footer" slot-scope="data" class="slot">
                  <div>
                    <b class="text-black">Selected Range</b> {{data.rangeText}}
                  </div>
                  <div style="margin-left: auto">
                    <a @click="data.clickApply();"  v-if="!data.in_selection" class="btn button btn-primary select-button">Select</a>
                  </div>
                </div>
              </date-range-picker>
            </div>
<!--            <label>-->
<!--              {{ "Fetch Reviews" }}<br>-->
<!--              <input type="radio" v-model="duration" value="all">All-->
<!--              &nbsp;&nbsp;<input type="radio" v-model="duration" value="month">Last Month-->
<!--            </label>-->
          </div>

          <div class="medium-12 columns">
            <label>
              {{ $t('INBOX_MGMT.ADD.REVIEW.CHANNEL_NAME.LABEL') }}
              <input
                  v-model.trim="inbox_name"
                  type="text"
                  :placeholder="
              $t('INBOX_MGMT.ADD.REVIEW.CHANNEL_NAME.PLACEHOLDER')
            "
              />
            </label>
          </div>

          <label class="medium-12 columns">
            {{ $t('INBOX_MGMT.ADD.REVIEW.TYPE.LABEL') }}
            <select v-model="accountType">
              <option :value="'facebook'">
                {{
                  $t(
                      'INBOX_MGMT.ADD.REVIEW.TYPE.OPTIONS.FACEBOOK'
                  )
                }}
              </option>
              <option :value="'google'">
                {{
                  $t(
                      'INBOX_MGMT.ADD.REVIEW.TYPE.OPTIONS.GOOGLE'
                  )
                }}
              </option>
            </select>
          </label>

<!--          <div class="modal-footer">-->
<!--            <div class="medium-12 columns">-->
<!--              <woot-submit-button-->
<!--                  :loading="uiFlags.isCreating"-->
<!--                  :disabled="!accountType || !inboxName"-->
<!--                  :button-text="$t('INBOX_MGMT.ADD.REVIEW.SUBMIT_BUTTON')"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
        </form>
      </div>
      <div class="small-4">
        <facebook-review
            v-if="accountType === 'facebook'"
            :inbox_name="inbox_name"
            :start_date="dateRange.startDate"
            :end_date="dateRange.endDate"
        />
        <google-review
            v-if="accountType === 'google'"
            :inbox_name="inbox_name"
            :start_date="dateRange.startDate"
            :end_date="dateRange.endDate"
        />
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import FacebookReview from "./FacebookReview";
import GoogleReview from "./GoogleReview";
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Label from "../../../../../components/ui/Label";
import moment from 'moment';

export default {
  components: {
    Label,
    FacebookReview,
    PageHeader,
    GoogleReview,
    DateRangePicker,
  },
  data() {
    return {
      inbox_name: '',
      accountType: '',
      duration: 'all',
      startDate: new Date(),
      endDate: new Date(),
      dateRange: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  methods: {
    async createChannel() {
    },
    onDateChange(e){
      this.startDate = moment(e.startDate).format('YYYY-MM-DD');
      this.endDate = moment(e.endDate).format('YYYY-MM-DD');
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
};
</script>
<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}

.inbox-conversation__date-range-wrapper {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column
}

.select-button {
  display: flex;
  background-color: #1C7EF3;
  height: 30px;
}

.range_picker {
  text-align: center;
  margin-right: 0;
}
</style>
