<template>
  <div>
    <modal :on-close="closeMissedCallMenu" :show="isMissedCallMenuOpen && setText">
      <div class="call-menu-wrapper">
        <div class="call-menu">
          <div class="call-menu_header">
            Missed Call SMS
          </div>
          <div>
            <div>
              <input
                  id="missed-call-menu__is-enabled-checkbox"
                  v-model="isEnabled"
                  class="missed-call-menu__checkbox"
                  type="checkbox"
                  @input="handleIsEnabled"
              />

              <label for="missed-call-menu__is-enabled-checkbox">
                Enable missed call message
              </label>
            </div>
            <label>
              Message sent to caller
              <textarea
                class="missed-call-menu--textarea"
                v-model.trim="missedCallSMSText"
                type="text"
                >
              </textarea>
              <div class="missed-call-menu--button">
                <woot-submit-button
                    button-text="Submit"
                    :loading="submitInProgress"
                    @click="submitMissedCallSMS"
                />
              </div>
            </label>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Modal from "./Modal";
import {missedCallSMS} from "../api/missedCallSMS";
import alertMixin from '../../shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],

  components: {
    Modal
  },

  data(){
    return{
      submitInProgress: false,
      isEnabled: false,
      missedCallSMSText: ""
    }
  },
  computed: {
    ...mapGetters({
      isMissedCallMenuOpen: 'missedCall/getMissedCallMenuOpen',
      channelId: 'missedCall/getChannelId',
      accountId: 'getCurrentAccountId',
      isInnerEnabled: 'missedCall/getMissedCallSMSEnabled',
      innerSMSText: 'missedCall/getMissedCallSMSText'
    }),
    setText(){
      if(this.innerSMSText !== ""){
        this.missedCallSMSText = this.innerSMSText;
        this.isEnabled = this.isInnerEnabled;
      }
      return true;
    }
  },
  methods: {
    buildPayload() {
      let payload = {};
      payload.missed_sms_enabled = this.isEnabled;
      payload.missed_sms_text = this.missedCallSMSText;
      return payload;
    },
    async submitMissedCallSMS(){
      this.submitInProgress = true;
      this.$store.commit('missedCall/setMissedCallSMSText', this.missedCallSMSText);
      this.$store.commit('missedCall/setMissedCallSMSEnabled', this.isEnabled);
      this.$store.commit('missedCall/setChannelId', this.channelId);
      const response = await missedCallSMS.updateMissedCallSMS(this.accountId, this.channelId, this.buildPayload());
      this.showAlert("Update Successful");
      this.submitInProgress = false;
      this.$store.commit('missedCall/setMissedCallMenuOpen', false);
    },
    handleIsEnabled(e) {
      this.isEnabled =  e.target.checked;
    },
    closeMissedCallMenu() {
      this.$store.commit("missedCall/setMissedCallMenuOpen", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.call-menu {
  padding: 32px;
}
.call-menu_header {
  padding: 0;
}
.missed-call-menu--button {
  display: flex;
  justify-content: flex-end;
}
.missed-call-menu--textarea {
  height: 60px;
  margin-bottom: 10px;
  padding: 2px 12px;
}
</style>
