<template>
  <div class="date-picker">
    <date-picker
      type="datetime"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :disabled-date="disableDate"
      @change="handleChange"
    />
  </div>
</template>

<script>
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import DatePicker from 'vue2-datepicker';

export default {
  components: { DatePicker },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Date,
      default: () => new Date(),
    },
    minDate: {
      type: [String, Date],
      default: () => new Date(),
    },
    maxDate: {
      type: [String, Date],
      default: null,
    },
  },


  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const minDate = this.minDate ? new Date(this.minDate) : today;
      const maxDate = this.maxDate ? new Date(this.maxDate) : null;
      const isBeforeToday = isBefore(date, today);
      const isAfterMaxDate = maxDate ? isAfter(date, maxDate) : false;
      const isEqualToToday = isEqual(date, today);
      return isBeforeToday || isAfterMaxDate;
    }
  },
};
</script>

<style scoped>
/* Add any specific styles for your date picker component here */
</style>
