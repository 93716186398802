<template>
  <woot-modal
    :show="show"
    :on-close="handleClose"
    :class="['tags-selector--modal', { 'expanded': isMultiselectOpen }]"
    :closeOnBackdropClick="false"
  >
    <div class="column">
      <woot-modal-header
        header-title="Select Tags"
        header-content="Select multiple tags to filter contacts"
      />
      <form class="row" @submit.prevent="handleSubmit">
        <div class="medium-12 columns">
          <multiselect
            v-model="selectedTags"
            :options="accountLabels"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            @open="multiselectopened"
            @close="multiselectclosed"
            :preserve-search="true"
            placeholder="Select tags to filter"
            label="title"
            track-by="title"
          >
          </multiselect>
        </div>
        <div class="modal-footer medium-12 columns">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="isSubmitDisabled"
              :button-text="$t('CREATE_ACCOUNT.FORM.SUBMIT')"
              :loading="loading"
              button-class="large expanded"
            />
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from "vue-multiselect";
import alertMixin from 'shared/mixins/alertMixin';
export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Multiselect
  },
  data() {
    return {
      loading: false,
      selectedTags: [],
      isMultiselectOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      accountLabels: 'labels/getLabels',
    }),
    isSubmitDisabled() {
      return this.selectedTags.length === 0;
    }
  },
  methods: {
    handleClose() {
      this.resetForm();
      this.$emit('close-tags-filer',this.selectedTags);
    },
    handleSubmit() {
      if (this.selectedTags.length > 0) {
        this.$emit('close-filter-tags-modal',this.selectedTags);
          this.handleClose();
      }
    },
    resetForm() {
      this.selectedTags=[];
    },
    multiselectopened() {
      this.isMultiselectOpen = true;
    },
    multiselectclosed() {
      this.isMultiselectOpen = false;
    },
  },
};
</script>
<style lang="scss">
.alert-wrap {
  margin: var(--space-zero) var(--space-large);
  margin-top: var(--space-medium);

  .callout {
    display: flex;
    justify-content: center;
  }
}
.tags-modal{
  min-height: 500px;
}

.tags-selector--modal {
  .tags-modal {
    min-height: 300px;
    transition: min-height 0.3s ease;

    .modal-footer {
      transition: margin-top 0.3s ease;
    }
  }

  &.expanded {
    .tags-modal {
      min-height: 600px;
    }

    .modal-footer {
      margin-top: 300px;
    }
  }
}

.icon-wrap {
  font-size: var(--font-size-big);
  margin-left: var(--space-smaller);
  margin-right: var(--space-slab);
}
</style>
