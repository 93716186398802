<template>
  <div class="contact-manage-view">
    <div class="row padding-contact-1">
      <div class="flex col-md-4 columns">
        <back-button :back-url="backUrl"></back-button>
        <span class="contact-span"> Contacts </span>
      </div>
    </div>
    <div class="row main-border">
      <div class="col-md-12">
        <manage-layout :contact-id="contactId" />
        <create-contact :show="showCreateModal" @cancel="onToggleCreate" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactsHeader from '../components/Header';
import ManageLayout from 'dashboard/modules/contact/components/ManageLayout';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact';
import BackButton from "../../../../components/widgets/BackButton.vue";

export default {
  components: {
    BackButton,
    ContactsHeader,
    CreateContact,
    ManageLayout,
  },
  props: {
    contactId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      searchQuery: '',
      showCreateModal: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    backUrl() {
      return `/app/accounts/${this.$route.params.accountId}/contacts`;
    },
  },
  mounted() {},
  methods: {
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllContacts = !!this.searchQuery && newQuery === '';
      if (refetchAllContacts) {
        this.$store.dispatch('contacts/get', { page: 1 });
      }
      this.searchQuery = newQuery;
    },
    onSearchSubmit() {
      this.selectedContactId = '';
      if (this.searchQuery) {
        this.$store.dispatch('contacts/search', {
          search: this.searchQuery,
          page: 1,
        });
      }
    },
    onToggleCreate() {
      this.showCreateModal = !this.showCreateModal;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-manage-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 0;
}
.padding-contact-1 {
  padding: 1rem !important;
}
.contact-span {
  font-size: medium !important;
  font-weight: 600 !important;
}
.main-border {
  border-top: 1px solid var(--color-border);
}
</style>
