<template>
  <section class="campaigns-table-wrap">
    <div class="top-bar">
      <div class="left-section">
        <span class="back-button" @click="onBackClick">
          <i class="ion-ios-arrow-left"></i> {{$t('CAMPAIGN.ADD.TABLE.BACK')}}
        </span>
        <span class="campaign-name campaign-status" v-if="campaignName"  >
          <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.NAME')}}:</strong> {{campaignName}}</p>
        </span>
        <span v-if="campaignStatus" class="campaign-status">
          <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.STATUS')}}:</strong> {{campaignStatus}}</p>
        </span>
      </div>
      <div class="right-section">
        <button @click="exportToCSV" class="export-button">
          Export Leads
        </button>
        <select v-model="selectedStatus" @change="applyFilter">
          <option value="all">{{$t('CAMPAIGN.ADD.TABLE.ALL')}}</option>
          <option value="no_answer">{{$t('CAMPAIGN.ADD.TABLE.NO_ANSWER')}}</option>
          <option value="answered - success">{{$t('CAMPAIGN.ADD.TABLE.ANSWERED_SUCCESS')}}</option>
          <option value="answered - fail">{{$t('CAMPAIGN.ADD.TABLE.ANSWERED_FAILED')}}</option>
          <option value="pending">{{$t('CAMPAIGN.ADD.TABLE.PENDING')}}</option>
        </select>
      </div>
    </div>


    <ve-table
      :columns="columns"
      max-height="calc(100vh - 25.4rem)"
      scroll-width="155rem"
      :table-data="filteredTableData"
      :border-around="true"
    />

    <div v-if="isLoading" class="campaign--loader">
      <spinner />
      <span>Loading</span>
    </div>

    <calling-summary-modal :content="currentSummary" :show="isSummaryModalVisible" @close-content-modal="isSummaryModalVisible = false" />

  </section>
</template>


<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import { mapGetters } from 'vuex';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import CallingSummaryModal from '../../../../components/widgets/modal/CallingSummaryModal.vue';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
    CallingSummaryModal
  },
  mixins: [clickaway],
  props: {
    campaignId: {
      type: String,
      default: '',
    },
    campaignName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,
      isSummaryModalVisible: false,
      currentSummary: '',
      columns: [
        {
          field: 'call_start_timestamp',
          key: 'call_start_timestamp',
          title: "Start Time",
          align: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="text-truncate">
                {new Date(row.call_start_timestamp).toLocaleString()}
              </div>
            );
          },
        },
        {
          field: 'call_end_timestamp',
          key: 'call_end_timestamp',
          title: "End Time",
          align: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="text-truncate">
                {new Date(row.call_end_timestamp).toLocaleString()}
              </div>
            );
          },
        },
        {
          field: 'ai_agent',
          key: 'ai_agent',
          title: "Ai Agent",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <span title={row.ai_agent}>{row.ai_agent}</span>
            </div>
          ),
        },
        {
          field: 'first_name',
          key: 'first_name',
          title: "First name",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <span title={row.first_name}>{row.first_name}</span>
            </div>
          ),
        },
        {
          field: 'last_name',
          key: 'last_name',
          title: "Last Name",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <span title={row.last_name}>{row.last_name}</span>
            </div>
          ),
        },
        {
          field: 'from_phone',
          key: 'from_phone',
          title: "From",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <span title={row.from_phone}>{row.from_phone}</span>
            </div>
          ),
        },
        {
          field: 'to_phone',
          key: 'to_phone',
          title: "To",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <span title={row.to_phone}>{row.to_phone}</span>
            </div>
          ),
        },
        {
          field: 'call_length',
          key: 'call_length',
          title: "Call Length",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <span title={row.call_length}>{row.call_length} sec</span>
            </div>
          ),
        },
        {
          field: 'status',
          key: 'status',
          title: "Status",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <span title={row.status}>{row.status}</span>
            </div>
          ),
        },
        {
          field: 'recording_url',
          key: 'recording_url',
          title: "Recording",
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href={row.recording_url}
                title={row.recording_url}
              >
                Recording
              </a>
            </div>
          ),
        },
        {
          field: 'summary',
          key: 'summary',
          title: "",
          align: 'left',
          renderBodyCell: row => (
            <div class="button-wrapper">
              <WootButton
                variant="link"
                icon="ion-ios-telephone"
                color-scheme="green"
                onClick={() => this.showSummary(row)}
              >
                summary
              </WootButton>
            </div>
          ),
        },
      ],
      campaignStatus: '',
      selectedStatus: 'all',
      callResults: [],
    };
  },


  async created() {
    await this.fetchCampaignResults();
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    filteredTableData() {
      if (this.selectedStatus && this.selectedStatus!='all') {
        return this.callResults.filter(result => result.status === this.selectedStatus);
      }
      else if(this.selectedStatus == 'all')
      {
        return this.callResults;
      }
    },
    // tableData() {
    //   if (this.isLoading) {
    //     return [];
    //   }
    //   return this.callResults;
    // },
  },
  methods: {
    showSummary(data) {
      if (!data.row.summary) {
        this.currentSummary = `No summary of conversation with ${data.row.first_name} ${data.row.last_name}`;
      } else {
        this.currentSummary = data.row.summary;
      }
      this.isSummaryModalVisible = true;
    },
    onBackClick(){
      this.$emit('close-results')
    },
    async fetchCampaignResults() {
      try {
        this.isLoading = true;
        const campaignPayload = {
          account_id: this.accountId.toString(),
          filter_by: 'all',
          campaign_id: this.campaignId,
        };
        const response = await axios.post(
          'https://agentapi.conversate.us/v1/campaign/get_campaign_results',
          campaignPayload,
          {
            headers: {
              Authentication: 'ec90c54bb8f24c7e9eekjahgs7bb8762jdf51',
            }
          }
        );
        this.callResults = response.data.call_results || [];
        this.campaignStatus = response.data.campaign_status || 'Unknown';
      } catch (error) {
        console.error('Error fetching campaign results:', error);
      } finally {
        this.isLoading = false;
      }
    },
    exportToCSV() {
      const filteredResults = this.filteredTableData;
      const csvRows = [];


      csvRows.push([
        'call_start_timestamp', 'call_end_timestamp', 'ai_agent',
        'first_name', 'last_name', 'from_phone',
        'to_phone', 'call_length', 'status', 'recording_url', 'summary'
      ]);

      filteredResults.forEach(row => {
        csvRows.push([
          new Date(row.call_start_timestamp).toLocaleString(),
          new Date(row.call_end_timestamp).toLocaleString(),
          row.ai_agent || '',
          row.first_name || '',
          row.last_name || '',
          row.from_phone || '',
          row.to_phone || '',
          row.call_length || '',
          row.status || '',
          row.recording_url || '',
          row.summary || ''
        ]);
      });

      const csvString = csvRows.map(e => e.join(',')).join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const fileName = `${this.selectedStatus.replace(/\s+/g, '-').toLowerCase()}-${this.campaignName}.csv`;
      const link = document.createElement('a');

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.campaigns-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
    thead.ve-table-header .ve-table-header-tr .ve-table-header-th {
      font-size: var(--font-size-mini);
      padding: var(--space-small) var(--space-two);
    }
    tbody.ve-table-body .ve-table-body-tr .ve-table-body-td {
      padding: var(--space-slab) var(--space-two);
    }
  }

  .row--title-block {
    align-items: center;
    display: flex;
    text-align: left;

    .title {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize;
    }
  }
  .label {
    padding: var(--space-smaller) var(--space-small);
  }
}

.campaign--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  min-width: 20rem;
}

.campaigns-table-wrap {
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    background-color: white; // Set background to white or transparent

    .left-section {
      display: flex;
      align-items: center;

      .back-button {
        margin-right: 1.5rem;
        cursor: pointer;
        font-weight: bold;
      }

      .campaign-name{
        margin-right: 1.5rem;
      }

      .campaign-status {
        font-size: 1.2rem;

        strong {
          font-weight: bold; // Bold for the heading
        }

        span {
          font-weight: lighter; // Lighter for the status value
          margin-left: 0.5rem;
        }
      }
    }

    .right-section {
      display: flex;
      align-items: center;

      .export-button {
        margin-right: 1rem;
      }

      select {
        padding: 0.5rem;
        font-size: 1.2rem;
        min-width: 10rem;
        border: 1px solid #ccc;
      }
    }
  }

  .campaign--loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  .export-button {
    padding: 1.2rem 1rem;
    font-size: 1.2rem;
    margin-bottom: 1.25rem;
    margin-right: 1rem;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    transition: background-color 0.3s;
  }

  .export-button:hover {
    background-color: #0056b3;
  }

}


</style>
