<template>
  <div class="medium-12 columns sms-bot-settings-form">

    <div>
      <input
          id="sms-bot-settings__is-enabled-checkbox"
          v-model="isEnabled"
          class="sms-bot-settings__checkbox"
          type="checkbox"
          @input="handleIsEnabled"
      />

      <label for="sms-bot-settings__is-enabled-checkbox">
        Enable Autoresponse Bot
      </label>
    </div>

    <div v-if="isEnabled">
      <div class="form-block" style="margin-top: 0">
        <label>
          Greeting
          <textarea
              v-model.trim="greeting"
              type="text"
              placeholder="e.g., &quot;Welcome to company!&quot;"
          />
        </label>
      </div>

<!--      <div class="form-block" style="margin-top: 0">-->
<!--        <label>-->
<!--          Missed Redirect-->
<!--          <textarea-->
<!--              style="min-height: 130px"-->
<!--              v-model.trim="missed_redirect"-->
<!--              type="text"-->
<!--              placeholder="e.g., &quot;Welcome to company!&quot;"-->
<!--          />-->
<!--        </label>-->
<!--      </div>-->

      <div class="form-block">
        <label>
          Collect
        </label>

        <div class="sms-bot-item-wrapper">
          <label>
            Name
          </label>

          <textarea
              v-model.trim="collect_name"
              type="text"
              placeholder="e.g., &quot;What is your name?&quot;"
          />

            <div>
                <input
                        id="sms-bot-settings__is-collect-email-enabled-checkbox"
                        v-model="isCollectEmailEnabled"
                        class="sms-bot-settings__checkbox"
                        type="checkbox"
                        @input="handleIsCollectEmailEnabled"
                />

                <label for="sms-bot-settings__is-collect-email-enabled-checkbox">
                    Enable Email
                </label>
            </div>

          <label v-if="isCollectEmailEnabled">
            Email
          </label>

          <textarea
              v-model.trim="collect_email"
              v-if="isCollectEmailEnabled"
              type="text"
              placeholder="e.g., &quot;Share your email to get the important information!&quot;"
          />


          <div>
              <input
                      id="sms-bot-settings__is-collect-phone-enabled-checkbox"
                      v-model="isCollectPhoneEnabled"
                      class="sms-bot-settings__checkbox"
                      type="checkbox"
                      @input="handleIsCollectPhoneEnabled"
              />

              <label for="sms-bot-settings__is-collect-phone-enabled-checkbox">
                  Enable Phone Number
              </label>
          </div>

        <label v-if="isCollectPhoneEnabled">
          Phone Number
        </label>

        <textarea
            v-model.trim="collect_phone"
            v-if="isCollectPhoneEnabled"
            type="text"
            placeholder="e.g., &quot;Share your phone number to get the important information!&quot;"
        />
        </div>
      </div>

      <div class="form-block">
        <label>
          Menu
          <textarea
              style="min-height: 130px"
              v-model.trim="menu"
              type="text"
              placeholder="e.g., &quot;To schedule an appointment, respond with 'Appointment', for our address, respond 'Address', or 'Agent' to talk to a live agent&quot;"
          />
        </label>
      </div>


      <div class="form-block"
           v-for="(item, index) in menu_items"
      >
        <div class="delete-icon"
             v-if="menu_items.length !== 1"
             @click="() => {deleteItem(index)}"
        >
          <img src="~dashboard/assets/images/close.svg" alt="Close icon" />
        </div>

        <label>
          Menu Item {{index + 1}}
        </label>

        <div class="sms-bot-item-wrapper">
          <label :class="{ error: menu_items[index].error_keyword }">
            Keyword

            <textarea
                style="min-height: 85px"
                v-model.trim="menu_items[index].keyword"
                type="text"
                placeholder="Enter keyword to match with menu item, e.g. 'Address'"
                @input="check"
            />
          </label>


          <label :class="{ error: menu_items[index].error_response }">
            Response

            <textarea
                style="min-height: 85px"
                v-model.trim="menu_items[index].response"
                type="text"
                placeholder="Enter response SMS bot sends, e.g. '7609 Mckinley Ave Los Angeles, CA, 90001'"
                @input="check"
            />
          </label>

        </div>
      </div>


      <div class="sms-bot-settings__buttons-wrapper">
        <woot-submit-button
            button-text="Add Item"
            @click="addItem"
        />

      </div>




      <div class="form-block">
        <label>
          Agent Transfer
        </label>

        <div class="sms-bot-item-wrapper">
          <label :class="{ error: redirect_keyword_error }">
            Keyword

            <textarea
                style="min-height: 85px"
                v-model.trim="redirect_keyword"
                type="text"
                placeholder="Enter keyword to redirect customer to live agent, e.g. 'Agent'"
            />
          </label>


          <label :class="{ error: redirect_response_error }">
            Response

            <textarea
                style="min-height: 85px"
                v-model.trim="redirect_response"
                type="text"
                placeholder="Enter response SMS bot sends, e.g. 'Please wait, agent is coming!'"
            />
          </label>

        </div>
      </div>

<!--      <div class="form-block">-->
<!--        <label>-->
<!--          Agent unavailable-->
<!--          <textarea-->
<!--              style="min-height: 70px"-->
<!--              v-model.trim="unavailable"-->
<!--              type="text"-->
<!--              placeholder="e.g., &quot;Unfortunately all the agents are busy now&quot;"-->
<!--          />-->
<!--        </label>-->
<!--      </div>-->




        <div>
            <input
                    id="sms-bot-settings__is-agent-unavailable-enabled-checkbox"
                    v-model="isAgentUnavailableEnabled"
                    class="sms-bot-settings__checkbox"
                    type="checkbox"
                    @input="handleIsAgentUnavailableEnabled"
            />

            <label for="sms-bot-settings__is-agent-unavailable-enabled-checkbox">
                Enable Agent unavailable
            </label>
        </div>

        <label v-if="isAgentUnavailableEnabled">
            Agent unavailable
        </label>

        <textarea
                v-model.trim="unavailable"
                v-if="isAgentUnavailableEnabled"
                style="min-height: 70px"
                type="text"
                placeholder="e.g., &quot;Unfortunately all the agents are busy now&quot;"
        />

      <div class="form-block" style="margin-top: 0">
        <label>
          Missed Redirect
          <textarea
              style="min-height: 130px"
              v-model.trim="missed_redirect"
              type="text"
              placeholder="e.g., &quot;Sorry, but there is no one currently able to assist. Please write your question here and we will contact you as soon as possible&quot;"
          />
        </label>
      </div>





      <!-- <div class="form-block">
        <label>
          Failed
          <textarea
              style="min-height: 70px"
              v-model.trim="failed"
              type="text"
              placeholder="e.g., &quot;Sorry, invalid entry. Please try again&quot;"
          />
        </label>
      </div> -->



      <div class="sms-bot-settings__buttons-wrapper">
        <woot-submit-button
            button-text="Submit"
            :loading="updateInProgress"
            @click="updateSMSBotSettings"
        />

      </div>
    </div>


  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import signalwireChannelAPI from "../../../../../../../api/channel/signalwireChannel";
import {globalAIP} from "../../../../../../../api/globalAIP";
import {mapGetters} from "vuex";

export default {
  name: "SmsBotSettings",
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object
    }
  },

  data() {
    return {
      updateInProgress: false,

      greeting: "Welcome to the company!",
      collect_phone: "",
      collect_name: "What is your name?",
      collect_email: "",
      menu: "To schedule an appointment, respond with 'Appointment', for our address, respond 'Address', or 'Agent' to talk to a live agent",
      menu_items: [
        {
          keyword: "Address",
          response: "7609 Mckinley Ave Los Angeles, CA, 90001",
          error_keyword: false,
          error_response: false
        }
      ],


      redirect_keyword: "Agent",
      redirect_response: "Please wait, agent is coming!",
      redirect_keyword_error: false,
      redirect_response_error: false,


      unavailable: "",
      // failed: "Sorry, invalid entry. Please try again",
      missed_redirect: "Sorry, but there is no one currently able to assist. Please write your question here and we will contact you as soon as possible",

      isEnabled: false,
        isCollectPhoneEnabled: false,
        isCollectEmailEnabled: false,
        isAgentUnavailableEnabled: false,

    }
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId'
    }),
  },

  async mounted() {
    this.isEnabled = this.inbox.is_sms_bot_enabled;

    if (this.inbox.sms_bot_id) {
      const response = await signalwireChannelAPI.getSmsBot(this.inbox.sms_bot_id);

      const smsBot = response.data.sms_bot;
      const smsBotParam = response.data.sms_bot_param;

      this.greeting = smsBot.greeting ? smsBot.greeting : "";
      this.collect_name = smsBotParam.collect_info ? smsBotParam.collect_info.name : "";
      this.collect_phone = smsBotParam.collect_info ? (smsBotParam.collect_info.phone ? smsBotParam.collect_info.phone : "") : "";
      this.collect_email = smsBotParam.collect_info ? (smsBotParam.collect_info.email ? smsBotParam.collect_info.email : "") : "";
      this.menu = smsBotParam.menu ?  smsBotParam.menu : "";
      this.menu_items = smsBotParam.menu_item ? smsBotParam.menu_item :  [{keyword: "", response: "", error_keyword: false, error_response: false}];
      this.redirect_keyword = smsBotParam.redirect_menu_item ? smsBotParam.redirect_menu_item.keyword : "";
      this.redirect_response = smsBotParam.redirect_menu_item ? smsBotParam.redirect_menu_item.response : "";
      this.unavailable = smsBotParam.unavailable ? smsBotParam.unavailable : "";
      // this.failed = smsBotParam.failed ? smsBotParam.failed : "";
      this.missed_redirect = smsBotParam.missed_redirect ? smsBotParam.missed_redirect : "";

      if (smsBotParam.collect_info && smsBotParam.collect_info.email) {
          this.isCollectEmailEnabled = true;
      }
      if (smsBotParam.collect_info && smsBotParam.collect_info.phone) {
          this.isCollectPhoneEnabled = true;
      }

      if (smsBotParam.unavailable) {
          this.isAgentUnavailableEnabled = true;
      }

      this.menu_items = this.menu_items.map((value) => {
        let newValue = {};

        newValue.keyword = value.keyword;
        newValue.response = value.response;

        newValue.error_keyword = false;
        newValue.error_response = false;

        return newValue;
      });
    }
  },

  methods: {
    async handleIsEnabled(e) {
      this.isEnabled = e.target.checked;

      await globalAIP.updateSmsBotEnabled(this.accountId, this.inbox.id, e.target.checked);

      await this.$store.dispatch("inboxes/get");
    },
      handleIsCollectEmailEnabled(e) {
        this.isCollectEmailEnabled = e.target.checked;

        if (!e.target.checked) {
            this.collect_email = "";
        }
        if (e.target.checked) {
            this.collect_email = "Share your email to get the important information!";
        }
      },
      handleIsCollectPhoneEnabled(e) {
        this.isCollectPhoneEnabled = e.target.checked;
        if (!e.target.checked) {
            this.collect_phone = "";
        }
        if (e.target.checked) {
            this.collect_phone = "Share your phone number to get the important information!";
        }
      },
      handleIsAgentUnavailableEnabled(e) {
        this.isAgentUnavailableEnabled = e.target.checked;

        if (!e.target.checked) {
            this.unavailable = "";
        }
        if (e.target.checked) {
            this.unavailable = "Unfortunately all the agents are busy now";
        }
      },
    addItem() {
      this.menu_items.push({keyword: "", response: "", error_keyword: false, error_response: false});
    },
    buildObject() {
      const response = {};

      const trimGreeting = this.greeting ? this.greeting.trim() : "";
      const trimCollectName = this.collect_name ? this.collect_name.trim() : "";
      const trimCollectEmail = this.collect_email ? this.collect_email.trim() : "";
      const trimCollectPhone = this.collect_phone ? this.collect_phone.trim() : "";
      const trimMenu = this.menu ? this.menu.trim() : "";

      const trimMenuItems = this.menu_items.map(value => {return {keyword: value.keyword.trim(), response: value.response.trim()}});

      const trimRedirectKeyword = this.redirect_keyword ? this.redirect_keyword.trim() : "";
      const trimRedirectResponse = this.redirect_response ? this.redirect_response.trim() : "";

      const trimUnavailable = this.unavailable ? this.unavailable.trim() : "";
      // const trimFailed = this.failed ? this.failed.trim() : "";
      const trimMissedRedirect = this.missed_redirect ? this.missed_redirect.trim() : "";

      response.sms_bot_param = {};
      response.sms_bot = {};

      response.sms_bot.greeting = trimGreeting;
      response.sms_bot.inbox_id = this.inbox.id;

      response.sms_bot_param.collect_info = {};
      response.sms_bot_param.redirect_menu_item = {};

      response.sms_bot_param.collect_info.name = trimCollectName;
      response.sms_bot_param.collect_info.email = trimCollectEmail;
      response.sms_bot_param.collect_info.phone = trimCollectPhone;
      response.sms_bot_param.menu = trimMenu;
      response.sms_bot_param.menu_item = trimMenuItems;
      response.sms_bot_param.redirect_menu_item.keyword = trimRedirectKeyword;
      response.sms_bot_param.redirect_menu_item.response = trimRedirectResponse;
      response.sms_bot_param.unavailable = trimUnavailable;
      // response.sms_bot_param.failed = trimFailed;
      response.sms_bot_param.missed_redirect = trimMissedRedirect;

      return response;
    },
    deleteItem(index) {
      this.menu_items.splice(index, 1);
    },
    check() {
      let flag = true;

      this.menu_items.forEach((item) => {
        if ((item.keyword.trim().length !== 0) && (item.response.trim().length === 0))  {
          item.error_response = true;

          flag = false;
        } else {
          item.error_response = false;
        }

        if ((item.keyword.trim().length === 0) && (item.response.trim().length !== 0)) {
          item.error_keyword = true;

          flag = false;
        } else {
          item.error_keyword = false;
        }

      });

      if (flag) {
        this.menu_items.forEach((item, index) => {
          this.menu_items.forEach((item_2, index_2) => {
            if (index !== index_2) {
              if (item.keyword === item_2.keyword) {
                item.error_keyword = true;
                item_2.error_keyword = true;
                flag= false;
              }
            }
          });
        });
      }

      if (flag) {
        this.menu_items.forEach((item) => {
          item.error_keyword = false;
        });
      }



      if ((this.redirect_keyword.length === 0) && ((this.redirect_response.length !== 0) )) {
        this.redirect_keyword_error = true;

        flag = false;
      } else {
        this.redirect_keyword_error = false;
      }

      if ((this.redirect_keyword.length !== 0) && ((this.redirect_response.length === 0) )) {
        this.redirect_response_error = true;

        flag = false;
      } else {
        this.redirect_response_error = false;
      }




      return flag;
    },
    async updateSMSBotSettings() {
      try {
        const isCorrect = this.check();

        if (!isCorrect) {
          return;
        }

        this.updateInProgress = true;

        let response = null;

        if (this.inbox.sms_bot_id) {
          response = await signalwireChannelAPI.updateSmsBotSettings(this.buildObject(), this.inbox.sms_bot_id);
        } else {
          response = await signalwireChannelAPI.createSmsBotSettings(this.buildObject());
        }

        await this.$store.dispatch("inboxes/get");

        this.showAlert("Update Successful");
      }
      catch (e) {
        console.log(e);

        this.showAlert("Error");

        this.updateInProgress = false;
      }

      this.updateInProgress = false;
    }
  }
}
</script>

<style scoped>
.sms-bot-settings-form {
  margin-bottom: 30px;
}

.form-block {
  margin-top: 30px;

  position: relative;
}

.sms-bot-settings__buttons-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.sms-bot-item-wrapper {
  border: 1px solid #e0e6ed;

  padding: 20px;

  border-radius: 5px;

  margin-bottom: 15px;
}

.delete-icon{
  padding: 0;
  position: absolute;
  top:20px;
  right:-30px;
  border:none;
  background: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.sms-bot-settings__checkbox {
    cursor: pointer;
}
</style>
