import FileVaultView from './components/FileVaultView'
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
    {
        path: frontendURL('accounts/:accountId/file-vault'),
        name: 'file_vault',
        roles: ['administrator', 'agent'],
        component: FileVaultView
    }
]
