<template>
  <div>
    <form class="contact--form" @submit.prevent="addAIAgent">
      <div class="row">
        <div class="medium-6 columns">
          <label :class="{ error: $v.name.$error }">
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NAME.LABEL') }} </u>
            <input
              v-model.trim="name"
              type="text"
              :placeholder="$t('AGENT_MGMT.AI_AGENT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
          </label>
        </div>
        <div class="medium-6 columns">
          <label :class="{ error: $v.companyName.$error }">
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.COMPANY_NAME.LABEL') }} </u>
            <input
              v-model.trim="companyName"
              type="text"
              :placeholder="$t('AGENT_MGMT.AI_AGENT.ADD.FORM.COMPANY_NAME.PLACEHOLDER')"
              @input="$v.companyName.$touch"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="medium-6 columns">
          <label>
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.PERSONALITY.LABEL') }} </u>
            <select
              v-model="personality"
              @change="onChangePersonality"
              class="status--filter"
            >
              <option value="" disabled selected>
                {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.PERSONALITY.PLACEHOLDER') }}
              </option>
              <option
                v-for="item in $t(
                'AGENT_MGMT.AI_AGENT.ADD.FORM.PERSONALITY.ITEMS'
              )"
                :key="item['KEY']"
                :value="item['VALUE']"
              >
                {{ item['VALUE'] }}
              </option>
            </select>
          </label>
        </div>
        <div class="medium-6 columns">
          <label :class="{ error: $v.inbox.$error }">
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.INBOXES.LABEL') }} </u>
            <multiselect
              v-model="inbox"
              :options="inboxesList"
              track-by="id"
              label="name"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              :placeholder="
              $t('AGENT_MGMT.ADD.FORM.ADD_AGENTS_ON_INBOX.PLACEHOLDER')
            "
            >
            </multiselect>
          </label>
          <div class="prompt-top-mergin">
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.INBOXES.NOTE') }}
          </div>
        </div>
      </div>
      <div class="row prompt-top">
        <div class="medium-12 columns">
          <label>
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.PROMPT.LABEL') }} </u>
            <textarea
              v-model.trim="aiPrompt"
              type="text"
              rows="4"
              :placeholder="$t('AGENT_MGMT.AI_AGENT.ADD.FORM.PROMPT.PLACEHOLDER')"

            />
          </label>
          <span :aria-disabled="!aiPrompt" @click="aiPrompt && (showAIAssistanceModal = true)"  :class="['button-ai-assist', 'cta-btn', 'cta-btn-light',
    'dark:cta-btn-dark', 'hover:cta-btn-light-hover',
    'dark:hover:cta-btn-dark-hover', { 'disabled': !aiPrompt }]">
            <fluent-icon icon="wand" size="14" class="ai-typing--icon ai-assist-button-child" />
            {{ $t('INTEGRATION_SETTINGS.OPEN_AI.AI_ASSIST') }}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="medium-12 columns">
          <label >
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.TEMPLATE.LABEL') }} </u>
            <select
              v-model="template"
              @change="onChangeTemplate"
              class="status--filter"
            >
              <option value="" disabled selected>
                {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.TEMPLATE.PLACEHOLDER') }}
              </option>
              <option
                v-for="item in $t('AGENT_MGMT.AI_AGENT.ADD.FORM.TEMPLATE.ITEMS')"
                :key="item['KEY']"
                :value="item['VALUE']"
              >
                {{ item['VALUE'] }}
              </option>
            </select>
          </label>
          <div class="prompt-top-mergin">
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.TEMPLATE.NOTE') }}
          </div>
        </div>
      </div>
      <div class="row prompt-top">
        <div class="medium-12 columns">
          <label >
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.TEMPLATE_DETAIL.LABEL') }} </u>
            <textarea
              v-model.trim="templateDetail"
              type="text"
              rows="4"

              :placeholder="$t('AGENT_MGMT.AI_AGENT.ADD.FORM.TEMPLATE_DETAIL.PLACEHOLDER')"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="medium-12 columns">
          <label :class="{ error: trainingRequired}">
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.TRAINING.LABEL') }} </u>
            <textarea
              v-model.trim="training"
              type="text"
              rows="4"
              :placeholder="
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.TRAINING.PLACEHOLDER')
            "
              @input="training"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="medium-4 columns">
          <div class="up-load-file" @click="triggerFileInput">
            <!-- New File Upload Input -->
            <input
              ref="fileInput"
              type="file"
              accept=".pdf, .txt"
              style="display: none;"
              @change="handleFileChange"
            />
            <!-- Placeholder for File Upload -->
            <label class="file-upload-placeholder file-place">
              {{
                selectedFileName ||
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.FILE_UPLOAD_LABEL')
              }}
            </label>
          </div>
        </div>
        <div class="medium-4 columns">
          <label :class="{ error : invalidUrl || urlRequired }">
            <input
              v-model.trim="siteUrl"
              type="text"
              :disabled = "crawlSiteUrl == 'noCrawl'"
              :placeholder="
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.WEBSITE_LABEL')
            "
            />
          </label>
        </div>
        <div class="medium-offset-1 medium-3 columns">
          <div class="row margin-crawl-bottom">
            <div class="medium-12 columns">
              <label class="w-full">
                <input
                  id="public"
                  v-model="crawlSiteUrl"
                  type="radio"
                  name="selectedType"
                  value="noCrawl"
                  class="label-input"
                />
                <label class="label-tag" for="public">{{
                    $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.NO_CRAWL')
                  }}</label>
              </label>
            </div>
          </div>
          <div class="row margin-crawl-bottom">
            <div class="medium-12 columns">
              <label class="w-full">
                <input
                  id="public"
                  v-model="crawlSiteUrl"
                  type="radio"
                  name="selectedType"
                  value="crawlURL"
                  class="label-input"
                />
                <label class="label-tag" for="public">{{
                    $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.URL_LABEL')
                  }}</label>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="medium-12 columns">
              <label class="w-full">
                <input
                  id="public"
                  v-model="crawlSiteUrl"
                  type="radio"
                  name="selectedType"
                  value="crawlSite"
                  class="label-input"
                />
                <label class="label-tag" for="public">{{
                    $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.SITE_LABEL')
                  }}</label>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row prompt-top">
        <div class="medium-8 columns">
          <label :class="{ error: $v.greeting.$error }">
          <textarea
            v-model.trim="greeting"
            type="text"
            rows="5"
            :placeholder="
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.SCHEDULE_PLACEHOLDER')
            "
            @input="$v.greeting.$touch"
          />
          </label>
        </div>
        <div class="medium-offset-1 medium-3 columns">
          <u>
            <label>
              {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.SCHEDULE_LABEL') }}
            </label>
          </u>

          <label class="w-full margin-bottom-greeting">
            <input
              id="public"
              v-model="crawlSchedule"
              type="radio"
              name="scheduleType"
              value="onlyOnce"
              class="label-input"
            />
            <label class="label-tag" for="public">{{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.ONCE_LABEL')
              }}</label>
          </label>
          <label class="w-full margin-bottom-greeting">
            <input
              id="public"
              v-model="crawlSchedule"
              type="radio"
              name="scheduleType"
              value="perDay"
              class="label-input"
            />
            <label class="label-tag" for="public">{{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.PER_DAY_LABEL')
              }}</label>
          </label>
          <label class="w-full">
            <input
              id="public"
              v-model="crawlSchedule"
              type="radio"
              name="scheduleType"
              value="weekly"
              class="label-input"
            />
            <label class="label-tag" for="public">{{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.WEEKLY_LABEL')
              }}</label>
          </label>
          <label class="w-full">
            <input
              id="public"
              v-model="crawlSchedule"
              type="radio"
              name="scheduleType"
              value="monthly"
              class="label-input"
            />
            <label class="label-tag" for="public">
              {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CRAWL.MONTHLY_LABEL') }}
            </label>
          </label>
        </div>
      </div>

      <div class="row prompt-top">
        <div class="medium-8 columns">
          <label>
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.SCHEDULE.LABEL') }} </u>
            <select v-model="scheduleLink" class="status--filter">
              <option value="" disabled selected>
                {{
                  $t('AGENT_MGMT.AI_AGENT.ADD.FORM.SCHEDULE.EVENT_PLACEHOLDER')
                }}
              </option>
              <option
                v-for="link in schedulingLinks"
                :key="link.slug"
                :value="link.url"
              >
                {{ link.name }}
              </option>
            </select>
          </label>
          <div class="prompt-top-mergin">
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.SCHEDULE.NOTE') }}
          </div>

          <label>
            <input
              v-model.trim="customLink"
              type="text"
              :placeholder="
              $t('AGENT_MGMT.AI_AGENT.ADD.FORM.SCHEDULE.CUSTOM_PLACEHOLDER')
            "
            />
          </label>
        </div>

        <div class="medium-offset-1 medium-3 columns">
          <u>
            <label>
              {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.LABEL') }}
            </label>
          </u>
          <label class="w-full notification-first-bottom">
            <input
              id="public"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="none"
              class="label-input"
            />
            <label class="label-tag" for="public">{{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.NONE')
              }}</label>
          </label>
          <label class="w-full margin-bottom-greeting">
            <input
              id="public"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="email"
              class="label-input"
            />
            <label class="label-tag" for="public">{{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.EMAIL')
              }}</label>
          </label>
          <label class="w-full notification-last-bottom">
            <input
              id="public"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="sms"
              class="label-input"
            />
            <label class="label-tag" for="public">{{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.SMS')
              }}</label>
          </label>
          <label class="w-full">
            <input
              id="public"
              v-model="notificationType"
              type="radio"
              name="notificationType"
              value="emailSms"
              class="label-input"
            />
            <label class="label-tag" for="public">{{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.EMAIL_SMS')
              }}</label>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="medium-4 columns">
          <u>
            <label>
              {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.DATA_COLLECTION.LABEL') }}
            </label>
          </u>
          <label>
            <input v-model="dataName" type="checkbox" />
            <span>
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.DATA_COLLECTION.NAME') }}
          </span>
          </label>
          <label>
            <input v-model="dataPhone" type="checkbox" />
            <span>
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.DATA_COLLECTION.PHONE') }}
          </span>
          </label>
          <label>
            <input v-model="dataEmail" type="checkbox" />
            <span>
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.DATA_COLLECTION.EMAIL') }}
          </span>
          </label>
        </div>

        <div class="medium-4 columns">
          <div class="row">
            <div class="medium-12">
              <u>
                <label>
                  {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.CUSTOM_ATTRIBUTES.LABEL') }}
                </label>
              </u>
              <div
                v-for="(div, index) in customAttributes"
                :key="`domain-${index}`"
                class="row"
              >
                <div class="medium-9">
                  <label>
                    <input
                      v-model.trim="div.name"
                      type="text"
                      :placeholder="
                      $t(
                        'AGENT_MGMT.AI_AGENT.ADD.FORM.CUSTOM_ATTRIBUTES.PLACEHOLDER'
                      )
                    "
                    />
                  </label>
                </div>
                <div class="medium-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                    @click="addField(div, customAttributes)"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    />
                  </svg>

                  <!--          Remove Svg Icon-->
                  <svg
                    v-show="customAttributes.length > 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                    @click="removeField(index, customAttributes)"
                  >
                    >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="medium-4 columns">
          <div class="row">
            <div class="medium-12">
              <div
                v-for="(div, index) in notificationDomain"
                :key="`domain-${index}`"
                class="row"
              >
                <div class="medium-9">
                  <label :class="{ error: emailRequired }">
                    <input
                      v-model.trim="div.domain"
                      type="email"
                      :placeholder="
                      $t(
                        'AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.DOMAIN_PLACEHOLDER'
                      )
                    "
                    />
                  </label>
                </div>
                <div class="medium-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                    @click="addField(div, notificationDomain)"
                  >
                    >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    />
                  </svg>

                  <!--          Remove Svg Icon-->
                  <svg
                    v-show="notificationDomain.length > 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    crawlSchedule
                    class="ml-2 cursor-pointer"
                    @click="removeField(index, notificationDomain)"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="medium-12">
              <div
                v-for="(div, index) in notificationPhone"
                :key="`domain-${index}`"
                class="row"
              >
                <div class="medium-9">
                  <label :class="{ error: !validatePhone(div.phone) || numberRequired}">
                    <input
                      v-model.trim="div.phone"
                      type="text"
                      :placeholder="
                      $t(
                        'AGENT_MGMT.AI_AGENT.ADD.FORM.NOTIFICATION.PHONE_PLACEHOLDER'
                      )
                    "
                      @input="validatePhone(div.phone)"
                    />
                  </label>
                </div>
                <div class="medium-3 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                    @click="addField(div, notificationPhone)"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#007ef3"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    />
                  </svg>

                  <!--          Remove Svg Icon-->
                  <svg
                    v-show="notificationPhone.length > 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                    @click="removeField(index, notificationPhone)"
                  >
                    >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#314155"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="medium-12 columns">
          <label>
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.ADVANCED.LABEL') }} </u>
          </label>
          <div class="">
            <div class="range-form">
              <input
                id="prompt_confidence"
                v-model="prompt_confidence"
                class="input-range-form"
                type="range"
                min="0"
                max="1"
                step="0.01"
              />
              <label class="range-label">
                {{
                  $t('AGENT_MGMT.AI_AGENT.ADD.FORM.ADVANCED.PROMPT_CONFIDENCE')
                }}: {{ prompt_confidence }}
              </label>
            </div>
            <span class="field-description">
            {{
                $t('AGENT_MGMT.AI_AGENT.ADD.FORM.ADVANCED.PROMPT_CONFIDENCE_NOTE')
              }}
          </span>
            <div class="range-form">
              <input
                id="prompt_top_p"
                v-model="prompt_top_p"
                class="input-range-form"
                type="range"
                min="0"
                max="1"
                step="0.01"
              />
              <label class="range-label">
                {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.ADVANCED.PROMPT_TOP') }}:
                {{ prompt_top_p }}
              </label>
            </div>
            <span class="field-description">
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.ADVANCED.PROMPT_TOP_NOTE') }}
          </span>
            <div class="range-form">
              <input
                id="prompt_temperature"
                v-model="prompt_temperature"
                class="input-range-form"
                type="range"
                min="0"
                max="2"
                step="0.01"
              />
              <label class="range-label">
                {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.ADVANCED.TEMPERATURE') }}:
                {{ prompt_temperature }}
              </label>
            </div>
            <span class="field-description">
            {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.ADVANCED.TEMPERATURE_NOTE') }}
          </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="medium-12">
          <label>
            <u> {{ $t('AGENT_MGMT.AI_AGENT.ADD.FORM.FUNCTIONS.LABEL') }} </u>
          </label>
          <div
            v-for="(div, index) in webhookFunctions"
            :key="`webhook-${index}`"
            class="row"
          >
            <div class="medium-2 columns">
              <label>
                <input
                  v-model.trim="div.name"
                  type="text"
                  :placeholder="$t('AGENT_MGMT.AI_AGENT.ADD.FORM.FUNCTIONS.NAME')"
                />
              </label>
            </div>
            <div class="medium-2 columns">
              <label>
                <input
                  v-model.trim="div.webhook_url"
                  type="text"
                  :placeholder="$t('AGENT_MGMT.AI_AGENT.ADD.FORM.FUNCTIONS.URL')"
                />
              </label>
            </div>
            <div class="medium-3 columns">
              <label>
                <input
                  v-model.trim="div.instructions"
                  type="text"
                  :placeholder="
                  $t('AGENT_MGMT.AI_AGENT.ADD.FORM.FUNCTIONS.INSTRUCTION')
                "
                />
              </label>
            </div>
            <div class="medium-2 columns">
              <label>
                <input
                  v-model.trim="div.username"
                  type="text"
                  :placeholder="
                  $t('AGENT_MGMT.AI_AGENT.ADD.FORM.FUNCTIONS.USERNAME')
                "
                />
              </label>
            </div>
            <div class="medium-2 columns">
              <label>
                <input
                  v-model.trim="div.password"
                  type="text"
                  :placeholder="
                  $t('AGENT_MGMT.AI_AGENT.ADD.FORM.FUNCTIONS.PASSWORD')
                "
                />
              </label>
            </div>

            <div class="medium-1 columns flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
                @click="addField(div, webhookFunctions)"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#007ef3"
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                />
              </svg>

              <!--          Remove Svg Icon-->
              <svg
                v-show="webhookFunctions.length > 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
                @click="removeField(index, webhookFunctions)"
              >
                >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#314155"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :loading="loading"
            :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
          />
          <button class="button clear" @click.prevent="onCancel">
            {{ $t('CONTACT_FORM.FORM.CANCEL') }}
          </button>
        </div>
      </div>
    </form>
    <a-i-agent-options-modal
      :show="showAIAssistanceModal"
      @close-modal="closeModal"
      @item-selected="openAIAssist"
    />
      <ai-agent-content-modal :content="aiPrompt" :hookid="hookid" :ai-option="aiOption" :show.sync="showContentModal" @close-content-modal="showAiContentModal = false" @apply-text="insertText" />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';


import adminMixin from '../../../../mixins/isAdmin';
import aiMixin from '../../../../mixins/aiMixin';
import { CMD_AI_ASSIST } from '../../../../routes/dashboard/commands/commandBarBusEvents';
import { buildHotKeys } from '../../../../../shared/helpers/KeyboardHelpers';
import uiSettingsMixin from '../../../../mixins/uiSettings';
import AIAgentOptionsModal from '../../../../components/widgets/modal/AIAgentOptionsModal.vue';
import AiAgentContentModal from '../../../../components/widgets/modal/AiAgentContentModal.vue';

import { required } from 'vuelidate/lib/validators';
import { validatePhoneForE164 } from '../../../../../shared/helpers/Validators';
import { mapGetters } from 'vuex';
import AIAssistanceButton from '../../../../components/widgets/AIAssistanceButton.vue';
import eventListenerMixins from '../../../../../shared/mixins/eventListenerMixins';
import AIAssistanceModal from '../../../../components/widgets/AIAssistanceModal.vue';
const JUSTSCHED_URL = `${window.chatwootConfig.calendsoLink}api/teams/event-type-info`;
const SCRAPING_URL = `${window.chatwootConfig.scrapingUrl}`;
const CREATE_AI_ASSISTANT_URL = `${window.chatwootConfig.aiAssistantUrl}`;
export default {
  components: { AIAssistanceModal, AIAssistanceButton, AIAgentOptionsModal, AiAgentContentModal },
  mixins: [alertMixin, eventListenerMixins, adminMixin, aiMixin, uiSettingsMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    crawlSiteUrl(newVal) {
      if (newVal === 'noCrawl') {
        this.siteUrl = '';
      }
    },
  },
  data() {
    return {
      hookid:'',
      showAIAssistanceModal: false,
      showAiContentModal: false,
      notevalue: false,
      aiOption: '',
      selectedFileName: null,
      numberRequired:false,
      emailRequired:false,
      personality: '',
      invalidUrl:false,
      inbox: '',
      name: '',
      template: '',
      companyName: '',
      phoneNumber: '',
      aiPrompt: '',
      training: '',
      trainingRequired: false,
      urlRequired: false,
      siteUrl: '',
      templateDetail: '',
      crawlSiteUrl: 'noCrawl',
      greeting: '',
      crawlSchedule: 'onlyOnce',
      notificationType: 'none',
      customLink: '',
      scheduleLink: '',
      dataName: '',
      dataPhone: '',
      dataEmail: '',
      notificationDomain: [{ domain: '' }],
      notificationPhone: [{ phone: '' }],
      customAttributes: [{ name: '' }],
      prompt_confidence: 0.6,
      prompt_top_p: 1.0,
      prompt_temperature: 1.0,
      webhookFunctions: [
        {
          name: '',
          webhook_url: '',
          username: '',
          password: '',
          instructions: '',
        },
      ],
      schedulingLinks: [],
      notificationEmail: false,
      notificationSMS: false,
      docs: [],
      websiteContent: '',
      webContent: {
        title:'',
        content:''
      },
      loading: false,
      domainValues: '',
      phoneValues: '',
      customAttributesValues: '',
      aiFile: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      inboxesList: 'inboxes/getInboxes',
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    showContentModal(){
      return this.showAiContentModal;
    }
  },
  validations: {
    name: {
      required,
    },
    inbox: {
      required,
    },

    greeting: {
      required,
    },
    companyName: {
      required,
    },

  },
  mounted() {
    this.eventData();
  },
  methods: {
    insertText(generatedContent){
      this.aiPrompt = generatedContent

    },
    openAIAssist(selectedItem) {
      this.hookid=this.hookId
      this.aiOption=selectedItem.key
      this.showAiContentModal = true;
    },
    eventData() {
      let vm = this;
      const encodedString = Buffer.from(this.currentUser.email).toString(
        'base64'
      );
      try {
        fetch(JUSTSCHED_URL + `?email=${encodedString}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        })
          .then(resp => resp.json())
          .then(event => {
            vm.schedulingLinks = event;
          });
      } catch (error) {
        throw new Error(error);
      }
    },
    getAIAgentObject() {
      let selectedInboxes = this.inbox?.map(x => ({
        id: x.id.toString(),
        name: x.name,
      }));

      if (this.notificationType === 'none') {
        this.notificationEmail = false;
        this.notificationSMS = false;
      } else if (this.notificationType === 'sms') {
        this.notificationSMS = true;
        this.notificationEmail=false;
      } else if (this.notificationType === 'email') {
        this.notificationEmail = true;
        this.notificationSMS=false;
      } else if (this.notificationType === 'emailSms') {
        this.notificationEmail = true;
        this.notificationSMS = true;
      }

      this.phoneValues = this.notificationPhone?.map(item => item.phone);
      this.domainValues = this.notificationDomain?.map(item => item.domain);
      this.customAttributesValues = this.customAttributes?.map(
        item => item.name
      );
      let collectionAttributes = [];
      if (this.dataName) {
        collectionAttributes.push('name');
      }
      if (this.dataEmail) {
        collectionAttributes.push('email');
      }
      if (this.dataPhone) {
        collectionAttributes.push('phone');
      }

      return {
        name: this.name,
        company_name: this.companyName,
        personality: this.personality,
        inboxes: selectedInboxes,
        template: this.template,
        prompt: this.aiPrompt,
        training: this.training,
        greeting_message: this.greeting,
        website_content: this.websiteContent.toString(),
        scheduling: this.crawlSiteUrl,
        scheduling_url: this.scheduleLink ? this.scheduleLink : this.customLink,
        schedule: this.crawlSchedule,
        website_url: this.siteUrl,
        documents: this.docs,
        template_detail: this.templateDetail,
        notifications: {
          email: {
            enabled: this.notificationEmail,
            email: this.domainValues,
          },
          sms: {
            enabled: this.notificationSMS,
            phone_number: this.phoneValues,
          },
        },
        data_collection: collectionAttributes,
        custom_attributes: this.customAttributesValues,
        advanced: {
          prompt_top_p: this.prompt_top_p,
          prompt_temperature: this.prompt_temperature,
          prompt_confidence: this.prompt_confidence,
        },
        functions: this.webhookFunctions,
        account_id: this.accountId.toString(),
      };
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAIAgent() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.urlRequired = false;
      this.invalidUrl = false;
      this.trainingRequired = false;
      this.loading = true;

      if (this.crawlSiteUrl != 'noCrawl' && (!this.siteUrl || this.siteUrl.trim() === ''))
      {
        this.urlRequired = true;
        this.loading = false;
        return;
      }

      if (this.siteUrl && this.crawlSiteUrl != 'noCrawl') {
        try {
          const scraping = await axios.post(SCRAPING_URL, {
            websiteUrl: this.siteUrl,
            crawl: this.crawlSiteUrl,
          });
          this.websiteContent = scraping?.data?.data;
          this.webContent = scraping?.data?.data;
        } catch (e) {
          this.showAlert(e.message);
          this.invalidUrl=true;
          this.loading=false;
          return;
        }
      }

      if (this.crawlSiteUrl == 'noCrawl' && (!this.training || this.training.trim() === '')) {
        this.trainingRequired = true;
        this.loading = false;
        return;
      }

      let agentObj = { ...this.getAIAgentObject() };

      if(this.notificationSMS && !this.notificationEmail && !this.phoneValues.every(value => value.trim() !== ''))
      {
        this.numberRequired=true;
        this.emailRequired=false;
        this.loading = false;
        return;
      }
      if (this.notificationEmail && !this.notificationSMS && !this.domainValues.every(value => value.trim() !== '')) {
        this.emailRequired=true;
        this.numberRequired=false;
        this.loading = false;
        return;
      }
      if (this.notificationEmail && this.notificationSMS && (!this.domainValues.every(value => value.trim() !== '') || !this.phoneValues.every(value => value.trim() !== '')) )
      {
        if(this.domainValues.every(value => value.trim() !== '') && !this.phoneValues.every(value => value.trim() !== ''))
        {
          this.emailRequired=false;
          this.numberRequired=true;
        }
        else if(this.phoneValues.every(value => value.trim() !== '') && !this.domainValues.every(value => value.trim() !== ''))
        {
          this.numberRequired=false;
          this.emailRequired=true;
        }
        else if(!this.phoneValues.every(value => value.trim() !== '') && !this.phoneValues.every(value => value.trim() !== ''))
        {
          this.numberRequired=true;
          this.emailRequired=true;
        }
        this.loading = false;
        return;
      }


      const aiAssistant = await axios.post(CREATE_AI_ASSISTANT_URL, agentObj);
      agentObj.assistant_id = aiAssistant?.data?.assistant_id;
      agentObj.file_attributes = aiAssistant?.data?.file_attributes;
      agentObj.notification_type = this.notificationType;
      agentObj.phoneValues = this.phoneValues;
      agentObj.emailValues = this.domainValues;
      agentObj.customAttributes = this.customAttributesValues;
      agentObj.collectionName = this.dataName ? this.dataName : false;
      agentObj.collectionEmail = this.dataEmail ? this.dataEmail : false;
      agentObj.collectionPhone = this.dataPhone ? this.dataPhone : false;
      agentObj.file_name = this.aiFile.name;
      agentObj.documents = [];
      agentObj.website_content = {
        title: this.webContent.title,
        content: this.webContent.content.contentText
      };

      try {
        await this.$store.dispatch('agents/createAIAgent', agentObj);
        this.loading = false;
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.$emit('cancel');
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const input = event.target;
      if (input.files.length > 0) {
        const file = input.files[0];
        this.aiFile = input.files[0];
        const reader = new FileReader();
        reader.onload = e => {
          const base64Content = e.target.result.split(',')[1];

          // Append the file information to your object
          const documentObject = {
            name: file.name,
            content: base64Content,
          };

          let documents = [];
          documents.push(documentObject);
          this.docs = documents;
          // Optionally, update the selected file name
          this.selectedFileName = file.name;
        };

        // Read the file as Data URL, which is Base64-encoded
        reader.readAsDataURL(file);
      } else {
        this.selectedFileName = null;
      }
    },
    closeModal(){
      this.showAIAssistanceModal= false;
    },
    validatePhone(phone) {
      if (phone && phone.length > 0) {
        return validatePhoneForE164(phone); // Assuming validatePhoneForE164 is a function to validate phone numbers
      } else {
        return true;
      }
    },
    addField(value, fieldType) {
      fieldType.push({ value: '' });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    onChangePersonality() {
      switch(this.personality) {
        case 'Informal':
          this.aiPrompt = 'You are an informal assistant that will ensure the customer is satisfied.';
          break;
        case 'Professional':
          this.aiPrompt = 'You are a professional assistant that will ensure the customer is satisfied.';
          break;
        case 'Witty':
          this.aiPrompt = 'You are a witty assistant who likes to make light of every situation but is very dedicated to helping people.';
          break;
        default:
          this.aiPrompt = '';
          break;
      }
    },
    onChangeTemplate() {
      switch(this.template) {
        case 'Customer Service':
          this.templateDetail = 'You are a customer service agent and your job is to help anyway you can.';
          break;
        default:
          this.templateDetail = '';
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}

.input-group-label {
  font-size: var(--font-size-small);
}

.prompt-top-mergin {
  margin-top: -11px;
}
.prompt-top {
  margin-top: 1rem;
}
.up-load-file {
  padding: 0.6rem;
  border: 1px solid #e0e6ed;
  font-weight: normal;
  line-height: 1.5;
  border-radius: 0.5rem;
  color: #3c4858;
}
.margin-crawl-bottom {
  margin-bottom: -14px;
}
.margin-bottom-greeting {
  margin-bottom: -4px;
}
.notification-first-bottom {
  margin-bottom: -7px;
}
.notification-last-bottom {
  margin-bottom: -11px;
}
.file-place {
  color: #a6b7cb;
}
.range-box {
  margin: 15px;
}
.range-form {
  display: flex;
  padding-right: 10px;
  grid-gap: 10px;
}
.input-range-form {
  width: 100%;
  height: 1.5rem;
  padding: 0;
}
#prompt-textarea {
  height: 150px;
}
.range-label {
  width: 50%;
}
.field-description {
  font-size: 12px;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.button-ai-assist {
  color: black;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  float: right;
}

.button-ai-assist .icon {
  margin-right: 8px;
}

.button-ai-assist:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

@tailwind components;
@layer components {
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .cta-btn {
    animation: gradient 5s ease infinite;
    border: 0;
  }

  .cta-btn-light {
    background: linear-gradient(
        255.98deg,
        rgba(161, 87, 246, 0.2) 15.83%,
        rgba(71, 145, 247, 0.2) 81.39%
    ),
    linear-gradient(0deg, #f2f5f8, #f2f5f8);
  }

  .cta-btn-dark {
    background: linear-gradient(
        255.98deg,
        rgba(161, 87, 246, 0.2) 15.83%,
        rgba(71, 145, 247, 0.2) 81.39%
    ),
    linear-gradient(0deg, #313538, #313538);
  }

  .cta-btn-light-hover {
    background: linear-gradient(
        255.98deg,
        rgba(161, 87, 246, 0.2) 15.83%,
        rgba(71, 145, 247, 0.2) 81.39%
    ),
    linear-gradient(0deg, #e3e5e7, #e3e5e7);
  }

  .cta-btn-dark-hover {
    background: linear-gradient(
        255.98deg,
        rgba(161, 87, 246, 0.2) 15.83%,
        rgba(71, 145, 247, 0.2) 81.39%
    ),
    linear-gradient(0deg, #202425, #202425);
  }

  /* Radar ping animation */
  @keyframes ping {
    75%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  .radar-ping-animation {
    animation: ping 1s ease infinite;
  }
}

</style>
