/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  getLimitInfo(id) {
      return axios.get(`${this.url}extra`)
  }

  removeLimits(data) {
    return axios.post(`${this.url}remove_extra`, data);
  }

}

export default new AccountAPI();
