import Vue from 'vue';
import Vuex from 'vuex';

import accounts from './modules/accounts';
import agents from './modules/agents';
import auth from './modules/auth';
import cannedResponse from './modules/cannedResponse';
import contactConversations from './modules/contactConversations';
// import contacts from './modules/contacts';
import contacts from './modules/contacts';
import contactLabels from './modules/contactLabels';
import notifications from './modules/notifications';
import conversationLabels from './modules/conversationLabels';
import conversationMetadata from './modules/conversationMetadata';
import conversationPage from './modules/conversationPage';
import conversations from './modules/conversations';
import conversationSearch from './modules/conversationSearch';
import conversationStats from './modules/conversationStats';
import conversationTypingStatus from './modules/conversationTypingStatus';
import signalwireCameraStatus from './modules/signalwireCameraStatus';
import signalwireCallCreated from './modules/signalwireCallCreated'
import globalConfig from 'shared/store/globalConfig';
import inboxes from './modules/inboxes';
import inboxMembers from './modules/inboxMembers';
import inboxAssignableAgents from './modules/inboxAssignableAgents';
import integrations from './modules/integrations';
import labels from './modules/labels';
import codes from './modules/codes';
import reports from './modules/reports';
import userNotificationSettings from './modules/userNotificationSettings';
import webhooks from './modules/webhooks';
import teams from './modules/teams';
import teamMembers from './modules/teamMembers';
import campaigns from './modules/campaigns';
import contactNotes from './modules/contactNotes';
import signalwireInboxCreation from "./modules/SignalwireInboxCreation";
import sipEndpoint from "./modules/sipEndpoint";
import missedCall from "./modules/missedCall";
import showNotificationAlert from "./modules/showNotificationAlert";
import dashboard from "./modules/dashboard";
import fileVault from "./modules/fileVault";
import draftMessages from './modules/draftMessages';
import csat from './modules/csat';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    accounts,
    agents,
    auth,
    cannedResponse,
    contactConversations,
    contacts,
    contactLabels,
    csat,
    notifications,
    conversationLabels,
    conversationMetadata,
    conversationPage,
    conversations,
    conversationSearch,
    conversationStats,
    conversationTypingStatus,
    signalwireCameraStatus,
    signalwireCallCreated,
    globalConfig,
    inboxes,
    inboxMembers,
    inboxAssignableAgents,
    integrations,
    labels,
    codes,
    reports,
    userNotificationSettings,
    webhooks,
    teams,
    teamMembers,
    signalwireInboxCreation,
    sipEndpoint,
    campaigns,
    contactNotes,
    missedCall,
    showNotificationAlert,
    dashboard,
    fileVault,
    draftMessages,
  },
});
