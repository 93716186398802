export const missedCallSMS  = {
  async updateMissedCallSMS(accountId, channelId, payload) {
      const response = axios.patch(`/api/v1/accounts/${accountId}/channels/signalwire_links/${channelId}`, {
          signalwire_link: {
              ...payload
          }
      },
          {
              headers: {
                  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
              }
          });
  }
}
