<template>
  <div class="columns small-9 small-offset-2">
    <div v-if="!hasLoginStarted" class="login-init full-height">
      <a href="#" @click="startLogin()">
        <img
            src="~dashboard/assets/images/channels/facebook_login.png"
            alt="Facebook-logo"
        />
      </a>
      <p>
        {{
          useInstallationName(
              $t('INBOX_MGMT.ADD.FB.REVIEW_HELP'),
              globalConfig.installationName
          )
        }}
      </p>
    </div>
    <div v-else>
      <loading-state v-if="showLoader" :message="emptyStateMessage" />
      <form v-if="!showLoader" class="row" @submit.prevent="createChannel()">
        <div class="medium-12 columns">
          <page-header
              :header-title="$t('INBOX_MGMT.ADD.DETAILS.TITLE')"
              :header-content="
              useInstallationName(
                $t('INBOX_MGMT.ADD.DETAILS.DESC'),
                globalConfig.installationName
              )
            "
          />
        </div>
        <div class="medium-12 columns">
          <div class="medium-12 columns">
            <div class="input-wrap" :class="{ error: $v.selectedPage.$error }">
              {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PAGE') }}
              <multiselect
                  v-model.trim="selectedPage"
                  :close-on-select="true"
                  :allow-empty="true"
                  :options="getSelectablePages"
                  track-by="id"
                  label="name"
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                  :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_A_VALUE')"
                  selected-label
                  @select="setPageName"
              />
              <span v-if="$v.selectedPage.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER') }}
              </span>
            </div>
          </div>
<!--          <div class="medium-12 columns">-->
<!--            <label :class="{ error: $v.pageName.$error }">-->
<!--              {{ $t('INBOX_MGMT.ADD.FB.INBOX_NAME') }}-->
<!--              <input-->
<!--                  v-model.trim="pageName"-->
<!--                  type="text"-->
<!--                  :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_NAME')"-->
<!--                  @input="$v.pageName.$touch"-->
<!--              />-->
<!--              <span v-if="$v.pageName.$error" class="message">-->
<!--                {{ $t('INBOX_MGMT.ADD.FB.ADD_NAME') }}-->
<!--              </span>-->
<!--            </label>-->
<!--          </div>-->
          <div class="medium-12 columns text-left">
            <input type="submit" value="Create Inbox" class="button" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
/* eslint-env browser */
/* global FB */
import { required } from 'vuelidate/lib/validators';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import { mapGetters } from 'vuex';
import ChannelApi from '../../../../../api/channels';
import PageHeader from '../../SettingsSubPageHeader';
import router from '../../../../index';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import accountMixin from '../../../../../mixins/account';
import ConversationApi from "../../../../../api/conversations";
import InboxesAPI from "../../../../../api/inboxes";

const FB_APP_ID = `${window.chatwootConfig.fbAppId}`;
export default {
  components: {
    LoadingState,
    PageHeader,
  },
  mixins: [globalConfigMixin, accountMixin],
  props: {
    inbox_name: {
      type: String,
      default: '',
    },
    accountType: {
      type: String,
      default: 'facebook',
    },
    start_date: {
      type: Date,
      default: '',
    },
    end_date: {
      type: Date,
      default: '',
    }
  },

  data() {
    return {
      isCreating: false,
      omniauth_token: '',
      user_access_token: '',
      channel: 'facebook',
      selectedPage: { name: null, id: null },
      pageName: '',
      pageList: [],
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      hasLoginStarted: false,
    };
  },

  validations: {
    pageName: {
      required,
    },

    selectedPage: {
      isEmpty() {
        return this.selectedPage !== null && !!this.selectedPage.name;
      },
    },
  },

  computed: {
    showLoader() {
      return !this.user_access_token || this.isCreating;
    },
    getSelectablePages() {
     return this.pageList;
    },
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
    }),
  },

  created() {
    this.initFB();
    this.loadFBsdk();
  },

  mounted() {
    this.initFB();
  },

  methods: {
    startLogin() {
      this.hasLoginStarted = true;
      this.tryFBlogin();
    },
    decodeBase64() {
      return atob(FB_APP_ID);
    },

    setPageName({ name }) {
      this.$v.selectedPage.$touch();
      this.pageName = name;
    },

    initChannelAuth(channel) {
      if (channel === 'facebook') {
        this.loadFBsdk();
      }
    },

    initFB() {
      if (window.fbSDKLoaded === undefined) {
        const fbId = this.decodeBase64()
        window.fbAsyncInit = () => {
          FB.init({
            appId: fbId,
            xfbml: true,
            version: 'v7.0',
            status: true,
          });
          window.fbSDKLoaded = true;
          FB.AppEvents.logPageView();
        };
      }
    },

    loadFBsdk() {
      ((d, s, id) => {
        let js;
        // eslint-disable-next-line
        const fjs = (js = d.getElementsByTagName(s)[0]);
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },

    tryFBlogin() {
      FB.login(
          response => {
            if (response.status === 'connected') {
              this.fetchPages(response.authResponse.accessToken);
            } else if (response.status === 'not_authorized') {
              // The person is logged into Facebook, but not your app.
              this.emptyStateMessage = this.$t(
                  'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
              );
            } else {
              // The person is not logged into Facebook, so we're not sure if
              // they are logged into this app or not.
              this.emptyStateMessage = this.$t(
                  'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
              );
            }
          },
          {
            scope: 'pages_read_user_content,pages_manage_engagement,pages_manage_metadata,pages_messaging',
          }
      );
    },

    async fetchPages(_token) {
      try {
        const response = await ChannelApi.fetchFacebookPages(
            _token,
            this.accountId,
            'review'
        );

        const { data: { data }, } = response;
        this.pageList = data.page_details;
        this.user_access_token = data.user_access_token;
      } catch (error) {
        // Ignore error
      }
    },

    channelParams() {
      return {
        user_access_token: this.user_access_token,
        page_access_token: this.selectedPage.access_token,
        page_id: this.selectedPage.id,
        inbox_name: this.inbox_name ? this.inbox_name : this.selectedPage.name,
        account_type: this.accountType,
        range: {
          start_date: this.start_date,
          end_date: this.end_date,
        }
      };
    },

    createChannel() {
      var vm = this;
      this.$v.$touch();
      let inbox = '';
      if (!this.$v.$error) {
        this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
        this.isCreating = true;
        this.$store.dispatch('inboxes/createReviewFBChannel', this.channelParams())
          .then(res => {
            inbox = res;
            vm.$store.dispatch('inboxes/fetchFBReviews', vm.channelParams())
              .then(async data => {
                await InboxesAPI.create_FB_review_conversation(inbox.id, data);
              })
              .then(data => {
                router.replace({
                  name: 'settings_inboxes_add_agents',
                  params: {page: 'new', inbox_id: inbox.id},
                });
              })
              .catch(() => {
                vm.isCreating = false;
              });
          })
      }
    },
  },
};
</script>
