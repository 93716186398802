<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-header">
        <woot-modal-header header-title="Call Summary" />
        <button type="button" class="close" @click.prevent="onClose">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="modal-content flex flex-col w-full">
        <div v-if="content" class="w-full">
          <h4 class="h4-text-font text-base mt-1 text-slate-700 dark:text-slate-100">
            Summary
          </h4>
          <p class="scrollable-content" v-dompurify-html="formatMessage(content, false)" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import messageFormatterMixin from '../../../../shared/mixins/messageFormatterMixin';
import aiMixin from '../../../mixins/aiMixin';
import OpenAPI from '../../../api/integrations/openapi';

export default {

  mixins: [aiMixin, messageFormatterMixin],
  props: {
    content: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-content-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-dialog {
  background: white;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 900px;
  max-width: 100%;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
}

.close {
  background: none;
  border: none;
  font-size: 4rem;
  color: #000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: background 0.3s, opacity 0.3s;
}

.close:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  opacity: 0.75;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.scrollable-content {
  max-height: calc(6 * 1.5em);
  overflow-y: auto;
  margin-top: 10px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-top: 20px;
}

.h4-text-font {
  font-size: 1.6rem !important;
}

.text-align {
  text-align: right;
}
</style>

