<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-header">
        <woot-modal-header header-title="Create Campaign" :header-content="message"/>
        <button type="button" class="close" @click.prevent="onClose">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="modal-content flex flex-col w-full" @submit.prevent="create">
        <div  class="modal-body">
          <div class="stats-container">
            <p>{{message}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.NAME')}}:</strong> {{payload.campaign_name}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.COUNT')}}:</strong> {{payload.leads_count}}</p>
            <p v-if="payload.automatic_pool"><strong>{{$t('CAMPAIGN.ADD.DETAILS.AREA_CODES')}}:</strong> {{payload.areaCodesCount}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.LENGTH')}}:</strong> {{payload.estimated_call_duration}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.BALANCE')}}:</strong> {{payload.account_balance_credits}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.COST')}}:</strong> {{payload.estimated_cost}}</p>
          </div>
        </div>

        <div class="modal-footer">
          <woot-button variant="clear" @click.prevent="onClose">
            {{$t('CAMPAIGN.ADD.DETAILS.CANCEL')}}
          </woot-button>
          <woot-button v-if="result == true">
            {{$t('CAMPAIGN.ADD.DETAILS.CREATE')}}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import messageFormatterMixin from '../../../../shared/mixins/messageFormatterMixin';
import AILoader from '../AILoader.vue';
import aiMixin from '../../../mixins/aiMixin';
import OpenAPI from '../../../api/integrations/openapi';
import { validatePhoneForE164 } from '../../../../shared/helpers/Validators';

export default {
  components: {
    AILoader,
  },
  mixins: [aiMixin, messageFormatterMixin],
  data() {
    return {};
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    campaignObj: {
      type: Object,
      default: () => {},
    },
    result: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      appIntegrations: 'integrations/getAppIntegrations',
      getAccount: 'accounts/getAccount',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close-add-ids-modal');
    },
    isValidCallerId(phone) {
      if (phone && phone.length > 0) {
        return validatePhoneForE164(phone);
      } else {
        return true;
      }
    },
    getCampaignDetails(){
      let campaignDetails = {
        title: this.payload.campaign_name,
        campaign_type: "outbound_calling",
        message: "This is calling campaign",
      };
      if(this.payload.automatic_pool == true)
      {
        campaignDetails.selected_type = 'Automatic_pool'
        return campaignDetails
      }
      if(this.payload.automatic_pool == false)
      {
        campaignDetails.selected_type = 'SignalWire_inbox'
        campaignDetails.inbox_id = this.payload.inbox_id
        return campaignDetails
      }
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async create() {
      try {
        const response = await axios.post('https://agentapi.conversate.us/v1/campaign/create_campaign', this.campaignObj ,{
          headers: {
            'Authentication': 'ec90c54bb8f24c7e9eekjahgs7bb8762jdf51'
          }
        });
        if(response.status == '200' && response.data.message == 'Campaign created successfully') {
          let campaignDetails = this.getCampaignDetails();
          campaignDetails.calling_campaign_id = response.data.campaign_id
          let leadsObj = {
            leads : this.payload.leads,
            account_id: this.payload.account_id,
            calling_campaign_id: campaignDetails.calling_campaign_id
          }
          if(this.payload.audianceType == 'select_CSV'){
            campaignDetails.leads = true;
            await this.$store.dispatch('contacts/createLeadsContact', leadsObj);
          }
            await this.$store.dispatch('campaigns/create', campaignDetails);
          this.showAlert("Campaign Created Successfully");
          this.onClose();
        }
        else {
          this.showAlert("Campaign creation Failed");
          this.onClose();
        }
      } catch (error) {
        this.showAlert("Campaign creation Failed");
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-dialog {
  background: white;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 900px;
  max-width: 100%;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
}

.close {
  background: none;
  border: none;
  font-size: 4rem;
  color: #000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: background 0.3s, opacity 0.3s;
}

.close:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  opacity: 0.75;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-top: 20px;
}

.stats-container {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 20px 0;
  text-align: left; /* Align text to the left */
  padding-left: 20px; /* Add margin from the left side */
}

.stats-container p {
  margin-bottom: 10px;
}

.stats-container strong {
  color: #333;
  font-weight: bold;
}


.woot-button {
  margin-left: 10px;
}
</style>
