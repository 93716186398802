<template>
    <div v-if="!isFetchingAppIntegrations">
        <div v-if="isAIIntegrationEnabled" class="relative ai-assist-button-container">
            <AIAssistanceCTAButton
                    @click="openAIAssist"
            />
            <fluent-icon icon="wand" size="14" class="ai-typing--icon ai-assist-button-child" />

            <woot-modal
                    :show.sync="showAIAssistanceModal"
                    :on-close="hideAIAssistanceModal"
            >
                <AIAssistanceModal
                        :ai-option="aiOption"
                        @apply-text="insertText"
                        @close="hideAIAssistanceModal"
                />
            </woot-modal>
        </div>
        <div v-else-if="shouldShowAIAssistCTAButtonForAdmin" class="relative">
            <AIAssistanceCTAButton @click="openAICta" />
            <woot-modal :show.sync="showAICtaModal" :on-close="hideAICtaModal">
                <AICTAModal @close="hideAICtaModal" />
            </woot-modal>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AICTAModal from './AICTAModal.vue';
import AIAssistanceModal from './AIAssistanceModal.vue';
import adminMixin from 'dashboard/mixins/aiMixin';
import aiMixin from 'dashboard/mixins/isAdmin';
import { CMD_AI_ASSIST } from 'dashboard/routes/dashboard/commands/commandBarBusEvents';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import { buildHotKeys } from 'shared/helpers/KeyboardHelpers';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import AIAssistanceCTAButton from './AIAssistanceCTAButton.vue';

export default {
    components: {
        AIAssistanceModal,
        AICTAModal,
        AIAssistanceCTAButton,
    },
    mixins: [aiMixin, eventListenerMixins, adminMixin, uiSettingsMixin],
    data: () => ({
        showAIAssistanceModal: false,
        showAICtaModal: false,
        aiOption: '',
        initialMessage: '',
    }),
    computed: {
        ...mapGetters({
            currentChat: 'getSelectedChat',
        }),
        isAICTAModalDismissed() {
            return this.uiSettings.is_open_ai_cta_modal_dismissed;
        },
        // Display a AI CTA button for admins if the AI integration has not been added yet and the AI assistance modal has not been dismissed.
        shouldShowAIAssistCTAButtonForAdmin() {
            return (
                this.isAdmin &&
                !this.isAIIntegrationEnabled &&
                !this.isAICTAModalDismissed
            );
        },
        // Display a AI CTA button for agents and other admins who have not yet opened the AI assistance modal.
        shouldShowAIAssistCTAButton() {
            return this.isAIIntegrationEnabled && !this.isAICTAModalDismissed;
        },
    },

    mounted() {
        bus.$on(CMD_AI_ASSIST, this.onAIAssist);
        this.initialMessage = this.draftMessage;
    },

    methods: {
        onKeyDownHandler(event) {
            const keyPattern = buildHotKeys(event);
            const shouldRevertTheContent =
                ['meta+z', 'ctrl+z'].includes(keyPattern) && !!this.initialMessage;
            if (shouldRevertTheContent) {
                this.$emit('replace-text', this.initialMessage);
                this.initialMessage = '';
            }
        },
        hideAIAssistanceModal() {
            this.recordAnalytics('DISMISS_AI_SUGGESTION', {
                aiOption: this.aiOption,
            });
            this.initialMessage = '';
            this.showAIAssistanceModal = false;
        },
        openAIAssist() {
            // Dismiss the CTA modal if it is not dismissed
            if (!this.isAICTAModalDismissed) {
                this.updateUISettings({
                    is_open_ai_cta_modal_dismissed: true,
                });
            }
            this.initialMessage = this.draftMessage;
            const ninja = document.querySelector('ninja-keys');
            ninja.open({ parent: 'ai_assist' });
        },
        hideAICtaModal() {
            this.showAICtaModal = false;
        },
        openAICta() {
            this.showAICtaModal = true;
        },
        onAIAssist(option) {
            this.aiOption = option;
            this.showAIAssistanceModal = true;
        },
        insertText(message) {
            this.$emit('replace-text', message);
        },
    },
};
</script>
<style scoped>
.ai-typing--wrap {
    display: flex;
    align-items: center;
    gap: 4px;

    .ai-typing--icon {
        color: var(--v-500);
    }
}
.ai-assist-button-container{
    position: relative;
}
.ai-assist-button-child{
    position: absolute;
    top: 8.5px;
    left: 6px;
}
</style>
