import { frontendURL} from "../../helper/URLHelper";
import Event from "./Event";
import CustomerVaultView from "./CustomerVaultView";

export default {
  routes: [
    {
      path: frontendURL('event/:id'),
      name: 'event',
      component: Event,
    },
    {
      path: frontendURL('vault/'),
      name: 'vault',
      component: CustomerVaultView,
    },
  ],
};
