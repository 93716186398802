<template>
    <woot-modal :show.sync="show" :on-close="onCancel" modal-type="right-aligned">
        <div class="column content-box">
            <woot-modal-header
                    :header-title="$t('FOLDER_MGMT.LOGS.TITLE')"
                    :class="'background_header'"
            />
            <div class="row log_border" v-for="log in getVaultLogs" :key="log.id">
              <div class="columns medium-10 medium-offset-1">
                  <label class="label_line_height">
                      {{ log.description }}
                  </label>
              </div>
            </div>
        </div>
    </woot-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "VaultLogs",
    computed: {
        ...mapGetters({
            getVaultLogs: 'getVaultLogs',
        }),
    },
    data() {
        return {
          show: true,
        }
    },
    props: {
        onClose: {
            type: Function,
            required: true,
        },
    },
   async mounted() {
        await this.$store.dispatch('getLogs')
   },
   methods: {
        onCancel() {
            this.onClose();
        },
   }
}
</script>

<style scoped>
.log_border {
    border-top: 1px solid #e0e6ed;
    margin-top: -5px;
}
.background_header {
    background: #f4f6fb;
}
.label_line_height {
    line-height: 33px;
}
</style>
